export const isHigherOrEqualSemanticVersion = (
  version1,
  version2 = '0.0.0'
) => {
  if (version1 === version2) {
    return true;
  }
  const version1Parts = version1.split('.');
  const version2Parts = version2.split('.');

  for (let i = 0; i < version1Parts.length; i++) {
    if (version1Parts[i] > version2Parts[i]) {
      return true;
    }
  }

  return false;
};
