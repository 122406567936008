import React, { useState } from 'react';
import {
  I18nProvider,
  useI18nContext,
  Banner,
  InfoBanner,
  Button,
} from '@procore/core-react';
import { trackEvent } from '@/react/shared/analytics';
import i18n from './i18n';
import {
  getBannerDismissed,
  getBeta,
  getForcedIntoBeta,
  includesBannerDismissed,
  setBannerDismissed,
  setBeta,
} from '@/utils/localStorageUtil';

const OptInBanner = () => {
  const I18n = useI18nContext();
  const [visible, setVisible] = useState(
    !includesBannerDismissed()
  );

  if (visible && getForcedIntoBeta()) {
    return (
      <InfoBanner>
        <Banner.Content data-testid="forced-into-beta-banner">
          <Banner.Title>
            {I18n.t('forcedIntoExperienceBanner.title')}
          </Banner.Title>

          <Banner.Body>
            <div
              dangerouslySetInnerHTML={{
                __html: I18n.t(
                  'forcedIntoExperienceBanner.body', {
                    supportEmail: `<a href="mailto:apisupport@procore.com">apisupport@procore.com</a>`
                  }
                )
              }}
            />
          </Banner.Body>
        </Banner.Content>

        <Banner.Dismiss
          onClick={() => {
            setBannerDismissed(true);
            trackEvent('developers.apps.form_based_manifests.opted_in', {});
            setVisible(false);
          }}
        />
      </InfoBanner>
    )
  } else if (visible && getBeta()) {
    return (
      <InfoBanner>
        <Banner.Content>
          <Banner.Title>
            {I18n.t('optInAcceptedBanner.title')}
          </Banner.Title>

          <Banner.Body>
            {I18n.t('optInAcceptedBanner.body')}
          </Banner.Body>
        </Banner.Content>

        <Banner.Dismiss
          onClick={() => {
            setBannerDismissed(true);
            trackEvent('developers.apps.form_based_manifests.opted_in', {});
            setVisible(false);
          }}
        />
      </InfoBanner>
    );
  } else if (visible && !getBeta()) {
    return (
      <InfoBanner>
        <Banner.Content>
          <Banner.Title>
            {I18n.t('optInDeclinedBanner.title')}
          </Banner.Title>

          <Banner.Body>
            {I18n.t('optInDeclinedBanner.body')}
            <a href="/documentation/building-apps-define-manifest" target="_blank">
              {I18n.t('optInDeclinedBanner.body2')}
            </a>
          </Banner.Body>
        </Banner.Content>

        <Banner.Action>
          <Button
            onClick={() => {
              setBeta(true);
              window.location.reload();
            }}
          >
            {I18n.t('optInDeclinedBanner.button')}
          </Button>
        </Banner.Action>

        <Banner.Dismiss
          onClick={() => {
            setBannerDismissed(true);
            setVisible(false);
          }}
        />
      </InfoBanner>
    );
  } else {
    return (<></>);
  }
}

const Container =  () => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <OptInBanner />
  </I18nProvider>
)

export default Container;
