import axios from 'axios';
import Qs from 'qs';
import { getHeaders, PATCH_HEADERS, POST_HEADERS } from '@/utils/apiUtil';
import { MANIFEST_TYPES } from '../../shared/constants';

const WISTIA_UPLOAD_URL = 'https://upload.wistia.com';

const api = (wistiaApiKey, wistiaMarketplaceProjectId) => ({
  uploadWistiaVideo(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('api_password', wistiaApiKey);
    data.append('project_id', wistiaMarketplaceProjectId);
    return axios.post(WISTIA_UPLOAD_URL, data, getHeaders());
  },

  getAppSecret(clientID) {
    return axios.get(`/api/v1/oauth_applications/${clientID}`, {
      headers: getHeaders(),
      params: { id: clientID },
    });
  },

  resetAppSecret(uid) {
    return axios.post(`/api/v1/oauth_applications/${uid}/reset_secret`, {
      headers: getHeaders(),
    });
  },

  resetSandboxAppSecret(uid) {
    return axios.post(`/api/v1/sandboxes/${uid}/reset_secret`, {
      headers: getHeaders(),
    });
  },

  fetchDeveloperApp(devAppId) {
    return axios.get(`/api/v1/developer_apps/${devAppId}`, {
      headers: getHeaders(),
      params: { developer_app_id: devAppId },
    });
  },

  updateDeveloperApp(devAppId, data) {
    return axios.patch(
      `/api/v1/developer_apps/${devAppId}`,
      data,
      getHeaders(PATCH_HEADERS)
    );
  },

  updateAppVersion(appVersion) {
    return axios.patch(
      `/api/v1/app_versions/${appVersion.id}`,
      {
        app_version: appVersion,
      },
      getHeaders(PATCH_HEADERS)
    );
  },

  fetchPermissions(devAppId) {
    return axios.get('/api/v1/developer_app_permissions', {
      headers: getHeaders(),
      params: { developer_app_id: devAppId },
    });
  },

  postFeatureServiceJob(featureServiceKickoffPath) {
    return axios.request(featureServiceKickoffPath, {
      method: 'post',
      data: {},
      headers: getHeaders(POST_HEADERS),
    });
  },

  generateFakeSandbox(generateFakeSandboxPath) {
    return axios.request(generateFakeSandboxPath, {
      method: 'post',
      data: {},
      headers: getHeaders(POST_HEADERS),
    });
  },

  updateSandbox(sandboxId, data) {
    return axios.patch(
      `/api/v1/sandboxes/${sandboxId}`,
      data,
      getHeaders(PATCH_HEADERS)
    );
  },

  createMarketplaceApp(developerAppId, data) {
    return axios.post(
      `/api/v1/developer_apps/${developerAppId}/submissions`,
      data,
      getHeaders(POST_HEADERS)
    );
  },

  updateMarketplaceApp(marketplaceAppId, payload) {
    return axios.patch(
      `/api/v1/submissions/${marketplaceAppId}`,
      payload,
      getHeaders(PATCH_HEADERS)
    );
  },

  getManifests(appID, appType) {
    const params = {
      'filters[developer_app_id]': appID,
    };

    if (appType === MANIFEST_TYPES.PRODUCTION) {
      params['filters[versionable_type]'] = 'ProductionEnvironment';
    } else if (appType === MANIFEST_TYPES.SANDBOX) {
      params['filters[versionable_type]'] = 'Sandbox';
    }

    return axios.request({
      url: '/api/v1/manifests',
      method: 'get',
      paramsSerializer: Qs.stringify,
      params,
    });
  },

  createManifest(devAppID, appType, manifest, semanticVersion) {
    return axios.request({
      url: '/api/v1/app_versions',
      method: 'post',
      data: {
        app_version: {
          developer_app_id: devAppID,
          environment_type: appType,
          manifest: {
            content: manifest,
          },
          semantic_version: semanticVersion,
        },
      },
      paramsSerializer: Qs.stringify,
    });
  },

  promoteManifest(appVersionId, releaseNotes) {
    return axios.request({
      url: '/api/v1/app_version_promotions',
      method: 'post',
      data: {
        app_version_promotion: {
          source_app_version_id: appVersionId,
          changelog: releaseNotes ||  null,
        },
      },
    });
  },

  destroyDeveloperApp(id) {
    return axios.request({
      url: `/api/v1/developer_apps/${id}`,
      method: 'delete',
    });
  },
});

export default api;
