import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { buildCountryList } from '@/global_helpers/getCountries';
import { MultiSelect } from '@procore/core-react';

const MultiCountrySelect = ({
  name,
  label,
  I18n,
  onChange,
  isDisabled,
  FormLabel,
  countries: baseCountries,
  field,
  errors
}) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryMap, setCountryMap] = useState({});

  useEffect(() => {
    const loadCountries = async () => {
      const countryList = await buildCountryList(baseCountries, I18n.locale);
      setCountries(
        countryList.map(({ id, name }) => {
          return { id, label: name };
        })
      );
      setCountryMap(
        countryList.reduce((acc, country) => {
          acc[country.id] = country;
          return acc;
        }, {})
      );
    };
    loadCountries();
  }, [I18n.locale, baseCountries]);

  useEffect(() => {
    if (Object.keys(countryMap).length === 0) return;
    setSelectedCountries(
      field?.map((country) => {
        return { id: country.id, label: countryMap[country.id].name };
      })
    );
  }, [field, countryMap]);

  const handleMultiSelectChange = (newSelectedCountries) => {
    setSelectedCountries(newSelectedCountries);
    onChange(newSelectedCountries.map(({ id }) => id));
  };

  return (
    <Form.FieldWrapper
      fieldId={`multi-country-select-${name}`}
      required
      label={
        <FormLabel
          changeFields={[name]}
          contentI18nTag={label}
          showInfo={false}
        />
      }
      errors={errors}
    >
      <MultiSelect
        disabled={isDisabled}
        block
        onChange={handleMultiSelectChange}
        options={countries}
        value={selectedCountries}
        qa={{
          option: (option) => `multi-option-${option.id}`,
          token: (option) => `multi-token-${option.id}`,
          tokenClear: (option) => `multi-token-clear-${option.id}`,
        }}
        error={!!errors?.length}
      />
    </Form.FieldWrapper>
  );
};

MultiCountrySelect.propTypes = {
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  I18n: PT.shape({
    locale: PT.string,
  }).isRequired,
  onChange: PT.func.isRequired,
  isDisabled: PT.bool,
  FormLabel: PT.elementType.isRequired,
};

export default MultiCountrySelect;
