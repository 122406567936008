import React from 'react';
import { Button, Modal } from '@procore/core-react';

class MarketplaceWithdrawConfirmationModal extends React.Component {
  render() {
    const { open, hide, I18n, onWithdraw } = this.props;

    return (
      <Modal open={open} onClickOverlay={hide}>
        <Modal.Header onClose={hide}>
          {I18n.t('modals.marketplaceWithdrawConfirmation.title')}
        </Modal.Header>

        <Modal.Body>
          {I18n.t('modals.marketplaceWithdrawConfirmation.body')}
        </Modal.Body>

        <Modal.Footer>
          <Modal.FooterButtons>
            <Button
              variant="tertiary"
              onClick={hide}
              data-qa="marketplace-withdraw-confirmation-back"
            >
              {I18n.t('modals.marketplaceWithdrawConfirmation.cancel')}
            </Button>

            <Button
              variant="primary"
              onClick={onWithdraw}
              data-qa="marketplace-withdraw-confirmation-submit"
            >
              {I18n.t('modals.marketplaceWithdrawConfirmation.confirm')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MarketplaceWithdrawConfirmationModal;
