const fixToTop = {
  toggle: (toggleEl, triggerEl, win = window) => {
    if (!toggleEl) {
      return;
    }
    const $toggleEl = $(toggleEl),
      $triggerEl = $(triggerEl) || $(toggleEl);

    const windowSt = $(win).scrollTop();
    const elOt = $triggerEl.offset().top;
    const isPositionFixed = $toggleEl.css('position') === 'fixed';

    if (windowSt > elOt && !isPositionFixed) {
      const height = $toggleEl.height();
      $toggleEl.css({ position: 'fixed', top: '0' });
      $toggleEl.parent().css({ 'padding-bottom': `${height}px` });
    } else if (windowSt <= elOt && isPositionFixed) {
      $toggleEl.css({ position: 'static' });
      $toggleEl.parent().css({ 'padding-bottom': '0' });
    }
  },
};

module.exports = fixToTop;
