import axios from 'axios';
import Qs from 'qs';

const api = () => ({
  validateManifest: (devAppId, envType, manifest) =>
    axios.request({
      url: '/api/v1/manifest_validations',
      method: 'post',
      data: {
        manifest: {
          content: manifest,
        },
      },
      params: {
        developer_app_id: devAppId,
        environment_type: envType,
      },
      paramsSerializer: Qs.stringify,
    }),
});

export default api;
