import React, { useState } from 'react';
import PT from 'prop-types';

import {
  Button,
  H1,
  DetailPage,
  Tearsheet,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import { useToastAlertContext } from '@procore/toast-alert';

import { Duplicate } from '@procore/core-icons';

import PrettyJSONViewer from '../../../shared/PrettyJSONViewer';

const TOAST_TIMEOUT = 2000;

const ViewManifestModal = ({ version, manifest, isOpen, close }) => {
  const I18n = useI18nContext();

  const { showToast, hideAllToasts } = useToastAlertContext();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(manifest);
    showToast.success(I18n.t('modals.viewManifest.successfulCopy'));
    setTimeout(hideAllToasts, TOAST_TIMEOUT)
  };

  return (
    <>
      <div
        className="react-toast-container"
      />
      <Tearsheet
        open={isOpen}
        onClose={close}
      >
        <DetailPage width="xl" data-testid="view-manifest-modal">
          <DetailPage.Main>
            <DetailPage.Header onClose={close}>
              <DetailPage.Title>
                <H1>
                  {I18n.t(
                    'modals.viewManifest.manifest',
                    { version }
                  )}
                </H1>
              </DetailPage.Title>
            </DetailPage.Header>

            <DetailPage.Body>
              <DetailPage.Card>
                <DetailPage.Section>
                  <Typography intent="body">
                    {I18n.t('modals.viewManifest.copyDetails')}
                  </Typography>
                </DetailPage.Section>
                <DetailPage.Section>
                  <PrettyJSONViewer
                    data-qa="manifest-json-viewer"
                    json={manifest}
                  />
                </DetailPage.Section>
              </DetailPage.Card>
            </DetailPage.Body>

            <DetailPage.Footer
              style={{position: 'absolute', bottom: '0px', width: '100%'}}
            >
              <Button
                variant="primary"
                data-qa="manifest-modal-copy-button"
                data-testid="manifest-modal-copy-button"
                style={{ marginLeft: 'auto' }}
                onClick={copyToClipboard}
              >
                <Duplicate />
                {I18n.t('modals.viewManifest.copy')}
              </Button>
            </DetailPage.Footer>
          </DetailPage.Main>
        </DetailPage>
      </Tearsheet>
    </>
  );
}

ViewManifestModal.propTypes = {
  close: PT.func.isRequired,
  isOpen: PT.bool.isRequired,
  manifest: PT.string.isRequired,
  version: PT.string.isRequired,
};

export { ViewManifestModal };
