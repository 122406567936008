/* eslint-disable react/prop-types */
/* eslint-disable react/no-access-state-in-setstate */

import React from 'react';
import PT from 'prop-types';
import { assocPath, omit } from 'ramda';
import {
  MANIFEST_TABS,
  MANIFEST_TYPES,
  MARKETPLACE_APPROVAL_STATES,
} from '@/react/shared/constants';
import { serialize } from 'object-to-formdata';
import { trackEvent } from '@/react/shared/analytics';
import api from './api';
import I18n from './i18n';
import { TABS } from './constants';

const StateContainer = (WrappedComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props);

      this.api = api(props.wistiaApiKey, props.wistiaMarketplaceProjectId);

      this.state = {
        developerAppsIndexPath: props.developerAppsIndexPath,
        developerAppsShowPath: props.developerAppsShowPath,
        marketplaceDraftListingPath: props.marketplaceDraftListingPath,
        app: {
          owner: {},
          sandbox: {},
          contacts: [],
          collaborators: [],
          pictures: [],
          installations: [],
        },
        appListingName: props.appListingName,
        manifests: Object.values(MANIFEST_TYPES).reduce((acc, type) => {
          acc[type] = [];
          return acc;
        }, {}),
        pagination: {
          pageNumber: 1,
          pageSize: 100,
          total: 0,
          totalPages: 1,
        },
        loadingMap: {
          global: true,
          manifests: {
            ...Object.values(MANIFEST_TYPES).reduce((acc, type) => {
              acc[type] = false;
              return acc;
            }, {}),
          },
        },
        error: {},
        newOwner: '',
        tab: TABS.GENERAL,
        manifestTab: MANIFEST_TABS.SANDBOX,
        unsavedMarketplaceChanges: false,
        marketplaceListingApplicationEnabled: props.marketplaceListingApplicationEnabled,
      };
    }

    componentDidMount() {
      this.api
        .get(this.props.appID)
        .then((resp) => {
          this.setState({
            app: resp.data,
            ...this._newLoadingMap('global', false),
          });

          this.fetchManifests(this.props.appID, MANIFEST_TYPES.SANDBOX);
          this.fetchManifests(this.props.appID, MANIFEST_TYPES.PRODUCTION);
        })
        .catch((err) => {
          this.onError('load', err);
        });
    }

    componentDidUpdate(prevProps) {
      if (this.props.appUID && prevProps.appUID !== this.props.appUID) {
        this.fetchManifests(this.props.appUID, MANIFEST_TYPES.PRODUCTION);
      }
    }

    _newLoadingMap = (key, isLoading) => ({
      loadingMap: {
        ...this.state.loadingMap,
        [key]: !!isLoading,
      },
    });

    handleSave = () => {
      this.setState(this._newLoadingMap('global', true));

      this.api
        .updateApp(this.state.app)
        .then((resp) => {
          this.setToast('App Updated!', 'success');
          this.setState({
            app: resp.data,
            error: {},
            ...this._newLoadingMap('global', false),
          });
        })
        .catch((err) => {
          this.onError('save', err);
        });
    };

    handleAppChange = (field, value) => {
      this.setState({
        app: { ...this.state.app, [field]: value },
      });
    };

    handleSandboxChange = (field, value) => {
      this.setState({
        app: {
          ...this.state.app,
          sandbox: { ...this.state.app.sandbox, [field]: value },
        },
      });
    };

    handleUndelete = () => {
      this.setState(this._newLoadingMap('global', true));

      this.api
        .undeleteApp(this.state.app)
        .then((resp) => {
          this.setToast(I18n.t('en.messages.appUndeleted'), 'success');
          this.setState({
            app: resp.data,
            error: {},
            ...this._newLoadingMap('global', false),
          });
        })
        .catch((err) => {
          this.onError('save', err);
        });
    };

    onMarketplaceChange = () => {
      this.setState({ unsavedMarketplaceChanges: true });
    };

    handleCreateOrUpdateMarketplaceApp = (marketplaceApp) => {
      const formattedForFormData = (items) => {
        const obj = {};
        items?.forEach((item, index) => {
          obj[index] = item;
        });
        return obj;
      };

      const formData = serialize(
        {
          submission: {
            ...marketplaceApp,
            ...{
              pictures_attributes: formattedForFormData(
                marketplaceApp.pictures
              ),
              videos_attributes: formattedForFormData(marketplaceApp.videos),
              showcase_video_attributes: marketplaceApp.showcase_video,
              security_questionnaire_document_attributes: 
                marketplaceApp.security_questionnaire_document
            },
          },
        },
        { allowEmptyArrays: true }
      );
      if (this.state.app.unpublished_app) {
        this.api
          .updateMarketplaceApp(this.state.app.unpublished_app.id, formData)
          .then((response) => {
            this.onMarketplaceUpdate(response.data);
          })
          .catch((err) => {
            this.onError('submission', err);
          });
      } else {
        this.api
          .createMarketplaceApp(this.state.app.id, formData)
          .then((response) => {
            this.onMarketplaceUpdate(response.data);
          })
          .catch((err) => {
            this.onError('submission', err);
          });
      }
    };

    onMarketplaceUpdate = (marketplaceApp) => {
      const updatedApp = {
        ...this.state.app,
      };

      if (
        marketplaceApp.approval_state === MARKETPLACE_APPROVAL_STATES.APPROVED
      ) {
        updatedApp.published_app = marketplaceApp;
        updatedApp.unpublished_app = null;
      } else {
        updatedApp.unpublished_app = marketplaceApp;
        updatedApp.published_app = null;
      }

      this.setState({
        app: updatedApp,
        unsavedMarketplaceChanges: false,
      });
    };

    onError = (key, error) => {
      this.setState({
        loadingMap: { manifests: {} },
        error: { [key]: error },
      });
    };

    handleClearError = (errorName) => {
      this.setState((prevState) => {
        omit(errorName, prevState.error);
      });
    };

    setToast = (message, variant) => {
      if (!this.props.showToast[variant]) {
        console.error(`Invalid toast variant: ${variant}`);
        return;
      }
      this.props.showToast[variant](message);
    };

    setNewOwner = (owner) => {
      this.setState({
        newOwner: owner,
        app: { ...this.state.app, owner_id: owner.id },
      });
    };

    switchTab = (tab) => {
      this.setState({ tab });
    };

    switchManifestTab = (manifestTab) => {
      this.setState({ manifestTab });
    };

    handleDropLogo = (files) => {
      this.api.attachLogo(this.state.app, files[0]).then((resp) => {
        this.setState({
          ...this._newLoadingMap('global', false),
          app: {
            ...this.state.app,
            thumbnail: files[0],
            thumbnail_url: resp.data.thumbnail_url,
          },
        });

        this.setToast(I18n.t('en.messages.logoUploaded'), 'success');
      });
    };

    handleRemoveLogo = () => {
      this.api.removeLogo(this.state.app).then(() => {
        this.setState({
          app: { ...this.state.app, thumbnail_url: null },
        });
        this.setToast(I18n.t('en.messages.logoRemoved'), 'success');
      });
    };

    handleCreateManifest =
      (devAppID, appType) => (manifest, semanticVersion) => {
        this.setState({
          loadingMap: assocPath(
            ['manifests', appType],
            true,
            this.state.loadingMap
          ),
        });

        this.api
          .createManifest(devAppID, appType, manifest, semanticVersion)
          .then((resp) => {
            this.setState({
              manifests: {
                ...this.state.manifests,
                [appType]: [
                  ...this.state.manifests[appType],
                  { ...resp.data.manifest, promotable: resp.data.promotable },
                ],
              },
              loadingMap: assocPath(
                ['manifests', appType],
                false,
                this.state.loadingMap
              ),
            });
          })
          .catch((err) => {
            this.onError('manifests', err);
          });
      };

    handlePromoteManifest = () => (appVersionId) => {
      this.setState({
        loadingMap: assocPath(
          ['manifests', MANIFEST_TYPES.SANDBOX],
          true,
          this.state.loadingMap
        ),
      });

      this.api
        .promoteManifest(appVersionId)
        .then(() => {
          this.fetchManifests(this.props.appID, MANIFEST_TYPES.SANDBOX);
          this.fetchManifests(this.props.appID, MANIFEST_TYPES.PRODUCTION);

          this.setState({
            loadingMap: assocPath(
              ['manifests', MANIFEST_TYPES.SANDBOX],
              false,
              this.state.loadingMap
            ),
            manifestTab: MANIFEST_TABS.PRODUCTION,
          });
        })
        .catch((err) => {
          this.onError('manifests', err);
        });
    };

    handleUpdateAppVersion = () => (appVersion) => {
      this.setState({
        loadingMap: {
          manifests: {
            ...this.state.loadingMap.manifests,
            [MANIFEST_TYPES.PRODUCTION]: true,
          },
        },
      });

      this.api
        .updateAppVersion(appVersion)
        .then(() => {
          this.fetchManifests(this.props.appID, MANIFEST_TYPES.SANDBOX);
          this.fetchManifests(this.props.appID, MANIFEST_TYPES.PRODUCTION);

          this.setState({
            loadingMap: {
              manifests: {
                ...this.state.loadingMap.manifests,
                [MANIFEST_TYPES.PRODUCTION]: false,
              },
            },
            manifestTab: MANIFEST_TABS.PRODUCTION,
          });
        })
        .catch((err) => {
          this.onError('manifests', err);
        });
    };

    handleTrackEvent = (key, params) => {
      trackEvent(key, {
        developer_app_id: this.state.app.id,
        app_name: this.state.app.name,
        ...params,
      });
    };

    fetchManifests(devAppId, appType) {
      this.setState({
        loadingMap: assocPath(
          ['manifests', appType],
          true,
          this.state.loadingMap
        ),
      });

      this.api
        .getManifests(devAppId, appType)
        .then((resp) => {
          this.setState({
            manifests: {
              ...this.state.manifests,
              [appType]: resp.data,
            },
            loadingMap: assocPath(
              ['manifests', appType],
              false,
              this.state.loadingMap
            ),
          });
        })
        .catch((err) => {
          this.onError('manifests', err);
        });
    }

    render() {
      return (
        <WrappedComponent
          {...{
            adminDeveloperAppShowStore: {
              state: {
                developerAppsIndexPath: this.developerAppsIndexPath,
                developerAppsShowPath: this.developerAppsShowPath,
                marketplaceDraftListingPath: this.marketplaceDraftListingPath,
                appListingName: this.appListingName,
                api: this.api,
                ...this.state,
              },
              handlers: {
                appChange: this.handleAppChange,
                clearError: this.handleClearError,
                setError: this.onError,
                sandboxChange: this.handleSandboxChange,
                dropLogo: this.handleDropLogo,
                setToast: this.setToast,
                removeLogo: this.handleRemoveLogo,
                save: this.handleSave,
                undelete: this.handleUndelete,
                createManifest: this.handleCreateManifest,
                promoteManifest: this.handlePromoteManifest,
                updateAppVersion: this.handleUpdateAppVersion,
                setNewOwner: this.setNewOwner,
                switchTab: this.switchTab,
                switchManifestTab: this.switchManifestTab,
                handleCreateOrUpdateMarketplaceApp:
                  this.handleCreateOrUpdateMarketplaceApp,
                onMarketplaceUpdate: this.onMarketplaceUpdate,
                onMarketplaceChange: this.onMarketplaceChange,
                trackEvent: this.handleTrackEvent,
              },
            },
          }}
          {...this.props}
        />
      );
    }
  };

export const AdminDeveloperAppShowStorePT = PT.shape({
  handlers: PT.shape({
    appChange: PT.func.isRequired,
    clearError: PT.func.isRequired,
    setError: PT.func.isRequired,
    sandboxChange: PT.func.isRequired,
    dropLogo: PT.func.isRequired,
    removeLogo: PT.func.isRequired,
    save: PT.func.isRequired,
    undelete: PT.func.isRequired,
    createManifest: PT.func.isRequired,
    promoteManifest: PT.func.isRequired,
    updateAppVersion: PT.func.isRequired,
    setNewOwner: PT.func.isRequired,
    setToast: PT.func.isRequired,
    switchTab: PT.func.isRequired,
    switchManifestTab: PT.func.isRequired,
    onMarketplaceUpdate: PT.func.isRequired,
    onMarketplaceChange: PT.func.isRequired,
  }),
  state: PT.shape({
    developerAppsIndexPath: PT.string.isRequired,
    developerAppsShowPath: PT.string.isRequired,
    marketplaceDraftListingPath: PT.string.isRequired,
    appListingName: PT.string,
    api: PT.shape({}).isRequired,
    app: PT.shape({}).isRequired,
    manifests: PT.shape({}).isRequired,
    error: PT.shape({}),
    loadingMap: PT.shape({}).isRequired,
    newOwner: PT.string.isRequired,
    tab: PT.string.isRequired,
    manifestTab: PT.string.isRequired,
    pagination: PT.shape({}).isRequired,
  }),
});

export default StateContainer;
