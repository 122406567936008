import React from 'react';
import { Button, Modal } from '@procore/core-react';
import styled from 'styled-components';

const StyleResetDiv = styled.div`
  & > * {
    margin: 0;
    line-height: 1rem;
  }
`;

class AdminMessageModal extends React.Component {
  render() {
    const { isVisible, hide, message, I18n } = this.props;

    return (
      <Modal open={isVisible} onClickOverlay={hide}>
        <Modal.Header onClose={hide}>
          {I18n.t('modals.adminMessage.title')}
        </Modal.Header>
        <Modal.Body>
          <StyleResetDiv
            className="pre-wrap"
            data-qa="admin-message"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Button
              variant="secondary"
              onClick={hide}
              data-qa="admin-message-back"
            >
              {I18n.t('modals.adminMessage.back')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AdminMessageModal;
