export function isValidJSON(val) {
  let isJson = true;
  try {
    if (typeof val === 'string') {
      JSON.parse(val);
    } else {
      const strVal = JSON.stringify(val);
      JSON.parse(strVal);
    }
  } catch (err) {
    isJson = false;
    throw new Error(`Error parsing json: ${err}`);
  }

  return isJson;
}
