import moment from 'moment';
import PT from 'prop-types';
import { Box, FlexList, P, H2, Required, Token } from '@procore/core-react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const renderInstallation = (I18n, isAdminView) => (installation) => {
  return (
    <Box key={installation.id}>
      <P>{installation.company_name}</P>
      {installation.company_is_demo && (
        <>
          <br />
          <Required variant="required">
            {I18n.t('installFeed.demoCompany')}
          </Required>
        </>
      )}
      {isAdminView && installation.user_email_address && (
        <>
          <br />
          <P>{installation.user_email_address}</P>
        </>
      )}
      <br />
      <P>
        {moment(installation.procore_updated_at).format('MMMM Do YYYY, h:mm a')}
      </P>
      <br />

      <Box paddingTop="xs">
        <Token>
          <Token.Label>{installation.status.toUpperCase()}</Token.Label>
        </Token>
      </Box>
    </Box>
  );
};

const InstallFeed = ({ I18n, installations, isAdminView, ...rest }) => {
  const orderedRows = installations.sort((a, b) => {
    return moment(a.procore_updated_at).isAfter(moment(b.procore_updated_at))
      ? 0
      : 1;
  });

  return (
    <Box padding="md" {...rest}>
      <H2 className={cx(styles.feedTitle)}>{I18n.t('installFeed.title')}</H2>
      <Box marginTop="sm">
        <FlexList direction="column">
          {orderedRows.map(renderInstallation(I18n, isAdminView))}
        </FlexList>
      </Box>
    </Box>
  );
};

InstallFeed.propTypes = {
  installations: PT.arrayOf(
    PT.shape({
      id: PT.number,
      company_name: PT.string,
      status: PT.string,
      procore_updated_at: PT.string,
      company_is_demo: PT.bool,
    })
  ).isRequired,
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
};

export default InstallFeed;
