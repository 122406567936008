import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import {
  Banner,
  Box,
  Button,
  Card,
  ErrorBanner,
  Flex,
  InfoBanner,
  Pill,
  Spinner,
  Table,
  Tabs,
  Tooltip,
  Typography,
  useI18nContext
} from '@procore/core-react';
import { Check } from '@procore/core-icons';
import InfoPopover from '../../../shared/infoPopover/InfoPopover';
import SandboxManifestsTable from '../../../common/SandboxManifestsTable';
import ProductionManifestsTable from '../../../common/ProductionManifestsTable';
import { MANIFEST_TABS, MANIFEST_TYPES } from '@/react/shared/constants';
import { UseShowContext } from '../Show.context';
import { ViewManifestModal } from '../modals/ViewManifestModal';
import { useToastAlertContext } from '@procore/toast-alert';

const ENVIRONMENTS = {
  PRODUCTION: 'Production',
  SANDBOX: 'Sandbox',
};

const ManifestsTable = ({ sandboxManifests, productionManifests, canSetSemanticVersions }) => {
  const I18n = useI18nContext();
  const { showToast } = useToastAlertContext();

  const combinedManifests = [
    ...sandboxManifests.map((manifest) => ({
      environment: ENVIRONMENTS.SANDBOX,
      ...manifest
    })),
    ...productionManifests.map((manifest) => ({
      environment: ENVIRONMENTS.PRODUCTION,
      ...manifest
    }))
  ].sort(
    (manifest1, manifest2) => {
      return (
        new Date(manifest2.created_at) - new Date(manifest1.created_at)
      );
    }
  );

  const [isOpen, setIsOpen] = useState(false);
  const [viewingManifest, setViewingManifest] = useState(null);

  return (
    <>
      <ViewManifestModal
        version={viewingManifest?.semantic_version}
        manifest={JSON.stringify(viewingManifest?.content)}
        isOpen={isOpen}
        close={() => { setIsOpen(false) }}
      />

      <Table.Container>
        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell data-qa="manifest-version-header" snugfit>
                {I18n.t('versionsTab.version')}
              </Table.HeaderCell>
              <Table.HeaderCell data-qa="manifest-created-at-header" snugfit>
                {I18n.t('versionsTab.createdAt')}
              </Table.HeaderCell>
              <Table.HeaderCell
                data-qa="manifest-app-version-id-header"
                snugfit
              >
                {I18n.t('versionsTab.appVersionKey')}
              </Table.HeaderCell>
              <Table.HeaderCell data-qa="published-to-marketplace-header" snugfit>
                {I18n.t('versionsTab.publishedToMarketplace')}
              </Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>

          <Table.Body>
            {combinedManifests.map((manifest) => (
              <Table.BodyRow key={manifest.id}>
                <Table.BodyCell snugfit>
                  <Box padding="none lg">
                    <Flex direction="column">
                    <a
                      href="javascript:;"
                      data-qa={`manifest-${manifest.version}-view-button`}
                      onClick={() => {
                        setViewingManifest(manifest);
                        setIsOpen(true);
                      }}
                      size="sm"
                    >
                      { manifest.semantic_version }
                    </a>
                    <Pill color={manifest.environment === ENVIRONMENTS.SANDBOX ? 'magenta' : 'blue' }>
                      { manifest.environment }
                    </Pill>
                    </Flex>
                  </Box>
                </Table.BodyCell>
                <Table.BodyCell snugfit>
                  <Table.TextCell>{manifest.created_at}</Table.TextCell>
                </Table.BodyCell>
                <Table.BodyCell snugfit>
                  <Box
                    onClick={() => {
                        navigator.clipboard.writeText(
                          manifest.app_version_id
                        );
                        showToast.success(
                          I18n.t('configurationsTab.copySuccess')
                        );
                    }}
                  >
                      <Tooltip overlay={<Tooltip.Content>{I18n.t('versionsTab.clickToCopy')}</Tooltip.Content>} trigger="hover">
                        <Typography intent="body">
                          { manifest.app_version_id }
                        </Typography>
                      </Tooltip>
                  </Box>
                </Table.BodyCell>
                <Table.BodyCell>
                  <Box padding="none lg">
                    {manifest.published_to_marketplace ? (
                      <Check size="sm" />
                    ) : null}
                  </Box>
                </Table.BodyCell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table>
      </Table.Container>
    </>
  );
}

ManifestsTable.propTypes = {
  canSetSemanticVersions: PT.bool.isRequired,
  productionManifests: PT.arrayOf(PT.shape({})).isRequired,
  sandboxManifests: PT.arrayOf(PT.shape({})).isRequired,
};

// eslint-disable-next-line complexity
const ManifestsCard = () => {
  const {
    developerAppId,
    manifestSupportDocPath,
    canSetSemanticVersions,
    api,
  } = UseShowContext();

  const I18n = useI18nContext();
  const [sandboxManifests, setSandboxManifests] = useState([]);
  const [productionManifests, setProductionManifests] = useState([]);
  useEffect(()  => {
  	api.getManifests(developerAppId, MANIFEST_TYPES.SANDBOX).then(({ data }) => {
      setSandboxManifests(data);
    });
  }, [setSandboxManifests]);
  useEffect(()  => {
  	api.getManifests(developerAppId, MANIFEST_TYPES.PRODUCTION).then(({ data }) => {
      setProductionManifests(data);
    });
  }, [setProductionManifests]);

  const [manifestTab, setManifestTab] = useState(MANIFEST_TABS.SANDBOX);
  const switchTab = (manifestTab) => {
  	setManifestTab(manifestTab);
  };
  const error = '';
  const showSandboxBanner = false;

  return (
    <Card>
      <Box padding="xl">
        <Box paddingBottom="md">
          <h2 style={{ display: 'inline' }}>{I18n.t('viewManifests')}</h2>
          <InfoPopover>
            <a href={manifestSupportDocPath} target="_blank">
              {I18n.t('manifestHelp')}
            </a>
          </InfoPopover>
        </Box>
        {error && (
          <ErrorBanner>
            <Banner.Content>
              <Banner.Title>{I18n.t('errorOccurred')}</Banner.Title>
              <Banner.Body>{error}</Banner.Body>
            </Banner.Content>
          </ErrorBanner>
        )}
        {showSandboxBanner && (
          <InfoBanner>
            <Banner.Content>
              <Banner.Title>{I18n.t('sandboxManifestCreated')}</Banner.Title>
              <Banner.Body>{I18n.t('visitSandbox')}</Banner.Body>
            </Banner.Content>
            <Banner.Action>
              <Button variant="secondary" onClick={scrollToSandboxCard}>
                {I18n.t('view')}
              </Button>
            </Banner.Action>
            <Banner.Dismiss onClick={() => toggleSandboxBanner(false)} />
          </InfoBanner>
        )}

        <ManifestsTable
          sandboxManifests={sandboxManifests}
          productionManifests={productionManifests}
          canSetSemanticVersions={canSetSemanticVersions}
        />
      </Box>
    </Card>
  );
};

ManifestsCard.propTypes = {
  developerAppId: PT.string.isRequired,
  sandboxManifests: PT.arrayOf(PT.shape({})).isRequired,
  productionManifests: PT.arrayOf(PT.shape({})).isRequired,
  manifestSupportDocPath: PT.string.isRequired,
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  updateAppVersion: PT.func.isRequired,
  error: PT.string,
  showSandboxBanner: PT.bool,
  toggleSandboxBanner: PT.func,
  canSetSemanticVersions: PT.bool.isRequired,
  switchTab: PT.func.isRequired,
  tab: PT.string.isRequired,
};

ManifestsCard.defaultProps = {
  error: '',
  showSandboxBanner: false,
  toggleSandboxBanner: () => {},
};

export default ManifestsCard;
