import React from 'react';
import PT from 'prop-types';
import { Button, Modal } from '@procore/core-react';

const ConfirmMediumDeletionModal = ({
  I18n,
  isVisible,
  hide,
  onConfirm,
  file,
}) => {
  return (
    <Modal open={isVisible} onClickOverlay={hide}>
      <Modal.Header onClose={hide}>
        {I18n.t('confirmDeletionModal.header')}
      </Modal.Header>

      <Modal.Body>
        {I18n.t('confirmDeletionModal.body', { file_name: file.name })}
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons>
          <Button
            variant="tertiary"
            onClick={hide}
            data-qa="delete-file-cancel"
          >
            {I18n.t('confirmDeletionModal.cancel')}
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              onConfirm();
              hide();
            }}
            data-qa="delete-file-confirm"
          >
            {I18n.t('confirmDeletionModal.confirm')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmMediumDeletionModal.propTypes = {
  I18n: PT.shape({}).isRequired,
  file: PT.shape({}),
  isVisible: PT.bool.isRequired,
  hide: PT.func.isRequired,
  onConfirm: PT.func.isRequired,
};

export default ConfirmMediumDeletionModal;
