import React from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import Form from '@/react/shared/form';
import Notification from '@/react/notifications/Notification';
import ErrorUtil from '@/utils/errorUtil';

import {
  Banner,
  Box,
  Button,
  ErrorBanner,
  FlexList,
  Spinner,
  useI18nContext,
} from '@procore/core-react';

const errorBanner = (error, I18n) => {
  if (!error) {
    return null;
  }

  return (
    <ErrorBanner>
      <Banner.Content>
        <Banner.Title>{I18n.t('form.errorOccurred')}</Banner.Title>
        <Banner.Body>{ErrorUtil.extractErrorMsg(error)}</Banner.Body>
      </Banner.Content>
    </ErrorBanner>
  );
};

const NotificationForm = ({
  adminNotificationsUrl,
  isNew,
  notificationsStore,
}) => {
  const I18n = useI18nContext();
  const { state, handlers } = notificationsStore;
  const notification = state.notification || {};
  const BLOCK_PLACEHOLDER = '██████████████';

  const notificationTimestamp = (date) => (date ? new Date(date) : undefined);

  return (
    <div>
      {errorBanner(state.error, I18n)}
      <Spinner loading={state.requestPending}>
        <Form>
          <Form.Content vertical>
            <Form.Field
              required
              label={I18n.t('form.title')}
              data-qa="title-field"
              type="text"
              value={notification.title}
              onChange={(e) => {
                handlers.onChange('title', e.target.value);
              }}
            />
            <Form.Textarea
              required
              label={I18n.t('form.summary')}
              data-qa="summary-field"
              value={notification.summary}
              onChange={(e) => {
                handlers.onChange('summary', e.target.value);
              }}
            />
            <Form.Textarea
              required
              label={I18n.t('form.body')}
              data-qa="body-field"
              value={notification.body}
              onChange={(e) => {
                handlers.onChange('body', e.target.value);
              }}
            />
            <Box padding="none md">
              <a
                href="https://guides.github.com/features/mastering-markdown/"
                target="_blank"
              >
                {I18n.t('form.markdownHelp')}
              </a>
            </Box>
            <Form.Checkbox
              label={I18n.t('form.criticalNotification')}
              data-qa="critical-checkbox"
              disabled={!isNew}
              checked={notification.critical}
              onClick={() =>
                handlers.onChangeMultiFields({
                  critical: !notification.critical,
                  show_after: null,
                  draft: false,
                })
              }
            />
            <Form.Checkbox
              label={I18n.t('form.draftNotification')}
              data-qa="draft-checkbox"
              checked={notification.draft}
              disabled={notification.critical}
              onClick={() => handlers.onChange('draft', !notification.draft)}
            />
            <Form.Field
              label={I18n.t('form.showAfter')}
              data-qa="show_after-field"
              type="text"
              disabled={notification.critical}
              value={notification.show_after}
              onChange={(e) => {
                handlers.onChange('show_after', e.target.value);
              }}
            />
          </Form.Content>
        </Form>
        <Box padding="none xl xl xl">
          <h1>{I18n.t('form.notificationPreview')}</h1>
        </Box>
        <Box
          className="dev-notification-previewer"
          padding="xl"
          margin="none xl xl xl"
        >
          <Notification
            className={cx('dev-notification', 'a-card--unread')}
            title={notification.title || BLOCK_PLACEHOLDER}
            timestamp={notificationTimestamp(notification.show_after)}
            markdown={notification.body || BLOCK_PLACEHOLDER}
          />
          <Notification
            className={cx('dev-notification')}
            title={BLOCK_PLACEHOLDER}
            timestamp={notificationTimestamp(notification.show_after)}
            markdown={BLOCK_PLACEHOLDER}
          />
        </Box>
        <Box padding="xl">
          <FormControls
            I18n={I18n}
            isNew={isNew}
            notification={notification}
            adminNotificationsUrl={adminNotificationsUrl}
            notificationsStore={notificationsStore}
          />
        </Box>
      </Spinner>
    </div>
  );
};

const FormControls = ({
  I18n,
  isNew,
  notification,
  notificationsStore,
  adminNotificationsUrl,
}) => {
  const buttonActionText = isNew
    ? I18n.t('controls.notify')
    : I18n.t('controls.update');

  const { handlers } = notificationsStore;

  const navigateToNotificationsAdmin = () => {
    window.location = adminNotificationsUrl;
  };

  return (
    <FlexList size="sm" direction="row">
      {!isNew && (
        <Button
          data-qa="delete-button"
          variant="secondary"
          size="lg"
          onClick={() => {
            const shouldDelete = confirm(I18n.t('controls.confirmDelete'));
            if (shouldDelete) {
              handlers.deleteNotification(
                notification.id,
                () => {
                  handlers.deleteNotificationSuccess();
                  navigateToNotificationsAdmin();
                },
                handlers.onError
              );
            }
          }}
        >
          <i className="ci ci-delete" />
        </Button>
      )}
      <Button
        variant="secondary"
        size="lg"
        onClick={navigateToNotificationsAdmin}
      >
        {I18n.t('controls.cancel')}
      </Button>
      <Button
        data-qa="notify-button"
        variant="primary"
        size="lg"
        onClick={() => {
          let shouldSave = true;
          if (notification.critical && isNew) {
            shouldSave = confirm(I18n.t('controls.confirmSave'));
          }
          if (shouldSave) {
            handlers.saveNotification(
              notification,
              (notification) => {
                handlers.saveNotificationSuccess(notification);
                navigateToNotificationsAdmin();
              },
              handlers.onError
            );
          }
        }}
      >
        {buttonActionText}
      </Button>
    </FlexList>
  );
};

NotificationForm.propTypes = {
  isNew: PT.bool.isRequired,
  adminNotificationsUrl: PT.string.isRequired,
  notificationsStore: PT.shape({
    state: PT.shape({
      notification: PT.shape({}),
      requestPending: PT.bool,
      error: PT.instanceOf(Error),
    }),
    handlers: PT.shape({
      fetch: PT.func,
      deleteNotification: PT.func,
      deleteNotificationSuccess: PT.func,
      saveNotification: PT.func,
      saveNotificationSuccess: PT.func,
      onError: PT.func,
    }),
  }).isRequired,
};

export default NotificationForm;
