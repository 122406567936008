import React from 'react';
import { compose } from 'recompose';
import ProductionManifestsTable from './ProductionManifestsTable';
import { I18nProvider } from '@procore/core-react';
import i18n from './i18n';
import StateContainer from './StateContainer';

const ProductionManifestsTableWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <ProductionManifestsTable {...props} />
  </I18nProvider>
);

export default compose(StateContainer.withState)(
  ProductionManifestsTableWithI18n
);
