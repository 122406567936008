import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import { element, shape, string } from 'prop-types';
import { Box, Button, Card, colors } from '@procore/core-react';
import { ArrowsCycleClockwise } from '@procore/core-icons';
import styled from 'styled-components';
import {
  CHUNK_LOAD_ERROR_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
} from '@/react/shared/constants';

Bugsnag.start({
  apiKey: 'c54514cb73b6f980458cc40d5675c08b',
  appType: 'frontend',
  plugins: [new BugsnagPluginReact()],
  // `enabledBreadcrumbTypes` options ['navigation', 'request', 'log', 'user', 'error']
  enabledBreadcrumbTypes: ['log', 'error'],
  releaseStage: process.env.NODE_ENV,
  onError: function(event) {
    if (event.app.releaseStage === 'production' && /\.procoretech-qa\.com$/.test(window.location.hostname)) {
      event.app.releaseStage = 'staging';
    }
  },
  enabledReleaseStages: [ 'production', 'staging' ],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const ErrorCard = styled(Card)`
  background-color: ${colors.red85};
`;

const ReloadButton = styled(Button)`
  margin-right: 8px;
`;

const i18n = {
  reload: 'Reload',
};

const ErrorView = ({ error }) => {
  if (error?.message === CHUNK_LOAD_ERROR_MESSAGE) {
    return (
      <ErrorCard>
        <Box padding="xl" marginTop="md">
          {CHUNK_LOAD_ERROR_MESSAGE}
          <ReloadButton
            iconRight={<ArrowsCycleClockwise size="xs" />}
            variant="tertiary"
            onClick={() => window.location.reload()}
            size="md"
            style={{ marginLeft: '8px' }}
          >
            {i18n.reload}
          </ReloadButton>
        </Box>
      </ErrorCard>
    );
  }
  return (
    <ErrorCard>
      <Box padding="xl" marginTop="md">
        {DEFAULT_ERROR_MESSAGE}
      </Box>
    </ErrorCard>
  );
};

ErrorView.propTypes = {
  error: shape({
    message: string,
  }).isRequired,
};

export const BugsnagContext = React.createContext();

export const BugsnagProvider = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <BugsnagContext.Provider value={Bugsnag}>
      {children}
    </BugsnagContext.Provider>
  </ErrorBoundary>
);

BugsnagProvider.propTypes = {
  children: element.isRequired,
};

const withBugsnag = (WrappedComponent) => (props) => (
  <ErrorBoundary FallbackComponent={ErrorView}>
    <BugsnagContext.Provider value={Bugsnag}>
      <WrappedComponent {...props} />
    </BugsnagContext.Provider>
  </ErrorBoundary>
);

export default withBugsnag;
