import React from 'react';
import PT from 'prop-types';
import {
  Card,
  Box,
  ErrorBanner,
  Banner,
  Flex,
  useI18nContext,
} from '@procore/core-react';
import FakeSandboxButton from './FakeSandboxButton';
import Pending from './Pending';
import Failed from './Failed';
import Live from './Live';

const SANDBOX_STATUS_MAP = {
  failed: Failed,
  live: Live,
  pending: Pending,
};

const renderStatusComponent = (status, props) => {
  const StatusComponent = SANDBOX_STATUS_MAP[status] || Pending;
  return <StatusComponent {...props} />;
};

const SandboxCard = ({
  developerAppId,
  canGenerateFakeSandbox,
  generateFakeSandbox,
  sandboxStatus,
  error,
  ...props
}) => {
  const I18n = useI18nContext();
  // Subscribe to the Sandbox websocket updates
  return (
    <Card>
      <Box data-anchor="sandbox-card-header" padding="xl xl none xl">
        <h2 style={{ display: 'inline' }}>{I18n.t('sandboxAccount')}</h2>
      </Box>

      {error && (
        <Box padding="xl xl none xl">
          <ErrorBanner>
            <Banner.Content>
              <Banner.Title>{I18n.t('errorOccurred')}</Banner.Title>
              <Banner.Body>{error}</Banner.Body>
            </Banner.Content>
          </ErrorBanner>
        </Box>
      )}

      {renderStatusComponent(sandboxStatus, props)}
      {canGenerateFakeSandbox && sandboxStatus !== 'live' && (
        <Flex justifyContent="center">
          <FakeSandboxButton generateFakeSandbox={generateFakeSandbox} />
        </Flex>
      )}
    </Card>
  );
};

SandboxCard.propTypes = {
  canEdit: PT.bool.isRequired,
  developerAppId: PT.string.isRequired,
  fields: PT.shape({}).isRequired,
  featureServiceKickoffPath: PT.string,
  onChange: PT.func.isRequired,
  onPostFeatureServiceJob: PT.func.isRequired,
  onUpdateSandbox: PT.func.isRequired,
  sandboxStatus: PT.string.isRequired,
  error: PT.string,
  sandboxCredentialsDocPath: PT.string.isRequired,
  redirectUriDocPath: PT.string.isRequired,
};

SandboxCard.defaultProps = {
  error: '',
  featureServiceKickoffPath: '',
  sandboxStatus: 'pending',
};

export default SandboxCard;
