import React from 'react';
import { compose } from 'recompose';
import { I18nProvider } from '@procore/core-react';
import StateContainer from '../NotificationReaderStateContainer';
import ShowPage from './ShowPage';
import i18n from '../i18n';
import withBugsnag from '@/react/shared/BugsnagProvider';

const ShowPageWithI18n = (props) => {
  return (
    <I18nProvider locale={i18n.locale} translations={i18n.translations}>
      <ShowPage {...props} />
    </I18nProvider>
  );
};

export default compose(withBugsnag, StateContainer)(ShowPageWithI18n);
