import styled from 'styled-components';
import {
  Box,
  Dropdown,
  Grid,
  H3,
  P,
  Table,
} from '@procore/core-react';

const getColor = (color) => {
  const COLORS = {
    get: '#1BC19C',
    post: '#529EDC',
    put: '#EDC139',
    patch: '#EDC139',
    delete: '#E47576',
  };

  return COLORS[color];
};

export const EndpointRequest = styled.div`
  font-family: 'Fira Mono', monospace;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  padding: 12px;
  border-radius: 4px;
  border-left: 8px solid transparent;
  border-right: 1px solid #dfdfe0;
  border-top: 1px solid #dfdfe0;
  border-bottom: 1px solid #dfdfe0;
  border-left-color: ${({ verb }) => getColor(verb)};
`;

export const SectionTitle = styled(H3)`
  padding-bottom: 16px;
  font-size: 18px;
`;

export const LeftColumn = styled(Grid.Col)`
  padding: 16px 24px;
`;

export const RightColumn = styled(Grid.Col)`
  background-color: #f9fafa;
  display: ${({ expanded }) => (expanded ? 'none' : 'block')};
  padding: 16px 24px;
  position: relative;
`;

export const RightColumnPlaceholder = styled(RightColumn)`
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const RestDetailRow = styled(Grid.Row)``;

export const MimeTypeDropdown = styled(Dropdown)`
  background: none;
  color: #ffffff;
`;

export const PaddedTableCell = styled(Table.TextCell)`
  margin: ${({ indentation }) => `${indentation ? 0 : 16}px`};
  padding-left: ${({ indentation }) => `${indentation * 8}px`};
`;

export const ColumnCell = styled(Table.TextCell)`
  flex-direction: column;
`;

export const TableHeaderCell = styled(Table.HeaderCell)`
  border: none;
  font-size: 14px;
`;

export const Section = styled(Box)`
  margin: 50px 0;
`;

export const DetailsPageTableContainer = styled(Table.Container)`
  & .table {
    margin-bottom: 0;
  }
`;

export const ErrorStatusTitle = styled(H3)`
  margin: 16px 0;
`;
export const ErrorStatusDesc = styled(P)`
  margin-left: 0;
`;

export const detailCodeStyles = {
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
  height: '100%',
  maxHeight: '500px',
};

export const EnumWrapper = styled(Box)`
  overflow-wrap: break-word;
`;

export const FixedWidthTable = styled(Table)`
  table-layout: fixed;
  width: 100%;
`;

export const SampleWrapper = styled.div`
  position: sticky;
  top: 120px;
`;
