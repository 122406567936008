import React from 'react';
import { Flex, Box, useI18nContext } from '@procore/core-react';

const Failed = () => {
  const I18n = useI18nContext();
  const supportText = I18n.t('sandbox.ongoingIssue', {
    contact_support: `<a href="https://support.procore.com/references/contact-support" target="_blank">${I18n.t(
      'sandbox.contactSupport'
    )}</a>`,
  });

  return (
    <Box padding="xl">
      <Flex alignItems="center" justifyContent="center" direction="column">
        <h3>{I18n.t('sandbox.somethingWentWrong')}</h3>
        <p>{I18n.t('sandbox.teamNotified')}</p>
        <p dangerouslySetInnerHTML={{ __html: supportText }} />
      </Flex>
    </Box>
  );
};

export default Failed;
