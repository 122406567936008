import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Switch,
  Typography,
  Link,
} from '@procore/core-react';
import { MARKETPLACE_APP_STATES } from '@/react/shared/constants';
import { useErrorContext } from '@/react/context/ErrorProvider.context';
import { omit, pathOr } from 'ramda';
import PopoverTag from '@/react/shared/PopoverTag';
import StateContainer from './StateContainer';
import { MARKETPLACE_TABS } from './constants';
import TabbedFormBody from './FormTabs/TabbedFormBody';
import { AdminFooter, Footer } from './Footer';
import AdminMessageForm from './AdminMessageForm';
import { InReviewMessage, AdminMessageBanner } from './Banners';
import Form from '@/react/shared/form';
import { selectSecurityQuestionnaire } from './selectors/securityQuestionnaireSelector';

const TabHeader = ({ tab, I18n }) => {
  const hasSubtext =
    I18n.lookup(`tabInfo.descriptions.${tab}`) ||
    I18n.lookup(`tabInfo.linkText.${tab}`);

  return (
    <Flex direction="column">
      <Typography intent="h2">{I18n.t(`tabInfo.titles.${tab}`)}</Typography>
      {hasSubtext && (
        <p>
          {I18n.lookup(`tabInfo.descriptions.${tab}`) && (
            <Typography intent="body" color="gray40">
              {I18n.t(`tabInfo.descriptions.${tab}`)}
            </Typography>
          )}

          {I18n.lookup(`tabInfo.linkText.${tab}`) && (
            <Link
              href="https://developers.procore.com/documentation/partner-content-reqs"
              target="_blank"
            >
              {I18n.t(`tabInfo.linkText.${tab}`)}
            </Link>
          )}
        </p>
      )}
    </Flex>
  );
};

const MarketplaceAppForm = (props) => {
  const {
    I18n,
    fields,
    unmappedFields,
    unmappedPublishedFields,
    mapMarketplaceAppFields,
    setLoadingValue,
    setError,
    setValid,
    save,
    onUpdate,
    isAdminView,
    canEdit,
    onChange,
    trackEvent,
    canSubmitMarketplaceApp,
    marketplaceDraftListingPath,
    newDraftPreviewActive,
    isProcoreEmployee,
    helpEmail,
    valid,
    validateFields,
    errors,
    loadingValuesMap,
    hasPublishedApp,
    developerAppId,
    marketplaceEnabled,
    onAppChange,
    updateDeveloperApp,
    tab,
    applicationEnabled = false,
    showBanners = true,
  } = props;
  const { addErrors } = useErrorContext();

  useEffect(() => {
    addErrors(errors); // Add errors to context
  }, [errors]);

  useEffect(() => {
    if (unmappedFields) {
      mapMarketplaceAppFields(unmappedFields, unmappedPublishedFields);
      setLoadingValue('global', false);
    }
  }, []);

  useEffect(() => {
    mapMarketplaceAppFields(unmappedFields, unmappedPublishedFields);
    setLoadingValue('global', false);
  }, [unmappedFields]);

  const getSaveFields = () => {
    const tabs = Object.values(MARKETPLACE_TABS);

    const topLevelFields = omit(
      [
        '__modified__',
        'product_tools',
        'regions',
        'countries',
        'approval_state',
        ...tabs,
      ],
      fields
    );

    const tabsFields = tabs.reduce((acc, currTab) => {
      return { ...acc, ...fields[currTab] };
    }, {});

    const remove_media_ids = tabs.reduce((acc, currTab) => {
      return [...acc, ...(fields[currTab]?.remove_media_ids ?? [])];
    }, []);

    const pathedProductTools = pathOr(
      [],
      [MARKETPLACE_TABS.TOOLS, 'product_tools'],
      fields
    );

    const pathedRegions = pathOr(
      [],
      [MARKETPLACE_TABS.REGIONS, 'regions'],
      fields
    );

    const pathedCountries = pathOr(
      [],
      [MARKETPLACE_TABS.REGIONS, 'countries'],
      fields
    );

    const securityQuestionnaire = tabsFields.security_questionnaire
      ? selectSecurityQuestionnaire(tabsFields.security_questionnaire)
      : {};

    return {
      product_tool_ids: Object.keys(pathedProductTools).filter(
        (productToolId) => pathedProductTools[productToolId]
      ),
      region_ids: Object.keys(pathedRegions).filter(
        (regionId) => pathedRegions[regionId]
      ),
      country_ids: Object.keys(pathedCountries).filter(
        (countryId) => pathedCountries[countryId]
      ),
      ...topLevelFields,
      ...tabsFields,
      securityQuestionnaire,
      // ordering is important here, remove_media_ids should be last to override
      remove_media_ids,
    };
  };

  const saveForm = (formFields) => {
    save(
      formFields,
      (marketplaceAppFieldsSuccess) => {
        if (onUpdate) {
          onUpdate(marketplaceAppFieldsSuccess);
        }
        setValid(true);
        setError(null);
        setLoadingValue('global', false);
      },
      (marketplaceAppFieldsError) => {
        setError(marketplaceAppFieldsError);
        setLoadingValue('global', false);
      }
    );
  };

  return (
    <Spinner loading={loadingValuesMap.global}>
      <Box padding="lg">
        <Box data-testid="marketplace-app-form">
          <TabHeader tab={tab} I18n={I18n} />

          {hasPublishedApp && (
            <PopoverTag title={I18n.t('published').toUpperCase()}>
              <p>{I18n.t('publishedDetails')}</p>
            </PopoverTag>
          )}
          {isAdminView && applicationEnabled && (
            <Box padding="xl xl xl none">
              <Switch
                onChange={() => {
                  updateDeveloperApp({
                    id: developerAppId,
                    marketplace_enabled: !marketplaceEnabled,
                  }).then((resp) => {
                    onAppChange(
                      'marketplace_enabled',
                      resp.data.marketplace_enabled
                    );
                  });
                }}
                checked={marketplaceEnabled}
              >
                {I18n.t('marketplaceEnabled')}
              </Switch>
            </Box>
          )}
        </Box>
        {showBanners && (
          <>
            {isAdminView && (
              <Box padding="lg none">
                <AdminMessageBanner
                  state={fields.state}
                  approval_state={fields.approval_state}
                  admin_message={fields.admin_message}
                  isAdminView={isAdminView}
                />
              </Box>
            )}
            {!isAdminView &&
              fields.state === MARKETPLACE_APP_STATES.IN_REVIEW && (
                <Box padding="lg none">
                  <InReviewMessage />
                </Box>
              )}
          </>
        )}

        <TabbedFormBody {...props} />

        {isAdminView ? (
          <Box paddingTop="lg">
            <AdminMessageForm
              adminMessage={fields.admin_message}
              onChange={(field, value) =>
                onChange(field, value, 'marketplaceApp')
              }
              isConcierge={fields.is_concierge_app}
              I18n={I18n}
            />
            <AdminFooter
              fields={fields}
              saveForm={saveForm}
              getSaveFields={getSaveFields}
              I18n={I18n}
              onChange={onChange}
              trackEvent={trackEvent}
              marketplaceDraftListingPath={marketplaceDraftListingPath}
              newDraftPreviewActive={newDraftPreviewActive}
            />
          </Box>
        ) : (
          <Box paddingTop="lg">
            <Footer
              canEdit={canEdit}
              canSubmitMarketplaceApp={canSubmitMarketplaceApp}
              marketplaceDraftListingPath={marketplaceDraftListingPath}
              newDraftPreviewActive={newDraftPreviewActive}
              valid={valid}
              helpEmail={helpEmail}
              fields={fields}
              saveForm={saveForm}
              getSaveFields={getSaveFields}
              I18n={I18n}
              onChange={onChange}
              trackEvent={trackEvent}
              validateFields={validateFields}
              setError={setError}
            />
          </Box>
        )}
      </Box>
    </Spinner>
  );
};

MarketplaceAppForm.defaultProps = {
  hasPublishedApp: false,
  unmappedFields: {},
  unmappedPublishedFields: {},
  setLoadingValue: () => {},
  setValid: () => {},
  errorsPresent: () => {},
  trackEvent: () => {},
  isProcoreEmployee: false,
  helpEmail: '',
  marketplaceDraftListingPath: '',
  newDraftPreviewActive: false,
};

export default StateContainer(MarketplaceAppForm);
