import React, { useState } from 'react';
import pathOr from 'ramda/src/pathOr';
import { useDecorator, hookSchema } from '@procore/react-state-patterns';
import api from './api';
import { debounce } from '@/utils/functionUtil';

const VALIDATE_SCHEMA_DEBOUNCE_MILLIS = 1000;
const API = api();

const validate = (parentAppId, parentAppType, manifest, updateState) => {
  API.validateManifest(parentAppId, parentAppType, manifest)
    .then((resp) => {
      const errors = pathOr([], ['data', 'errors'], resp);
      updateState({
        passedValidation: !!(errors.length === 0),
        validating: false,
        schemaErrors: errors,
        apiError: null,
      });
    })
    .catch((err) => {
      updateState({ apiError: err, validating: false });
    });
  updateState({ validating: true });
};

const debouncedValidate = debounce(validate, VALIDATE_SCHEMA_DEBOUNCE_MILLIS);

// Custom State Hook
const useSchemaValidationState = () => {
  const [state, setState] = useState({
    apiError: null,
    jsonErrors: [],
    schemaErrors: [],
    validating: false,
    passedValidation: false,
  });

  const updateState = (newState) =>
    setState((currState) => ({
      ...currState,
      ...newState,
    }));

  const handlers = {
    validateSchema: (
      parentAppId,
      parentAppType,
      manifest,
      debounced = true
    ) => {
      updateState({ passedValidation: false });
      const validator = debounced ? debouncedValidate : validate;
      validator(parentAppId, parentAppType, manifest, updateState);
    },
    validateJSON: (jsonErrors) => {
      updateState({ jsonErrors });
    },
  };

  return hookSchema(state, handlers, 'validations');
};

// Export decorator
export const withValidationState = useDecorator(useSchemaValidationState);

export default withValidationState;
