import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Flex,
  Menu,
  Popover,
  RadioButton,
  Spinner,
  Table,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import { EllipsisVertical } from '@procore/core-icons';
import { trackEvent } from '@/react/shared/analytics';
import { UseShowContext } from '../Show.context';
import PermissionDetailsModal from '../modals/PermissionDetailsModal';
import AddCollaboratorModal from '../modals/AddCollaboratorModal';

const Collaborators = () => {
  const I18n = useI18nContext();

  const {
    developerEmail,
    developerAppId,
    api,
    canEdit,
    state: { devApp },
  } = UseShowContext();

  const [collaborators, setCollaborators] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [addCollaboratorModalOpen, setAddCollaboratorModalOpen] = useState(
    false
  );
  const [loadingInvitations, setLoadingInvitations] = useState(false);
  const [loadingCollaborators, setLoadingCollaborators] = useState(false);

  const sendInvitation = (email, role) => {
    setLoadingInvitations(true);
    api.sendInvitation(developerAppId, email, role).then((resp) => {
      setInvitations([...invitations, resp.data]);
      setAddCollaboratorModalOpen(false);
      setLoadingInvitations(false);
    });

    trackEvent('developers.my_apps.collaborators.invited', {
      email: developerEmail,
      developer_app_name: devApp.internal_name,
    });
  };

  useEffect(() => {
    setLoadingCollaborators(true);
    api.fetchCollaborators(developerAppId).then(({ data }) => {
      setCollaborators(data);
      setLoadingCollaborators(false);
    });
  }, []);

  useEffect(() => {
    setLoadingInvitations(true);
    api.fetchInvitations(developerAppId).then(({ data }) => {
      setInvitations(data);
      setLoadingInvitations(false);
    });
  }, []);

  const setRole = (collaborator, role) => {
    const newCollaborators = [...collaborators];
    const position = newCollaborators.indexOf(collaborator);
    newCollaborators[position].role = role;
    setCollaborators(newCollaborators);
  };

  const onChangeRow = (collaborator, role) => (e) => {
    if (e.target.value === 'on') {
      setRole(collaborator, role);
    }
  };

  const updatePermissions = () => {
    setLoadingCollaborators(true);
    api.updateCollaborators(developerAppId, collaborators).then(({ data }) => {
      setCollaborators(data);
      setLoadingCollaborators(false);
    });
  };

  const currentUserCollaborator = collaborators.find((c) => (c.email === developerEmail));
  const actingAsOwner = (currentUserCollaborator?.role === 'owner');

  const cancelInvite = (invitation) => {
    setLoadingInvitations(true);
    api.cancelInvitation(developerAppId, invitation.id).then(({ data }) => {
      const updatedInvitations = invitations.filter((i) => {
        return i.id !== invitation.id;
      });
      setInvitations(updatedInvitations);
      setLoadingInvitations(false);
    })
  };

  const removeCollaborator = (collaborator) => {
    setLoadingCollaborators(true);
    api.removeCollaborator(developerAppId, collaborator.id).then(({ data }) => {
      setLoadingCollaborators(false);
    });
  }

  const transferOwnership = (collaborator) => {
    setLoadingCollaborators(true);
    const updatedCollaborators = [
      { ...collaborator, role: 'owner' },
      { ...currentUserCollaborator, role: 'admin' }
    ]
    api.updateCollaborators(developerAppId, updatedCollaborators).then(({ data }) => {
      setCollaborators(data);
      setLoadingCollaborators(false);
    });
  }

  const collaboratorRow = (collaborator) => {
    const menuOptions = [];
    const isSelf = collaborator.email === developerEmail;
    const isOwner = collaborator.role === 'owner';
    const isInvitation = !collaborator.name;
    const rowEditable =
      !isInvitation &&
      !isSelf &&
      !isOwner &&
      canEdit;

    if (isInvitation) {
      menuOptions.push('cancelInvite');
    }

    if (!isSelf && !isInvitation) {
      if (actingAsOwner) {
        menuOptions.push('transferOwnership');
      }

      if (!isOwner) {
        menuOptions.push('removeCollaborator');
      }
    }

    const key = `${isInvitation ? 'invitation' : 'collaborator'}-${collaborator.id}`;

    return (
      <Table.BodyRow key={key} data-testid={key}>
        <Table.BodyCell>
          <Table.TextCell>
            <Flex direction="column">
              <Typography weight="bold" color="red45">
                { isInvitation ? collaborator.email : collaborator.name }
              </Typography>
              <Typography>
                { isInvitation ?  I18n.t('collaboratorsTab.pendingInvite') : collaborator.email }
              </Typography>
            </Flex>
          </Table.TextCell>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex style={{width: '100%'}} justifyContent="center">
            <RadioButton
              disabled={true}
              checked={collaborator.role === 'owner'}
              onChange={onChangeRow(collaborator, 'owner')}
            />
          </Flex>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex style={{width: '100%'}} justifyContent="center">
            <RadioButton
              disabled={!rowEditable}
              checked={collaborator.role === 'admin'}
              onChange={onChangeRow(collaborator, 'admin')}
            />
          </Flex>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex style={{width: '100%'}} justifyContent="center">
            <RadioButton
              disabled={!rowEditable}
              checked={collaborator.role === 'developer'}
              onChange={onChangeRow(collaborator, 'developer')}
            />
          </Flex>
        </Table.BodyCell>
        <Table.BodyCell>
          { menuOptions.length > 0 && (
            <Table.IconCell>
              <Popover
                placement="left"
                overlay={
                  <Popover.Content>
                    <Card>
                      <Menu onSelect={(e) => {
                        if (e.item === 'cancelInvite') {
                          cancelInvite(collaborator);
                        } else if (e.item === 'removeCollaborator') {
                          removeCollaborator(collaborator);
                        } else if (e.item === 'transferOwnership') {
                          transferOwnership(collaborator);
                        }
                      }}>
                        <Menu.Options>
                          {menuOptions.map((option) => (
                            <Menu.Item
                              key={option}
                              item={option}
                              data-testid={option}
                            >
                              {I18n.t(`collaboratorsTab.collaboratorMenu.${option}`)}
                            </Menu.Item>
                          ))}
                        </Menu.Options>
                      </Menu>
                    </Card>
                  </Popover.Content>
                }
              >
                <EllipsisVertical
                  data-testid={`${key}-menu-icon`}
                  style={{cursor: 'pointer'}}
                />
              </Popover>
            </Table.IconCell>
          )}
        </Table.BodyCell>
      </Table.BodyRow>
    );
  };

  return (
    <Spinner loading={loadingInvitations || loadingCollaborators}>
      <Card data-testid="collaborators">
        <Box padding="lg">
          <Box padding="xl xl none xl">
            <h2>{I18n.t('manageCollaborators')}</h2>
          </Box>

          <Table.Container>
            <Table>
              <Table.Header>
                <Table.HeaderRow>
                  <Table.HeaderCell>
                    {I18n.t('collaboratorsTab.user')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {I18n.t('collaboratorsTab.owner')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {I18n.t('collaboratorsTab.admin')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {I18n.t('collaboratorsTab.developer')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                  </Table.HeaderCell>
                </Table.HeaderRow>
              </Table.Header>

              <Table.Body>
                {collaborators.map(collaboratorRow)}
                {invitations.map(collaboratorRow)}
              </Table.Body>
            </Table>
          </Table.Container>

          <Box
            marginTop="sm"
            display="flex"
            justifyContent="flex-end"
            alignContent="center"
          >
            <Box marginRight="sm">
              <Button
                variant="secondary"
                onClick={() => {
                  setDetailsModalOpen(true);
                }}
              >
                {I18n.t('collaboratorsTab.viewPermissionDetails')}
              </Button>
            </Box>
            <Box marginRight="sm">
              <Button variant="primary" onClick={updatePermissions}>
                {I18n.t('collaboratorsTab.updatePermissions')}
              </Button>
            </Box>
            <Button
              variant="primary"
              onClick={() => {
                setAddCollaboratorModalOpen(true);
              }}
            >
              {I18n.t('collaboratorsTab.addUser')}
            </Button>
          </Box>

          <PermissionDetailsModal
            open={detailsModalOpen}
            setOpen={setDetailsModalOpen}
          />

          <AddCollaboratorModal
            open={addCollaboratorModalOpen}
            setOpen={setAddCollaboratorModalOpen}
            sendInvitation={sendInvitation}
          />
        </Box>
      </Card>
    </Spinner>
  );
};

export default Collaborators;
