import React from 'react';
import ReactMarkdown from 'react-markdown';

const linkReferenceRenderer = (reference) => {
  if (!reference.href) {
    return <>[{reference.children}]</>;
  }
  return <a href={reference.$ref}>{reference.children}</a>;
};

const MarkdownRenderer = ({ source }) => {
  source = source.replace(/(?<!  )\n/g, '  \n');
  return (
    <ReactMarkdown
      source={source}
      renderers={{ linkReference: linkReferenceRenderer }}
    />
  );
};

export default MarkdownRenderer;
