import React from 'react';
import PT from 'prop-types';
import moment from 'moment';
import { Button, Flex, FlexList, useI18nContext } from '@procore/core-react';

import Form from '@/react/shared/form';
import { autoSelectCopy } from '../../../../global_helpers/autoSelectCopy';

const TabSandbox = ({ app = {}, events }) => {
  const I18n = useI18nContext();
  if (app.sandbox) {
    return (
      <React.Fragment>
        <SandboxForm
          I18n={I18n}
          sandbox={app.sandbox}
          onChange={events.sandboxChange}
        />
        <Flex direction="row" justifyContent="flex-end">
          <FlexList size="sm">
            <Button
              onClick={events.save}
              variant="primary"
              block
              size="lg"
              data-qa="save-general-changes-button"
            >
              {I18n.t('actions.save')}
            </Button>
          </FlexList>
        </Flex>
      </React.Fragment>
    );
  }

  return <h2>This app has no sandbox</h2>;
};

TabSandbox.propTypes = {
  app: PT.shape({ sandbox: PT.object }).isRequired,
  events: PT.shape({
    sandboxChange: PT.func.isRequired,
  }).isRequired,
};

const SandboxForm = ({ I18n, sandbox, onChange }) => {
  return (
    <div>
      <Form data-qa="sandbox-form">
        <Form.Description>
          <header>
            <h2>Sandbox OAuth App</h2>
            <p className="information">
              This are the attributes for this application's{' '}
              <strong>Sandbox</strong>. Data pulled from Sandbox Environment.
              Changing these settings <strong>will</strong> affect the sandbox.
            </p>
            <div className="form-group">
              <label>{I18n.t('labels.created_at')}</label>
              {moment(sandbox.created_at).format('MMMM Do YYYY, h:mm:ss a')}
            </div>

            <div className="form-group">
              <label>{I18n.t('labels.updated_at')}</label>
              {sandbox.updated_at}
            </div>
          </header>
        </Form.Description>

        <Form.Content>
          <Form.Column>
            <Form.Field
              readOnly
              data-qa="sandbox-company-id"
              label={I18n.t('labels.company_id')}
              type="text"
              value={sandbox.company_id || ''}
            />
            <Form.Field
              readOnly
              data-qa="sandbox-uri"
              label={I18n.t('labels.sandbox_uri')}
              type="text"
              value={sandbox.url || ''}
              onClick={autoSelectCopy}
            />
            <Form.Textarea
              data-qa="sandbox-redirect-uri"
              label={I18n.t('labels.oauth_redirect_uri')}
              type="text"
              required={true}
              value={sandbox.redirect_uri || ''}
              onChange={(event) => {
                onChange('redirect_uri', event.target.value);
              }}
            >
              <p className="a-field information">
                Use one line per URI.
                <br />
                Use urn:ietf:wg:oauth:2.0:oob for redirectless OAuth.
                <br />
                URIs must be HTTPS.
              </p>
            </Form.Textarea>
          </Form.Column>

          <Form.Column>
            <Form.Field
              readOnly
              data-qa="sandbox-app-id"
              label={I18n.t('labels.app_id')}
              type="text"
              value={sandbox.app_id || ''}
              onClick={autoSelectCopy}
            />
            <Form.Field
              readOnly
              data-qa="sandbox-app-secret"
              label={I18n.t('labels.app_secret')}
              value={sandbox.app_secret || ''}
            />
            <Form.Textarea
              data-qa="sandbox-cors-domain"
              label={I18n.t('labels.allowed_cors_domain')}
              type="text"
              value={sandbox.allowed_cors_domain}
              onChange={(event) => {
                onChange('allowed_cors_domain', event.target.value);
              }}
            >
              <p className="a-field information">
                Domain from which cross origin requests will be accepted.
              </p>
            </Form.Textarea>
          </Form.Column>

          <Form.Column>
            <Form.Field
              readOnly
              data-qa="sandbox-procore-app-id"
              label={I18n.t('labels.procore_app_id')}
              type="text"
              value={sandbox.procore_app_id || ''}
            />
          </Form.Column>
        </Form.Content>
      </Form>
    </div>
  );
};

SandboxForm.propTypes = {
  sandbox: PT.shape({
    app_id: PT.string,
    company_id: PT.number,
    created_at: PT.string.isRequired,
    redirect_uri: PT.string,
    procore_app_id: PT.number,
    sandbox_uri: PT.string,
    updated_at: PT.string.isRequired,
    url: PT.string,
  }).isRequired,
  onChange: PT.func.isRequired,
};

export default TabSandbox;
