import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Button,
  Box,
  EmptyState,
  useI18nContext,
  Table,
  Flex,
} from '@procore/core-react';
import { Pencil, Trash } from '@procore/core-icons/dist';
import AddComponentModal from '../../modals/AddComponentModal';

const Components = ({ currentManifest, setCurrentManifest }) => {
  const [addComponentModalOpen, setAddComponentModalOpen] = useState(false);
  const [editingComponent, setEditingComponent] = useState({});

  const I18n = useI18nContext();
  const manifestContent = currentManifest?.configured_manifest_content || {};
  const { sidepanel, fullscreen } = manifestContent;

  const sidepanelPresent = sidepanel && Object.keys(sidepanel).length !== 0;
  const fullscreenPresent = fullscreen && Object.keys(fullscreen).length !== 0;
  const allowedComponentsArray = [];
  if (!sidepanelPresent) allowedComponentsArray.push('sidepanel');
  if (!fullscreenPresent) allowedComponentsArray.push('fullscreen');

  const editComponent = (type, component) => () => {
    setEditingComponent({ type, ...component });
    setAddComponentModalOpen(true);
  };

  const deleteComponent = (type) => () => {
    const { [type]: _deleting, ...newContent } = manifestContent;

    setCurrentManifest({
      ...currentManifest,
      configured_manifest_content: newContent,
    });
  };

  // sidepanel XOR fullscreen for the add button outside the EmptyState
  const canAddComponent =
    (sidepanelPresent || fullscreenPresent) &&
    !(sidepanelPresent && fullscreenPresent);

  return (
    <Box padding="lg" data-testid="components-card-body">
      <AddComponentModal
        open={addComponentModalOpen}
        closeModal={() => {
          setAddComponentModalOpen(false);
          setEditingComponent({});
        }}
        editingComponent={editingComponent}
        updateManifest={(configuredManifest) => {
          setCurrentManifest({
            ...currentManifest,
            configured_manifest_content: configuredManifest,
          });
        }}
        allowedComponentsArray={allowedComponentsArray}
        currentManifest={currentManifest?.configured_manifest_content || {}}
      />

      {!sidepanelPresent && !fullscreenPresent && (
        <EmptyState data-testid="components-empty">
          <EmptyState.NoItems />
          <EmptyState.Description>
            {I18n.t('configurationsTab.emptyState')}
          </EmptyState.Description>
          <EmptyState.Actions>
            <Button
              onClick={() => setAddComponentModalOpen(true)}
              data-testid="add-component-button"
            >
              {I18n.t('configurationsTab.addComponent')}
            </Button>
          </EmptyState.Actions>
        </EmptyState>
      )}

      {(sidepanelPresent || fullscreenPresent) && (
        <Table.Container data-testid="components-table">
          <Table>
            <Table.Header>
              <Table.HeaderRow>
                <Table.HeaderCell>
                  {I18n.t('configurationsTab.type')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {I18n.t('configurationsTab.url')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {I18n.t('configurationsTab.views')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {I18n.t('configurationsTab.params')}
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.HeaderRow>
            </Table.Header>

            <Table.Body>
              {sidepanelPresent && (
                <Table.BodyRow data-testid="sidepanel-row">
                  <Table.BodyCell>
                    <Table.TextCell>
                      {I18n.t('configurationsTab.sidepanel')}
                    </Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.TextCell>{sidepanel?.source_url}</Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.TextCell>{sidepanel?.views?.length}</Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.TextCell>
                      {sidepanel.user_configuration
                        ? Object.keys(
                            sidepanel.user_configuration.schema.properties
                          ).length
                        : 0}
                    </Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.IconCell>
                      <Button
                        onClick={editComponent('sidepanel', sidepanel)}
                        icon={<Pencil />}
                        variant="tertiary"
                      />
                      <Button
                        onClick={deleteComponent('sidepanel')}
                        icon={<Trash />}
                        variant="tertiary"
                      />
                    </Table.IconCell>
                  </Table.BodyCell>
                </Table.BodyRow>
              )}

              {fullscreenPresent && (
                <Table.BodyRow data-testid="fullscreen-row">
                  <Table.BodyCell>
                    <Table.TextCell>
                      {I18n.t('configurationsTab.fullscreen')}
                    </Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.TextCell>{fullscreen.source_url}</Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.TextCell />
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.TextCell>
                      {fullscreen.user_configuration
                        ? Object.keys(
                            fullscreen.user_configuration.schema.properties
                          ).length
                        : 0}
                    </Table.TextCell>
                  </Table.BodyCell>

                  <Table.BodyCell>
                    <Table.IconCell>
                      <Button
                        onClick={editComponent('fullscreen', fullscreen)}
                        icon={<Pencil />}
                        variant="tertiary"
                      />
                      <Button
                        onClick={deleteComponent('fullscreen')}
                        icon={<Trash />}
                        variant="tertiary"
                      />
                    </Table.IconCell>
                  </Table.BodyCell>
                </Table.BodyRow>
              )}
            </Table.Body>
          </Table>
        </Table.Container>
      )}

      {canAddComponent && (
        <Flex padding="lg none" direction="row" justifyContent="flex-end">
          <Button
            variant="secondary"
            onClick={() => setAddComponentModalOpen(true)}
          >
            {I18n.t('configurationsTab.addComponent')}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

Components.propTypes = {
  currentManifest: PT.shape({
    configured_manifest_content: PT.shape({}),
  }).isRequired,
  setCurrentManifest: PT.func.isRequired,
};

export default Components;
