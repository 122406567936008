import React from 'react';
import useProviders, { useStateHook } from '@procore/react-state-patterns';

export default useProviders(
  useStateHook(
    { viewingManifest: null, tableExpanded: false },
    {
      setViewingManifest: () => (manifest) => ({
        viewingManifest: manifest,
      }),
      setViewingReleaseNotes: () => (manifest) => ({
        viewingReleaseNotes: manifest,
      }),
      toggleTableExpanded: ({ tableExpanded }) => () => ({
        tableExpanded: !tableExpanded,
      }),
    },
    'prodManifestTableStore'
  )
);
