import { pathOr, map } from 'ramda';
import moment from 'moment';

export const strToDate = (dayString) => moment(dayString);

export const sortByDateDesc = (dateArray) => {
  return dateArray.sort((a, b) => strToDate(b) - strToDate(a));
};

export const sortByDateAsc = (dateArray) => {
  return dateArray.sort((a, b) => strToDate(a) - strToDate(b));
};

export const buildDatesForTimePeriod = (metricsData) => {
  const allDayStrings = Object.keys(metricsData).reduce((allDays, metric) => {
    const datesForMetric = Object.keys(metricsData[metric].rows);
    return allDays.concat(datesForMetric);
  }, []);

  const allDates = map(strToDate, allDayStrings);
  const startDate = moment.min(allDates).clone();
  const endDate = moment.max(allDates).clone();

  const allDatesWithinTimePeriod = [];
  let currentDateItr = startDate;

  while (currentDateItr.isSameOrBefore(endDate)) {
    allDatesWithinTimePeriod.push(currentDateItr.format('YYYY-MM-D'));
    currentDateItr.add(1, 'days');
  }

  return sortByDateDesc(allDatesWithinTimePeriod);
};

export const formatMetricsDataDates = (metricsData) =>
  Object.keys(metricsData).reduce((formattedMetricsData, metric) => {
    const metricData = metricsData[metric].rows;
    const datesForMetric = Object.keys(metricData);

    formattedMetricsData[metric] = datesForMetric.reduce(
      (formattedDates, date) => {
        formattedDates[strToDate(date).format('YYYY-MM-D')] = metricData[date];

        return formattedDates;
      },
      {}
    );

    return formattedMetricsData;
  }, {});

export const mapCountForDate = (metricData, date) =>
  pathOr(0, ['count'], metricData[date]);
