import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Box } from '@procore/core-react';

class TopNavOptionsLink extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { linkPath, isActive, image, style, caret } = this.props;

    const linkClasses = cx(this.props.classes, 'nav-options-link', {
      'nav-options-link--active': isActive,
    });

    const contents = (
      <React.Fragment>
        {image}
        {this.props.children}
        {caret && (
          <Box display="inline" paddingLeft="xs">
            <i className="fa fa-caret-down arrow-down" />
          </Box>
        )}
      </React.Fragment>
    );

    if (linkPath) {
      return (
        <a href={linkPath} className={linkClasses} style={style}>
          {contents}
        </a>
      );
    }
    return (
      <div className={linkClasses} style={style}>
        {contents}
      </div>
    );
  }
}

TopNavOptionsLink.propTypes = {
  linkPath: PropTypes.string,
  classes: PropTypes.array,
  isActive: PropTypes.bool,
  caret: PropTypes.bool,
  image: PropTypes.node,
};

TopNavOptionsLink.defaultProps = {
  isActive: false,
  caret: false,
};

export default TopNavOptionsLink;
