import styled from 'styled-components';
import { Typeahead } from '@procore/core-react';

export const SearchBar = styled(Typeahead)`
  & input {
    border: none;
    box-sizing: border-box;
  }
  border: 1px solid #acb5b9;
  border-radius: 4px;
  margin: 16px 8px 8px 8px;
  position: sticky;
  top: 0;
  width: auto;
  z-index: 10;
  @media only screen and (max-width: 1100px) {
    margin: 40px 16px 0 32px;
  }
`;

export const SideBar = styled.div`
  align-self: stretch;
  background-color: #f9fafa;
  border-right: 1px solid #dfdfe0;
  display: flex;
  flex-direction: column;
  min-width: 288px;
  overflow-y: auto;
  padding: 0 8px;
  position: static;

  @media only screen and (max-width: 1100px) {
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    display: ${(props) => (props.sidebarShown ? 'block' : 'none')};
    z-index: 1000;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  margin: 16px 8px;
`;
