/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */

import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Flex,
  FlexList,
  Modal,
  Table,
  useI18nContext,
} from '@procore/core-react';
import { isEmpty } from 'ramda';
import Form from '@/react/shared/form';
import InfoPopover from '@/react/shared/infoPopover/InfoPopover';
import SimpleConfirmModal from '@/react/shared/SimpleConfirmModal';
import ManifestModal from '../ManifestModal';
import { autoSelectCopy } from '../../../global_helpers/autoSelectCopy';

const _manifestToString = (I18n, manifest = {}) => {
  let manifestString;
  try {
    manifestString = JSON.stringify(manifest, null, 2);
  } catch (e) {
    manifestString = I18n.t('jsonParseError');
  }
  return manifestString;
};

const SandboxManifestsTable = ({
  manifests,
  parentApp,
  createManifest,
  promoteManifest,
  templates,
  sandboxManifestTableStore: tableStore,
  canModifyManifests,
  trackEvent,
  canViewClientCredentials,
  canSetSemanticVersions,
  canCreateSandboxManifests,
  formBasedExperience,
  dmsaPermissions
}) => {
  const I18n = useI18nContext();
  const {
    state: {
      draftManifest,
      semanticVersion,
      viewingManifest,
      tableExpanded,
      showCreateModal,
    },
  } = tableStore;
  const clearViewingManifest = () =>
    tableStore.handlers.setViewingManifest(null);

  const sortedManifests = manifests.sort((m1, m2) => m2.version - m1.version);

  const latestManifest = sortedManifests.length > 0 ? sortedManifests[0] : null;
  const templateVersions = Object.keys(templates.state.templatesMap);
  const latestVersion = templateVersions.sort()[templateVersions.length - 1];
  const latestTemplate = templates.state.templatesMap[latestVersion];

  useEffect(() => {
    const draftUnset = draftManifest === undefined;
    const draftSetToTemplate =
      draftManifest === _manifestToString(I18n, latestTemplate);
    const templatesLoading = templates.state.loading || templates.state.error;

    if (latestManifest && (draftUnset || draftSetToTemplate)) {
      tableStore.handlers.setDraftManifest(
        _manifestToString(I18n, latestManifest.next_version_content)
      );
    } else if (draftUnset && latestTemplate) {
      tableStore.handlers.setDraftManifest(
        _manifestToString(I18n, latestTemplate)
      );
    } else if (
      draftUnset &&
      !templatesLoading &&
      isEmpty(templates.state.templatesMap)
    ) {
      templates.handlers.fetch();
    }
  }, [
    draftManifest,
    latestManifest,
    latestTemplate,
    templates.state.loading,
    templates.state.error,
  ]);

  // This effect retrieves the snippets to be passed into the modal
  useEffect(() => {
    if (!templates.state.snippetsLoading) {
      templates.handlers.fetchTemplateSnippets(!!latestManifest, '3');
      templates.handlers.fetchTemplateSnippets(!!latestManifest, '4.1');
    }
  }, [manifests, templates.state.snippetsLoading]);

  const [releaseNotes, setReleaseNotes] = useState('');

  let displayedManifests;
  if (tableExpanded) {
    displayedManifests = sortedManifests;
  } else {
    displayedManifests = latestManifest ? [latestManifest] : [];
  }

  return (
    <>
      <Box padding="md none">
        <FlexList size="sm">
          {manifests.length > 1 && (
            <Button
              data-qa="show-old-manifests-button"
              onClick={tableStore.handlers.toggleTableExpanded}
              variant="secondary"
            >
              {tableExpanded
                ? I18n.t('hideOldVersions')
                : I18n.t('showOldVersions')}
            </Button>
          )}
          {!formBasedExperience && (<Button
            data-qa="create-new-manifest-button"
            disabled={!canModifyManifests || !canCreateSandboxManifests}
            onClick={() => {
              tableStore.handlers.setCreateModalVisibilityAndInitialContent(
                true,
                tableStore.state.draftManifest
              );
              trackEvent(
                'developers.app_manifests.sandbox_manifest_editor.opened'
              );
            }}
          >
            {I18n.t('createNewVersion')}
          </Button>
          )}
          {!canCreateSandboxManifests && !formBasedExperience && (
            <InfoPopover>
              <Box>
                Sandbox manifest creation is temporarily disabled while we're working on infrastructure upgrades.
              </Box>
              <a href="https://developers.procore.com/documentation/announcements">See details here</a>
            </InfoPopover>
          )}
        </FlexList>
      </Box>
      <Table.Container style={{ width: '100%' }}>
        <Table data-qa="apps-manifests-table">
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell data-qa="manifest-version-header" snugfit>
                {I18n.t('version')}
              </Table.HeaderCell>
              <Table.HeaderCell data-qa="manifest-created-at-header" snugfit>
                {I18n.t('createdAt')}
              </Table.HeaderCell>
              <Table.HeaderCell
                data-qa="manifest-app-version-id-header"
                snugfit
              >
                {I18n.t('appVersionKey')}
              </Table.HeaderCell>
              <Table.HeaderCell data-qa="manifest-promote-header" snugfit />
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {displayedManifests.map((manifest) => (
              <Table.BodyRow key={manifest.id}>
                <Table.BodyCell snugfit>
                  <Box padding="none lg">
                    <a
                      href="javascript:;"
                      data-qa={`manifest-${manifest.version}-view-button`}
                      onClick={() =>
                        tableStore.handlers.setViewingManifest(manifest)
                      }
                      size="sm"
                      variant="secondary"
                    >
                      {canSetSemanticVersions
                        ? manifest.semantic_version
                        : manifest.version}
                    </a>
                  </Box>
                </Table.BodyCell>
                <Table.BodyCell snugfit>
                  <Table.TextCell>{manifest.created_at}</Table.TextCell>
                </Table.BodyCell>
                <Table.BodyCell snugfit>
                  <Form.Field
                    readOnly
                    style={{ padding: '6px', width: '100%' }}
                    type="text"
                    data-qa={`manifest-${manifest.version}-app-version-key`}
                    value={manifest.app_version_id}
                    onClick={autoSelectCopy}
                  />
                </Table.BodyCell>
                <Table.BodyCell>
                  <Flex justifyContent="center">
                    <Modal.State>
                      {(visibility) => (
                        <>
                          {!formBasedExperience && manifest.promotable && (
                            <Button
                              data-qa={`manifest-${manifest.version}-promote-button`}
                              onClick={visibility.show}
                              size="sm"
                              variant="secondary"
                              disabled={!canModifyManifests}
                            >
                              {I18n.t('promote')}
                            </Button>
                          )}

                        {formBasedExperience && (
                          <Button
                            data-qa="view-manifest-button"
                            onClick={() =>
                              tableStore.handlers.setViewingManifest(manifest)
                            }
                            size="sm"
                            variant="primary"
                          >
                          View Manifest
                          </Button>
                        )}

                          <SimpleConfirmModal
                            header={I18n.t('promoteConfirmationModal.header')}
                            body={<>
                              <p>
                                {I18n.t('promoteConfirmationModal.promotionExplanation')}
                              </p>
                              {canSetSemanticVersions ? <>
                                    <p>
                                      {I18n.t('promoteConfirmationModal.releaseNotesExplanation')}
                                    </p>
                                    <Box padding="md">
                                      <Form.Textarea
                                        required
                                        data-qa="release-notes"
                                        value={releaseNotes}
                                        onChange={(e) => (setReleaseNotes(e.target.value))}
                                        type="textarea"
                                        label={<>
                                          <strong>
                                            {I18n.t('promoteConfirmationModal.releaseNotes')}
                                          </strong>
                                        </>}
                                      />
                                    </Box>
                                  </> : <></>}
                              </>}
                            cancelText={I18n.t(
                              'promoteConfirmationModal.cancel'
                            )}
                            confirmText={I18n.t(
                              'promoteConfirmationModal.confirm'
                            )}
                            dataQaRoot="promote"
                            visibility={visibility}
                            onConfirm={() => {
                              promoteManifest(manifest.app_version_id, releaseNotes);
                            }}
                          />
                        </>
                      )}
                    </Modal.State>
                  </Flex>
                </Table.BodyCell>
              </Table.BodyRow>
            ))}
          </Table.Body>
        </Table>
      </Table.Container>
      {viewingManifest && (
        <ManifestModal
          viewOnly
          parentApp={parentApp}
          onClose={clearViewingManifest}
          manifest={_manifestToString(I18n, viewingManifest.content)}
        />
      )}
      {showCreateModal && (
        <ManifestModal
          templatesLoading={templates.state.loading}
          parentApp={parentApp}
          onClose={({ resetContent }) => {
            if (resetContent) {
              tableStore.handlers.closeAndResetModalContent(
                tableStore.state.initialEditorContent
              );
            } else {
              tableStore.handlers.setModalVisibility(false);
            }
            trackEvent(
              'developers.app_manifests.sandbox_manifest_editor.closed'
            );
          }}
          manifest={draftManifest}
          onChange={tableStore.handlers.setDraftManifest}
          semanticVersion={semanticVersion}
          onChangeSemanticVersion={tableStore.handlers.setSemanticVersion}
          onCreate={() => createManifest(draftManifest, semanticVersion)}
          onManifestValidationSuccess={() => {
            tableStore.handlers.setDraftManifest(
              _manifestToString(I18n, JSON.parse(draftManifest))
            );
          }}
          templateSnippets={templates.state.templateSnippets}
          hasExistingManifest={!!latestManifest}
          latestManifestVersion={
            latestManifest ? latestManifest.semantic_version : I18n.t('none')
          }
          canViewClientCredentials={canViewClientCredentials}
          canSetSemanticVersions={canSetSemanticVersions}
          dmsaPermissions={dmsaPermissions}
        />
      )}
    </>
  );
};

SandboxManifestsTable.propTypes = {
  manifests: PT.arrayOf(PT.shape({})),
  parentApp: PT.shape({
    id: PT.string,
    type: PT.string.isRequired,
  }),
  createManifest: PT.func,
  promoteManifest: PT.func,
  templates: PT.shape({
    handlers: PT.shape({
      fetch: PT.func,
      fetchTemplateSnippets: PT.func,
    }),
    state: PT.shape({
      templatesMap: PT.shape({}),
      loading: PT.bool,
      error: PT.shape({}),
      snippetsLoading: PT.bool,
      templateSnippets: PT.shape({}),
    }),
  }).isRequired,
  sandboxManifestTableStore: PT.shape({
    state: PT.shape({
      draftManifest: undefined,
      viewingManifest: PT.shape({}),
      tableExpanded: PT.bool,
      showCreateModal: PT.bool,
    }),
    handlers: PT.shape({
      setDraftManifest: PT.func,
      setCreateModalVisibility: PT.func,
      setViewingManifest: PT.func,
      toggleTableExpanded: PT.func,
    }),
  }).isRequired,
  canModifyManifests: PT.bool,
  trackEvent: PT.func,
  canViewClientCredentials: PT.bool,
  canSetSemanticVersions: PT.bool,
  canCreateSandboxManifests: PT.bool,
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
};

SandboxManifestsTable.defaultProps = {
  manifests: [],
  parentApp: {
    id: '',
  },
};

export default SandboxManifestsTable;
