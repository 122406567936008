/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import moment from 'moment';
import { pathOr } from 'ramda';
import PT from 'prop-types';
import {
  Banner,
  Box,
  Breadcrumbs,
  Button,
  ErrorBanner,
  Modal,
  DetailPage,
  Page,
  Sidebar,
  Spinner,
  Tabs,
  ToolHeader,
} from '@procore/core-react';
import { File, People, Plus } from '@procore/core-icons';
import Metrics from '@/react/shared/Metrics';
import Form from '@/react/shared/form';
import ErrorUtil from '@/utils/errorUtil';
import styled from 'styled-components';
import TabGeneral from './TabGeneral';
import TabSandbox from './TabSandbox';
import TabMarketplace from './TabMarketplace';
import { TABS } from './constants';
import { AdminDeveloperAppShowStorePT } from './StateContainer';
import MarketplaceAppForm from '@/react/common/marketplace_form';
import MarketplaceErrorBanner from '@/react/shared/MarketplaceErrorBanner';
import { isHigherOrEqualSemanticVersion } from '@/utils/semverUtil';

class Show extends React.Component {
  getErrorBanners(I18n) {
    const { state, handlers } = this.props.adminDeveloperAppShowStore;
    const { error } = state;

    const errorKeys = Object.keys(error);
    if (errorKeys.length === 0) {
      return null;
    }

    const errorTitleMap = {
      save: I18n.t('errorTitles.save'),
      load: I18n.t('errorTitles.load'),
    };

    return (
      <div>
        {errorKeys.map((errKey) => (
          <BannerWithError
            key={errKey}
            title={errorTitleMap[errKey]}
            error={error[errKey]}
            onClick={() => handlers.clearError(errKey)}
          />
        ))}
      </div>
    );
  }

  onTabSwitch = (I18n, tab) => {
    const { state, handlers } = this.props.adminDeveloperAppShowStore;

    if (state.tab === TABS.MARKETPLACE && state.unsavedData) {
      const resp = window.confirm(I18n.t('unsavedPrompt'));
      // Only switch tab after confirming
      if (resp) {
        handlers.switchTab(tab);
      }
    } else {
      handlers.switchTab(tab);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  formatTimestamp(timestamp) {
    return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
  }

  activeTab() {
    const { state, handlers } = this.props.adminDeveloperAppShowStore;
    const marketplaceApp =
      state.app?.unpublished_app || state.app?.published_app;

    switch (state.tab) {
      case TABS.SANDBOX:
        return <TabSandbox app={state.app} events={handlers} />;
      case TABS.MARKETPLACE:
        const productionManifests = state.manifests.ProductionEnvironment;
        const lastPublishedManifest = productionManifests.find(
          (manifest) => manifest.state === 'published'
        );
      
        const possibleVersions = productionManifests.filter((manifest) =>
          isHigherOrEqualSemanticVersion(
            manifest.semantic_version,
            lastPublishedManifest?.semantic_version
          )
        );

        return (
          <>
            <MarketplaceErrorBanner />
            <MarketplaceAppForm
              isAdminView
              canEdit
              canSubmitMarketplaceApp
              unmappedFields={marketplaceApp}
              unmappedPublishedFields={pathOr(
                {},
                ['app', 'published_app'],
                state
              )}
              save={handlers.handleCreateOrUpdateMarketplaceApp} // Save expects a function that returns a promise
              uploadWistiaVideo={state.api.uploadWistiaVideo}
              isProcoreEmployee={this.props.isProcoreEmployee}
              onChange={handlers.onMarketplaceChange}
              onUpdate={handlers.onMarketplaceUpdate}
              productTools={this.props.productTools}
              regions={this.props.regions}
              countries={this.props.countries}
              trackEvent={handlers.trackEvent}
              marketplaceDraftListingPath={
                this.props.marketplaceDraftListingPath
              }
              newDraftPreviewActive={this.props.newDraftPreviewActive}
              developerAppId={state.app.id}
              marketplaceEnabled={state.app.marketplace_enabled}
              onAppChange={handlers.appChange}
              updateDeveloperApp={state.api.updateApp}
              setError={(err) => {
                handlers.setError('marketplace', err.message);
              }}
              applicationEnabled={
                this.props.marketplaceListingApplicationEnabled
              }
              possibleVersions={possibleVersions}
              securityStandards={this.props.securityStandards}
              ssoProviders={this.props.ssoProviders}
            />
          </>
        );

      case TABS.METRICS:
        return <Metrics isAdminView metricsData={this.props.metricsData} />;
      case TABS.GENERAL:
      default:
        return (
          <TabGeneral
            switchManifestTab={handlers.switchManifestTab}
            manifestTab={state.manifestTab}
            loadingMap={state.loadingMap}
            app={state.app}
            appListingName={state.appListingName}
            manifests={state.manifests}
            events={handlers}
            canCreateSandboxManifests={this.props.canCreateSandboxManifests}
            dmsaPermissions={this.props.dmsaPermissions}
          />
        );
    }
  }

  infoMessage(I18n) {
    let type;
    let message;

    const { app } = this.props.adminDeveloperAppShowStore.state;

    if (!app.uid) {
      type = 'warning';
      message = I18n.t('messages.noProdCredentials');
    } else if (app.external_procore_owner_id !== app.owner.procore_id) {
      type = 'error';
      message = I18n.t('messages.idMismatch');
    } else {
      //  No warnings or errors
      return null;
    }

    return <div className={`highlight-${type}`}>{message}</div>;
  }

  render() {
    const { adminDeveloperAppShowStore, I18n } = this.props;
    const { state, handlers } = adminDeveloperAppShowStore;

    return (
      <Spinner loading={state.loadingMap.global}>
        <DetailPage width="xl" initialIsVisible>
          <DetailPage.Main className="page-body">
            <DetailPage.Breadcrumbs>
              <Breadcrumbs className="breadcrumbs">
                <a href="/admin">
                  <Breadcrumbs.Crumb>{I18n.t('admin')}</Breadcrumbs.Crumb>
                </a>
                <a href={state.developerAppsIndexPath}>
                  <Breadcrumbs.Crumb>
                    {I18n.t('developerApps')}
                  </Breadcrumbs.Crumb>
                </a>
                <a href={state.developerAppsShowPath}>
                  <Breadcrumbs.Crumb active>
                    {state.app.internal_name}
                  </Breadcrumbs.Crumb>
                </a>
              </Breadcrumbs>
            </DetailPage.Breadcrumbs>

            <ToolHeader>
              <ToolHeader.Title>
                {I18n.t('edit')} - {state.app.internal_name}
                {state.app.deleted_at
                  ? `(${I18n.t('labels.deleted').toUpperCase()})`
                  : ''}
              </ToolHeader.Title>
              <TabList
                tabs={TABS}
                active={state.tab}
                onClick={(tab) => this.onTabSwitch(I18n, tab)}
              />
            </ToolHeader>

            {this.getErrorBanners(I18n)}

            <DetailPage.Body>
              <DetailPage.Card>
                <DetailPage.Section>{this.activeTab(I18n)}</DetailPage.Section>
              </DetailPage.Card>
            </DetailPage.Body>
          </DetailPage.Main>

          <DetailPage.Aside open>
            <Sidebar>
              <Sidebar.Button>
                <Button
                  icon={<Plus />}
                  block
                  data-qa="integration-contact-sidebar-button"
                >
                  <a
                    className="default"
                    href={`/admin/developer_apps/${state.app.id}/contacts/new`}
                  >
                    {' '}
                    {I18n.t('actions.newContact')}
                  </a>
                </Button>
              </Sidebar.Button>
              {state.app.deleted_at && (
                <Sidebar.Button>
                  <Button
                    onClick={handlers.undelete}
                    block
                    icon={<File />}
                    data-qa="undelete-app-sidebar-button"
                  >
                    {I18n.t('actions.undelete')}
                  </Button>
                </Sidebar.Button>
              )}
              <ChangeOwner
                I18n={I18n}
                newOwner={state.newOwner}
                onChange={handlers.appChange}
                setNewOwner={handlers.setNewOwner}
              />
              <Sidebar.Divider />

              <Sidebar.Header>
                <Sidebar.HeaderLabel>
                  <strong>{I18n.t('ownerInfo')}</strong>
                </Sidebar.HeaderLabel>
              </Sidebar.Header>

              <Box margin="none lg sm lg">
                <div className="form-group">
                  <label>{I18n.t('labels.name')}</label>
                  {state.app.owner.name}
                </div>

                <div className="form-group">
                  <label>{I18n.t('labels.email')}</label>
                  {state.app.owner.email}
                </div>

                <div className="form-group">
                  <label>{I18n.t('labels.procore_id')}</label>
                  {state.app.owner.procore_id}
                </div>

                {this.infoMessage(I18n)}
              </Box>
              <Sidebar.Divider />

              <Sidebar.Header>
                <Sidebar.HeaderLabel>
                  <strong>{I18n.t('metadata')}</strong>
                </Sidebar.HeaderLabel>
              </Sidebar.Header>

              <Box margin="none lg sm lg">
                <div className="form-group">
                  <label>{I18n.t('labels.created_at')}</label>
                  {this.formatTimestamp(state.app.created_at)}
                </div>
                <div className="form-group">
                  <label>{I18n.t('labels.updated_at')}</label>
                  {this.formatTimestamp(state.app.updated_at)}
                </div>

                <div className="form-group">
                  <label>{I18n.t('labels.deleted_at')}</label>
                  {state.app.deleted_at
                    ? this.formatTimestamp(state.app.deleted_at)
                    : I18n.t('labels.not_deleted')}
                </div>
              </Box>
            </Sidebar>
          </DetailPage.Aside>
        </DetailPage>
      </Spinner>
    );
  }
}

const BannerWithError = ({ title, error, onClick }) => {
  if (error) {
    const msg = ErrorUtil.extractErrorMsg(error);

    return (
      <ErrorBanner>
        <Banner.Content>
          <Banner.Title>{title}</Banner.Title>
          <Banner.Body>{msg}</Banner.Body>
        </Banner.Content>
        <Banner.Dismiss onClick={onClick} />
      </ErrorBanner>
    );
  }

  return null;
};

BannerWithError.propTypes = {
  title: PT.string.isRequired,
  error: PT.shape({}),
  onClick: PT.func.isRequired,
};

BannerWithError.defaultProps = {
  error: {},
};

const TabList = ({ tabs, active, onClick }) => (
  <Tabs className="tabs tabs--full">
    {Object.entries(tabs).map(([key, value]) => (
      <Tabs.Tab
        key={key}
        data-qa={`admin-${key.toLowerCase()}-tab`}
        variant={active === value ? 'active' : ''}
      >
        <Tabs.Link onClick={() => onClick(value)}>{value}</Tabs.Link>
      </Tabs.Tab>
    ))}
  </Tabs>
);

TabList.propTypes = {
  tabs: PT.shape({}).isRequired,
  active: PT.string.isRequired,
  onClick: PT.func.isRequired,
};

const ChangeOwner = ({ I18n, newOwner, setNewOwner, onChange }) => {
  const StyledDiv = styled.div`
    height: 450px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  return (
    <Modal.State>
      {({ isVisible, show, hide }) => (
        <>
          <Sidebar.Button>
            <Button onClick={show} block icon={<People />}>
              {I18n.t('actions.changeOwner')}
            </Button>
          </Sidebar.Button>
          <Modal open={isVisible} onClickOverlay={hide}>
            <StyledDiv>
              <Modal.Header onClose={hide}>
                Change Owner (Dangerous)
              </Modal.Header>
              <Modal.Body style={{ overflow: 'initial' }}>
                <p>{I18n.t('messages.careful')}</p>
                <Form.AsyncPicker
                  clearable={false}
                  value={newOwner}
                  sift
                  label={I18n.t('labels.owner')}
                  url="/api/v1/admin/developers"
                  labelKey="email"
                  onChange={(value) => {
                    setNewOwner(value);
                  }}
                />
                <p>{I18n.t('messages.newOwnerSave')}</p>
              </Modal.Body>

              <Modal.Footer className="flex-end">
                <Modal.FooterButtons>
                  <Button variant="tertiary" onClick={hide}>
                    {I18n.t('actions.cancel')}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      hide();
                      onChange('owner', newOwner);
                    }}
                  >
                    {I18n.t('actions.setOwner')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </StyledDiv>
          </Modal>
        </>
      )}
    </Modal.State>
  );
};

ChangeOwner.propTypes = {
  tabs: PT.shape({}).isRequired,
  newOwner: PT.string.isRequired,
  I18n: PT.func.isRequired,
  setNewOwner: PT.func.isRequired,
  onChange: PT.func.isRequired,
};

Show.propTypes = {
  adminDeveloperAppShowStore: AdminDeveloperAppShowStorePT.isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  metricsData: PT.shape({}),
  isProcoreEmployee: PT.bool,
  productTools: PT.arrayOf(PT.shape({})),
  regions: PT.arrayOf(PT.shape({})),
  marketplaceDraftListingPath: PT.string,
  newDraftPreviewActive: PT.bool,
  canCreateSandboxManifests: PT.bool,
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
  marketplaceListingApplicationEnabled: PT.bool,
};

Show.defaultProps = {
  metricsData: {},
  isProcoreEmployee: false,
  productTools: [],
  regions: [],
  marketplaceDraftListingPath: '',
};

export default Show;
