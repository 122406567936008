import React from 'react';
import { Table } from '@procore/core-react';
import { pathOr } from 'ramda';
import {
  buildDatesForTimePeriod,
  strToDate,
  formatMetricsDataDates,
  mapCountForDate,
} from './utils';

const mapMetricsHeaders = (I18n, formattedMetricsData) =>
  Object.keys(formattedMetricsData).map((metric) => (
    <Table.HeaderCell key={metric}>
      {I18n.t(`metricsTable.headers.${metric}`)}
    </Table.HeaderCell>
  ));

const mapTableBody = (allDatesWithinTimePeriod, formattedMetricsData) =>
  allDatesWithinTimePeriod.map((date) => (
    <Table.BodyRow key={date}>
      <Table.BodyCell key={date}>
        <Table.TextCell>
          {strToDate(date).format('DD MMMM YYYY')}
        </Table.TextCell>
      </Table.BodyCell>
      {mapMetricsCounts(formattedMetricsData, date)}
    </Table.BodyRow>
  ));

const mapMetricsCounts = (formattedMetricsData, date) =>
  Object.keys(formattedMetricsData).map((metric) => (
    <Table.BodyCell key={metric}>
      <Table.TextCell>
        {mapCountForDate(formattedMetricsData[metric], date)}
      </Table.TextCell>
    </Table.BodyCell>
  ));

const MetricsTable = ({ I18n, metricsData, ...rest }) => {
  const allDatesWithinTimePeriod = buildDatesForTimePeriod(metricsData);
  const formattedMetricsData = formatMetricsDataDates(metricsData);

  return (
    <Table.Container {...rest}>
      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell key={'date'}>
              {I18n.t('metricsTable.headers.date')}
            </Table.HeaderCell>
            {mapMetricsHeaders(I18n, formattedMetricsData)}
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {mapTableBody(allDatesWithinTimePeriod, formattedMetricsData)}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

export default MetricsTable;
