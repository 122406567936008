import React from 'react';
import PT from 'prop-types';
import { Box } from '@procore/core-react';

const CardFooter = ({ children }) => (
  <footer className="core-react-card__footer">
    <Box padding="xl">{children}</Box>
  </footer>
);

CardFooter.propTypes = {
  children: PT.node.isRequired,
};

export default CardFooter;
