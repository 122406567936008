import React, { useEffect } from 'react';
import PT from 'prop-types';
import Notification from '../Notification';
import { useI18nContext } from '@procore/core-react';

const ShowPage = ({
  notificationsBaseUrl,
  notificationId,
  notificationReaderStore,
}) => {
  const I18n = useI18nContext();
  const { state } = notificationReaderStore;
  useEffect(() => {
    notificationReaderStore.handlers.fetchNotification(notificationId);
  }, []);

  const notification = state.notification || {};

  return (
    <div className="notification-reader">
      <div className="margin-y-md">
        <a href={notificationsBaseUrl}>{I18n.t('backToNotifications')}</a>
      </div>
      <Notification
        className="dev-notification "
        title={notification.title}
        timestamp={notification.created_at}
        markdown={notification.body || ''}
      />
    </div>
  );
};

ShowPage.propTypes = {
  notificationId: PT.string.isRequired,
  notificationsBaseUrl: PT.string.isRequired,
  notificationReaderStore: PT.shape({
    state: PT.shape({
      loading: PT.bool,
      error: PT.instanceOf(Error),
      notification: PT.array,
    }),
    handlers: PT.shape({
      fetchNotification: PT.func,
    }),
  }).isRequired,
};

// Wrap in higher order component that handles notification state management
export default ShowPage;
