import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    none: 'None',
    readonly: 'Read-Only',
    standard: 'Standard',
    admin: 'Admin',
    permissionBuilder: 'Permission Builder',
    selectTheLevel:
      'Select the level of permission for the tools your app needs.',
    tool: 'Tool',
    cancel: 'Cancel',
    updateManifest: 'Update Manifest',
    error: 'Error',
    errorExistingPermissions: 'Error parsing existing permissions',
    company: 'Company',
    openBuilder: 'Open Builder',
    project: 'Project',
  },
});
