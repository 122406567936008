import React from 'react';
import PT from 'prop-types';
import Qs from 'qs';
import axios from 'axios';
import cx from 'classnames';
import { omit } from 'ramda';
import { Typography } from '@procore/core-react';

import 'react-select/dist/react-select.css';

import Select, { Async } from 'react-select';
import { getHeaders } from '@/utils/apiUtil';
import { FormError, Label } from './Form';

export const SelectPicker = ({
  className,
  label,
  onChange,
  placeholder,
  value,
  options,
  required,
  disabled,
  clearable,
  errors,
  'data-qa': dataQa,
  ...rest
}) => {
  return (
    <div className={cx(className)}>
      <Label required={required} label={label} />
      {errors && (
        <Typography color="red50" intent="small" italic>
          {errors.join(', ')}
        </Typography>
      )}
      <div data-qa={dataQa}>
        <Select
          inputProps={{
            'data-qa': `${dataQa}-input`,
            ...rest,
          }}
          disabled={disabled}
          clearable={clearable}
          placeholder={placeholder}
          value={value}
          options={options}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

SelectPicker.propTypes = {
  disabled: PT.bool,
  clearable: PT.bool,
  className: PT.string,
  required: PT.bool,
  label: PT.node,
  onChange: PT.func,
  options: PT.arrayOf(PT.object),
  placeholder: PT.string,
  value: PT.string,
  'data-qa': PT.string,
};

SelectPicker.defaultProps = {
  disabled: false,
  clearable: false,
  className: '',
  required: false,
  label: '',
  onChange: () => {},
  options: [],
  placeholder: 'Select...',
  value: '',
  'data-qa': '',
};

export const AsyncPicker = ({
  className,
  clearable,
  sift,
  label,
  labelKey,
  onChange,
  placeholder,
  value,
  valueKey,
  url,
  required,
  disabled,
  multi,
  showHref,
  errors,
  staticOptions,
  'data-qa': dataQa,
  ...rest
}) => {
  const getOptions = (input) => {
    const search = { search: input };
    const params = sift ? { filters: search } : search;

    return axios
      .request({
        method: 'get',
        url,
        params,
        headers: getHeaders(),
        paramsSerializer: (params) =>
          Qs.stringify(params, { arrayFormat: 'brackets' }),
      })
      .then((resp) => {
        const { data } = resp;
        let options;
        if (!showHref) {
          options = data.map((a) => omit(['href'], a));
        } else {
          options = data;
        }

        return { options: staticOptions.concat(options) };
      });
  };

  return (
    <div className={cx(className)}>
      <Label required={required} label={label} />
      <div data-qa={dataQa}>
        <Async
          inputProps={{
            'data-qa': `${dataQa}-input`,
          }}
          disabled={disabled}
          clearable={clearable}
          multi={multi}
          value={value}
          placeholder={placeholder}
          valueKey={valueKey}
          labelKey={labelKey}
          loadOptions={getOptions}
          onChange={onChange}
          {...rest}
        />
      </div>
      <FormError errors={errors} />
    </div>
  );
};

AsyncPicker.propTypes = {
  className: PT.string,
  clearable: PT.bool,
  sift: PT.bool,
  disabled: PT.bool,
  multi: PT.bool,
  required: PT.bool,
  showHref: PT.bool,
  label: PT.node,
  labelKey: PT.string,
  onChange: PT.func,
  placeholder: PT.string,
  staticOptions: PT.arrayOf(PT.shape({})),
  url: PT.string,
  value: PT.oneOfType([PT.string, PT.number, PT.arrayOf(PT.string)]),
  valueKey: PT.string,
  'data-qa': PT.string,
};

AsyncPicker.defaultProps = {
  className: '',
  clearable: false,
  sift: true,
  disabled: false,
  required: false,
  multi: false,
  label: '',
  labelKey: 'name',
  onChange: () => {},
  placeholder: 'Select...',
  staticOptions: [],
  value: '',
  valueKey: 'id',
  showHref: false,
  url: '',
  'data-qa': '',
};
