import I18nUtil from '../../utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    legacy_api: 'Vapid',
    rest: 'Rest',
    reference: 'Reference',
    reference_error: 'Error Fetching References...',
  },
});
