import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    admin: 'Admin',
    createdAt: 'Created At',
    createdBy: 'Created By',
    creatorEmail: 'Creator Email',
    deletedAt: 'Deleted At',
    developerApps: 'Developer Apps',
    errors: {
      occurred: 'An error occurred',
    },
    inReview: 'In Review',
    inReviewAt: 'In Review At',
    manageDeveloperApps: 'Manage Developer Apps',
    manifest: 'Manifest',
    marketplace: 'Marketplace',
    marketplaceListing: 'Marketplace Listing',
    name: 'Name',
    new: 'New',
    notAvailable: 'N/A',
    ownerEmail: 'Owner Email',
    published: 'Published',
    reviewQueue: 'Review Queue',
    sidebar: {
      minimizeSidebar: 'Minimize Sidebar',
    },
    update: 'Update',
    search: 'Search'
  },
});
