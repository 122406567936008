import React from 'react';
import PT from 'prop-types';
import { Button, ConfirmModal, Modal, Spinner } from '@procore/core-react';

const DeleteAppModal = ({ I18n, visibility, onDelete, isDeletingApp }) => (
  <ConfirmModal
    open={visibility.isVisible}
    onClose={visibility.hide}
    onClickOverlay={visibility.hide}
    headline={I18n.t('deleteAppConfirmation')}
  >
    <Spinner loading={isDeletingApp}>
      <Modal.Body>{I18n.t('deleteAppWarning')}</Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Button
            data-qa="cancel-delete-app-button"
            variant="tertiary"
            disabled={isDeletingApp}
            onClick={visibility.hide}
          >
            {I18n.t('cancel')}
          </Button>
          <Button
            data-qa="confirm-delete-app-button"
            variant="secondary"
            disabled={isDeletingApp}
            onClick={onDelete}
          >
            {I18n.t('delete')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Spinner>
  </ConfirmModal>
);

DeleteAppModal.propTypes = {
  I18n: PT.shape({}).isRequired,
  onDelete: PT.func.isRequired,
  isDeletingApp: PT.bool,
  visibility: PT.shape({
    isVisible: PT.bool,
    hide: PT.func,
    show: PT.func,
    toggle: PT.func,
  }).isRequired,
};

DeleteAppModal.defaultProps = {
  isDeletingApp: false,
};

export default DeleteAppModal;
