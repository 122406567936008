import axios from 'axios';
import { getHeaders } from '@/utils/apiUtil';

const api = (notificationsAdminAPIBaseUrl) => ({
  fetchNotifications: (successCb, errorCb) => {
    axios
      .get(notificationsAdminAPIBaseUrl, { headers: getHeaders() })
      .then(successCb)
      .catch(errorCb);
  },

  fetchNotification: (notificationId, successCb, errorCb) => {
    axios
      .get(`${notificationsAdminAPIBaseUrl}/${notificationId}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        const notification = resp.data;
        successCb(notification);
      })
      .catch((err) => {
        errorCb(err);
      });
  },

  save: (notification, successCb, errorCb) => {
    const method = notification.id ? 'patch' : 'post';
    let path = notificationsAdminAPIBaseUrl;

    if (notification.id) {
      path += `/${notification.id}`;
    }

    axios[method](path, notification)
      .then((resp) => successCb(resp.data))
      .catch(errorCb);
  },

  delete: (notificationId, successCb, errorCb) => {
    axios
      .delete(`${notificationsAdminAPIBaseUrl}/${notificationId}`)
      .then((resp) => {
        successCb();
      })
      .catch((err) => {
        errorCb(err);
      });
  },
});

export default api;
