import React from 'react';
import PT from 'prop-types';
import { Modal, Button, Spinner, Box } from '@procore/core-react';
import Form from '@/react/shared/form';

class ResetCredentialsConfirmationModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      secretResetCount: props.secretResetCount
    };
  }

  render() {
    const {
      I18n,
      appUid,
      loading,
      resetAppSecret,
      resetAppSecretSuccess,
      onError,
      isVisible,
      show,
      hide,
      resetCredentialsConfirmed,
      toggleResetCredentialsConfirmed,
      canModifyCredentials,
      qaPrefix,
      production,
    } = this.props;

    return (
      <React.Fragment>
        <Form.Button
          data-qa={`${qaPrefix}-reset-credentials`}
          label={I18n.t('labels.clientSecret')}
          text={production && this.state.secretResetCount == 0 ? I18n.t('modals.showProductionCredentials.button') : I18n.t('modals.resetProductionCredentials.generateNewClientSecret')}
            onClick={() => {
              if(!production || this.state.secretResetCount > 0) {
                show();
              } else {
                resetAppSecret(
                  appUid,
                  (resp) => {
                    resetAppSecretSuccess(resp);
                    this.setState({ secretResetCount: this.state.secretResetCount + 1 })
                    hide();
                  },
                  (section, err) => {
                    onError(section, err);
                    hide();
                  }
                );
              }
            }}
          disabled={!canModifyCredentials}
        />
        <Modal open={isVisible} onClickOverlay={hide}>
          <Spinner loading={loading}>
            <Modal.Header onClose={hide}>
              {I18n.t('modals.resetProductionCredentials.title')}
            </Modal.Header>
            <Modal.Body>
              <p>{I18n.t('modals.resetProductionCredentials.message')}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: I18n.t(
                    'modals.credentials.singleUseCredentialsExplanation',
                    {
                      important_text: `<b>${I18n.t(
                        'modals.credentials.importantText'
                      )}</b>`,
                      once_ever_text: `<b>${I18n.t(
                        'modals.credentials.onceEverText'
                      )}</b>`,
                    }
                  ),
                }}
              />

              <Form.Checkbox
                data-qa={`${qaPrefix}-reset-credentials-confirm-checkbox`}
                checked={resetCredentialsConfirmed}
                onClick={toggleResetCredentialsConfirmed}
                label={I18n.t(
                  'modals.resetProductionCredentials.confirmMessage'
                )}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.FooterButtons>
                <Button
                  variant="tertiary"
                  onClick={hide}
                  data-qa={`${qaPrefix}-reset-credentials-cancel`}
                >
                  {I18n.t('modals.resetProductionCredentials.cancel')}
                </Button>
                <Button
                  className="button-warning"
                  data-qa={`${qaPrefix}-reset-credentials-confirm`}
                  disabled={!resetCredentialsConfirmed}
                  onClick={() => {
                    resetAppSecret(
                      appUid,
                      (resp) => {
                        resetAppSecretSuccess(resp);
                        hide();
                      },
                      (section, err) => {
                        onError(section, err);
                        hide();
                      }
                    );
                  }}
                >
                  {I18n.t('modals.resetProductionCredentials.submit')}
                </Button>
              </Modal.FooterButtons>
            </Modal.Footer>
          </Spinner>
        </Modal>
      </React.Fragment>
    );
  }
}

ResetCredentialsConfirmationModal.propTypes = {
  I18n: PT.shape({}).isRequired,
  appUid: PT.string,
  loading: PT.bool.isRequired,
  resetAppSecret: PT.func.isRequired,
  resetAppSecretSuccess: PT.func.isRequired,
  onError: PT.func.isRequired,
  canModifyCredentials: PT.bool.isRequired,
  qaPrefix: PT.string.isRequired,
  secretResetCount: PT.number,
  production: PT.bool.isRequired,
};

export default ResetCredentialsConfirmationModal;
