import React, { createContext, useContext, useState, useMemo } from 'react';
import { ALL_TABS } from './SideNav.constants';
import PropTypes from 'prop-types';

export const TabContext = createContext({
  activeTab: null,
  setActiveTab: () => {},
  errors: {},
  setTabErrors: () => {},
});

export const useSideNavContext = () => {
  return useContext(TabContext);
};

export const SideNavProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(ALL_TABS.CONFIGURATIONS);
  const [errors, setErrors] = useState({});

  const value = useMemo(
    () => ({ activeTab, setActiveTab, errors, setErrors }),
    [activeTab, errors]
  );

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

SideNavProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
