import axios from 'axios';
import Qs from 'qs';
import { getHeaders } from '@/utils/apiUtil';

export default {
  fetchApps(environment, pageNumber, pageSize, search, sort, name, uid) {
    return axios.request({
      url: '/api/v1/admin/oauth_applications',
      method: 'get',
      params: {
        environment: environment,
        page: pageNumber,
        per_page: pageSize,
        filters: {
          name: name,
          uid: uid,
          search: search,
        },
        sort: sort,
      },
      headers: getHeaders(),
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });
  },

  getUserOptions(input) {
    return axios.request({
      url: '/api/v1/admin/developers',
      method: 'get',
      params: { search: input },
    });
  },
};
