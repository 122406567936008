import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    admin: 'Admin',
    appEngagementReport: 'App Engagement Report',
    companies_using_app: 'Companies Using This App',
    company_name: 'Company Name',
    developerApps: 'Developer Apps',
    edit: 'Edit',
    last_updated_at: 'Last Updated At',
    ownerInfo: 'Owner Info',
    unsavedPrompt:
      'You have unsaved changes, are you sure you wish to continue?',
    actions: {
      cancel: 'Cancel',
      changeOwner: 'Change Owner',
      save: 'Save Changes',
      newContact: 'Integration Request Contact',
      setOwner: 'Set New Owner',
      undelete: 'Undelete',
    },
    detailedMetricsView: {
      totalMarketplaceVisits: 'Total Marketplace Visits',
      companyInstallations: 'Company Installations',
      noMetricsAvailable: 'No Metrics Available For This App At This Time',
    },
    descriptions: {
      appAvatar: `Set the logo for this app. Please ensure it is a square image larger
    than 200x200px. Limit 1 file. New uploads will replace existing logo.`,
      internal: `Fields submitted by a developer when creating their app.`,
      manifests: 'Add and view the application manifests.',
      public: `Information here is publicly facing. These fields will be pulled
    and displayed in the marketplace.`,
      appCredentials: `Data Pulled from Procore Database. Changing these settings
    will affect this application.`,
      contacts: `These are the contacts that will be emailed when an integration
    request is received.`,
      collaborators: 'The current collaborators on the application',
    },
    internalAccessConfirmation: `
    Warning: You are about to change the internal access of an application.
    Before confirming this change, please make sure that you have reached
    out to Squad DMX about this change.
  `,
    labels: {
      allowed_cors_domain: 'Allowed CORS Domain:',
      app_id: 'App ID:',
      app_secret: 'App Secret:',
      company: 'Company:',
      connectable: 'Connectable:',
      confidential: 'Confidential',
      created_at: 'Created At:',
      deleted_at: 'Deleted At:',
      deleted: 'Deleted',
      email: 'Email:',
      internal_api_access: 'Internal API Access:',
      internal_name: 'Internal Name:',
      legacy: 'Legacy:',
      app_listing: 'App Listing:',
      name: 'Name:',
      not_deleted: 'Not Deleted',
      oauth_redirect_uri: 'OAuth Redirect URI:',
      owner: 'Owner:',
      procore_id: 'Procore ID:',
      privacy: 'App credentials are set to:',
      public: 'Public',
      role: 'Role:',
      updated_at: 'Updated At:',
      company_id: 'Company ID',
      sandbox_uri: 'Sandbox URI',
      procore_app_id: 'Procore App ID',
    },
    errorTitles: {
      save: 'Could not save',
      load: 'Could not load',
      notAvailable: 'N/A',
    },
    installFeed: {
      title: 'Activity',
      demoCompany: 'DEMO COMPANY',
    },
    marketplaceApp: {
      emptyTitle: 'Marketplace App not submitted',
      emptyDescription:
        'The user has not yet submitted their Marketplace App Listing.',
    },
    messages: {
      appUndeleted: `App Undeleted!`,
      careful: `Note: Be extremely careful changing this! Changing the owner will
    immediately give someone else full control of this app + the ability to see
    the secrets.`,
      confirmItemDeletion: `Are you sure you want to delete this item?`,
      fileRemoved: `Removed File!`,
      filesUploaded: `Uploaded Files!`,
      idMismatch: `Error: The owner of this app is different between Procore and
    the Developer portal. Any lookups for this app will not work correctly
    until this is corrected.`,
      logoRemoved: `Removed Logo!`,
      logoUploaded: `Uploaded Logo!`,
      newOwnerSave: `Note: after setting the new owner, you will still have to
    save the application in order for the change to persist.`,
      noProdCredentials: `Notice: This app has not generated production credentials.`,
    },
    legacyHelp:
      'Legacy apps are not explicitly connected via app manifest, but are implicitly connected through API usage.',
    metadata: 'Metadata',
    no_applications_at_time: 'No installations at this time.',
    noCollaborators: 'No Collaborators other than the owner',
    noContactsSet: 'No Contacts Set',
    placeholders: {
      category: 'Select a Category...',
      marketplace_app: 'Select a Marketplace App...',
    },
    sectionHeaders: {
      collaborators: 'Collaborators',
      contacts: 'Integration Request Contacts',
      internal: 'Internal Developer App Fields',
      appAvatar: 'App Avatar',
      manifests: 'Manifests',
      appCredentials: 'Production OAuth Credentials',
      public: 'Public Facing Information',
    },
    connectableHelp: `Connectable apps are displayed on the company admin app management page.`,
  },
});
