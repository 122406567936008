import React from 'react';
import PT from 'prop-types';
import { Modal, Button, Box } from '@procore/core-react';
import Form from '@/react/shared/form';
import { autoSelectCopy } from '../../../../global_helpers/autoSelectCopy';

class AppCredentialsModal extends React.Component {
  render() {
    const { I18n, credentials, forgetSecret, isVisible, hide } = this.props;

    const forgetSecretAnd = (cb) => () => {
      forgetSecret();
      cb();
    };

    return (
      <Modal open={isVisible || credentials.secret}>
        <Modal.Header onClose={forgetSecretAnd(hide)}>
          {I18n.t('modals.productionCredentials.title')}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Content>
              <Form.Column>
                <Box display="flex-column" gap="lg">
                  <Form.Field
                    readOnly
                    data-qa="developer-app-client-id"
                    label={<span>{I18n.t('labels.clientId')}</span>}
                    type="text"
                    value={credentials.uid}
                    onClick={autoSelectCopy}
                  />
                  <Form.Field
                    readOnly
                    data-qa="developer-app-client-secret"
                    label={<span>{I18n.t('labels.clientSecret')}</span>}
                    type="text"
                    value={credentials.secret}
                    onClick={autoSelectCopy}
                  />
                </Box>
              </Form.Column>
            </Form.Content>
          </Form>
          <p
            dangerouslySetInnerHTML={{
              __html: I18n.t(
                'modals.credentials.singleUseCredentialsExplanation',
                {
                  important_text: `<b>${I18n.t(
                    'modals.credentials.importantText'
                  )}</b>`,
                  once_ever_text: `<b>${I18n.t(
                    'modals.credentials.onceEverText'
                  )}</b>`,
                }
              ),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Button
              variant="secondary"
              onClick={forgetSecretAnd(hide)}
              data-qa="credentials-back"
            >
              {I18n.t('modals.productionCredentials.close')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    );
  }
}

AppCredentialsModal.propTypes = {
  I18n: PT.shape({}).isRequired,
  credentials: PT.shape({}).isRequired,
  forgetSecret: PT.func.isRequired,
};

export default AppCredentialsModal;
