import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    page: {
      admin: 'Admin',
      createNotification: 'Create Notification',
      critical: 'Critical',
      errorOccurred: 'An error occurred',
      minimizeSidebar: 'Minimize Sidebar',
      notifications: 'Notifications',
      notify: 'Notify',
      summary: 'Summary',
      title: 'Title',
      update: 'Update',
      visible: 'Visible',
    },
    form: {
      add: 'Add',
      body: 'Body - This will NOT be in the email:',
      errorOccurred: 'An error occurred',
      notificationPreview: 'Notification Preview',
      summary:
        'Summary - For email notifications, this will be in the email body:',
      title:
        'Title - For email notifications, this will be in the email subject:',
      criticalNotification: `Critical Notification - Immediately send emails to
      all developers who remain opted-in to critical notification emails`,
      draftNotification: 'Draft Notifications are hidden from developers',
      update: 'Update',
      visible: 'Visible',
      markdownHelp: 'Markdown Notation Help?',
      showAfter:
        'Notification is not visible until that time. Format (YYYY-mm-dd)',
    },
    controls: {
      cancel: 'Cancel',
      notify: 'Notify',
      update: 'Update',
      confirmDelete: 'Are you sure you wish to delete this notification?',
      confirmSave: "You are about to create a critical notification which will cause emails to be sent out to those subscribed. Do you wish to continue?",
    },
  },
});
