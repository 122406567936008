import React, { useState } from 'react';
import {
  Banner,
  Box,
  Button,
  ErrorBanner,
  InfoBanner,
  useI18nContext,
} from '@procore/core-react';
import {
  MARKETPLACE_APP_STATES,
  MARKETPLACE_APPROVAL_STATES,
} from '@/react/shared/constants';

import AdminMessageModal from './modals/AdminMessageModal';
import DOMPurify from 'dompurify';

export const InReviewMessage = () => {
  const I18n = useI18nContext();
  return (
    <InfoBanner data-qa="marketplace-success-banner">
      <Banner.Content>
        <Banner.Title>
          {I18n.t('labels.marketplaceApp.inReviewMessage')}
        </Banner.Title>
        <Banner.Body>
          {I18n.t('labels.marketplaceApp.inReviewDetails')}
        </Banner.Body>
      </Banner.Content>
    </InfoBanner>
  );
};

const StateBanner = ({ state }) => {
  const I18n = useI18nContext();
  return (
    <InfoBanner data-qa="marketplace-info-banner">
      <Banner.Content>
        <Banner.Title>
          {I18n.t('labels.marketplaceApp.statusMessage')}
          {state === MARKETPLACE_APP_STATES.IN_REVIEW
            ? I18n.t('labels.marketplaceApp.inReview')
            : state}
        </Banner.Title>
      </Banner.Content>
    </InfoBanner>
  );
};

export const AdminMessageBanner = ({
  state,
  approval_state,
  admin_message,
  isAdminView,
}) => {
  if (!state || !approval_state) {
    return null;
  }
  const I18n = useI18nContext();
  const [adminMessageOpen, setAdminMessageOpen] = useState(false);

  if (
    state === MARKETPLACE_APP_STATES.IN_REVIEW ||
    approval_state === MARKETPLACE_APPROVAL_STATES.UNREVIEWED ||
    !admin_message
  ) {
    if (isAdminView) {
      return <StateBanner state={state} />;
    }
    return null;
  }

  const BannerType =
    approval_state === MARKETPLACE_APPROVAL_STATES.APPROVED
      ? InfoBanner // BHTODO: SuccessBanner is not defined, needs replacement
      : ErrorBanner;
  const titleMessage =
    approval_state === MARKETPLACE_APPROVAL_STATES.APPROVED
      ? 'labels.marketplaceApp.approvalMessage'
      : 'labels.marketplaceApp.rejectionMessage';

  return (
    <>
      <BannerType data-qa="marketplace-review-status-banner">
        <Banner.Content>
          <Banner.Title>{I18n.t(titleMessage)}</Banner.Title>
          {approval_state === MARKETPLACE_APPROVAL_STATES.REJECTED && (
            <Banner.Body>
              {I18n.t('labels.marketplaceApp.rejectBody')}
            </Banner.Body>
          )}
        </Banner.Content>
        {admin_message && (
          <Banner.Action>
            <Button
              variant="secondary"
              onClick={() => setAdminMessageOpen((val) => !val)}
            >
              {adminMessageOpen
                ? I18n.t('labels.marketplaceApp.hideDetails')
                : I18n.t('labels.marketplaceApp.showDetails')}
            </Button>
          </Banner.Action>
        )}
        <Banner.ExpandableContent expanded={adminMessageOpen}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(admin_message),
            }}
          />
        </Banner.ExpandableContent>
      </BannerType>
    </>
  );
};
