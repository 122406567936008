import { useState, useCallback } from 'react';

export const useDynamicFields = (
  initialFields,
  maxFields,
  fieldTemplate = ''
) => {
  const [fields, setFields] = useState(initialFields);

  const addField = useCallback(
    (onFieldChange) => {
      if (fields.length < maxFields) {
        const updated = [...fields, fieldTemplate];
        setFields(updated);
        onFieldChange(updated);
      }
    },
    [fields, maxFields, fieldTemplate]
  );

  const removeField = useCallback(
    (toRemoveIdx, onFieldChange) => {
      if (fields.length > 1) {
        const updated = fields.filter((_, idx) => idx !== toRemoveIdx);
        setFields(updated);
        onFieldChange(updated);
      }
    },
    [fields]
  );

  const updateField = useCallback(
    (toUpdateIdx, value) => {
      const updatedFields = fields.map((field, idx) => {
        if (idx !== toUpdateIdx) return field;
        return typeof field === 'object' ? { ...field, ...value } : value;
      });

      setFields(updatedFields);
    },
    [fields]
  );

  return {
    fields,
    addField,
    removeField,
    updateField,
  };
};
