import React, { useEffect, useState } from 'react';
import PT from 'prop-types';

import {
  Box,
  Button,
  Flex,
  FlexList,
  Modal,
  Spinner,
  useI18nContext,
  P,
  Typography,
} from '@procore/core-react';
import ComponentListItem from '../ComponentListItem/ComponentListItem';

const Snippets = ({
  I18n,
  templateSnippets,
  injectComponent,
  hasExistingManifest,
  schemaVersion,
}) => {
  const [schemaV4, setSchemaV4] = useState(false);
  useEffect(() => {
    const isSchemaV4 = schemaVersion === '4.1';
    setSchemaV4(isSchemaV4);
  }, [schemaVersion]);

  let versionedSnippets;
  if (schemaV4) {
    versionedSnippets = templateSnippets['4.1'].snippets;
  } else {
    versionedSnippets = templateSnippets['3'].snippets;
  }

  const copyReadySnippet = (snippet) => {
    if (hasExistingManifest && !schemaV4) {
      const str = JSON.stringify(snippet, null, 2);
      // taking out braces
      return str.substr(1, str.length - 2);
    }
    return snippet;
  };

  return (
    <Box padding="none md">
      <Box marginBottom="xs">
        <strong>{I18n.t('componentTypes')}</strong>
      </Box>
      <Flex>
        <Box>
          {!schemaV4 && (
            <Box>
              <ComponentListItem
                componentName={I18n.t('dataConnector')}
                componentTooltipText={
                  hasExistingManifest
                    ? I18n.t('existingManifestTooltip')
                    : I18n.t('dataConnectorTooltip')
                }
                componentTooltipLink="https://developers.procore.com/documentation/building-apps-define-manifest"
                templateData={copyReadySnippet(versionedSnippets.oauth)}
                injectComponent={() =>
                  injectComponent(versionedSnippets.oauth, 'oauth')
                }
                schemaV4={schemaV4}
              />
            </Box>
          )}
          <Box>
            <ComponentListItem
              componentName={I18n.t('embeddedApp')}
              componentTooltipText={
                hasExistingManifest
                  ? I18n.t('existingManifestTooltip')
                  : I18n.t('embeddedAppTooltip')
              }
              componentTooltipLink="https://developers.procore.com/documentation/building-apps-define-manifest"
              templateData={copyReadySnippet(versionedSnippets.iframe)}
              injectComponent={() =>
                injectComponent(versionedSnippets.fullscreen, 'fullscreen')
              }
              schemaV4={schemaV4}
            />
          </Box>
        </Box>
        {schemaV4 && (
          <Box marginLeft="md">
            <ComponentListItem
              componentName={I18n.t('sidepanel')}
              componentTooltipText={
                hasExistingManifest
                  ? I18n.t('existingManifestTooltip')
                  : I18n.t('embeddedAppTooltip')
              }
              componentTooltipLink="https://developers.procore.com/documentation/building-apps-define-manifest"
              injectComponent={() =>
                injectComponent(versionedSnippets.sidepanel, 'sidepanel')
              }
              schemaV4={schemaV4}
            />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

Snippets.propTypes = {
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  templateSnippets: PT.shape({
    3: {
      snippets: {
        oauth: {},
        iframe: {},
      },
    },
    4: {
      snippets: {
        oauth: {},
        iframe: {},
        sidepanel: {},
      },
    },
  }).isRequired,
  injectComponent: PT.func.isRequired,
  hasExistingManifest: PT.bool.isRequired,
  schemaVersion: PT.string.isRequired,
};

export default Snippets;
