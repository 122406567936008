import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Card,
  Flex,
  Form,
  Page,
  RadioButton,
  Table,
  Tabs,
  Tearsheet,
  useI18nContext,
  Title,
  H1,
  H2,
  Typography,
  Link,
  H3,
  Required,
  Tooltip,
  Checkbox,
} from '@procore/core-react';
import { PERMISSION_NAME_TO_ID } from '@/react/common/Permissions/permissions_constants';
import { UseShowContext } from '../Show.context';
import { Help } from '@procore/core-icons';

const StyledPage = styled(Page)`
  width: 804px;
`;

const hasPermissions = (permissions) => {
  return Object.keys(permissions).some((key) => Object.keys(permissions[key]).length > 0);
};

const EditPermissionsModal = ({
  open,
  setOpen,
  setPermissions,
  scope,
  setScope,
  authCodeEnabled,
  authCodeLocked,
  setAuthCodeEnabled,
}) => {
  const I18n = useI18nContext();
  const {
    developerAppId,
    state: { devApp, editingPermissions = {} },
    dmsaPermissions,
  } = UseShowContext();
  const [formData, setFormData] = useState({
    company: {},
    project: {},
    ...editingPermissions,
  });
  const levelDisplay = scope === 'company' ? 'Company' : 'Project';

  const [showPermissions, setShowPermissions] = useState(hasPermissions(editingPermissions));
  const [localAuthCodeEnabled, setLocalAuthCodeEnabled] =
    useState(authCodeEnabled);

  useEffect(() => {
    setFormData({
      company: {},
      project: {},
      ...editingPermissions,
    });
    setLocalAuthCodeEnabled(authCodeEnabled);
    setShowPermissions(hasPermissions(editingPermissions));
  }, [editingPermissions]);

  const permissionsRow = (p) => {
    const buttonFor = (permissionLevel, disabled = false) => {
      const checked = formData[scope][p.name] === permissionLevel;
      const onClick = () => {
        setFormData({
          ...formData,
          [scope]: {
            ...formData[scope],
            [p.name]: permissionLevel,
          },
        });
      };

      return (
        <RadioButton
          checked={checked}
          onChange={onClick}
          disabled={disabled}
          data-testid={`${scope}-${p.name}-${permissionLevel}-button`}
        />
      );
    };

    const deletePermission = (permission) => {
      const scopedPermissions = formData[scope];
      const { [permission.name]: _deleting, ...newPermissions } =
        scopedPermissions;

      setFormData({
        ...formData,
        [scope]: newPermissions,
      });
    };

    return (
      <Table.BodyRow key={p.name} data-testid={`${scope}-${p.name}-buttons`}>
        <Table.BodyCell>
          <Table.TextCell>{p.friendly_name}</Table.TextCell>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex padding="none" justifyContent="center" alignItems="center">
            <RadioButton
              checked={!formData[scope][p.name]}
              onChange={() => deletePermission(p)}
              data-testid={`${scope}-${p.name}-none-button`}
            />
          </Flex>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex padding="none" justifyContent="center" alignItems="center">
            {buttonFor(
              'readonly',
              p.disabled_uals.includes(PERMISSION_NAME_TO_ID.readonly)
            )}
          </Flex>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex padding="none" justifyContent="center" alignItems="center">
            {buttonFor(
              'standard',
              p.disabled_uals.includes(PERMISSION_NAME_TO_ID.standard)
            )}
          </Flex>
        </Table.BodyCell>
        <Table.BodyCell>
          <Flex padding="none" justifyContent="center" alignItems="center">
            {buttonFor(
              'admin',
              p.disabled_uals.includes(PERMISSION_NAME_TO_ID.admin)
            )}
          </Flex>
        </Table.BodyCell>
      </Table.BodyRow>
    );
  };

  const savePermissionsToManifest = () => {
    setPermissions(formData);
    setAuthCodeEnabled(localAuthCodeEnabled);
    setOpen(false);
  };

  return (
    <Tearsheet open={open} onClose={() => setOpen(false)} placement="right">
      <StyledPage style={{ minWidth: '804px' }}>
        <Form onSubmit={savePermissionsToManifest}>
          <Form.Form>
            <Page.Main
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
            >
              <Page.Header
                onClose={() => {
                  setOpen(false);
                }}
              >
                <Flex
                  padding="lg none none none"
                  alignItems="flex-start"
                  direction="column"
                  justifyContent="space-between"
                >
                  <Page.Title>
                    <H1>{I18n.t('modals.editPermissions.title')}</H1>
                  </Page.Title>
                </Flex>
              </Page.Header>

              <Page.Body style={{ flex: '1 0 auto' }}>
                <Card>
                  <Box padding="lg">
                    <Box padding="none none lg none">
                      <Title>
                        <Title.Text>
                          <H2>{I18n.t('modals.editPermissions.subtitle')}</H2>
                        </Title.Text>
                        <Title.Subtext>
                          {/* space between the below */}
                          <Typography intent="body">
                            {I18n.t('modals.editPermissions.description')}
                          </Typography>
                        </Title.Subtext>
                      </Title>
                    </Box>
                    <Flex
                      paddingTop="xxs"
                      alignItems="flex-start"
                      direction="column"
                      justifyContent="space-between"
                    >
                      <Flex
                        direction="row"
                        alignContent="space-between"
                        gap="sm"
                      >
                        <H3>
                          {I18n.t('modals.editPermissions.componentType')}
                          <Required />
                        </H3>
                        <Tooltip
                          overlay={I18n.t('modals.editPermissions.authCodeHelper')}
                          trigger="hover"
                          placement="top"
                          style={{ marginLeft: '5px' }}
                        >
                          <Help />
                        </Tooltip>
                      </Flex>
                      <Checkbox
                        data-testid="auth-code-checkbox"
                        checked={localAuthCodeEnabled}
                        disabled={authCodeLocked}
                        onChange={() => {
                          setLocalAuthCodeEnabled((val) => !val);
                        }}
                      >
                        {I18n.t('modals.editPermissions.authCode')}
                      </Checkbox>
                      <Checkbox
                        data-testid="service-account-checkbox"
                        checked={showPermissions}
                        onChange={() => {
                          setShowPermissions((val) => !val);
                        }}
                      >
                        {I18n.t('modals.editPermissions.serviceAccount')}
                      </Checkbox>
                    </Flex>
                    {showPermissions && (
                      <>
                        <Box padding="md none">
                          <H2>{I18n.t('modals.editPermissions.permissionsGridSubtitle')}</H2>
                          <Typography intent="body">
                            {I18n.t('modals.editPermissions.helper', {
                              level: levelDisplay,
                            })}{' '}
                            <Link
                              href="/documentation/developer-managed-service-accounts"
                              target="_blank"
                            >
                              {I18n.t('modals.editPermissions.infoCTA')}
                            </Link>
                          </Typography>
                        </Box>
                        <Flex
                          padding="lg none md none"
                          alignItems="flex-start"
                          direction="column"
                          justifyContent="space-between"
                        >
                          <Tabs>
                            <Tabs.Tab
                              onClick={() => setScope('company')}
                              active={scope === 'company'}
                            >
                              <Tabs.Link data-testid="company-tab">
                                {I18n.t('configurationsTab.company')}
                              </Tabs.Link>
                            </Tabs.Tab>
                            <Tabs.Tab
                              onClick={() => setScope('project')}
                              active={scope === 'project'}
                            >
                              <Tabs.Link data-testid="project-tab">
                                {I18n.t('configurationsTab.project')}
                              </Tabs.Link>
                            </Tabs.Tab>
                          </Tabs>
                        </Flex>

                        <Table.Container>
                          <Table>
                            <Table.Header>
                              <Table.HeaderRow>
                                <Table.HeaderCell>
                                  {I18n.t('configurationsTab.tool')}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  <Flex
                                    padding="none"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {I18n.t('configurationsTab.none')}
                                  </Flex>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  <Flex
                                    padding="none"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {I18n.t('configurationsTab.readOnly')}
                                  </Flex>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  <Flex
                                    padding="none"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {I18n.t('configurationsTab.standard')}
                                  </Flex>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  <Flex
                                    padding="none"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    {I18n.t('configurationsTab.admin')}
                                  </Flex>
                                </Table.HeaderCell>
                              </Table.HeaderRow>
                            </Table.Header>
                            <Table.Body>
                              {scope === 'company'
                                ? dmsaPermissions.company.map((p) =>
                                    permissionsRow(p)
                                  )
                                : dmsaPermissions.project.map((p) =>
                                    permissionsRow(p)
                                  )}
                            </Table.Body>
                          </Table>
                        </Table.Container>
                      </>
                    )}
                  </Box>
                </Card>
              </Page.Body>

              <Page.Footer>
                <Box padding="lg">
                  <Flex justifyContent="flex-end">
                    <Box margin="none lg">
                      <Button
                        variant="secondary"
                        onClick={() => setOpen(false)}
                        data-testid="edit-permissions-cancel-button"
                      >
                        {I18n.t('modals.editPermissions.cancel')}
                      </Button>
                    </Box>
                    <Button type="submit" data-testid="save-permissions">
                      {I18n.t('modals.editPermissions.save')}
                    </Button>
                  </Flex>
                </Box>
              </Page.Footer>
            </Page.Main>
          </Form.Form>
        </Form>
      </StyledPage>
    </Tearsheet>
  );
};

EditPermissionsModal.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  scope: PT.string.isRequired,
  setScope: PT.func.isRequired,
  setPermissions: PT.func.isRequired,
};

export default EditPermissionsModal;
