import axios from 'axios';
import Qs from 'qs';
import { getHeaders } from '@/utils/apiUtil';

export default {
  fetchApps(pageNumber, pageSize, user, search, sort, inReview, withDeleted) {
    return axios.request({
      url: '/api/v1/admin/developers',
      method: 'get',
      params: {
        page: pageNumber,
        per_page: pageSize,
        filters: {
          owner_id: user.map((u) => u.id),
          search,
          in_review: inReview,
          with_deleted: withDeleted,
        },
        sort,
      },
      headers: getHeaders(),
      paramsSerializer(params) {
        return Qs.stringify(params, { arrayFormat: 'brackets' });
      },
    });
  },

  getUserOptions(input) {
    return axios.request({
      url: '/api/v1/admin/developers',
      method: 'get',
      params: { search: input },
    });
  },
};
