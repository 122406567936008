import React, { useState } from 'react';
import { Button, ConfirmModal, Modal, Typography } from '@procore/core-react';

const FormDrivenNotAvailableModal = ({ I18n }) => {
  const [open, setOpen] = useState(true);
  const hide = () => setOpen(false);
  return (
    <ConfirmModal
      headline={I18n.t('modals.formDrivenNotAvailable.title')}
      open={open}
      onClickOverlay={hide}
      onClose={hide}
    >
      <Modal.Body>
        <Typography>
          {I18n.t('modals.formDrivenNotAvailable.body')}
          <a target="_blank" href="/documentation/building-apps-define-manifest">
            {I18n.t('modals.formDrivenNotAvailable.bodyLink')}
          </a>
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Button variant="primary" onClick={hide}>
            {I18n.t('modals.formDrivenNotAvailable.confirm')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </ConfirmModal>
  );
}

export default FormDrivenNotAvailableModal;
