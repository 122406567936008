import React from 'react';
import PT from 'prop-types';
import {
  Banner,
  Box,
  Button,
  Card,
  ErrorBanner,
  Flex,
  Modal,
  Spinner,
  useI18nContext,
} from '@procore/core-react';
import DeleteAppModal from './DeleteAppModal';

const DeleteAppCard = ({
  deleteCardStore,
  canDestroy,
  developerAppsPath,
  developerAppId,
}) => {
  const I18n = useI18nContext();
  const { state, handlers } = deleteCardStore;

  const deleteApp = (closeModal) => () =>
    handlers.deleteDeveloperApp(
      developerAppId,
      () => {
        window.location = developerAppsPath;
        handlers.deleteDeveloperAppSuccess();
      },
      (err) => {
        closeModal();
        handlers.onError(err);
      }
    );

  return !canDestroy ? null : (
    <Modal.State>
      {(visibility) => (
        <React.Fragment>
          <DeleteAppModal
            I18n={I18n}
            visibility={visibility}
            onDelete={deleteApp(visibility.hide)}
            isDeletingApp={state.isDeletingApp}
          />
          <Box margin="xl none">
            <Card>
              <Spinner loading={false}>
                <Box padding="xl">
                  {state.error && (
                    <Box marginBottom="xl">
                      <ErrorBanner>
                        <Banner.Content>
                          <Banner.Title>{I18n.t('error')}</Banner.Title>
                          <Banner.Body>{error.message}</Banner.Body>
                        </Banner.Content>
                      </ErrorBanner>
                    </Box>
                  )}
                  <h2>{I18n.t('deleteAppTitle')}</h2>
                  {I18n.t('deleteAppInfo')}
                  <Flex justifyContent="flex-end">
                    <Button
                      data-qa="delete-app-button"
                      variant="secondary"
                      onClick={visibility.toggle}
                    >
                      {I18n.t('deleteApp')}
                    </Button>
                  </Flex>
                </Box>
              </Spinner>
            </Card>
          </Box>
        </React.Fragment>
      )}
    </Modal.State>
  );
};

DeleteAppCard.propTypes = {
  I18n: PT.shape({ t: PT.func }),
  deleteCardStore: PT.shape({
    state: PT.shape({
      isDeletingApp: PT.bool,
      error: PT.instanceOf(Error),
    }),
    handlers: PT.shape({
      deleteDeveloperApp: PT.func,
      deleteDeveloperAppSuccess: PT.func,
      onError: PT.func,
    }),
  }),
  developerAppsPath: PT.string.isRequired,
  canDestroy: PT.bool,
};

DeleteAppCard.defaultProps = {
  canDestroy: false,
  I18n: { t: () => {} },
};

export default DeleteAppCard;
