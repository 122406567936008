import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * For this component to have onClickOutside functionality, it must be wrapped
 * by the higher order component 'OnClickOutside'
 */

const DIRECTIONS_CLASS_MAP = {
  east: 'dropdown--drop-east',
  south: 'dropdown--drop-south',
  west: 'dropdown--drop-west',
};

class UnwrappedDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpenInitial,
    };

    // Pre-bind context of toggleDropdownState function
    this.toggleDropdownState = this.toggleDropdownState.bind(this);
  }

  toggleDropdownState() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClickOutside() {
    // User has clicked off of the dropdown, so close it
    this.setState({ isOpen: false });
  }

  render() {
    const { dropDownDirection, dropdownTarget, className } = this.props;

    const { isOpen } = this.state;

    const dropDownDirectionClass = DIRECTIONS_CLASS_MAP[dropDownDirection];
    const dropdownClasses = cx('dropdown', dropDownDirectionClass, {
      'dropdown--open': isOpen,
    });

    return (
      <span className={className}>
        <span className={dropdownClasses}>
          <span className="dropdown-trigger" onClick={this.toggleDropdownState}>
            {dropdownTarget}
          </span>
          <div className="dropdown-menu-style dropdown-menu-position">
            {this.props.children}
          </div>
        </span>
      </span>
    );
  }
}

UnwrappedDropdown.propTypes = {
  dropdownTarget: PropTypes.node.isRequired,
  dropDownDirection: PropTypes.oneOf(['east', 'south', 'west']),
  isOpenInitial: PropTypes.bool,
  className: PropTypes.string,
};

UnwrappedDropdown.defaultProps = {
  dropDownDirection: 'east',
  isOpenInitial: false,
};

export default UnwrappedDropdown;
