export const getOrdinal = (n, I18n, variant) => {
  let pluralRules;

  try {
    pluralRules = new Intl.PluralRules(I18n.locale, { type: 'ordinal' });
  } catch (e) {
    // fallback for en
    pluralRules = new Intl.PluralRules('en', { type: 'ordinal' });
  }

  if (variant === 'text') {
    return I18n.t(pluralRules.select(n), {
      scope: 'labels.marketplaceApp.ordinalIndicatorsText',
    });
  }

  return `${n}${I18n.t(pluralRules.select(n), {
    scope: 'labels.marketplaceApp.ordinalIndicators',
  })}`;
};
