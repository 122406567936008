import React, { useEffect } from 'react';
import PT from 'prop-types';
import {
  Breadcrumbs,
  Flex,
  DetailPage,
  ListPage,
  Spinner,
  Toast,
  ToolHeader,
  Table,
  Box,
  useI18nContext,
} from '@procore/core-react';
import { debounce } from '@/utils/functionUtil';
import PaginationSection from '@/react/shared/Pagination';
import { SelectPicker } from '@/react/shared/form/Pickers.jsx';
import SearchBar from '@/react/shared/SearchBar/Filters';

const Filters = ({ environment, filters, handleChange, handleEnvChange }) => {
  const handleChangeDebounced = debounce(handleChange, 500);
  return (
    <SearchBar
      searchValue={filters.search}
      handleChange={handleChangeDebounced}
      additionalControls={
        <SelectPicker
          data-qa="environment-selector"
          options={[
            { value: 'production', label: 'Production' },
            { value: 'sandbox', label: 'Sandbox' },
            { value: 'monthly_sandbox', label: 'Monthly Sandbox' },
          ]}
          onChange={handleEnvChange}
          value={environment}
        />
      }
    />
  );
};

const Index = ({ oauthApplicationIndexStore: { state, handlers } }) => {
  const I18n = useI18nContext();
  useEffect(() => {
    handlers.fetch(state.pagination.pageNumber);
  }, []);

  return (
    <DetailPage width="xl" initialIsVisible className="full-screen">
      <DetailPage.Main className="page-body">
        <DetailPage.Breadcrumbs>
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumbs.Crumb>{I18n.t('admin')}</Breadcrumbs.Crumb>
            <Breadcrumbs.Crumb active>
              {I18n.t('oauthApplications')}
            </Breadcrumbs.Crumb>
          </Breadcrumbs>
        </DetailPage.Breadcrumbs>

        <ToolHeader>
          <ToolHeader.Title>{I18n.t('oauthApplications')}</ToolHeader.Title>
        </ToolHeader>

        <ListPage.ContentControls style={{ flexShrink: 0 }}>
          <ListPage.Control block>
            <Filters
              environment={state.environment}
              filters={state.filters}
              handleChange={handlers.filterChange}
              handleEnvChange={handlers.environmentChange}
            />
          </ListPage.Control>
          <ListPage.Control>
            <PaginationSection {...state.pagination} pageTo={handlers.fetch} />
          </ListPage.Control>
        </ListPage.ContentControls>

        <DetailPage.Body className="full-screen">
          <Spinner loading={state.loading}>
            <Body
              I18n={I18n}
              apps={state.apps}
              error={state.error}
              handleSort={handlers.sortChange}
            />

            <ListPage.ContentControls>
              <ListPage.Control style={{ marginLeft: 'auto' }}>
                <PaginationSection
                  {...state.pagination}
                  pageTo={handlers.fetch}
                />
              </ListPage.Control>
            </ListPage.ContentControls>
          </Spinner>
        </DetailPage.Body>
      </DetailPage.Main>
    </DetailPage>
  );
};

const Body = ({ I18n, apps, error, handleSort }) => {
  if (error) {
    return (
      <Flex direction="row" alignItems="center" justifyContent="center">
        <Toast variant="error">{I18n.t('errors.occurred')}</Toast>
      </Flex>
    );
  }

  return (
    <Table.Container>
      <Table data-qa="apps-index-table">
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('name');
              }}
              data-qa="name-table-header"
              width="20%"
            >
              {I18n.t('name')}
            </Table.HeaderCell>
            <Table.HeaderCell data-qa="href-table-header">
              {I18n.t('associatedDeveloperApp')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('uid');
              }}
              data-qa="app-id-table-header"
            >
              {I18n.t('appId')}
            </Table.HeaderCell>
            <Table.HeaderCell data-qa="owner-name-table-header">
              {I18n.t('ownerName')}
            </Table.HeaderCell>
            <Table.HeaderCell data-qa="owner-email-table-header">
              {I18n.t('ownerEmail')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {apps.map((app) => (
            <TableRow
              I18n={I18n}
              key={app.uid}
              name={app.name}
              app_id={app.uid}
              href={app.href}
              owner_name={app.owner_name}
              owner_email={app.owner_email}
            />
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

const formatOwnerInfo = (info, defaultValue) => {
  return info ? info : defaultValue;
};

const TableRow = ({ I18n, app_id, name, href, owner_name, owner_email }) => {
  return (
    <Table.BodyRow>
      <Table.BodyCell>
        <Box padding="lg">{name}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <>
          {href ? (
            <Table.LinkCell href={href}>{name}</Table.LinkCell>
          ) : (
            <Table.TextCell padding="lg">
              {I18n.t('notAvailable')}
            </Table.TextCell>
          )}
        </>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{app_id}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">
          {formatOwnerInfo(owner_name, I18n.t('notAvailable'))}
        </Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">
          {formatOwnerInfo(owner_email, I18n.t('notAvailable'))}
        </Box>
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

Index.propTypes = {
  oauthApplicationIndexStore: PT.shape({
    handlers: PT.shape({
      fetch: PT.func.isRequired,
      onError: PT.func.isRequired,
      environmentChange: PT.func.isRequired,
      filterChange: PT.func.isRequired,
      sortChange: PT.func.isRequired,
    }),
    state: PT.shape({
      apps: PT.array.isRequired,
      error: PT.shape({}),
      loading: PT.bool,
      environment: PT.string.isRequired,
      filters: PT.shape({
        name: PT.string.isRequired,
        uid: PT.string.isRequired,
        search: PT.string.isRequired,
        sort: PT.string.isRequired,
      }).isRequired,
      pagination: PT.shape({
        pageNumber: PT.number.isRequired,
        pageSize: PT.number.isRequired,
        total: PT.number.isRequired,
        totalPages: PT.number.isRequired,
      }).isRequired,
    }),
  }),
};

Index.defaultProps = {
  oauthApplicationIndexStore: {
    state: {
      loading: false,
    },
  },
};

export default Index;
