import React from 'react';
import { string } from 'prop-types';
import { Token } from '@procore/core-react';

const ChangelogToken = ({ variant, label }) => (
  <Token className={`endpoint-changelog-token token-${variant}`}>
    <Token.Label>{label}</Token.Label>
  </Token>
);

ChangelogToken.propTypes = {
  label: string.isRequired,
  variant: string.isRequired,
};

export default ChangelogToken;
