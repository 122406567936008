import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import {
  I18nProvider,
  useI18nContext,
  Typography,
  Switch,
  Flex,
  Toast,
} from '@procore/core-react';
import i18n from './i18n';
import { trackEvent } from '@/react/shared/analytics';
import { getBeta, setBeta, setForcedIntoBeta } from '@/utils/localStorageUtil';

const TOAST_TIMEOUT = 2000;

const OptInToast = ({ message }) => {
  const toastRoot = document.querySelector('.react-toast-container');
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  const [showToast, setToastVisibility] = useState(false);
  useEffect(() => {
    if (!isMountingRef.current) {
      setToastVisibility(true);
      const timer = setTimeout(() => {
        setToastVisibility(false);
      }, TOAST_TIMEOUT);
      return () => clearTimeout(timer);
    }
    isMountingRef.current = false;
  }, [message]);

  const canShowToast = showToast && toastRoot;

  return (
    <>
      {canShowToast &&
        createPortal(
          <Toast variant="success">
            <Toast.Text>{message}</Toast.Text>
          </Toast>,
          toastRoot
        )}
    </>
  );
};

const OptInForm = ({}) => {
  const i18n = useI18nContext();

  const [checked, setChecked] = useState(getBeta());

  const toastMessage = checked
    ? i18n.t('toast.enabled')
    : i18n.t('toast.disabled');

  return (
    <>
      <OptInToast message={toastMessage} />
      <h2>{i18n.t('form.title')}</h2>
      <Flex>
        <Switch
          checked={checked}
          onChange={() => {
            const betaEnabled = getBeta();
            setBeta(!betaEnabled);
            if (betaEnabled) {
              setForcedIntoBeta(false);
              trackEvent('developers.apps.form_based_manifests.opted_out', {});
            } else {
              trackEvent('developers.apps.form_based_manifests.opted_in', {});
            }
            setChecked(!checked);
          }}
        />
        <Flex direction="column" alignItems="space-between" margin="none sm">
          <Typography>{i18n.t('form.label')}</Typography>
          <Typography intent="body" color="gray40">
            {i18n.t('form.description')}
            <a href="/documentation/building-apps-define-manifest" target="_blank">
              {i18n.t('form.linkLabel')}
            </a>
          </Typography>
        </Flex>
      </Flex>
    </>
  );
};

const Container = () => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <OptInForm />
  </I18nProvider>
);

export default Container;
