import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    cancel: 'Cancel',
    create: 'Create',
    createdAt: 'Created',
    appVersionKey: 'App Version Key',
    createInfo:
      'Creating a new manifest version will not take effect for existing users until they update their app. New users that connect your app will be on the latest manifest version.',
    appManifestSectionInfo:
      'UID and name will be autogenerated under the "app_manifest" root key and will override any input for these fields.',
    createNewVersion: 'Create New Version',
    enterVersionNumber: 'Enter Version Number',
    useSemanticVersion: 'Use semantic format major.minor.patch',
    semanticVersionPlaceholder: 'Ex 1.12.1234',
    lastSemanticVersion: 'Last Version',
    semanticVersionInfo: {
      intro: 'Given a version number MAJOR.MINOR.PATCH, increment the:',
      major: 'MAJOR version when you make incompatible API changes,',
      minor:
        'MINOR version when you add functionality in a backwards compatible manner, and',
      patch: 'PATCH version when you make backwards compatible bug fixes.',
      more_info:
        'See the <a href="https://semver.org/" target="_blank">Semantic Versioning Specification</a> for more info.',
    },
    none: 'None',
    manifestContent: 'Manifest Content',
    noManifestsCreated: 'No Manifests Created',
    validations: 'Validations',
    showOldVersions: 'Show Old Versions',
    hideOldVersions: 'Hide Old Versions',
    version: 'Version',
    view: 'View',
    jsonParseError: 'Invalid JSON, could not parse manifest',
    promote: 'Promote',
    promoteConfirmationModal: {
      header: 'Promote Manifest?',
      promotionExplanation:
        'Promoting this manifest version to production will enable it to be installed to Procore production accounts by input of its App Version ID. This manifest version will not be discoverable on the Procore Marketplace until you submit it for review and it is accepted by Procore.',
      releaseNotesExplanation:
        'The release notes for this manifest version will be published to the Procore marketplace once the manifest version has been accepted by Procore. This will be publicly available to all Procore users and cannot be updated once submitted.',
      releaseNotes: 'Release Notes',
      confirm: 'Promote',
      cancel: 'Cancel',
    },
    show: 'Show',
    hide: 'Hide',
    error: 'Error',
    validating: 'Validating...',
    collapsedValidations:
      'There are (%{num_of_violations}) content violations.',
    componentTypes: 'Component Types',
    appManifest: 'App Manifest',
    dataConnector: 'Data Connection',
    embeddedApp: 'Embedded',
    copyCodeSnippet: 'Copy Code Snippet',
    injectComponent: 'Inject Component',
    learnMore: 'Learn more about the',
    type: 'type',
    dataConnectorTooltip:
      'Copy the code snippet and paste into the <code>instances: [ ]</code> array under the <code>oauth</code> section.',
    embeddedAppTooltip:
      'Copy the code snippet and paste into the <code>instances: [ ]</code> array under the <code>iframe</code> section.',
    existingManifestTooltip:
      'Copy the code snippet and paste as a child under the <code>components</code> key.',
    appToolPermissions: 'App Tool Permissions',
    permissionsBuilder: 'Permissions Builder',
    permissionsBuilderTooltip:
      'When using the grant type of <code>client_credentials</code>, you must select which permissions your application needs individually. <br><br> Learn more about <a href="https://support.procore.com/references/user-permissions-matrix-web" target="_blank">Permissions.</a>',
    sidepanel: 'Sidepanel',
  },
});
