import React from 'react';
import { I18nProvider } from '@procore/core-react';
import { compose } from 'recompose';
import SandboxManifestsTable from './SandboxManifestsTable';
import i18n from './i18n';
import StateContainer from './StateContainer';
import TemplatesStateContainer from './TemplatesState';

const SandboxManifestsTableWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <SandboxManifestsTable {...props} />
  </I18nProvider>
);

export default compose(
  TemplatesStateContainer.withState,
  StateContainer.withState
)(SandboxManifestsTableWithI18n);
