import React, { useState } from 'react';
import { Banner, Box, Button, ErrorBanner } from '@procore/core-react';

const ValidationErrorsDisplayer = ({ I18n, apiError, errors }) => {
  const [expandedState, setExpanded] = useState(false);
  const inlineStyles = {
    bottom: '60px',
    left: '32px',
    position: 'fixed',
    width: 'calc(100vw - 64px)',
    zIndex: 5
  };
  if (apiError) {
    return (
      <Box paddingBottom="lg" style={inlineStyles}>
        <ErrorBanner data-qa="validation-errors-displayer">
          <ErrorContent title={I18n.t('error')} body={apiError.message} />
        </ErrorBanner>
      </Box>
    );
  }

  if (errors && errors.length > 0) {
    const multipleErrors = errors.length > 1;
    const isExpanded = multipleErrors ? expandedState : true;

    const action = isExpanded ? I18n.t('hide') : I18n.t('show');
    const errorBody = isExpanded
      ? errors.map((e) => (
          <Box key={e} padding="sm 0 sm 0">
            {e}
          </Box>
        ))
      : I18n.t('collapsedValidations', { num_of_violations: errors.length });

    return (
      <Box paddingBottom="lg" style={inlineStyles}>
        <ErrorBanner data-qa="validation-errors-displayer">
          <ErrorContent title={I18n.t('validations')} body={errorBody} />
          {multipleErrors && (
            <Banner.Action>
              <Button
                data-qa="validation-errors-toggle-button"
                variant="secondary"
                onClick={() => setExpanded(!expandedState)}
              >
                {action}
              </Button>
            </Banner.Action>
          )}
        </ErrorBanner>
      </Box>
    );
  }

  return null;
};

const ErrorContent = ({ title, body }) => (
  <Banner.Content>
    <Banner.Title>{title}</Banner.Title>
    <Banner.Body data-qa="validation-error-body" style={{ marginBottom: 0 }}>
      {body}
    </Banner.Body>
  </Banner.Content>
);

export default ValidationErrorsDisplayer;
