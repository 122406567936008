import React from 'react';
import api from '../api';
import { compose, mapProps, withStateHandlers } from 'recompose';

const numPages = (total, pageSize) => {
  return Math.max(1, Math.ceil(total / pageSize));
};

const ReviewQueueContainer = (Cmp) =>
  withStateHandlers(
    (_props) => ({
      apps: [],
      error: null,
      loading: true,
      filters: {
        user: [],
        search: '',
        sort: '',
        inReview: true,
        withDeleted: false,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 50,
        total: 0,
        totalPages: 1,
      },
    }),
    {
      fetchApps: ({
        filters: { user, search, sort, inReview, withDeleted },
        pagination: { pageSize, totalPages },
      }) => (pageNumber, successCb, errorCb) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
          return;
        }

        api
          .fetchApps(
            pageNumber,
            pageSize,
            user,
            search,
            sort,
            inReview,
            withDeleted
          )
          .then((resp) => successCb(resp))
          .catch(errorCb);

        return { loading: true };
      },

      fetchAppsSuccess: ({ pagination }) => ({ config, data, headers }) => {
        const total = parseInt(headers['total']);
        const pageSize = parseInt(headers['per-page']);
        const totalPages = numPages(total, pageSize);
        const pageNumber = config.params ? config.params.page : 1;

        return {
          apps: data,
          loading: false,
          pagination: {
            ...pagination,
            pageNumber,
            pageSize,
            total,
            totalPages,
          },
        };
      },

      onError: () => (error) => ({
        error,
        loading: false,
      }),

      getUserOptions: () => (input, callback, errorCb) => {
        api
          .getUserOptions(input)
          .then((resp) => {
            callback(null, { options: resp.data });
          })
          .catch((err) => {
            errorCb(err);
            callback(err, { options: [] });
          });
      },

      handleFilterChange: ({ filters }) => (filter, value) => ({
        filters: { ...filters, [filter]: value },
      }),

      handleSort: ({ filters }) => (column) => {
        if (column === filters.sort) {
          column = `-${column}`;
        }
        if (`-${column}` === filters.sort) {
          column = '';
        }

        return { filters: { ...filters, sort: column } };
      },
    }
  )(({ ...props }) => <Cmp {...props} />);

export default compose(
  ReviewQueueContainer,
  mapProps(
    ({
      fetchApps,
      fetchAppsSuccess,
      onError,
      handleFilterChange,
      handleSort,
      getUserOptions,
      ...props
    }) => ({
      reviewQueueStore: {
        handlers: {
          fetch: (pageNumber) =>
            fetchApps(pageNumber, fetchAppsSuccess, onError),
          onError,
          filterChange: (filter, value) => {
            handleFilterChange(filter, value);
            fetchApps(1, fetchAppsSuccess, onError);
          },
          sortChange: (column) => {
            handleSort(column);
            fetchApps(1, fetchAppsSuccess, onError);
          },
          getOptions: (input, callback) =>
            getUserOptions(input, callback, onError),
        },
        state: {
          apps: props.apps,
          error: props.error,
          isLoading: props.loading,
          filters: props.filters,
          pagination: props.pagination,
        },
      },
      ...props,
    })
  )
);
