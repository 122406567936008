import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MultiSelect } from '@procore/core-react';

const mapSecurityStandards = ({ id, variant, standard_name }) => ({
  id,
  label: `${standard_name} - ${variant}`,
});

const SecurityStandards = ({
  name,
  label,
  I18n,
  onChange,
  isDisabled,
  FormLabel,
  securityStandards,
  errors,
  field = null,
}) => {
  const [options, setOptions] = useState([]);
  const [groups, setGroups] = useState([]);
  const [labelMap, setLabelMap] = useState({});
  const [selected, setSelected] = useState(field?.map(mapSecurityStandards));

  useEffect(() => {
    // Create optgroups and options for input
    const { optgroups, options } = securityStandards.reduce(
      (acc, standard) => {
        let groupId = acc.optgroupsMap[standard.standard_name];

        // If the group doesn't exist yet, create it
        if (!groupId) {
          groupId = String(acc.optgroups.length + 1);
          acc.optgroupsMap[standard.standard_name] = groupId;
          acc.optgroups.push({ id: groupId, label: standard.standard_name });
        }

        // Add the option, associating it with the correct groupId
        acc.options.push({
          id: standard.id,
          label: standard.variant,
          groupId: groupId,
        });

        return acc;
      },
      { optgroups: [], options: [], optgroupsMap: {} }
    );

    const labelMap = securityStandards.reduce((acc, standard) => {
      acc[standard.id] = `${standard.standard_name} - ${standard.variant}`;
      return acc;
    }, {});
    setLabelMap(labelMap);

    // Update state with the generated options and optgroups
    setGroups(optgroups);
    setOptions(options);
  }, [securityStandards]);

  useEffect(() => {
    setSelected(field?.map(mapSecurityStandards));
  }, [field]);

  const handleMultiSelectChange = (updatedSelected) => {
    setSelected(
      updatedSelected.map((option) => ({
        id: option.id,
        label: labelMap[option.id],
        groupId: option.groupId,
      }))
    );
    onChange(updatedSelected.map(({ id }) => id));
  };

  return (
    <Form.FieldWrapper
      data-testid={`multi-security-select-${name}`}
      fieldId={`multi-security-select-${name}`}
      required
      label={
        <FormLabel
          changeFields={[name]}
          contentI18nTag={label}
          showInfo={false}
        />
      }
      errors={errors}
    >
      <MultiSelect
        data-testid={`multi-security-select-${name}`}
        disabled={isDisabled}
        block
        onChange={handleMultiSelectChange}
        options={options}
        optgroups={groups}
        value={selected}
        placeholder={I18n.t(
          'placeholders.marketplaceApp.selectSecurityStandards'
        )}
        qa={{
          option: (option) => `multi-option-${option.id}`,
          token: (option) => `multi-token-${option.id}`,
          tokenClear: (option) => `multi-token-clear-${option.id}`,
        }}
        error={errors}
      />
    </Form.FieldWrapper>
  );
};

SecurityStandards.propTypes = {
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  onChange: PT.func.isRequired,
  isDisabled: PT.bool,
  FormLabel: PT.elementType.isRequired,
  securityStandards: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      category: PT.string,
    })
  ).isRequired,
  errors: PT.arrayOf(PT.string),
};

export default SecurityStandards;
