import React from 'react';
import PT from 'prop-types';
import { Button, Required, Flex, useI18nContext } from '@procore/core-react';
import CardFooter from '../../shared/card/CardFooter';

const FormFooter = ({ saveDisabled, onSaveClick, buttonQaTag }) => {
  const I18n = useI18nContext();

  return (
    <CardFooter>
      <Flex direction="row" justifyContent="space-between">
        <Required>{I18n.t('requiredField')}</Required>
        <Button
          data-testid={buttonQaTag}
          data-qa={buttonQaTag}
          disabled={saveDisabled}
          variant="primary"
          onClick={() => {
            !saveDisabled && onSaveClick();
          }}
        >
          {I18n.t('update')}
        </Button>
      </Flex>
    </CardFooter>
  );
};

FormFooter.propTypes = {
  onSaveClick: PT.func.isRequired,
  saveDisabled: PT.bool.isRequired,
  buttonQaTag: PT.string.isRequired,
};

FormFooter.defaultProps = {};

export default FormFooter;
