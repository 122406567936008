import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Flex,
  Table,
  useI18nContext,
} from '@procore/core-react';
import { sidepanelViews, nestedSidepanelViews } from '../constants';

const titleize = (key) => (
  key.split('_').map((word) =>
    ([word[0].toUpperCase(), word.substring(1)].join(''))
  ).join(' ')
);

const ViewsTable = ({ selectedViews, setSelectedViews }) => {
  const I18n = useI18nContext();

  const selectedViewsSet = new Set(selectedViews);

  const addViews = (views) => {
    setSelectedViews([
      ...selectedViews.filter((v) => (!views.includes(v))),
      ...views
    ]);
  };

  const removeViews = (views) => {
    setSelectedViews(
      selectedViews.filter(
        (view) => (!views.includes(view))
      )
    )
  };

  const [expanded, setExpanded] = useState([]);
  const [order, setOrder] = useState('asc');
  const toggleOrder = () => {
    setOrder(order == 'asc' ? 'desc' : 'asc') ;
  };

  const renderSidepanelViews = (views, depth, parentKey) => {
    if (Object.keys(views).length === 0) {
      return (<></>);
    } else {
      return (
        Object.keys(views).sort((key1, key2) => {
          if (order === 'asc') {
            return (key1 > key2) ? 1 : -1;
          } else {
            return (key1 < key2) ? 1 : -1;
          }
        }).map((key) => {
          const qualifiedKey = [parentKey, key].filter(Boolean).join('.');
          const children = sidepanelViews.filter(
            (view) => (view.startsWith(qualifiedKey))
          );
          const allChildrenChecked = children.every(
            (child) => (selectedViewsSet.has(child))
          );
          const checked = (
            selectedViewsSet.has(qualifiedKey) ||
            allChildrenChecked
          );
          const indeterminate = (
            !allChildrenChecked &&
            children.find((child) => (selectedViewsSet.has(child)))
          );
          const viewExpanded = expanded.includes(qualifiedKey);
          const expandable = !sidepanelViews.includes(qualifiedKey);

          return (
            <React.Fragment key={qualifiedKey}>
              <Table.Group depth={depth}>
                <Table.BodyRow>
                  <Table.BodyCell>
                      <Flex direction="row">
                        { expandable ?
                          <Table.Carat
                            data-testid={`${qualifiedKey}-expand`}
                            style={{ 'cursor': 'pointer'}}
                            expanded={expanded.includes(qualifiedKey)}
                            onClick={() => {
                              if (viewExpanded) {
                                setExpanded(expanded.filter((k) => (k !== qualifiedKey)));
                              } else {
                                setExpanded([...expanded, qualifiedKey]);
                              }
                            }}
                          />
                          : <Box padding="sm" /> // keeps spacing consistent for rows that are/not expandable
                        }
                        <Table.Checkbox
                          data-testid={`${qualifiedKey}-checkbox`}
                          checked={checked}
                          indeterminate={indeterminate}
                          onChange={() => {
                            if (checked) {
                              removeViews(children);
                            } else {
                              addViews(children);
                            }
                          }}
                        />
                        <Box padding="none sm">
                          {titleize(key)}
                        </Box>
                      </Flex>
                  </Table.BodyCell>
                </Table.BodyRow>
              </Table.Group>
              { viewExpanded && renderSidepanelViews(views[key], depth + 1, qualifiedKey) }
            </React.Fragment>
          );
        })
      );
    }
  }

  return (
    <Table.Container data-testid="component-views">
      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell sortable variant={order} onClick={toggleOrder}>
              <Flex direction="row" padding="sm none">
                <Table.Checkbox
                  checked={selectedViews?.length === sidepanelViews.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedViews(sidepanelViews);
                    } else {
                      setSelectedViews([]);
                    }
                  }}
                />
                <Box padding="none sm">
                  {I18n.t('modals.addComponent.view')}
                </Box>
              </Flex>
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {renderSidepanelViews(nestedSidepanelViews, 0, null)}
        </Table.Body>
      </Table>
    </Table.Container>
  );
}

ViewsTable.propTypes = {
  selectedViews: PT.arrayOf(PT.string).isRequired,
  setSelectedViews: PT.func.isRequired,
};

export default ViewsTable;
