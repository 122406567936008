import React from 'react';
import PT from 'prop-types';
import { Button, Modal } from '@procore/core-react';
import { MARKETPLACE_APP_STATES } from '@/react/shared/constants';
import Form from '@/react/shared/form';
import { MARKETPLACE_APP_FIELDS_PT } from '@/react/shared/developerAppShowPropTypes';

const ConfirmAcceptModal = ({
  isVisible,
  hide,
  I18n,
  save,
  toggleSilent,
  fields,
}) => (
  <Modal open={isVisible} onClickOverlay={hide}>
    <Modal.Header onClose={hide}>
      {I18n.t('modals.confirmAccept.title')}
    </Modal.Header>
    <Modal.Body>
      <p>{I18n.t('modals.confirmAccept.body')}</p>

      <Form.Checkbox
        data-qa="marketplace-publish-silently"
        label={I18n.t('labels.marketplaceApp.publishSilently')}
        checked={fields.silent}
        onClick={toggleSilent}
      />
    </Modal.Body>

    <Modal.Footer>
      <Modal.FooterButtons>
        <Button variant="secondary" onClick={hide} data-qa="confirm-approve-back">
          {I18n.t('modals.confirmAccept.back')}
        </Button>

        <Button
          variant="primary"
          data-qa="confirm-approve-approve"
          onClick={() => {
            save({
              state: MARKETPLACE_APP_STATES.PUBLISHED,
              silent: fields.silent,
            });
            hide();
          }}
        >
          {I18n.t('modals.confirmAccept.approve')}
        </Button>
      </Modal.FooterButtons>
    </Modal.Footer>
  </Modal>
);

ConfirmAcceptModal.propTypes = {
  isVisible: PT.bool.isRequired,
  hide: PT.func.isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  save: PT.func.isRequired,
  toggleSilent: PT.func.isRequired,
  fields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES),
};

export default ConfirmAcceptModal;
