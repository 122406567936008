import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';

import {
  Button,
  Box,
  Flex,
  FlexList,
  Spinner,
  Tabs,
  useI18nContext,
} from '@procore/core-react';
import { autoSelectCopy } from '../../../../global_helpers/autoSelectCopy';
import Form from '@/react/shared/form';
import Dropzone from '../../../shared/dropzone';
import ProductionManifestsTable from '../../../common/ProductionManifestsTable';
import SandboxManifestsTable from '../../../common/SandboxManifestsTable';
import InfoPopover from '../../../shared/infoPopover/InfoPopover';
import { MANIFEST_TABS, MANIFEST_TYPES } from '../../../shared/constants';

const TabGeneral = ({
  app,
  appListingName,
  manifests,
  manifestTab,
  switchManifestTab,
  events,
  loadingMap,
  canCreateSandboxManifests,
  dmsaPermissions
}) => {
  const I18n = useI18nContext();
  return (
    <div>
      <Public I18n={I18n} app={app} appListingName={appListingName} onChange={events.appChange} />
      <Internal I18n={I18n} app={app} onChange={events.appChange} />
      <AppCredentials I18n={I18n} app={app} onChange={events.appChange} />
      <AppAvatar
        I18n={I18n}
        app={app}
        onDrop={events.dropLogo}
        onRemove={events.removeLogo}
      />
      <Contacts I18n={I18n} app={app} />
      <Collaborators I18n={I18n} app={app} />
      <Manifests
        tab={manifestTab}
        switchTab={switchManifestTab}
        I18n={I18n}
        devAppID={app.id}
        loading={loadingMap['manifests']}
        createManifest={(id, type) => events.createManifest(id, type)}
        promoteManifest={(appVersionId) => events.promoteManifest(appVersionId)}
        updateAppVersion={(appVersion) => events.updateAppVersion(appVersion)}
        manifests={manifests}
        trackEvent={events.trackEvent}
        canCreateSandboxManifests={canCreateSandboxManifests}
        dmsaPermissions={dmsaPermissions}
      />
      <Flex direction="row" justifyContent="flex-end">
        <FlexList size="sm">
          <Button
            onClick={events.save}
            variant="primary"
            block
            size="lg"
            data-qa="save-general-changes-button"
          >
            {I18n.t('actions.save')}
          </Button>
        </FlexList>
      </Flex>
    </div>
  );
};

const Public = ({ I18n, app, appListingName, onChange }) => (
  <Form>
    <Form.Description>
      <h2>{I18n.t('sectionHeaders.public')}</h2>
      <p className="information">{I18n.t('descriptions.public')}</p>
    </Form.Description>

    <Form.Content>
      <Form.Column>
        <Form.Field
          data-qa="public-app-name"
          label={I18n.t('labels.name')}
          type="text"
          value={app.name}
          onChange={(event) => {
            onChange('name', event.target.value);
          }}
        />
      </Form.Column>
      <Form.Column>
        <Form.Field
          readOnly
          data-qa="app-listing"
          label={I18n.t('labels.app_listing')}
          type="text"
          value={appListingName}
        />
      </Form.Column>
    </Form.Content>
  </Form>
);

const Internal = ({ I18n, app, onChange }) => (
  <Form>
    <Form.Description>
      <header>
        <h2>{I18n.t('sectionHeaders.internal')}</h2>
        <p className="information">{I18n.t('descriptions.internal')}</p>
      </header>
    </Form.Description>

    <Form.Content>
      <Form.Column>
        <Form.Field
          data-qa="internal-name"
          label={I18n.t('labels.internal_name')}
          required={true}
          type="text"
          value={app.internal_name}
          onChange={(event) => {
            onChange('internal_name', event.target.value);
          }}
        />
      </Form.Column>
    </Form.Content>
  </Form>
);

const AppCredentials = ({ I18n, app, onChange }) => {
  return (
    <Form>
      <Form.Description>
        <header>
          <h2>{I18n.t('sectionHeaders.appCredentials')}</h2>
          <p className="information">{I18n.t('descriptions.appCredentials')}</p>
        </header>
      </Form.Description>

      <Form.Content>
        <Form.Column>
          <Form.Field
            readOnly
            data-qa="procore-app-id"
            label={I18n.t('labels.app_id')}
            type="text"
            value={app.uid}
            onClick={autoSelectCopy}
          />
          <Form.Textarea
            data-qa="procore-app-redirect-url"
            label={I18n.t('labels.oauth_redirect_uri')}
            type="text"
            required={true}
            value={app.redirect_uri}
            onChange={(event) => {
              onChange('redirect_uri', event.target.value);
            }}
          >
            <p className="information">
              Use one line per URI.
              <br />
              Use urn:ietf:wg:oauth:2.0:oob for redirectless OAuth.
              <br />
              URIs must be HTTPS.
            </p>
          </Form.Textarea>
          {app.uid && (
            <Form.Checkbox
              data-qa="procore-app-legacy-checkbox"
              label={
                <span>
                  {I18n.t('labels.legacy')}
                  <InfoPopover>{I18n.t('legacyHelp')}</InfoPopover>
                </span>
              }
              checked={app.legacy}
              onClick={() => onChange('legacy', !app.legacy)}
            />
          )}

          <Form.RadioButton
            label={I18n.t('labels.privacy')}
            disabled={true}
            checked={app.confidential}
            text={I18n.t('labels.confidential')}
          />
          <Form.RadioButton
            disabled={true}
            checked={!app.confidential && app.confidential !== null}
            text={I18n.t('labels.public')}
          />
        </Form.Column>
        <Form.Column>
          <Form.Field
            readOnly
            data-qa="procore-app-secret"
            label={I18n.t('labels.app_secret')}
            value={app.app_secret || ''}
          />

          <Form.Textarea
            data-qa="procore-app-allowed-cors-domain"
            label={I18n.t('labels.allowed_cors_domain')}
            type="text"
            value={app.allowed_cors_domain}
            onChange={(event) => {
              onChange('allowed_cors_domain', event.target.value);
            }}
          >
            <p className="information">
              Domain from which cross origin requests will be accepted.
              <br />
              Separate multiple domains with commas.
            </p>
          </Form.Textarea>
          <Form.Checkbox
            data-qa="procore-app-connectable-checkbox"
            label={
              <span>
                {I18n.t('labels.connectable')}
                <InfoPopover>{I18n.t('connectableHelp')}</InfoPopover>
              </span>
            }
            checked={app.connectable}
            onClick={() => onChange('connectable', !app.connectable)}
          />
        </Form.Column>
      </Form.Content>
    </Form>
  );
};

const Contacts = ({ I18n, app }) => {
  const contactsList =
    app.contacts.length === 0
      ? I18n.t('noContactsSet')
      : app.contacts.map((contact) => (
          <li key={contact.id}>
            <a href={contact.links.edit}>
              {contact.name} - {contact.email}
            </a>
          </li>
        ));

  return (
    <Form>
      <Form.Description>
        <header>
          <h2>{I18n.t('sectionHeaders.contacts')}</h2>
          <p className="information">{I18n.t('descriptions.contacts')}</p>
        </header>
      </Form.Description>

      <Form.Content>
        <ul>{contactsList}</ul>
      </Form.Content>
    </Form>
  );
};

const Collaborators = ({ I18n, app }) => {
  const collaboratorList =
    app.collaborators === 0
      ? I18n.t('noCollaborators')
      : app.collaborators.map((collaborator) => (
          <li key={collaborator.id}>
            <div>
              <b> {I18n.t('labels.name')} </b>
              {pathOr(
                I18n.t('errorTitles.notAvailable'),
                ['name'],
                collaborator.developer
              )}
              <b> {I18n.t('labels.email')} </b>
              {pathOr(
                I18n.t('errorTitles.notAvailable'),
                ['email'],
                collaborator.developer
              )}
              <b> {I18n.t('labels.role')} </b>
              {pathOr(
                I18n.t('errorTitles.notAvailable'),
                ['role'],
                collaborator
              )}
            </div>
          </li>
        ));

  return (
    <Form>
      <Form.Description>
        <header>
          <h2>{I18n.t('sectionHeaders.collaborators')}</h2>
          <p className="information">{I18n.t('descriptions.collaborators')}</p>
        </header>
      </Form.Description>

      <Form.Content>
        <ul>{collaboratorList}</ul>
      </Form.Content>
    </Form>
  );
};

const AppAvatar = ({ I18n, app, onDrop, onRemove }) => {
  let logo;
  if (app.thumbnail_url) {
    logo = (
      <div className="a-field--image">
        <span
          data-qa="remove-logo-button"
          onClick={onRemove}
          className="a-field--remove"
        >
          X
        </span>
        <img src={app.thumbnail_url} />
      </div>
    );
  }

  return (
    <Form>
      <Form.Description>
        <header>
          <h2>{I18n.t('sectionHeaders.appAvatar')}</h2>
          <p className="information">{I18n.t('descriptions.appAvatar')}</p>
        </header>
      </Form.Description>
      <Form.Content>
        <Form.Column>
          <Dropzone
            files={[app.thumbnail]}
            data-qa="app-logo-dropzone"
            onDrop={(accepted, rejected) => onDrop(accepted, 'pictures')}
            acceptedTypes={['image/jpeg', 'image/png']}
            multiple={false}
          />
          {logo}
        </Form.Column>
      </Form.Content>
    </Form>
  );
};

const Manifests = ({
  I18n,
  createManifest,
  promoteManifest,
  updateAppVersion,
  manifests = [],
  loading,
  devAppID,
  tab,
  switchTab,
  trackEvent,
  canCreateSandboxManifests,
  dmsaPermissions
}) => {
  let formContent;
  let manifestType;

  if (tab === MANIFEST_TABS.PRODUCTION) {
    manifestType = MANIFEST_TYPES.PRODUCTION;
    formContent = (
      <ProductionManifestsTable
        isAdmin
        canModifyManifests
        parentApp={{
          id: devAppID,
          type: manifestType,
        }}
        updateAppVersion={updateAppVersion()}
        manifests={manifests[manifestType]}
        trackEvent={trackEvent}
        canSetSemanticVersions={true}
      />
    );
  } else {
    manifestType = MANIFEST_TYPES.SANDBOX;
    formContent = (
      <SandboxManifestsTable
        canModifyManifests
        canViewClientCredentials
        parentApp={{
          id: devAppID,
          type: manifestType,
        }}
        createManifest={createManifest(devAppID, manifestType)}
        promoteManifest={promoteManifest(manifestType)}
        manifests={manifests[manifestType]}
        trackEvent={trackEvent}
        canCreateSandboxManifests={canCreateSandboxManifests}
        dmsaPermissions={dmsaPermissions}
      />
    );
  }

  return (
    <Spinner
      loading={
        loading[MANIFEST_TYPES.PRODUCTION] || loading[MANIFEST_TYPES.SANDBOX]
      }
    >
      <Form>
        <Form.Description>
          <header>
            <h2>{I18n.t('sectionHeaders.manifests')}</h2>
            <p className="information">{I18n.t('descriptions.manifests')}</p>
          </header>
        </Form.Description>

        <Form.Content>
          <Box display="flex-column" style={{ width: '100%' }}>
            <Tabs>
              {/* Placing references in an array to guarantee iteration order */}
              {[MANIFEST_TABS.SANDBOX, MANIFEST_TABS.PRODUCTION].map(
                (tabName) => (
                  <Tabs.Tab
                    key={tabName}
                    data-qa={`manifest-tab-${tabName
                      .toLowerCase()
                      .replace(' ', '-')}`}
                    variant={tab === tabName ? 'active' : ''}
                  >
                    <Tabs.Link onClick={() => switchTab(tabName)}>
                      {tabName}
                    </Tabs.Link>
                  </Tabs.Tab>
                )
              )}
            </Tabs>

            {formContent}
          </Box>
        </Form.Content>
      </Form>
    </Spinner>
  );
};

TabGeneral.propTypes = {
  app: PT.shape({}),
  appListingName: PT.string,
  manifests: PT.shape({}).isRequired,
  events: PT.object,
  loadingMap: PT.shape({
    manifests: PT.shape({}),
  }).isRequired,
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
};

TabGeneral.defaultProps = {
  app: {
    allowed_cors_domain: '',
    redirect_uri: '',
    uid: '',
  },
};

Manifests.propTypes = {
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
  canCreateSandboxManifests: PT.bool,
  createManifest: PT.func,
  devAppID: PT.string,
  loading: PT.shape({}),
  manifests: PT.shape({}),
  promoteManifest: PT.func,
  switchTab: PT.func,
  tab: PT.string,
  trackEvent: PT.func,
  updateAppVersion: PT.func,
};

export default TabGeneral;
