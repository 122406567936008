import styled from 'styled-components';
import { Dropdown } from '@procore/core-react';
import { ExternalLink } from '@procore/core-icons';

export const NavReferenceDropdown = styled(Dropdown)`
  background: transparent;
  border-radius: 0;
  color: ${({ darkTheme }) => (darkTheme ? '#ABACAE;' : '#464646;')}
  display: block;
  font-size: 12px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 1px;
  line-height: 62px;
  margin-right: 24px;
  text-transform: uppercase;
  border-bottom: ${({ active }) => (active ? '3px solid #f47e42;' : 'none;')}

  &:hover {
    background: transparent;
    border-bottom: 3px solid #f47e42;
    color: ${({ darkTheme }) => (darkTheme ? '#ffffff;' : '#000000;')}
    cursor: pointer;
  }

  &:focus {
    background: transparent;
    color: ${({ darkTheme }) => (darkTheme ? '#ABACAE;' : '#464646;')}
  }

`;

export const ExternalIcon = styled(ExternalLink)`
  height: 12px;
  margin-left: 4px;
  width: 12px;
`;
