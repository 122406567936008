// import clone, css, mouseover, mouseout from jQuery
const OFFSCREEN = '-98789px';

const willOverflow = (totalSize) => (size) => (location) =>
  Number(location) + Number(size) > Number(totalSize);
const willOverflowWidth = willOverflow(window.innerWidth);
const willOverflowHeight = willOverflow(window.innerHeight);

const quickTip = {
  init: (defaultCursor = 'pointer') => {
    const masters = Array.from(
      document.querySelectorAll('[data-quicktip-master]')
    );
    const slaves = Array.from(
      document.querySelectorAll('[data-quicktip-slave]')
    );
    if (!masters.length || !slaves.length) {
      return;
    }

    slaves.forEach(quickTip.prep);
    masters.forEach((master) => {
      const slave = document.querySelector(
        `[data-quicktip-slave=${master.dataset.quicktipMaster}]`
      );
      if (!slave) {
        console.info(
          `Quicktip: No slave for "${master.dataset.quicktipMaster}". Abort.`
        );
        return;
      }
      master.style.cursor = master.dataset.quicktipCursor || defaultCursor;
      quickTip.listen(slave)(master);
    });
  },

  listen: (slave) => (master) => {
    $(master)
      .mouseover((e) => {
        const width = slave.offsetWidth,
          height = slave.offsetHeight;
        $(slave).css({
          left: willOverflowWidth(width)(e.clientX)
            ? e.clientX - width
            : e.clientX,
          top: willOverflowHeight(height)(e.clientY)
            ? e.clientY - height
            : e.clientY,
        });
      })
      .mouseout((e) => {
        slave.style.left = OFFSCREEN;
      });
  },

  prep: (slave) => {
    slave.style.position = 'fixed';
    slave.style.left = OFFSCREEN;
    const clone = $(slave).clone()[0];
    document.querySelector('body').appendChild(clone, null);
    slave.remove();
  },
};

module.exports = quickTip;
