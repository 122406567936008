import React, { createContext, useContext } from 'react';
import PT from 'prop-types';

const LaunchDarklyContext = createContext();

export const useLaunchDarklyContext = () => {
  const context = useContext(LaunchDarklyContext);

  if (!context) {
    throw new Error(
      'useLaunchDarklyContext must be used within a LaunchDarklyProvider'
    );
  }

  return context;
};

export const LaunchDarklyProvider = ({ launchDarklyFlags = {}, children }) => (
  <LaunchDarklyContext.Provider value={launchDarklyFlags}>
    {children}
  </LaunchDarklyContext.Provider>
);

LaunchDarklyProvider.propTypes = {
  launchDarklyFlags: PT.object,
  children: PT.node.isRequired,
};
