import React from 'react';
import PT from 'prop-types';
import { I18nProvider, useI18nContext } from '@procore/core-react';
import withBugsnag from '@/react/shared/BugsnagProvider';
import { compose } from 'recompose';
import Show from './Show';
import i18n from './i18n';
import StateContainer from './StateContainer';
import { ToastAlertProvider, useToastAlertContext } from '@procore/toast-alert';
import { LaunchDarklyProvider } from '@/react/context/LaunchDarkly.context';
import { ErrorProvider } from '@/react/context/ErrorProvider.context';

const WrappedComponent = (props) => {
  const I18n = useI18nContext();
  return <Show I18n={I18n} {...props} />;
};

const ShowWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <WrappedComponent {...props} />
  </I18nProvider>
);

const WrappedStateContainer = compose(
  withBugsnag,
  StateContainer
)(ShowWithI18n);

const StateContainerWithToast = (props) => {
  const { showToast } = useToastAlertContext();

  return <WrappedStateContainer showToast={showToast} {...props} />;
};

const ContainerWithToastProvider = (props) => (
  <ToastAlertProvider>
    <ErrorProvider>
      <LaunchDarklyProvider launchDarklyFlags={props?.launchDarklyFlags}>
        <StateContainerWithToast {...props} />
      </LaunchDarklyProvider>
    </ErrorProvider>
  </ToastAlertProvider>
);

ContainerWithToastProvider.propTypes = {
  launchDarklyFlags: PT.object,
};

export default ContainerWithToastProvider;
