import jQuery from 'jquery';

(($) => {
  const DATA_BEHAVIOR = '[data-behavior="modal"]';

  $(document).on('click', DATA_BEHAVIOR, function(event) {
    event.preventDefault();

    let target;
    if ($(this).data('target')) {
      target = $($(this).data('target'));
    } else {
      target = $(this).closest('.modal-component');
    }
    const modal = target;
    const mask = modal.find('.modal-mask');
    const close = modal.find('[data-behavior="modal-close"]');
    const container = modal.find('.modal-container');

    mask.addClass('open');

    container.on('click', (event) => {
      event.stopPropagation();
    });

    mask.on('click', (event) => {
      mask.removeClass('open');
      mask.off('click');
      close.off('click');
      container.off('click');
    });

    close.on('click', (event) => {
      event.preventDefault();

      mask.removeClass('open');
      mask.off('click');
      close.off('click');
      container.off('click');
    });
  });
})(jQuery);
