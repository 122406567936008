import { useState } from 'react';
import useProviders, { hookSchema } from '@procore/react-state-patterns';
import axios from 'axios';

const api = {
  fetchManifestTemplates: () =>
    axios.request({
      url: '/api/v1/manifest_templates',
      method: 'get',
    }),
  fetchTemplateSnippets: (hasExistingManifest, schemaVersion) => {
    return axios.get(`/api/v1/manifest_templates/${schemaVersion}/snippets`, {
      params: {
        has_existing_manifest: schemaVersion < 4 && hasExistingManifest,
      },
    });
  },
};

const useManifestTemplatesState = () => {
  const [state, setState] = useState({
    templatesMap: {},
    templateSnippets: {},
    error: null,
    loading: false,
    snippetsLoading: false,
  });

  const updateState = (newState) =>
    setState((currState) => ({
      ...currState,
      ...newState,
    }));

  const handlers = {
    fetchTemplateSnippets: (hasExistingManifest, schemaVersion) => {
      updateState({ loading: true });
      api
        .fetchTemplateSnippets(hasExistingManifest, schemaVersion)
        .then((res) => {
          const snippets = state.templateSnippets;
          snippets[schemaVersion] = res.data;
          updateState({
            templateSnippets: snippets,
            error: null,
            snippetsLoading: false,
          });
        })
        .catch((error) => {
          updateState({
            error,
            snippetsLoading: false,
          });
        });
    },
    fetch: () => {
      updateState({ loading: true });
      api
        .fetchManifestTemplates()
        .then((resp) => {
          updateState({
            templatesMap: resp.data,
            error: null,
            loading: false,
          });
        })
        .catch((err) => {
          updateState({
            error: err,
            loading: false,
          });
        });
    },
  };

  return hookSchema(state, handlers, 'templates');
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useProviders(useManifestTemplatesState);
