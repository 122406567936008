export const APP_CATEGORIES = [
  { value: 'custom', label: 'Custom' },
  { value: 'partner', label: 'Partner' },
  { value: 'unknown', label: "I don't know" },
];

export const MANIFEST_TABS = {
  PRODUCTION: 'Production',
  SANDBOX: 'Sandbox',
};

export const MANIFEST_TYPES = {
  PRODUCTION: 'ProductionEnvironment',
  SANDBOX: 'Sandbox',
};

export const MARKETPLACE_APP_STATES = {
  DRAFT: 'draft',
  IN_REVIEW: 'in_review',
  PUBLISHED: 'published',
};

export const MARKETPLACE_APPROVAL_STATES = {
  UNREVIEWED: 'unreviewed',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const DEFAULT_ERROR_MESSAGE =
  'The application has encountered an error. It has been logged and reported.';

export const CHUNK_LOAD_ERROR_MESSAGE =
  'Error loading JavaScript module bundle. Please reload the page.';
