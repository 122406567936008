/* eslint-disable no-shadow */

import React from 'react';
import { compose, mapProps, withStateHandlers } from 'recompose';
import { path, omit, clone, assocPath } from 'ramda';
import { serialize } from 'object-to-formdata';
import api from './api';
import i18n from './i18n';

import { MANIFEST_TABS, MANIFEST_TYPES } from '../../shared/constants';

import { trackEvent } from '../../shared/analytics';

export const onChange = ({ developerApp }) => (fieldPath, value, section) => {
  const appClone = { ...developerApp };

  // fieldPath here is either a string key
  // for a field, or an array that gives a
  // deep path to the field in the state
  const fullPath =
    typeof fieldPath === 'string'
      ? [section, fieldPath]
      : [section, ...fieldPath];

  const updatedApp = assocPath(fullPath, value, appClone);

  // Set the section modified flag to true
  updatedApp[section].__modified__ = true;
  return { developerApp: updatedApp };
};

// Since mapMarketplaceAppFields can throw a JSON error,
// anything using this method must catch JSON errors
const _mapFieldsToSections = (devApp = {}, section) => {
  const mapped = {
    production_access: devApp.production_access,
    published_at: devApp.published_at,
    marketplaceApp: devApp.last_marketplace_app,
    settings: {
      internal_name: devApp.internal_name,
      thumbnail: devApp.thumbnail_url
        ? { preview: devApp.thumbnail_url }
        : undefined,
      marketplace_enabled: devApp.marketplace_enabled,
    },
    credentials: {
      uid: devApp.uid,
      secret: devApp.app_secret,
      redirect_uri: devApp.redirect_uri,
      confidential: devApp.confidential,
      grant_type: devApp.grant_type,
    },
    sandbox: {
      id: path(['sandbox', 'id'], devApp),
      url: path(['sandbox', 'url'], devApp),
      app_id: path(['sandbox', 'app_id'], devApp),
      app_secret: path(['sandbox', 'app_secret'], devApp),
      redirect_uri: path(['sandbox', 'redirect_uri'], devApp),
      confidential: path(['sandbox', 'confidential'], devApp),
      grant_type: path(['sandbox', 'grant_type'], devApp),
    },
  };

  return section ? mapped[section] : mapped;
};

export const SECTIONS = {
  // used by both errorMap and loadingMap
  GLOBAL: 'global',
  SETTINGS: 'settings',
  CREDENTIALS: 'credentials',
  MANIFESTS: 'manifests',
  PRODUCTION: 'production',
  SANDBOX: 'sandbox',
  DEV_APP_FETCH: 'devAppFetch',
  MARKETPLACE_APP: 'marketplaceApp',
};

const DeveloperAppShowContainer = (WrappedComponent) =>
  withStateHandlers(
    (props) => ({
      manifestTab: MANIFEST_TABS.SANDBOX,
      developerApp: _mapFieldsToSections({}),
      developerAppId: props.developerAppId,
      developerAppUid: props.developerAppUid,
      developerAppsShowPath: props.developerAppsShowPath,
      developerAppsIndexPath: props.developerAppsIndexPath,
      dmsaPermissions: props.dmsaPermissions,
      productionManifests: [],
      sandboxManifests: [],
      appFieldPermissions: {},
      resetCredentialsConfirmed: false,
      resetSandboxCredentialsConfirmed: false,
      errorMap: {},
      loadingMap: { [SECTIONS.MANIFESTS]: {} },
      savingMap: {},
      api: api(props.wistiaApiKey, props.wistiaMarketplaceProjectId),
      isFirstOAuthManifest: false,
    }),
    {
      switchManifestTab: () => (manifestTab) => ({ manifestTab }),
      forgetSecret: ({ developerApp }) => () => {
        const newCreds = { ...developerApp.credentials, secret: undefined };
        return {
          developerApp: {
            ...developerApp,
            credentials: newCreds,
          },
        };
      },
      getAppSecret: ({ api, loadingMap }) => (clientID, successCb, errorCb) => {
        api
          .getAppSecret(clientID)
          .then((res) => {
            successCb(res.data);
          })
          .catch((err) => {
            errorCb(SECTIONS.CREDENTIALS, err);
          });
        return {
          loadingMap: { ...loadingMap, [SECTIONS.CREDENTIALS]: true },
        };
      },
      resetAppSecret: ({ api, loadingMap }) => (uid, successCb, errorCb) => {
        api
          .resetAppSecret(uid)
          .then((resp) => {
            const oauthApplication = resp.data;
            successCb(oauthApplication);
          })
          .catch((err) => {
            errorCb(SECTIONS.CREDENTIALS, err);
          });
        return {
          loadingMap: { ...loadingMap, [SECTIONS.CREDENTIALS]: true },
          resetCredentialsConfirmed: false,
        };
      },
      resetSandboxAppSecret: ({ api, loadingMap }) => (
        uid,
        successCb,
        errorCb
      ) => {
        api
          .resetSandboxAppSecret(uid)
          .then((resp) => {
            const oauthApplication = resp.data;
            successCb(oauthApplication);
          })
          .catch((err) => {
            errorCb(SECTIONS.SANDBOX, err);
          });

        return {
          loadingMap: { ...loadingMap, [SECTIONS.SANDBOX]: true },
          resetSandboxCredentialsConfirmed: false,
        };
      },
      resetOrGetAppSecretSuccess: ({ developerApp, errorMap, loadingMap }) => (
        oauthApplication
      ) => ({
        developerApp: { ...developerApp, credentials: oauthApplication },
        errorMap: { ...errorMap, [SECTIONS.CREDENTIALS]: null },
        loadingMap: { ...loadingMap, [SECTIONS.CREDENTIALS]: false },
      }),
      resetOrGetSandboxAppSecretSuccess: ({
        developerApp,
        errorMap,
        loadingMap,
      }) => (oauthApplication) => ({
        developerApp: {
          ...developerApp,
          sandbox: {
            ...developerApp.sandbox,
            app_id: oauthApplication.uid,
            app_secret: oauthApplication.secret,
          },
        },
        errorMap: { ...errorMap, [SECTIONS.SANDBOX]: null },
        loadingMap: { ...loadingMap, [SECTIONS.SANDBOX]: false },
      }),
      fetchPermissions: ({ api, loadingMap }) => (
        devAppId,
        successCb,
        errorCb
      ) => {
        api
          .fetchPermissions(devAppId)
          .then((resp) => {
            const appFieldPermissions = resp.data;
            successCb(appFieldPermissions);
          })
          .catch((err) => {
            errorCb(SECTIONS.GLOBAL, err);
          });
        return { loadingMap: { ...loadingMap, [SECTIONS.GLOBAL]: true } };
      },
      fetchPermissionsSuccess: ({ errorMap, loadingMap }) => (
        appFieldPermissions
      ) => ({
        appFieldPermissions,
        errorMap: { ...errorMap, [SECTIONS.GLOBAL]: null },
        loadingMap: { ...loadingMap, [SECTIONS.GLOBAL]: false },
      }),
      fetchDeveloperApp: ({ api, loadingMap }) => (
        devAppId,
        successCb,
        errorCb
      ) => {
        api
          .fetchDeveloperApp(devAppId)
          .then((resp) => {
            const developerApp = resp.data;
            successCb(developerApp);
          })
          .catch((err) => {
            errorCb(SECTIONS.DEV_APP_FETCH, err);
          });
        return {
          loadingMap: { ...loadingMap, [SECTIONS.DEV_APP_FETCH]: true },
        };
      },
      fetchDeveloperAppSuccess: ({ errorMap, loadingMap }) => (
        developerApp
      ) => {
        let devApp;
        try {
          devApp = _mapFieldsToSections(developerApp);
        } catch (e) {
          return {
            errorMap: {
              ...errorMap,
              [SECTIONS.DEV_APP_FETCH]: i18n.t('errors.marketplaceFetch'),
            },
            loadingMap: { ...loadingMap, [SECTIONS.DEV_APP_FETCH]: false },
          };
        }

        const newState = {
          developerApp: devApp,
          errorMap: { ...errorMap, [SECTIONS.DEV_APP_FETCH]: null },
          loadingMap: { ...loadingMap, [SECTIONS.DEV_APP_FETCH]: false },
        };

        // Update the status if it is live on fetch
        if (path(['sandbox', 'live'], developerApp)) {
          newState.sandboxStatus = 'live';
        }
        return newState;
      },
      fetchProductionManifests: ({ api, loadingMap }) => (
        uid,
        successCb,
        errorCb
      ) => {
        api
          .getManifests(uid, MANIFEST_TYPES.PRODUCTION)
          .then((response) => response.data)
          .then(successCb)
          .catch((err) => {
            errorCb(SECTIONS.MANIFESTS, err);
          });

        return {
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              [SECTIONS.PRODUCTIONS]: true,
            },
          },
        };
      },
      fetchProductionManifestsSuccess: ({ errorMap, loadingMap }) => (
        manifests
      ) => ({
        productionManifests: manifests,
        errorMap: { ...errorMap, [SECTIONS.MANIFESTS]: null },
        loadingMap: {
          ...loadingMap,
          [SECTIONS.MANIFESTS]: {
            ...loadingMap[SECTIONS.MANIFESTS],
            [SECTIONS.PRODUCTIONS]: false,
          },
        },
      }),
      fetchSandboxManifests: ({ api, loadingMap }) => (
        uid,
        successCb,
        errorCb
      ) => {
        api
          .getManifests(uid, MANIFEST_TYPES.SANDBOX)
          .then((response) => response.data)
          .then(successCb)
          .catch((err) => {
            errorCb(SECTIONS.MANIFESTS, err);
          });

        return {
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              [SECTIONS.SANDBOX]: true,
            },
          },
        };
      },
      fetchSandboxManifestsSuccess: ({ errorMap, loadingMap }) => (
        manifests
      ) => ({
        sandboxManifests: manifests,
        errorMap: { ...errorMap, [SECTIONS.MANIFESTS]: null },
        loadingMap: {
          ...loadingMap,
          [SECTIONS.MANIFESTS]: {
            ...loadingMap[SECTIONS.MANIFESTS],
            [SECTIONS.SANDBOX]: false,
          },
        },
      }),
      createOrUpdateMarketplaceApp: ({
        api,
        developerApp,
        developerAppId,
        loadingMap,
      }) => (marketplaceAppFields, successCb, errorCb) => {
        const marketplaceAppId = developerApp?.marketplaceApp?.id;
        const formattedFormData = (items) => {
          const obj = {};
          items?.forEach((item, index) => {
            obj[index] = item;
          });
          return obj;
        };

        const formData = serialize(
          {
            submission: {
              ...marketplaceAppFields,
              ...{
                pictures_attributes: formattedFormData(
                  marketplaceAppFields.pictures
                ),
                videos_attributes: formattedFormData(
                  marketplaceAppFields.videos
                ),
                showcase_video_attributes: marketplaceAppFields.showcase_video,
              },
            },
          },
          { allowEmptyArrays: true }
        );

        let apiCall;

        if (marketplaceAppId && marketplaceAppFields.state !== 'published') {
          apiCall = api.updateMarketplaceApp(marketplaceAppId, formData);
        } else {
          apiCall = api.createMarketplaceApp(developerAppId, formData);
        }

        apiCall
          .then((res) => res.data)
          .then(successCb)
          .catch((err) => {
            errorCb(SECTIONS.MARKETPLACE_APP, err);
          });

        return {
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MARKETPLACE_APP]: true,
          },
        };
      },
      createOrUpdateMarketplaceAppSuccess: ({
        developerApp,
        errorMap,
        loadingMap,
      }) => (marketplaceApp) => ({
        developerApp: {
          ...developerApp,
          marketplaceApp,
        },
        errorMap: { ...errorMap, [SECTIONS.MARKETPLACE_APP]: null },
        loadingMap: { ...loadingMap, [SECTIONS.MARKETPLACE_APP]: false },
      }),
      createManifest: ({ api, loadingMap }) => (
        id,
        appType,
        manifest,
        semanticVersion,
        successCb,
        errorCb
      ) => {
        api
          .createManifest(id, appType, manifest, semanticVersion)
          .then((response) => response.data)
          .then(successCb)
          .catch((err) => {
            errorCb(SECTIONS.MANIFESTS, err);
          });

        const newLoadState = {};
        if (appType === MANIFEST_TYPES.PRODUCTION) {
          newLoadState[SECTIONS.PRODUCTION] = true;
        } else {
          newLoadState[SECTIONS.SANDBOX] = true;
        }

        return {
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              ...newLoadState,
            },
          },
        };
      },
      createProductionManifestSuccess: ({
        productionManifests,
        errorMap,
        loadingMap,
      }) => (appVersion) => ({
        productionManifests: [...productionManifests, appVersion.manifest],
        errorMap: { ...errorMap, [SECTIONS.MANIFESTS]: null },
        loadingMap: {
          ...loadingMap,
          [SECTIONS.MANIFESTS]: {
            ...loadingMap[SECTIONS.MANIFESTS],
            [SECTIONS.PRODUCTIONS]: false,
          },
        },
      }),
      createSandboxManifestSuccess: ({
        sandboxManifests,
        errorMap,
        loadingMap,
      }) => (appVersion) => ({
        sandboxManifests: [
          ...sandboxManifests,
          { ...appVersion.manifest, promotable: appVersion.promotable },
        ],
        errorMap: { ...errorMap, [SECTIONS.MANIFESTS]: null },
        loadingMap: {
          ...loadingMap,
          [SECTIONS.MANIFESTS]: {
            ...loadingMap[SECTIONS.MANIFESTS],
            [SECTIONS.SANDBOX]: false,
          },
        },
      }),
      updateAppVersion: ({ api, loadingMap }) => (
        appVersion,
        successCb,
        errorCb
      ) => {
        api
          .updateAppVersion(appVersion)
          .then((response) => response.data)
          .then(successCb)
          .catch((err) => {
            errorCb(SECTIONS.MANIFESTS, err);
          });

        return {
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              [SECTIONS.PRODUCTIONS]: true,
            },
          },
        };
      },
      updateAppVersionSuccess: ({ errorMap, loadingMap }) => () => {
        return {
          manifestTab: MANIFEST_TABS.PRODUCTION,
          errorMap: { ...errorMap, [SECTIONS.MANIFESTS]: null },
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              [SECTIONS.PRODUCTIONS]: false,
            },
          },
        };
      },
      promoteManifest: ({ api, loadingMap }) => (
        appVersionId,
        releaseNotes,
        successCb,
        errorCb
      ) => {
        api
          .promoteManifest(appVersionId, releaseNotes)
          .then((response) => response.data)
          .then(successCb)
          .catch((err) => {
            errorCb(SECTIONS.MANIFESTS, err);
          });

        return {
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              [SECTIONS.SANDBOX]: true,
            },
          },
        };
      },
      promoteManifestSuccess: ({ errorMap, loadingMap }) => () => {
        return {
          manifestTab: MANIFEST_TABS.PRODUCTION,
          errorMap: { ...errorMap, [SECTIONS.MANIFESTS]: null },
          loadingMap: {
            ...loadingMap,
            [SECTIONS.MANIFESTS]: {
              ...loadingMap[SECTIONS.MANIFESTS],
              [SECTIONS.SANDBOX]: false,
            },
          },
        };
      },
      onError: ({ errorMap }) => (key = SECTIONS.GLOBAL, err) => {
        const errors = path(['response', 'data', 'errors'], err);
        const error = errors ? errors.join(' ') : err.message;

        return {
          loadingMap: { [SECTIONS.MANIFESTS]: {} },
          savingMap: {},
          errorMap: {
            ...errorMap,
            [key]: error,
          },
        };
      },
      updateDeveloperApp: ({ developerApp: developer_app, api, savingMap }) => (
        devAppId,
        section,
        successCb,
        errorCb
      ) => {
        const data = new FormData();

        const devAppSection = omit(
          ['__modified__'],
          clone(developer_app[section])
        );

        // All form fields for section
        const devAppSectionParams = Object.keys(devAppSection);

        devAppSectionParams.forEach((key) => {
          const value = devAppSection[key];
          if (value !== undefined && value !== null) {
            data.append(`developer_app[${key}]`, value);
          }
        });

        api
          .updateDeveloperApp(devAppId, data)
          .then((resp) => {
            const developerApp = resp.data;
            successCb(developerApp, section);
          })
          .catch((err) => {
            errorCb(section, err);
          });
        return { savingMap: { ...savingMap, [section]: true } };
      },
      updateDeveloperAppSuccess: ({
        developerApp: currDevApp,
        errorMap,
        savingMap,
      }) => (developerApp, section) => {
        // Update only the values in the section being saved
        const updatedApp = clone(currDevApp);
        try {
          updatedApp[section] = _mapFieldsToSections(developerApp, section);
        } catch (e) {
          return {
            errorMap: {
              ...errorMap,
              [section]: i18n.t('errors.loadingDeveloperApp'),
            },
            savingMap: { ...savingMap, [section]: false },
          };
        }

        return {
          developerApp: updatedApp,
          errorMap: { ...errorMap, [section]: null },
          savingMap: { ...savingMap, [section]: false },
        };
      },
      updateSandbox: ({ developerApp, api, savingMap }) => (
        sandboxId,
        successCb,
        errorCb
      ) => {
        const { sandbox } = developerApp;
        api
          .updateSandbox(sandboxId, omit(['__modified__'], sandbox))
          .then((resp) => {
            successCb(resp.data);
          })
          .catch((err) => {
            errorCb(SECTIONS.SANDBOX, err);
          });
        return { savingMap: { ...savingMap, [SECTIONS.SANDBOX]: true } };
      },
      updateSandboxSuccess: ({ developerApp, errorMap, savingMap }) => (
        sandboxData
      ) => {
        developerApp.sandbox = sandboxData;
        return {
          developerApp,
          errorMap: { ...errorMap, [SECTIONS.SANDBOX]: null },
          savingMap: { ...savingMap, [SECTIONS.SANDBOX]: false },
        };
      },
      generateFakeSandbox: ({ api }) => (
        generateFakeSandboxPath,
        successCb,
        errorCb
      ) => {
        api
          .generateFakeSandbox(generateFakeSandboxPath)
          .then((resp) => {
            successCb(resp.data);
          })
          .catch((err) => {
            errorCb(err);
          });
      },
      generateFakeSandboxSuccess: ({ developerApp }) => (sandboxData) => {
        developerApp.sandbox = sandboxData;
        return {
          developerApp,
          isSavingSandbox: false,
          sandboxStatus: 'live',
        };
      },
      postFeatureServiceJob: ({ api }) => (featureServiceKickoffPath, successCb, errorCb) => {
        api
          .postFeatureServiceJob(featureServiceKickoffPath)
          .then((resp) => {
            successCb(resp.data);
          })
          .catch((err) => {
            errorCb(SECTIONS.SANDBOX, err);
          });
        return { sandboxStatus: 'pending' };
      },
      postFeatureServiceJobSuccess: ({ errorMap }) => () => {
        // TODO: Show a success flash message
        return {
          errorMap: { ...errorMap, [SECTIONS.SANDBOX]: null },
        };
      },
      toggleResetCredentialsConfirmed: ({
        resetCredentialsConfirmed,
      }) => () => ({ resetCredentialsConfirmed: !resetCredentialsConfirmed }),
      toggleResetSandboxCredentialsConfirmed: ({
        resetSandboxCredentialsConfirmed,
      }) => () => ({
        resetSandboxCredentialsConfirmed: !resetSandboxCredentialsConfirmed,
      }),
      onChange,
      setIsFirstOAuthManifest: () => (isFirstOAuthManifest) => ({
        isFirstOAuthManifest,
      }),
      trackEvent: () => (key, params) => {
        trackEvent(key, params);
      },
    }
  )(({ ...props }) => <WrappedComponent {...props} />);

export default compose(
  DeveloperAppShowContainer,
  mapProps(
    ({
      manifestTab,
      developerApp,
      developerAppUid,
      developerAppsIndexPath,
      developerAppsShowPath,
      productionManifests,
      sandboxManifests,
      appFieldPermissions,
      resetCredentialsConfirmed,
      resetSandboxCredentialsConfirmed,
      errorMap,
      loadingMap,
      savingMap,
      api,
      isFirstOAuthManifest,
      createManifest,
      createOrUpdateMarketplaceApp,
      createOrUpdateMarketplaceAppSuccess,
      createProductionManifestSuccess,
      createSandboxManifestSuccess,
      fetchDeveloperApp,
      fetchDeveloperAppSuccess,
      fetchPermissions,
      fetchPermissionsSuccess,
      fetchProductionManifests,
      fetchProductionManifestsSuccess,
      fetchSandboxManifests,
      fetchSandboxManifestsSuccess,
      forgetSecret,
      getAppSecret,
      generateFakeSandbox,
      generateFakeSandboxSuccess,
      onError,
      onChange,
      postFeatureServiceJob,
      postFeatureServiceJobSuccess,
      promoteManifest,
      promoteManifestSuccess,
      resetAppSecret,
      resetSandboxAppSecret,
      resetOrGetAppSecretSuccess,
      resetOrGetSandboxAppSecretSuccess,
      setIsFirstOAuthManifest,
      switchManifestTab,
      toggleResetCredentialsConfirmed,
      toggleResetSandboxCredentialsConfirmed,
      trackEvent,
      updateAppVersion,
      updateAppVersionSuccess,
      updateDeveloperApp,
      updateDeveloperAppSuccess,
      updateSandbox,
      updateSandboxSuccess,
      ...props
    }) => ({
      developerAppShowStore: {
        handlers: {
          createManifest,
          createOrUpdateMarketplaceApp,
          createOrUpdateMarketplaceAppSuccess,
          createProductionManifestSuccess,
          createSandboxManifestSuccess,
          fetchDeveloperApp,
          fetchDeveloperAppSuccess,
          fetchPermissions,
          fetchPermissionsSuccess,
          fetchProductionManifests,
          fetchProductionManifestsSuccess,
          fetchSandboxManifests,
          fetchSandboxManifestsSuccess,
          forgetSecret,
          generateFakeSandbox,
          generateFakeSandboxSuccess,
          getAppSecret,
          onError,
          onChange,
          postFeatureServiceJob,
          postFeatureServiceJobSuccess,
          promoteManifest,
          promoteManifestSuccess,
          resetAppSecret,
          resetSandboxAppSecret,
          resetOrGetAppSecretSuccess,
          resetOrGetSandboxAppSecretSuccess,
          setIsFirstOAuthManifest,
          switchManifestTab,
          toggleResetCredentialsConfirmed,
          toggleResetSandboxCredentialsConfirmed,
          trackEvent,
          updateAppVersion,
          updateAppVersionSuccess,
          updateDeveloperApp,
          updateDeveloperAppSuccess,
          updateSandbox,
          updateSandboxSuccess,
        },
        state: {
          manifestTab,
          developerApp,
          developerAppUid,
          developerAppsIndexPath,
          developerAppsShowPath,
          productionManifests,
          sandboxManifests,
          appFieldPermissions,
          resetCredentialsConfirmed,
          resetSandboxCredentialsConfirmed,
          errorMap,
          loadingMap,
          savingMap,
          api,
          isFirstOAuthManifest,
        },
      },
      ...props,
    })
  )
);
