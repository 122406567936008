import React from 'react';
import PT from 'prop-types';
import TopNavNotificationsLink from './TopNavNotificationsLink';
import TopNavNotificationsList from './TopNavNotificationsList';
import Dropdown from '../../shared/dropdown/Dropdown';

const PREVIEW_WINDOW_SIZE = 3; // Number of notifications to show in preview

class TopNavNotifications extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { notificationsStore, loadNotificationsOnMount } = this.props;
    if (loadNotificationsOnMount) {
      notificationsStore.handlers.fetch();
    }
  }

  render() {
    const { notificationsStore, notificationsBaseUrl, isHidden } = this.props;

    const {
      state: { notifications },
    } = notificationsStore;

    const numOfUnreadNotifications = notifications.filter((n) => !n.read_at)
      .length;

    const topNavNotifications = (
      <TopNavNotificationsLink numOfUnread={numOfUnreadNotifications} />
    );

    if (!isHidden) {
      return (
        <Dropdown
          dropDownDirection="west"
          dropdownTarget={topNavNotifications}
          className="top-nav-notifications-dropdown"
        >
          <TopNavNotificationsList
            notificationsBaseUrl={notificationsBaseUrl}
            previewNum={PREVIEW_WINDOW_SIZE}
            notifications={notifications}
          />
        </Dropdown>
      );
    }
    // Notifications in top nav are hidden
    return null;
  }
}

TopNavNotifications.propTypes = {
  notificationsBaseUrl: PT.string.isRequired,
  isHidden: PT.bool,
  notificationsStore: PT.shape({
    state: PT.shape({
      loading: PT.bool,
      error: PT.instanceOf(Error),
      notifications: PT.array,
    }),
    handlers: PT.shape({
      fetch: PT.func,
    }),
  }).isRequired,
};

TopNavNotifications.defaultProps = {
  isHidden: false,
};

// Wrap in higher order component that handles notification state management
export default TopNavNotifications;
