import React from 'react';
import { Button, Modal } from '@procore/core-react';
import { MARKETPLACE_APP_STATES } from '@/react/shared/constants';

class ConfirmRejectModal extends React.Component {
  render() {
    const { isVisible, hide, I18n, id, save } = this.props;

    return (
      <Modal open={isVisible} onClickOverlay={hide}>
        <Modal.Header onClose={hide}>
          {I18n.t('modals.confirmReject.title')}
        </Modal.Header>
        <Modal.Body>{I18n.t('modals.confirmReject.body')}</Modal.Body>

        <Modal.Footer>
          <Modal.FooterButtons>
            <Button variant="secondary" onClick={hide} data-qa="confirm-reject-back">
              {I18n.t('modals.confirmReject.back')}
            </Button>

            <Button
              variant="primary"
              data-qa="confirm-reject-reject"
              onClick={() => {
                save(id, { state: MARKETPLACE_APP_STATES.DRAFT });
                hide();
              }}
            >
              {I18n.t('modals.confirmReject.reject')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmRejectModal;
