import React from 'react';
import { compose } from 'recompose';
import { I18nProvider } from '@procore/core-react';
import DeleteAppCard from './DeleteAppCard';
import StateContainer from './StateContainer';
import i18n from './i18n';

const DeleteAppCardWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <DeleteAppCard {...props} />
  </I18nProvider>
);

export default compose(StateContainer.withState)(DeleteAppCardWithI18n);
