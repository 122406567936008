import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  strToDate,
  buildDatesForTimePeriod,
  formatMetricsDataDates,
  mapCountForDate,
  sortByDateAsc,
} from './utils';

const LIGHTER_TEXT_COLOR = '#a3a1aa';
const AXIS_BORDER_COLOR = '#e5e4e7';

const SERIES_SETTINGS = {
  daily_app_installations_aggregates: {
    color: '#016dde',
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#c0d6eb'],
        [1, '#ffffff'],
      ],
    },
    marker: {
      symbol: 'circle',
      fillColor: '#016dde',
    },
  },
  daily_marketplace_app_views_aggregates: {
    color: '#000000',
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#dddedf'],
        [1, '#ffffff'],
      ],
    },
    marker: {
      symbol: 'circle',
      fillColor: '#000000',
    },
  },
};

export const mapYAxisCountsData = (
  allDatesWithinTimePeriod,
  metricsData,
  I18n
) =>
  Object.keys(metricsData).map((metric) => {
    const formattedDatesForMetricWithCounts = formatMetricsDataDates(
      metricsData
    );

    const counts = allDatesWithinTimePeriod.reduce(
      (counts, date) =>
        counts.concat([
          mapCountForDate(formattedDatesForMetricWithCounts[metric], date),
        ]),
      []
    );

    return {
      name: I18n.t(`metricsGraph.metrics.${metric}`),
      data: counts,
      ...SERIES_SETTINGS[metric],
    };
  });

const MetricsGraph = ({ I18n, metricsData, showBorder = true }) => {
  const xAxisData = sortByDateAsc(buildDatesForTimePeriod(metricsData));
  const yAxisData = mapYAxisCountsData(xAxisData, metricsData, I18n);
  const title = I18n.t('metricsGraph.title');
  const tooltipHeader = I18n.t('metricsGraph.tooltip.header');

  const config = {
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      align: 'left',
      style: {
        color: LIGHTER_TEXT_COLOR,
        fontSize: 16,
        marginBottom: 8,
      },
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      padding: 0,
      itemMarginTop: 5,
      itemMarginBottom: 10,
    },
    chart: {
      type: 'area',
      borderWidth: showBorder ? 1 : 0,
      borderRadius: 6,
      spacing: [32, 0, 32, 32],
      marginRight: 64,
      borderColor: AXIS_BORDER_COLOR,
    },
    tooltip: {
      headerFormat: `<span style="color: ${LIGHTER_TEXT_COLOR}">${tooltipHeader}</span><br/>`,
      pointFormat:
        '<span style="color:{series.color}">\u25CF</span>{series.name} - <b>{point.y:,.0f}</b><br/>',
      shared: true,
    },
    plotOptions: {
      series: {
        stacking: 'overlap',
        pointPlacement: 'on',
      },
    },
    xAxis: {
      tickColor: AXIS_BORDER_COLOR,
      lineColor: AXIS_BORDER_COLOR,
      crosshair: {
        width: 1,
        color: AXIS_BORDER_COLOR,
        dashStyle: 'solid',
        zIndex: 100,
      },
      tickmarkPlacement: 'on',
      categories: xAxisData.map((date) =>
        strToDate(date).format('DD MMM YYYY')
      ),
    },
    yAxis: {
      tickColor: AXIS_BORDER_COLOR,
      lineColor: AXIS_BORDER_COLOR,
      gridLineWidth: 0,
      lineWidth: 1,
      tickWidth: 1,
      title: null,
    },
    series: yAxisData,
  };

  return <HighchartsReact highcharts={Highcharts} options={config}/>;
};

export default MetricsGraph;
