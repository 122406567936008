import React from 'react';
import PT from 'prop-types';
import { FlexList } from '@procore/core-react';
import { ArrowsCycleClockwise } from '@procore/core-icons';

const ChangeIndicator = ({ changed, children }) => (
  <FlexList size="xs">
    {children}
    {changed && <ArrowsCycleClockwise size="sm" />}
  </FlexList>
);

ChangeIndicator.propTypes = {
  changed: PT.bool,
  children: PT.node,
};

ChangeIndicator.defaultProps = {
  changed: false,
  children: null,
};

export default ChangeIndicator;
