import axios from 'axios';

export const retrieveBuckets = (server, onError) =>
  axios
    .get(`/api/v1/admin/quota_minder_servers/${server}/buckets`)
    .then((res) => res.data)
    .catch((err) => onError(err.response.data.message));

export const deleteBucket = (bucketID, server, onError) =>
  axios
    .delete(`/api/v1/admin/quota_minder_servers/${server}/buckets/${bucketID}`)
    .catch((err) => onError(err.response.data.message));

export const submitBucket = (bucketData, server, onError) =>
  axios
    .post(`/api/v1/admin/quota_minder_servers/${server}/buckets`, {
      data: bucketData,
    })
    .catch((err) => onError(err.response.data.message));

export const updateBucket = (bucketID, bucketData, server, onError) =>
  axios
    .patch(`/api/v1/admin/quota_minder_servers/${server}/buckets/${bucketID}`, {
      data: bucketData,
    })
    .catch((err) => onError(err.response.data.message));
