import React from 'react';
import PT from 'prop-types';

import { Typography, Table, useI18nContext } from '@procore/core-react';
import { Check, Clear } from '@procore/core-icons';

const permissions = [
  { name: 'viewProdClientId', allowed: ['owner', 'admin', 'developer'] },
  { name: 'viewSandboxCreds', allowed: ['owner', 'admin', 'developer'] },
  { name: 'viewManifests', allowed: ['owner', 'admin', 'developer'] },
  { name: 'editAppSettings', allowed: ['owner', 'admin'] },
  { name: 'editManifests', allowed: ['owner', 'admin'] },
  { name: 'submitListing', allowed: ['owner', 'admin'] },
  { name: 'resetSecrets', allowed: ['owner', 'admin'] },
  { name: 'updatePermissions', allowed: ['owner', 'admin'] },
  { name: 'deleteApp', allowed: ['owner'] },
];

const TableRow = ({ permission }) => {
  const I18n = useI18nContext();

  return (
    <Table.BodyRow>
      <Table.BodyCell>
        <Table.TextCell>
          <Typography intent="h3">
            {I18n.t(`permissionsDetailsTable.${permission.name}`)}
          </Typography>
        </Table.TextCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.TextCell>
          {permission.allowed.includes('owner') ? <Check /> : <Clear />}
        </Table.TextCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.TextCell>
          {permission.allowed.includes('admin') ? <Check /> : <Clear />}
        </Table.TextCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.TextCell>
          {permission.allowed.includes('developer') ? <Check /> : <Clear />}
        </Table.TextCell>
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

TableRow.propTypes = {
  permission: PT.shape({
    name: PT.string.isRequired,
    allowed: PT.arrayOf(PT.string).isRequired,
  }).isRequired,
};

const PermissionsTable = () => {
  const I18n = useI18nContext();

  return (
    <Table.Container>
      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell>
              {I18n.t('permissionsDetailsTable.user')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {I18n.t('permissionsDetailsTable.owner')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {I18n.t('permissionsDetailsTable.admin')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {I18n.t('permissionsDetailsTable.developer')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {permissions.map((p) => (
            <TableRow key={p.name} permission={p} />
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

export default PermissionsTable;
