import React, { useCallback, useState } from 'react';
import { UseShowContext } from '../Show.context';
import {
  updateSandbox,
  makePostRequest,
  resetSandboxAppSecret,
} from '../hooks/SandboxHooks';
import { Spinner } from '@procore/core-react';
import SandboxCard from '@/react/developer_apps/show/sandbox/SandboxCard';
import { useI18nContext } from '@procore/core-react';

export const SandboxContainer = () => {
  const I18n = useI18nContext();

  const {
    developerAppId,
    canGenerateFakeSandbox,
    canGenerateSandbox,
    canEdit,
    redirectUriDocPath,
    sandboxCredentialsDocPath,
    sandboxStatus,
    generateFakeSandboxPath,
    featureServiceKickoffPath,
    state: { devApp, sandboxManifests },
  } = UseShowContext();

  const [sandboxFields, setSandboxFields] = useState(devApp?.sandbox);
  const [isModified, setIsModified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resetSandboxConfirmed, setResetSandboxConfirmed] = useState(false);

  const onError = React.useCallback((error) => {
    const errors = error?.response?.data?.errors;
    const errorMessage = errors ? errors.join(' ') : err.message;
    setError(errorMessage);
    setIsLoading(false);
  });

  const onChange = useCallback(
    (name, value) => {
      setSandboxFields({
        ...sandboxFields,
        [name]: value,
      });
      setIsModified(true);
    },
    [sandboxFields]
  );

  const saveSandbox = () => {
    updateSandbox(
      sandboxFields,
      (resp) => {
        setSandboxFields(resp);
        setIsModified(false);
        setIsLoading(false);
      },
      onError
    );
  };

  const noParamRequestWithCallbacks = (path) => () =>
    makePostRequest(
      path,
      (resp) => {
        setSandboxFields(resp);
        setIsModified(false);
        setIsLoading(false);
      },
      onError
    );

  const postFeatureServiceJob = useCallback(
    noParamRequestWithCallbacks(featureServiceKickoffPath)
  );

  const generateFakeSandbox = useCallback(
    noParamRequestWithCallbacks(generateFakeSandboxPath)
  );

  const resetOrGetSandboxAppSecretSuccess = ({ uid, secret }) => {
    setSandboxFields((current) => ({
      ...current,
      app_id: uid,
      app_secret: secret,
    }));
  };

  const toggleResetSandboxCredentialsConfirmed = () => {
    setResetSandboxConfirmed((confirmedStatus) => !confirmedStatus);
  };

  return (
    <Spinner loading={isLoading}>
      <SandboxCard
        canEdit={canEdit}
        canGenerateFakeSandbox={canGenerateFakeSandbox}
        canGenerateSandbox={canGenerateSandbox}
        developerAppId={developerAppId}
        error={error}
        fields={{ ...sandboxFields, __modified__: isModified }}
        generateFakeSandbox={generateFakeSandbox}
        hasSandboxManifest={!!sandboxManifests?.length}
        I18n={I18n}
        onChange={onChange}
        onError={onError}
        onPostFeatureServiceJob={postFeatureServiceJob}
        onUpdateSandbox={saveSandbox}
        redirectUriDocPath={redirectUriDocPath}
        resetAppSecret={resetSandboxAppSecret}
        resetAppSecretSuccess={resetOrGetSandboxAppSecretSuccess}
        resetCredentialsConfirmed={resetSandboxConfirmed}
        sandboxCredentialsDocPath={sandboxCredentialsDocPath}
        sandboxStatus={sandboxStatus}
        toggleResetCredentialsConfirmed={toggleResetSandboxCredentialsConfirmed}
      />
    </Spinner>
  );
};
