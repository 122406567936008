import React, { Component } from 'react';
import PT from 'prop-types';
import {
  Breadcrumbs,
  Spinner,
  ToolHeader,
  DetailPage,
} from '@procore/core-react';
import NotificationForm from './NotificationForm';

class NotificationFormPage extends Component {
  componentDidMount() {
    const { notificationId, notificationsStore } = this.props;
    if (notificationId) {
      notificationsStore.handlers.fetch(notificationId);
    }
  }

  render() {
    const {
      I18n,
      notificationId,
      notificationsStore,
      adminNotificationsUrl,
    } = this.props;
    const { state } = notificationsStore;
    const isNew = !notificationId;

    return (
      <DetailPage className="full-screen">
        <DetailPage.Main className="page-body">
          <DetailPage.Breadcrumbs>
            <Breadcrumbs className="breadcrumbs">
              <Breadcrumbs.Crumb>{I18n.t('page.admin')}</Breadcrumbs.Crumb>
              <Breadcrumbs.Crumb active>
                {I18n.t('page.notifications')}
              </Breadcrumbs.Crumb>
            </Breadcrumbs>
          </DetailPage.Breadcrumbs>

          <ToolHeader>
            <ToolHeader.Title>{I18n.t('page.notifications')}</ToolHeader.Title>
          </ToolHeader>

          <DetailPage.Body className="full-screen">
            <Spinner loading={state.requestPending}>
              <NotificationForm
                I18n={I18n}
                notificationsStore={notificationsStore}
                adminNotificationsUrl={adminNotificationsUrl}
                isNew={isNew}
              />
            </Spinner>
          </DetailPage.Body>
        </DetailPage.Main>
      </DetailPage>
    );
  }
}

NotificationFormPage.propTypes = {
  notificationId: PT.oneOfType([PT.number, PT.bool]).isRequired,
  adminNotificationsUrl: PT.string.isRequired,
  notificationsStore: PT.shape({
    state: PT.shape({
      notification: PT.shape({}),
      requestPending: PT.bool,
      error: PT.instanceOf(Error),
    }),
    handlers: PT.shape({
      fetch: PT.func,
      deleteNotification: PT.func,
      deleteNotificationSuccess: PT.func,
      saveNotification: PT.func,
      saveNotificationSuccess: PT.func,
      onError: PT.func,
    }),
  }).isRequired,
};

export default NotificationFormPage;
