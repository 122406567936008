import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FlashArea = ({ children, className, ...rest }) => (
  <div className={cx('flash-area', className)}>
    <div className="alert alert-notice" {...rest}>
      {children}
    </div>
  </div>
);

FlashArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FlashArea.defaultProps = {
  className: '',
};

export default FlashArea;
