import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../constants';
import { Flex, Grid, Typography, useI18nContext } from '@procore/core-react';
import { getOrdinal } from '@/global_helpers/getOrdinal';

const TabFeatures = ({
  fields,
  onFieldChange,
  isDisabled,
  validations,
  FormLabel,
}) => {
  const I18n = useI18nContext();
  const onChange = (fieldPath, value) =>
    onFieldChange([MARKETPLACE_TABS.FEATURES, ...fieldPath], value);

  return (
    <>
      {fields.feature_bullets.map((feature, index) => {
        const key = `feature_${index}`;
        return (
          <Grid.Row key={key}>
            <Grid.Col colWidth={8}>
              <Form.Field
                required
                data-qa={`marketplace-app-feature-bullet-${index}`}
                data-testid={`marketplace-app-feature-bullet-${index}`}
                disabled={isDisabled}
                label={
                  <Flex>
                    <Typography
                      intent="label"
                      style={{ marginRight: '.25rem' }}
                    >
                      {getOrdinal(index + 1, I18n)}
                    </Typography>
                    <FormLabel
                      changeFields={['feature_bullets', index]}
                      contentI18nTag="featureBullet"
                      copyText={feature}
                      showInfo={false}
                    />
                  </Flex>
                }
                type="text"
                value={feature}
                errors={pathOr([], ['feature_bullets', index], validations)}
                onChange={(e) => {
                  onChange(['feature_bullets', index], e.target.value);
                }}
                placeholder={I18n.t('placeholders.marketplaceApp.feature', {
                  ord: getOrdinal(index + 1, I18n, 'text'),
                })}
              />
            </Grid.Col>
          </Grid.Row>
        );
      })}
    </>
  );
};

TabFeatures.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  fields: PT.shape({
    feature_bullets: PT.arrayOf(PT.string),
  }).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({
    feature_bullets: PT.arrayOf(PT.arrayOf(PT.string)),
    features: PT.string,
  }).isRequired,
  onFieldChange: PT.func.isRequired,
  isDisabled: PT.bool.isRequired,
  FormLabel: PT.elementType.isRequired,
};

export default TabFeatures;
