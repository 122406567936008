const init = () => {
  document
    .querySelectorAll('[data-hover-to-reveal-password]')
    .forEach(hoverToRevealPassword);
};

const hoverToRevealPassword = (inputEl) => {
  inputEl.type = 'password';

  inputEl.addEventListener('mouseover', (event) => {
    event.target.type = 'text';
  });

  inputEl.addEventListener('mouseout', (event) => {
    event.target.type = 'password';
  });
};

module.exports = init;
