export const autoSelectCopy = (event) => {
  event.target.focus();
  event.target.select();
  document.execCommand('copy');

  const message = document.createElement('div');
  message.innerHTML = 'Copied';
  message.style.position = 'fixed';
  message.style.left = `${event.clientX}px`;
  message.style.top = `${10 + event.clientY}px`;
  message.style.zIndex = 9999;
  message.setAttribute('class', 'copied-text-message');
  message.setAttribute('id', 'copied_text_message');
  document.body.appendChild(message);

  window.setTimeout(() => {
    document.getElementById('copied_text_message').remove();
  }, 1500);
};

const init = () => {
  document.querySelectorAll('[data-auto-select-copy]').forEach((element) => {
    element.addEventListener('click', autoSelectCopy);
  });
};

export default init;
