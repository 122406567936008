import React, { useEffect } from 'react';
import PT from 'prop-types';
import {
  Button,
  Input,
  Modal,
  useI18nContext,
  Typography,
  Box,
  Flex,
} from '@procore/core-react';
import { Error } from '@procore/core-icons';

const bumpVersion = (version) => {
  if (!version) {
    return '0.1.0';
  }
  const [major, minor, patch] = version.split('.');
  return `${major}.${parseInt(minor, 10) + 1}.${patch}`;
};

const SemanticVersionModal = ({ open, setOpen, onSave, currentVersion }) => {
  const I18n = useI18nContext();

  const [semanticVersion, setSemanticVersion] = React.useState(
    bumpVersion(currentVersion)
  );
  useEffect(() => {
    setSemanticVersion(bumpVersion(currentVersion));
  }, [currentVersion]);

  const [error, setError] = React.useState(null);
  const [saving, setSaving] = React.useState(false);

  const saveManifest = async () => {
    setSaving(true);

    try {
      await onSave(semanticVersion);
    } catch (e) {
      const errors = e.response?.data?.error?.details;
      if (errors) {
        setError(errors[0].message);
      } else {
        setError(I18n.t('modals.semanticVersion.defaultError'));
      }
    } finally {
      setSaving(false);
    }
  };
  let lastVersionDisplay = I18n.t('modals.semanticVersion.lastVersion', {
    version: currentVersion,
  });
  if (!currentVersion) {
    lastVersionDisplay = I18n.t('modals.semanticVersion.noVersion');
  }

  const onClose = () => {
    setOpen(false);
    setError(null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onClickOverlay={onClose}
      data-testid="semantic-version-modal"
    >
      <Modal.Header onClose={onClose}>
        {I18n.t('modals.semanticVersion.title')}
      </Modal.Header>

      <Modal.Body>
        <Flex paddingBottom="md" direction="column" gap="lg">
          <Typography intent="body">
            {I18n.t('modals.semanticVersion.helper')}
          </Typography>
          <Typography intent="body">{lastVersionDisplay}</Typography>
        </Flex>
        <Typography as="label" intent="body" weight="bold">
          {I18n.t('modals.semanticVersion.versionNumber')}
        </Typography>
        <Input
          onChange={(e) => setSemanticVersion(e.target.value)}
          data-testid="semantic-version-input"
          value={semanticVersion}
          disabled={saving}
          error={Boolean(error)}
        />
        {error && (
          <Flex paddingTop="sm" alignItems="center">
            <Typography intent="body" color="red50">
              <Flex alignItems="center">
                <Error size="sm" />
                {error}
              </Flex>
            </Typography>
          </Flex>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons>
          <Button
            variant="tertiary"
            onClick={onClose}
            data-testid="cancel-version-button"
          >
            {I18n.t('modals.semanticVersion.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setError(null);
              saveManifest();
            }}
            data-testid="create-version-button"
          >
            {I18n.t('modals.semanticVersion.create')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

SemanticVersionModal.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  onSave: PT.func.isRequired,
};

export default SemanticVersionModal;
