import React, { useState, useEffect, useRef, useMemo } from 'react';
import PT from 'prop-types';
import {
  Button,
  Card,
  Form,
  Typography,
  Page,
  Box,
  Flex,
  Popover,
  Table,
  Tearsheet,
  useI18nContext,
} from '@procore/core-react';
import { Pencil, Trash } from '@procore/core-icons';
import AddParameterModal from './AddParameterModal';
import ViewsTable from './ViewsTable';
import { sidepanelViews } from '../constants';
import * as yup from 'yup';

// expands view list to include all child views explicitly from any
// ".all" views
const expandViews = (views) => {
  if (!views) {
    return [];
  }

  const viewsToReplace = views.filter((view) => (view.endsWith('.all')));
  let viewsList = [...views];

  viewsToReplace.forEach((view) => {
    const idx = viewsList.indexOf(view);

    const matcher = view.substr(0, view.length - 3);
    const childViews = sidepanelViews.filter((view) => (view.startsWith(matcher)));

    viewsList.splice(idx, 1);
    viewsList = [...viewsList, ...childViews];
  });

  return viewsList;
};

const AddComponentModal = ({
  open,
  closeModal,
  editingComponent,
  updateManifest,
  allowedComponentsArray,
  currentManifest,
}) => {
  const I18n = useI18nContext();

  const [component, setComponent] = useState(editingComponent);
  const typeRef = useRef(editingComponent?.type);

  useEffect(() => {
    setComponent(editingComponent);
    typeRef.current = editingComponent?.type;
  }, [editingComponent]);

  const componentOptions = {
    sidepanel: {
      id: 'sidepanel',
      label: I18n.t('modals.addComponent.sidePanel'),
      groupAssociation: 0,
    },
    fullscreen: {
      id: 'fullscreen',
      label: I18n.t('modals.addComponent.fullScreen'),
      groupAssociation: 0,
    },
  };

  const iframeTypes = allowedComponentsArray
    .map((type) => componentOptions[type])
    .filter((option) => option);

  // Future proofing: show all unclaimed component types + the type of the component being edited
  if (typeRef.current && !iframeTypes.find((t) => t.id === typeRef.current)) {
    iframeTypes.push(componentOptions[typeRef.current]);
  }

  const availableViews = sidepanelViews.map((view) => ({
    id: view,
    label: view,
    groupAssociation: 0,
  }));

  const properties = component?.user_configuration?.schema?.properties || {};
  const requiredProperties =
    component?.user_configuration?.schema?.required || [];

  const formValues = {
    type: componentOptions[component.type],
    url: component.source_url,
    views: component.views
      ? availableViews.filter((v) => component.views.includes(v.id))
      : [],
  };

  const schemaValidation = yup.object().shape({
    type: yup.object().required(I18n.t('validation.required')),
    url: yup
      .string()
      .test('url-validation', I18n.t('validation.url'), (value) => {
        try {
          new URL(value);
        } catch (e) {
          return false;
        }
        return true;
      })
      .required(I18n.t('validation.required')),
    views: yup
      .array()
      .of(yup.string())
      .when('type', {
        is: (type) => type?.id === 'sidepanel',
        then: (schema) => schema.min(1, I18n.t('validation.views')),
        otherwise: (schema) => schema.optional(),
      }),
  });

  let initialErrors = {};
  useEffect(() => {
    try {
      schemaValidation.validateSync(formValues, { abortEarly: false });
    } catch (e) {
      initialErrors = e.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
    }
  }, [editingComponent]);

  const addComponentToManifest = () => {
    const { type, ...rest } = component;
    const newManifest = currentManifest;

    if (typeRef.current && type !== typeRef.current) {
      delete newManifest[typeRef.current];
    }
    if (type === 'fullscreen' && component.views) {
      delete rest.views;
    }

    // default description until we remove requirement in manifest manager
    newManifest[type] = { ...rest, description: '' };

    updateManifest(newManifest);
    closeModal();
  };

  const setProperties = (props) => {
    setComponent((prevComponent) => ({
      ...prevComponent,
      user_configuration: {
        ...prevComponent?.user_configuration,
        schema: {
          type: 'object',
          ...prevComponent?.user_configuration?.schema,
          properties: props,
        },
      },
    }));
  };

  const setPropertyRequired = (property, required) => {
    let requiredProps = requiredProperties;
    const index = requiredProps.indexOf(property);

    if (required && index === -1) {
      // add the property if it's not already in the list
      requiredProps.push(property);
    } else if (!required && index !== -1) {
      // remove the property if it's already in the list
      requiredProps.splice(index, 1);
    }

    setComponent((prevComponent) => ({
      ...prevComponent,
      user_configuration: {
        ...prevComponent?.user_configuration,
        schema: {
          ...prevComponent?.user_configuration?.schema,
          required: requiredProps,
        },
      },
    }));
  };

  const leftColStart = 1;
  const fullColWidth = 12;

  const [addParameterModalOpen, setAddParameterModalOpen] = useState(false);
  const [editingProperty, setEditingProperty] = useState({});

  const editProperty = (property) => () => {
    setEditingProperty({
      key: property,
      ...properties[property],
      required: requiredProperties.includes(property),
    });
    setAddParameterModalOpen(true);
  };

  const saveProperty = (property) => {
    // remove fields related to old key
    // in case it has been changed
    setPropertyRequired(editingProperty.key, false);
    delete properties[editingProperty.key];

    // set values for new/updated key
    setPropertyRequired(property.key, property.required);
    setProperties({
      ...properties,
      [property.key]: {
        name: property.name,
        description: property.description,
        type: 'string',
      },
    });
  };

  const deleteProperty = (property) => {
    delete properties[property];

    setPropertyRequired(property, false);
    setProperties(properties);
  };

  return (
    <Tearsheet open={open} onClose={closeModal} placement="right">
      <Page data-testid="component-form" style={{ width: '804px' }}>
        <Page.Main style={{ position: 'relative' }}>
          <Form
            onSubmit={addComponentToManifest}
            initialValues={formValues}
            validationSchema={schemaValidation}
            initialErrors={initialErrors}
            validateOnBlur
            validateOnChange
          >
            {({ errors, setFieldValue, isValid, validateField }) => (
              <Form.Form>
                <Page.Header onClose={closeModal}>
                  <Page.Title>
                    <h1>{I18n.t('modals.addComponent.title')}</h1>
                  </Page.Title>
                </Page.Header>

                <Page.Body>
                  <Card>
                    <Box padding="lg">
                      <h1>{I18n.t('modals.addComponent.bodyTitle')}</h1>

                      <Form.Row>
                        <Form.Select
                          required
                          name="type"
                          label={I18n.t('modals.addComponent.type')}
                          options={iframeTypes}
                          onSelect={(type) => {
                            setComponent((prevComponent) => ({
                              ...prevComponent,
                              type: type.item.id,
                            }));
                          }}
                          colStart={leftColStart}
                          colWidth={fullColWidth}
                          data-testid="component-type"
                          placeholder={I18n.t('modals.addComponent.typePlaceholder')}
                          description={I18n.t(
                            'modals.addComponent.typeTooltip'
                          )}
                          onClear={() => {
                            setComponent((prevComponent) => ({
                              ...prevComponent,
                              type: null,
                            }));
                          }}
                        />
                      </Form.Row>

                      <Form.Row>
                        <Form.Text
                          name="url"
                          label={I18n.t('modals.addComponent.url')}
                          required
                          onChange={(e) => {
                            setComponent((prevComponent) => ({
                              ...prevComponent,
                              source_url: e.target.value,
                            }));
                          }}
                          colStart={leftColStart}
                          colWidth={fullColWidth}
                          data-testid="component-url"
                          placeholder={I18n.t(
                            'modals.addComponent.urlPlaceholder'
                          )}
                          description={I18n.t('modals.addComponent.urlTooltip')}
                        />
                      </Form.Row>

                      {component?.type === 'sidepanel' && (
                        <ViewsTable
                          selectedViews={expandViews(component.views)}
                          setSelectedViews={(views) => {
                            setFieldValue('views', views, true);
                            setComponent((prevComponent) => ({
                              ...prevComponent,
                              views: views,
                            }));
                          }}
                        />
                      )}

                      <Flex margin="xl none xl none" direction="column">
                        <Typography weight="bold" colWidth="12">
                          {I18n.t('modals.addComponent.parameterInterpolation')}
                        </Typography>
                        <Box margin="xs none">
                          <Typography>
                            {I18n.t(
                              'modals.addComponent.parameterInterpolationDescription'
                            )}
                          </Typography>
                        </Box>

                        {Object.keys(properties).length > 0 && (
                          <Table.Container style={{ width: '100%' }}>
                            <Table>
                              <Table.Header>
                                <Table.HeaderRow>
                                  <Table.HeaderCell>
                                    {I18n.t('modals.addComponent.name')}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {I18n.t('modals.addComponent.key')}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {I18n.t('modals.addComponent.description')}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    {I18n.t('modals.addComponent.required')}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell></Table.HeaderCell>
                                </Table.HeaderRow>
                              </Table.Header>

                              <Table.Body>
                                {Object.keys(properties).map((property) => (
                                  <Table.BodyRow key={property}>
                                    <Table.BodyCell>
                                      <Table.TextCell>
                                        {properties[property].name}
                                      </Table.TextCell>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                      <Table.TextCell>
                                        {property}
                                      </Table.TextCell>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                      <Table.TextCell>
                                        {properties[property].description}
                                      </Table.TextCell>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                      <Table.TextCell>
                                        {requiredProperties.includes(property)
                                          ? I18n.t('modals.addComponent.yes')
                                          : I18n.t('modals.addComponent.no')}
                                      </Table.TextCell>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                      <Table.IconCell>
                                        <Pencil
                                          onClick={editProperty(property)}
                                          data-testid={`edit-property-${property}`}
                                        />
                                        <Trash
                                          onClick={() =>
                                            deleteProperty(property)
                                          }
                                          data-testid={`delete-property-${property}`}
                                        />
                                      </Table.IconCell>
                                    </Table.BodyCell>
                                  </Table.BodyRow>
                                ))}
                              </Table.Body>
                            </Table>
                          </Table.Container>
                        )}

                        <Box padding="sm none">
                          <AddParameterModal
                            open={addParameterModalOpen}
                            setOpen={setAddParameterModalOpen}
                            editingProperty={editingProperty}
                            saveProperty={saveProperty}
                            deleteProperty={deleteProperty}
                          />

                          <Button
                            variant="secondary"
                            onClick={() => {
                              setEditingProperty({});
                              setAddParameterModalOpen(true);
                            }}
                            data-testid="component-add-parameter"
                          >
                            {I18n.t('modals.addComponent.addParameter')}
                          </Button>
                        </Box>
                      </Flex>

                      <Flex
                        direction="column"
                        alignContent="stretch"
                        alignItems="space-between"
                      ></Flex>
                    </Box>
                  </Card>
                </Page.Body>

                <Page.Footer>
                  <Card>
                    <Flex padding="md" justifyContent="flex-end">
                      <Box margin="none md">
                        <Button
                          variant="secondary"
                          onClick={closeModal}
                          data-testid="add-component-cancel-button"
                        >
                          {I18n.t('modals.addComponent.cancel')}
                        </Button>
                      </Box>
                      <Button
                        variant="primary"
                        data-testid="component-save"
                        type="submit"
                        disabled={!isValid}
                      >
                        {I18n.t('modals.addComponent.save')}
                      </Button>
                    </Flex>
                  </Card>
                </Page.Footer>
              </Form.Form>
            )}
          </Form>
        </Page.Main>
      </Page>
    </Tearsheet>
  );
};

AddComponentModal.propTypes = {
  open: PT.bool.isRequired,
  closeModal: PT.func.isRequired,
  updateManifest: PT.func.isRequired,
  allowedComponentsArray: PT.arrayOf(PT.string).isRequired,
  currentManifest: PT.object.isRequired,
  editingComponent: PT.shape({
    description: PT.string,
    source_url: PT.string,
    type: PT.string,
    user_configuration: PT.shape({
      schema: PT.shape({
        properties: PT.shape({}),
        required: PT.arrayOf(PT.string),
      }),
    }),
    views: PT.arrayOf(PT.string),
  }),
};

export default AddComponentModal;
