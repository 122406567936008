import React from 'react';
import { I18nProvider } from '@procore/core-react';
import withBugsnag from '@/react/shared/BugsnagProvider';
import { compose } from 'recompose';
import ReviewQueue from './ReviewQueue';
import i18n from '../i18n';
import ReviewQueueContainer from './ReviewQueueContainer';

const TableWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <ReviewQueue {...props} />
  </I18nProvider>
);

export default compose(withBugsnag, ReviewQueueContainer)(TableWithI18n);
