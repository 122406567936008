import React, { useState } from 'react';
import PT from 'prop-types';
import axios from 'axios';
import { getHeaders } from '@/utils/apiUtil';

/**
 * This higher order component handles the fetching and state management
 * of notifications
 */

const NotificationsStateContainer = (WrappedComponent) => (props) => {
  const [state, setState] = useState({
    loading: false,
    notifications: [],
    error: null,
  });

  const fetchNewNotifications = (resolve, reject) => {
    setState({ ...state, loading: true });

    return axios
      .get('/api/v1/notifications', { headers: getHeaders() })
      .then(resolve)
      .catch(reject);
  };

  function fetchNewNotificationsSuccess({ data: notifications }) {
    const sortedNotifications = notifications.sort((a, b) => {
      // Sort by newest notifications first
      const dOne = new Date(a['created_at']);
      const dTwo = new Date(b['created_at']);
      return dOne > dTwo ? -1 : dOne < dTwo ? 1 : 0;
    });

    setState({
      ...state,
      loading: false,
      notifications: sortedNotifications,
      error: null,
    });
  }

  function fetchNewNotificationsError(error) {
    setState({
      ...state,
      error,
      notifications: [],
      loading: false,
    });
  }

  return (
    <WrappedComponent
      {...props}
      {...{
        notificationsStore: {
          state,
          handlers: {
            fetch: () =>
              fetchNewNotifications(
                fetchNewNotificationsSuccess,
                fetchNewNotificationsError
              ),
          },
        },
      }}
    />
  );
};

NotificationsStateContainer.PT = {
  loadNotificationsOnMount: PT.bool.isRequired,
};

export default NotificationsStateContainer;
