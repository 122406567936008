import React, { useState } from 'react';
import PT from 'prop-types';
import {
  EmptyState,
  Button,
  Flex,
  Box,
  Table,
  useI18nContext,
  Tabs,
} from '@procore/core-react';
import { Check } from '@procore/core-icons';
import { mapPermissionDisplayName } from '@/react/common/Permissions/permissions_constants';
import { UseShowContext } from '../../Show.context';
import EditPermissionsModal from '../../modals/EditPermissionsModal';

const PermissionsRows = ({ permissions, scope, nameMap }) => {
  const scopedPermissions = permissions[scope] || {};
  return Object.keys(scopedPermissions)
    .sort()
    .map((k) => {
      return (
        <Table.BodyRow key={k}>
          <Table.BodyCell>
            <Table.TextCell>{nameMap[scope][k]}</Table.TextCell>
          </Table.BodyCell>
          <Table.BodyCell>
            <Flex justifyContent="center">
              <Table.IconCell>
                {scopedPermissions[k] === 'readonly' && <Check />}
              </Table.IconCell>
            </Flex>
          </Table.BodyCell>
          <Table.BodyCell>
            <Flex justifyContent="center">
              <Table.IconCell>
                {scopedPermissions[k] === 'standard' && <Check />}
              </Table.IconCell>
            </Flex>
          </Table.BodyCell>
          <Table.BodyCell>
            <Flex justifyContent="center">
              <Table.IconCell>
                {scopedPermissions[k] === 'admin' && <Check />}
              </Table.IconCell>
            </Flex>
          </Table.BodyCell>
        </Table.BodyRow>
      );
    });
};

const Permissions = ({
  currentManifest,
  setCurrentManifest,
  authCodeEnabled,
  authCodeLocked,
  setAuthCodeEnabled
}) => {
  const I18n = useI18nContext();

  const permissions =
    currentManifest?.configured_manifest_content?.permissions || {};
  const [scope, setScope] = useState('company');

  const {
    state: { setEditingPermissions },
    dmsaPermissions,
  } = UseShowContext();

  const [permissionNames] = useState(mapPermissionDisplayName(dmsaPermissions));
  const [editPermissionsModalOpen, setEditPermissionsModalOpen] =
    useState(false);

  const openEditPermissionsModal = () => {
    setEditPermissionsModalOpen(true);
    setEditingPermissions(permissions);
  };

  const hasProjectPermissions = Boolean(
    Object.keys(permissions.project || {}).length
  );
  const hasCompanyPermissions = Boolean(
    Object.keys(permissions.company || {}).length
  );

  const hasPermissions = hasProjectPermissions || hasCompanyPermissions;
  const displayComponentType = authCodeEnabled || hasPermissions;

  const noPermissionsInScope =
    (scope === 'project' && !hasProjectPermissions) ||
    (scope === 'company' && !hasCompanyPermissions);

  // only show tabs when there are some permissions present
  const showTabs =
    Object.keys(permissions?.company || {}).length > 0 ||
    Object.keys(permissions?.project || {}).length > 0;

  return (
    <Box padding="lg" data-testid="permissions-card-body">
      <EditPermissionsModal
        open={editPermissionsModalOpen}
        setOpen={setEditPermissionsModalOpen}
        setPermissions={(permissions) => {
          const newContent = {
            ...currentManifest?.configured_manifest_content,
            permissions,
          };

          setCurrentManifest({
            ...currentManifest,
            configured_manifest_content: newContent,
          });
        }}
        scope={scope}
        setScope={setScope}
        authCodeEnabled={authCodeEnabled}
        setAuthCodeEnabled={setAuthCodeEnabled}
        authCodeLocked={authCodeLocked}
      />

      {displayComponentType && (
        <Table.Container>
          <Table>
            <Table.Header>
              <Table.HeaderRow>
                <Table.HeaderCell>{'Component Type'}</Table.HeaderCell>
              </Table.HeaderRow>
            </Table.Header>

            <Table.Body data-testid={'component-type-table'}>
              {authCodeEnabled && (
                <Table.BodyRow>
                  <Table.BodyCell>
                    <Table.TextCell data-testid={'auth-code'}>
                      {'User Level Authentication'}
                    </Table.TextCell>
                  </Table.BodyCell>
                </Table.BodyRow>
              )}
              {hasPermissions && (
                <Table.BodyRow>
                  <Table.BodyCell>
                    <Table.TextCell data-testid={'service-account'}>
                      {'Service Account Authentication'}
                    </Table.TextCell>
                  </Table.BodyCell>
                </Table.BodyRow>
              )}
            </Table.Body>
          </Table>
        </Table.Container>
      )}
      {showTabs && (
        <Flex padding="lg none" justifyContent="space-between">
          <Tabs>
            <Tabs.Tab
              onClick={() => setScope('company')}
              active={scope === 'company'}
              data-testid="company-tab"
            >
              <Tabs.Link>{I18n.t('configurationsTab.company')}</Tabs.Link>
            </Tabs.Tab>
            <Tabs.Tab
              onClick={() => setScope('project')}
              active={scope === 'project'}
              data-testid="project-tab"
            >
              <Tabs.Link>{I18n.t('configurationsTab.project')}</Tabs.Link>
            </Tabs.Tab>
          </Tabs>
        </Flex>
      )}

      {noPermissionsInScope && (
        <EmptyState data-testid="permissions-empty">
          <EmptyState.NoItems />
          <EmptyState.Title style={{ width: '100%' }}>
            {I18n.t('permissionsDetailsTable.emptySubtitle')}
          </EmptyState.Title>
          <EmptyState.Description>
            {I18n.t('permissionsDetailsTable.emptyState')}
          </EmptyState.Description>
          <EmptyState.Actions>
            <Button onClick={openEditPermissionsModal}>
              {I18n.t('permissionsDetailsTable.addPermissions')}
            </Button>
          </EmptyState.Actions>
        </EmptyState>
      )}

      {!noPermissionsInScope && (
        <>
          <Table.Container data-testid="permissions-table">
            <Table>
              <Table.Header>
                <Table.HeaderRow>
                  <Table.HeaderCell>
                    {I18n.t('configurationsTab.tool')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Flex justifyContent="center">
                      {I18n.t('configurationsTab.readOnly')}
                    </Flex>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Flex justifyContent="center">
                      {I18n.t('configurationsTab.standard')}
                    </Flex>
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <Flex justifyContent="center">
                      {I18n.t('configurationsTab.admin')}
                    </Flex>
                  </Table.HeaderCell>
                </Table.HeaderRow>
              </Table.Header>

              <Table.Body data-testid={`${scope}-permissions`}>
                <PermissionsRows
                  permissions={permissions}
                  scope={scope}
                  nameMap={permissionNames}
                />
              </Table.Body>
            </Table>
          </Table.Container>

          <Flex padding="lg none" direction="row" justifyContent="flex-end">
            <Button variant="secondary" onClick={openEditPermissionsModal}>
              {I18n.t('configurationsTab.editPermissions')}
            </Button>
          </Flex>
        </>
      )}
    </Box>
  );
};

Permissions.propTypes = {
  currentManifest: PT.shape({
    configured_manifest_content: PT.shape({
      permissions: PT.shape({}),
    }),
  }),
  setCurrentManifest: PT.func.isRequired,
};

export default Permissions;
