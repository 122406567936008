import React from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { TextArea as CoreTextArea } from '@procore/core-react';
import { withFocus } from '../decorators/Focus';
import { Label, FormError } from './Form';

const Textarea = ({
  value,
  onBlur,
  onFocus,
  onChange,
  className,
  focused,
  label,
  required,
  children,
  errors,
  ...rest
}) => (
  <div className={cx(className, { 'a-field--selected': focused })}>
    <Label required={required} label={label} />
    <CoreTextArea
      error={errors && errors.length > 0}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      resize="vertical"
      value={value || ''}
      {...rest}
    />
    <FormError errors={errors} />
    {children}
  </div>
);

Textarea.propTypes = {
  className: PT.string,
  focused: PT.bool,
  label: PT.node,
  onBlur: PT.func,
  onFocus: PT.func,
  onChange: PT.func,
  required: PT.bool,
  value: PT.string,
  errors: PT.arrayOf(PT.string),
  children: PT.element,
};

Textarea.defaultProps = {
  className: '',
  label: '',
  focused: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  required: false,
  value: '',
  errors: [],
  children: <></>,
};

export default withFocus(Textarea);
