import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '@/utils/apiUtil';

/**
 * This higher order component handles the fetching and state management
 * of a notification
 */
const NotificationReaderStateContainer = (WrappedComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: false,
        notification: null,
        error: null,
      };
    }

    getNotificationApiPath(notificationId) {
      return `/api/v1/notifications/${notificationId}`;
    }

    markAsRead(notificationId, resolve, reject) {
      return axios
        .patch(this.getNotificationApiPath(notificationId), {
          headers: getHeaders(),
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }

    markAsReadSuccess({ data: notification }) {
      // TODO: Add global store to make realtime updates via React
      // https://procoretech.atlassian.net/browse/DMX-701
    }

    markAsReadError(error) {
      this.setState({ error });
    }

    fetchNotification = (notificationId, resolve, reject) => {
      this.setState({ loading: true });

      return axios
        .get(this.getNotificationApiPath(notificationId), {
          headers: getHeaders(),
        })
        .then(resolve)
        .catch(reject);
    };

    fetchNotificationSuccess = ({ data: notification }) => {
      // Only make request to mark notification as read if it isn't marked already
      if (!notification.read_at) {
        this.markAsRead(
          notification.id,
          this.markAsReadSuccess,
          this.markAsReadError
        );
      }

      this.setState({ loading: false, error: null, notification });
    };

    fetchNotificationError = (error) => {
      this.setState({ loading: false, error, notification: null });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...{
            notificationReaderStore: {
              state: this.state,
              handlers: {
                fetchNotification: (id) =>
                  this.fetchNotification(
                    id,
                    this.fetchNotificationSuccess,
                    this.fetchNotificationError
                  ),
              },
            },
          }}
        />
      );
    }
  };

NotificationReaderStateContainer.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default NotificationReaderStateContainer;
