import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Typography, colors } from '@procore/core-react';
import CopyOnClick from '@/react/shared/copyOnClick/CopyOnClick';
import ChangeIndicator from '../ChangeIndicator';
import styled from 'styled-components';

// if this element has a parent label with a classname of 'required' show an asterisk
const MarketplaceFormLabelTitle = styled(Typography)`
  label.required &::after {
    content: '*';
    color: red;
    display: inline-block;
    margin-left: 4px;
  }
`;

const MarketplaceFormLabel = ({
  changeFields,
  contentI18nTag,
  copyText,
  I18n,
  showCopyButton,
  showInfo = false,
  hasChanged,
}) => {
  return (
    <Box
      display="flex-row"
      alignItems="center"
      marginBottom={showCopyButton ? 'sm' : 'xs'}
    >
      <ChangeIndicator changed={hasChanged(changeFields)}>
        <Flex direction="column">
          <MarketplaceFormLabelTitle intent="label">
            {I18n.t(`labels.marketplaceApp.${contentI18nTag}`)}
          </MarketplaceFormLabelTitle>
          {showInfo && (
            <Typography
              intent="small"
              style={{
                margin: '4px 0',
                wordBreak: 'break-word',
                color: colors.gray45,
              }}
            >
              {I18n.t(`labels.marketplaceApp.info.${contentI18nTag}`)}
            </Typography>
          )}
        </Flex>
      </ChangeIndicator>
      {showCopyButton && (
        <Box marginLeft="sm">
          <CopyOnClick textToCopy={copyText}>
            <Button variant="secondary" size="sm">
              Copy
            </Button>
          </CopyOnClick>
        </Box>
      )}
    </Box>
  );
};

MarketplaceFormLabel.propTypes = {
  changeFields: PropTypes.array,
  contentI18nTag: PropTypes.string,
  copyText: PropTypes.string,
  I18n: PropTypes.object,
  showCopyButton: PropTypes.bool,
  showInfo: PropTypes.bool,
  hasChanged: PropTypes.func,
};

export default MarketplaceFormLabel;
