import React from 'react';
import { compose, mapProps, withStateHandlers } from 'recompose';
import api from '../api';

const numPages = (total, pageSize) => {
  return Math.max(1, Math.ceil(total / pageSize));
};

const DevelopersIndexContainer = (Cmp) =>
  withStateHandlers(
    () => ({
      apps: [],
      error: null,
      loading: true,
      filters: {
        user: [],
        search: '',
        sort: '',
      },
      pagination: {
        pageNumber: 1,
        pageSize: 50,
        total: 0,
        totalPages: 1,
      },
      sidebarOpen: true,
    }),
    {
      fetchApps: ({
        filters: { user, search, sort },
        pagination: { pageSize, totalPages },
      }) => (pageNumber, successCb, errorCb) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
          return;
        }

        api
          .fetchApps(pageNumber, pageSize, user, search, sort)
          .then((resp) => successCb(resp))
          .catch(errorCb);

        return { loading: true };
      },
      fetchAppsSuccess: ({ pagination }) => ({ config, data, headers }) => {
        const total = parseInt(headers.total, 10);
        const pageSize = parseInt(headers['per-page'], 10);
        const totalPages = numPages(total, pageSize);
        const pageNumber = config.params ? config.params.page : 1;

        return {
          apps: data,
          loading: false,
          pagination: {
            ...pagination,
            pageNumber,
            pageSize,
            total,
            totalPages,
          },
        };
      },
      onError: () => (error) => ({
        error,
        loading: false,
      }),
      getUserOptions: () => (input, callback, errorCb) => {
        api
          .getUserOptions(input)
          .then((resp) => {
            callback(null, { options: resp.data });
          })
          .catch((err) => {
            errorCb(err);
            callback(err, { options: [] });
          });
      },
      handleFilterChange: ({ filters }) => (filter, value) => ({
        filters: { ...filters, [filter]: value },
      }),
      handleSort: ({ filters }) => (column) => {
        if (column === filters.sort) {
          column = `-${column}`;
        }
        if (`-${column}` === filters.sort) {
          column = '';
        }

        return { filters: { ...filters, sort: column } };
      },
      sidebarToggle: ({ sidebarOpen }) => () => ({
        sidebarOpen: !sidebarOpen,
      }),
    }
  )(({ ...props }) => <Cmp {...props} />);

export default compose(
  DevelopersIndexContainer,
  mapProps(
    ({
      fetchApps,
      fetchAppsSuccess,
      onError,
      handleFilterChange,
      handleSort,
      getUserOptions,
      sidebarToggle,
      ...props
    }) => ({
      developersIndexStore: {
        handlers: {
          fetch: (pageNumber) =>
            fetchApps(pageNumber, fetchAppsSuccess, onError),
          onError,
          filterChange: (filter, value) => {
            handleFilterChange(filter, value);
            fetchApps(1, fetchAppsSuccess, onError);
          },
          sortChange: (column) => {
            handleSort(column);
            fetchApps(1, fetchAppsSuccess, onError);
          },
          getOptions: (input, callback) =>
            getUserOptions(input, callback, onError),
          sidebarToggle,
        },
        state: {
          apps: props.apps,
          liveAppIds: props.liveAppIds,
          error: props.error,
          isLoading: props.loading,
          filters: props.filters,
          pagination: props.pagination,
        },
      },
      ...props,
    })
  )
);
