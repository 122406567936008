import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../constants';
import { getOrdinal } from '@/global_helpers/getOrdinal';
import { Box, Button, Flex, Grid, Typography } from '@procore/core-react';
import { Plus, Trash } from '@procore/core-icons';
import { useDynamicFields } from '@/react/hooks/useDynamicFields';

const MAX_REQUIREMENTS = 3;
const TabRequirements = ({
  I18n,
  fields,
  isDisabled,
  onFieldChange,
  FormLabel,
  validations,
}) => {
  const initialFields = fields?.requirements || [''];
  const {
    fields: requirements,
    addField,
    removeField,
    updateField,
  } = useDynamicFields(initialFields, MAX_REQUIREMENTS);

  return (
    <Grid gutterX="sm" gutterY="lg">
      {requirements.map((requirement, index) => {
        const key = `requirement_${index}`;
        const hasError =
          (validations?.requirements && validations?.requirements[index]) ||
          false;
        return (
          <Grid.Row
            key={key}
            style={{ alignItems: hasError ? 'center' : 'flex-end' }}
          >
            <Grid.Col colWidth={8}>
              <Form.Field
                data-qa={`marketplace-app-integration-requirement-${index}`}
                data-testid={`marketplace-app-integration-requirement-${index}`}
                disabled={isDisabled}
                label={
                  <Flex>
                    <Typography
                      intent="label"
                      style={{ marginRight: '.25rem' }}
                    >
                      {getOrdinal(index + 1, I18n)}
                    </Typography>
                    <FormLabel
                      changeFields={['requirements', index]}
                      contentI18nTag="integrationRequirement"
                      copyText={requirement}
                      showInfo={false}
                    />
                  </Flex>
                }
                type="text"
                value={requirement}
                errors={pathOr([], ['requirements', index], validations)}
                onChange={(e) => {
                  updateField(index, e.target.value);
                  onFieldChange(
                    [MARKETPLACE_TABS.REQUIREMENTS, 'requirements', index],
                    e.target.value
                  );
                }}
                placeholder={I18n.t('placeholders.marketplaceApp.requirement', {
                  ord: getOrdinal(index + 1, I18n, 'text'),
                })}
              />
            </Grid.Col>
            <Grid.Col colWidth={4}>
              {!isDisabled && (
                <Box>
                  {index === requirements.length - 1 &&
                  requirements.length < MAX_REQUIREMENTS ? (
                    <Button
                      data-testid={`marketplace-app-add-integration-requirement-${index}`}
                      icon={<Plus />}
                      aria-label="Add"
                      variant="tertiary"
                      onClick={() =>
                        addField((updated) => {
                          const newIndex = index + 1;
                          onFieldChange(
                            [
                              MARKETPLACE_TABS.REQUIREMENTS,
                              'requirements',
                              newIndex,
                            ],
                            updated[newIndex]
                          );
                        })
                      }
                    />
                  ) : (
                    <Button
                      data-testid={`marketplace-app-remove-integration-requirement-${index}`}
                      icon={
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Trash size="sm" />
                        </Box>
                      }
                      aria-label="Remove"
                      variant="tertiary"
                      onClick={() =>
                        removeField(index, (updated) => {
                          onFieldChange(
                            [MARKETPLACE_TABS.REQUIREMENTS, 'requirements'],
                            updated
                          );
                        })
                      }
                      disabled={isDisabled}
                    />
                  )}
                </Box>
              )}
            </Grid.Col>
          </Grid.Row>
        );
      })}
    </Grid>
  );
};

TabRequirements.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  fields: PT.shape({
    requirements: PT.arrayOf(PT.string),
  }).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  validations: PT.shape({
    requirements: PT.arrayOf(PT.arrayOf(PT.string)),
  }).isRequired,
};

export default TabRequirements;
