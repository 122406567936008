// Using jquery.modal.min in vendor/assets/javascripts

const FlashFade = {
  init() {
    setTimeout(() => {
      $('#confirmation-email-sent-flash').fadeOut(1000);
    }, 3000);
  },
};

module.exports = FlashFade;
