import React, { useState } from 'react';
import {
  I18nProvider,
  Modal,
  useI18nContext,
  Typography,
  Button,
  Flex,
} from '@procore/core-react';
import { trackEvent } from '@/react/shared/analytics';
import {
  getBeta,
  includesBeta,
  setBeta,
  setForcedIntoBeta,
  includesForcedIntoBeta,
  removeBannerDismissed,
} from '@/utils/localStorageUtil';

import i18n from './i18n';

const OptInModal = ({}) => {
  const I18n = useI18nContext();
  const [open, setOpen] = useState(!includesBeta());

  // if they are not yet in the beta,
  // put them into it and display the modal
  if (!getBeta() && !includesForcedIntoBeta()) {
    setBeta(true);
    setForcedIntoBeta(true);
    removeBannerDismissed();
    setOpen(true);
  }

  return (
    <Modal
      open={open}
      onClickOverlay={() => {
        setOpen(false);
      }}
    >
      <Modal.Body>
        <Flex direction="column">
          <img src="assets/images/form_based_modal_picture.svg"/>
          <Flex padding="sm none">
            <Typography intent="h1">
              {I18n.t('modals.optIn.title')}
            </Typography>
          </Flex>
          <Flex padding="sm none">
            <Typography intent="body">
              {I18n.t('modals.optIn.bodyParagraph1')}
            </Typography>
          </Flex>
          <Flex padding="sm none">
            <Typography intent="body">
              {I18n.t('modals.optIn.bodyParagraph2')}
            </Typography>
          </Flex>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Button
            variant="primary"
            onClick={() => {
              trackEvent('developers.apps.form_based_manifests.opted_in', {});
              setBeta(true);
              setOpen(false);
              window.location.reload();
            }}
          >
            {I18n.t('modals.optIn.confirm')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
}

const Container =  () => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <OptInModal />
  </I18nProvider>
)

export default Container;
