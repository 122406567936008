import { path, pathOr } from 'ramda';

/**
 * Extract error message from API error response
 */
const extractErrorMsg = (errorResponse = {}) => {
  const errors = path(['response', 'data', 'errors'], errorResponse);
  // TODO: Generate better error message when given `errors` key
  const stringifiedErrors = JSON.stringify(errors, null, 1);
  return (
    stringifiedErrors || pathOr('Error occurred', ['message'], errorResponse)
  );
};

export default {
  extractErrorMsg,
};
