export const DEVELOPER_APPS_PATH = '/developer_apps';

export const sidepanelViews = [
    'budgeting.change_history.list',
    'budgeting.detail',
    'budgeting.forecasting.detail',
    'budgeting.list',
    'change_events.detail',
    'change_events.edit',
    'change_events.list',
    'change_events.new',
    'commitments.commitment_contract_change_orders.detail',
    'commitments.purchase_order_contracts.detail',
    'commitments.purchase_order_contracts.edit',
    'commitments.purchase_order_contracts.new',
    'commitments.requisitions.detail',
    'commitments.work_order_contracts.detail',
    'commitments.work_order_contracts.edit',
    'commitments.work_order_contracts.new',
    'correspondence.detail',
    'inspections.detail',
    'observations.edit',
    'observations.list',
    'prime_contracts.change_orders.detail',
    'prime_contracts.change_orders.potential_change_orders.detail',
    'prime_contracts.change_orders.potential_change_orders.new',
    'prime_contracts.detail',
    'prime_contracts.new',
    'prime_contracts.payment_applications.detail',
    'project_directory.person.edit',
    'project_directory.vendors.edit',
    'rfi.detail',
    'schedule.calendar.list',
    'schedule.calendar.lookaheads.list',
    'schedule.calendar.month.list',
    'schedule.calendar.task.detail',
    'schedule.calendar.task.list',
    'submittal_logs.detail',
  ];

export const generateNestedSidepanelViews = () => {
  const views = {};

  sidepanelViews.map((view) =>
    (view.split('.'))
  ).forEach((keys) => {
    let currentObj = views;

    keys.forEach((key) => {
      if (!currentObj[key]) {
        currentObj[key] = {};
      }

      currentObj = currentObj[key];
    });
  });

  return views;
};

export const nestedSidepanelViews = generateNestedSidepanelViews();
