import React from 'react';
import PT from 'prop-types';
import { Box, Token, useI18nContext } from '@procore/core-react';
import ConfirmMediumDeletionModal from './ConfirmMediumDeletionModal';
import FilePreviewModal from './FilePreviewModal';

const DropzoneModalsDocUpload = ({ disabled, file, removeFile }) => {
  const [previewModalOpen, setPreviewModalOpen] = React.useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const remove = (file) => () => removeFile(file);

  return (
    <Box padding="xs" data-testid="file-upload-preview">
      <Token variant="icon" disabled={disabled}>
        <Token.Label
          style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '150px',
          }}          
          onClick={() => setPreviewModalOpen(true)}
          data-qa="file-label-upload"
        >
          {file?.name}
        </Token.Label>

        <FilePreviewModal
          I18n={useI18nContext()}
          file={file}
          isVisible={previewModalOpen}
          hide={() => {
            setPreviewModalOpen(false);
          }}
        />

        <Token.Remove onClick={() => setDeletionModalOpen(true)} />

        <ConfirmMediumDeletionModal
          I18n={useI18nContext()}
          hide={() => setDeletionModalOpen(false)}
          isVisible={deletionModalOpen}
          onConfirm={remove(file)}
          file={file}
        />
      </Token>
    </Box>
  );
};

DropzoneModalsDocUpload.propTypes = {
  disabled: PT.bool,
  file: PT.shape({}).isRequired,
  removeFile: PT.func,
};

DropzoneModalsDocUpload.defaultProps = {
  disabled: false,
  removeFile: () => {},
};

export default DropzoneModalsDocUpload;
