import React from 'react';
import { I18nProvider } from '@procore/core-react';
import TopNavReference from './TopNavReference';
import i18n from '../i18n';

const TopNavReferenceWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <TopNavReference {...props} />
  </I18nProvider>
);

export default TopNavReferenceWithI18n;
