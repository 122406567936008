import { CHUNK_LOAD_ERROR_MESSAGE } from '@/react/shared/constants';

async function lazyWithRetry(fn, retries = 2, interval = 300) {
  try {
    const result = await fn();
    return result;
  } catch {
    if (retries > 0) {
      await new Promise((r) => setTimeout(r, interval));
      return lazyWithRetry(fn, retries - 1, interval);
    }
  }
  throw new Error(CHUNK_LOAD_ERROR_MESSAGE);
}

export default lazyWithRetry;
