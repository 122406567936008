import React, { useState } from 'react';
import PT from 'prop-types';
import { Box, useI18nContext, Typography, Form } from '@procore/core-react';
import { object, string } from 'yup';

export const isInstructionsEmpty = (instructions) => {
  return !instructions?.notes && Object.keys(instructions?.page || {}).length === 0;
}

export const postInstallationInstructionsValidation = object()
  .shape({
    notes: string(),
    page: object().shape({
      label: string(),
      url: string(),
    }),
  })
  .test(
    'all-post-installation-fields',
    'All post installation instruction fields must be present',
    (value) => {
      const { notes, page } = value;
      if (isInstructionsEmpty(value)) return true;
      // Yup expects a strict boolean return
      return Boolean(notes && page.label && page.url);
    }
  );

const deletePageIfNeeded = (instructions) => {
  if (!instructions.page) return instructions;
  if (Object.keys(instructions?.page).length === 0) {
    delete instructions.page;
  }
  return instructions;
};

const Instructions = ({ installInstructions = {}, updateInstructions }) => {
  const I18n = useI18nContext();

  const validateInstructions = () => {
    try {
      postInstallationInstructionsValidation.validateSync(
        deletePageIfNeeded(installInstructions)
      );
    } catch (e) {
      return true;
    }
    return false;
  };
  let [errorsPresent, setErrorsPresent] = useState(validateInstructions);
  const validateOnBlur = () => setErrorsPresent(validateInstructions);

  const errorDisplay = (fieldValue) => {
    if (!errorsPresent) return null;
    return fieldValue ? null : I18n.t('validation.required');
  };

  return (
    <Box padding="lg" data-testid="instructions-card-body">
      <Typography intent="h2">
        {I18n.t('configurationsTab.instructionsFormTitle')}
        <br />
        <br />
      </Typography>
      <Form>
        <Form.Row>
          <Form.Field
            required
            name="instructionsUrl"
            label={I18n.t('configurationsTab.instructionsUrl')}
            type="text"
            value={installInstructions?.page?.url}
            onChange={(e) => {
              const newInstructions = {
                ...installInstructions,
                page: {
                  ...installInstructions?.page,
                  url: e.target.value,
                },
              };
              if (e.target.value === '') {
                delete newInstructions.page.url;
              }
              updateInstructions(deletePageIfNeeded(newInstructions));
            }}
            onBlur={validateOnBlur}
            error={errorDisplay(installInstructions?.page?.url)}
          />
        </Form.Row>
        <Form.Row>
          <Form.Field
            required
            name="instructionsPageName"
            label={I18n.t('configurationsTab.instructionsPageName')}
            type="text"
            value={installInstructions?.page?.label}
            onChange={(e) => {
              const newInstructions = {
                ...installInstructions,
                page: {
                  ...installInstructions?.page,
                  label: e.target.value,
                },
              };
              if (e.target.value === '') {
                delete newInstructions.page.label;
              }
              updateInstructions(deletePageIfNeeded(newInstructions));
            }}
            onBlur={validateOnBlur}
            error={errorDisplay(installInstructions?.page?.label)}
          />
        </Form.Row>
      </Form>
      <Typography intent="h2">
        {I18n.t('configurationsTab.postInstallationFormTitle')}
        <br />
        <br />
      </Typography>
      <Typography intent="h3" color="gray45">
        {I18n.t('configurationsTab.postInstallationFormInfo')}
        <br />
        <br />
      </Typography>
      <Form>
        <Form.Form>
          <Form.Row>
            <Form.TextArea
              required
              name="postInstallationNotes"
              label={I18n.t('configurationsTab.postInstallationFormTitle')}
              type="text"
              value={installInstructions?.notes}
              onChange={(e) => {
                const newInstructions = {
                  ...installInstructions,
                  notes: e.target.value,
                };
                if (e.target.value === '') {
                  delete newInstructions.notes;
                }
                updateInstructions(deletePageIfNeeded(newInstructions));
              }}
              onBlur={validateOnBlur}
              error={errorDisplay(installInstructions?.notes)}
            />
          </Form.Row>
        </Form.Form>
      </Form>
    </Box>
  );
};

Instructions.propTypes = {
  I18n: PT.shape({ t: PT.func }),
  currentManifest: PT.shape({
    configured_manifest_content: PT.shape({
      post_installation_instruction: PT.shape({
        notes: PT.string,
        page: PT.shape({
          url: PT.string,
          label: PT.string,
        }),
      }),
    }),
  }),
  setCurrentManifest: PT.func,
};

export default Instructions;
