import React from 'react';
import PT from 'prop-types';
import { Button, Modal } from '@procore/core-react';

const MEDIA_TYPES = {
  PICTURE: 'Picture',
  VIDEO: 'Video',
  LOGO: 'Logo',
  DOCUMENT: 'Document',
  SHOWCASE_VIDEO: 'ShowcaseVideo',
};

const FilePreviewModal = ({ I18n, isVisible, hide, file }) => {
  const imageFile =
    [MEDIA_TYPES.PICTURE, MEDIA_TYPES.LOGO].includes(file.type);
  const documentFile = file.type === MEDIA_TYPES.DOCUMENT;
  const videoFile = [MEDIA_TYPES.VIDEO, MEDIA_TYPES.SHOWCASE_VIDEO].includes(file.type);

  return (
    <Modal open={isVisible} onClickOverlay={hide}>
      <Modal.Header onClose={hide}>{file.name}</Modal.Header>

      <Modal.Body>
        {imageFile && file.url && (
          <div className="image-preview">
            <img src={file.url} />
          </div>
        )}

        {documentFile && file.url && (
          <div className="image-preview">
            <a href={file.url} target="_blank">{I18n.t('filePreviewModal.viewDocument')}</a>
          </div>
        )} 

        {!((imageFile || documentFile)  && file.url) &&
          I18n.t('filePreviewModal.noPreviewAvailable')}
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons>
          <Button
            variant="tertiary"
            onClick={hide}
            data-qa="delete-file-cancel"
          >
            {I18n.t('filePreviewModal.close')}
          </Button>

          {(imageFile || documentFile || videoFile) && (
            <a download={file.name} href={file.url} target="_blank">
              <Button variant="primary" onClick={hide}>
                {I18n.t('download')}
              </Button>
            </a>
          )}
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

FilePreviewModal.propTypes = {
  I18n: PT.shape({}).isRequired,
  file: PT.shape({}),
  isVisible: PT.bool.isRequired,
  hide: PT.func.isRequired,
};

export default FilePreviewModal;
