import axios from 'axios';

export function trackEvent(key, params) {
  return axios.request({
    url: '/api/v1/analytics',
    method: 'post',
    data: {
      key,
      additional_properties: params,
    },
  });
}

export default trackEvent;
