import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    backToNotifications: '< Back to Notifications',
    readMore: 'Read More',
    notifications: 'Notifications',
    noNotifications: 'No Notifications.',
    noNotificationsSubtitle: 'Check back later',
    loadingNotifications: 'Loading...',
  },
});
