import { pathOr } from 'ramda';

/**
 * Translation function to convert translation key paths to
 * the correct text translation.
 */
const t = (I18nObj) => (keyPath, interpolations) => {
  const keyPathArray = keyPath.split('.');
  let result = pathOr(null, keyPathArray, I18nObj);
  if (!result) {
    return `[missing "${keyPath}" translation]`;
  }

  if (typeof interpolations === 'object') {
    // Interpolate keys in string of format '%{key}'
    result = Object.keys(interpolations).reduce((res, key) => {
      return res.replace(`%{${key}}`, interpolations[key]);
    }, result);
  }
  return result;
};

/**
 * Returns an I18n object with a t() translation function and
 * the original translation keys.
 */
const withI18n = (I18nObj) => ({
  t: t(I18nObj),
  translations: {
    ...I18nObj,
  },
  locale: 'en',
});

export default {
  withI18n,
};
