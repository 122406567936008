import React from 'react';
import { sort } from 'ramda';
import useProviders, { useStateHook } from '@procore/react-state-patterns';
import api from '../api';

export default useProviders(
  useStateHook(
    (props) => ({
      API: api(props.notificationsAPIBaseUrl),
      notifications: [],
      error: null,
      loading: true,
    }),
    {
      fetchNotifications: ({ API }) => (successCb, errorCb) => {
        API.fetchNotifications(successCb, errorCb);
        return { loading: true };
      },
      fetchNotificationsSuccess: () => (resp) => {
        const sorted = sort((a, b) => {
          // Sort by newest notifications first
          a = new Date(a.created_at);
          b = new Date(b.created_at);
          return a > b ? -1 : a < b ? 1 : 0;
        }, resp.data);
        return { notifications: sorted, loading: false, error: null };
      },
      fetchNotificationsError: () => (error) => ({ error, loading: false }),
    },
    ({ state, handlers }) => ({
      notificationsStore: {
        state,
        handlers: {
          fetchAll: () =>
            handlers.fetchNotifications(
              handlers.fetchNotificationsSuccess,
              handlers.fetchNotificationsError
            ),
        },
      },
    })
  )
);
