import React from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Flex,
  H1,
  H3,
  Page,
  Tearsheet,
  Typography,
  useI18nContext,
  colors,
} from '@procore/core-react';
import {
  Building,
  Check,
  ChevronLeft,
  Clear,
  ClipboardBulletedChecks,
  Location,
  RibbonPerson,
} from '@procore/core-icons/dist';
import { Divider } from '../utils';
import styled from 'styled-components';

const GreenCheck = styled(Check)`
  color: ${colors.green30};
`;

const RedClear = styled(Clear)`
  color: ${colors.red40};
`;

const i18Prefix = 'labels.marketplaceApp.securityCompliance';

const BooleanInfoField = ({ title, subtext, yesText }) => {
  const I18n = useI18nContext();

  const yesI18nText = (optionalText) => {
    return optionalText ?? I18n.t(`${i18Prefix}.questionnaire.modal.body.yes`);
  };

  return (
    <>
      <Flex gap="md" direction="column" marginTop="lg" marginBottom="lg">
        <>
          <H3>{title}</H3>
          {subtext && (
            <Typography intent="small" color="gray45">
              {subtext}
            </Typography>
          )}
        </>
        <Flex direction="column">
          <Flex alignItems="center" gap="md">
            <GreenCheck />
            <span>{yesI18nText(yesText)}</span>
          </Flex>
          <Flex gap="md" alignItems="center">
            <RedClear />
            <span>{I18n.t(`${i18Prefix}.questionnaire.modal.body.no`)}</span>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export const QuestionnaireModal = ({ close }) => {
  const I18n = useI18nContext();

  return (
    <Tearsheet
      data-testid="questionnaire-tearsheet"
      open={true}
      onClose={close}
      placement="right"
      stretch={false}
    >
      <Page data-testid="component-form" style={{ width: '900px' }}>
        <Page.Main style={{ position: 'relative' }}>
          <Page.Header style={{ paddingBottom: '8px' }}>
            <Page.Breadcrumbs>
              <Breadcrumbs>
                <Breadcrumbs.Crumb active onClick={close}>
                  <ChevronLeft />
                  {I18n.t(`${i18Prefix}.questionnaire.modal.page_title`)}
                </Breadcrumbs.Crumb>
              </Breadcrumbs>
            </Page.Breadcrumbs>
            <Page.Title>
              <Flex alignItems="flex-start" gap="md">
                <Box paddingTop="xxs">
                  <ClipboardBulletedChecks />
                </Box>
                <Box display="block">
                  <H1>{I18n.t(`${i18Prefix}.questionnaire.title`)}</H1>
                  <Typography intent="body" color="gray45">
                    {I18n.t(`${i18Prefix}.questionnaire.modal.title_note`)}
                  </Typography>
                </Box>
              </Flex>
            </Page.Title>
          </Page.Header>
          <Page.Body style={{ marginBottom: '8px' }}>
            <Page.Row>
              <Page.Column colWidth={6}>
                <Card style={{ width: '830px', padding: '16px' }}>
                  <H1>
                    {I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block1_title`
                    )}
                  </H1>
                  <H3>
                    {I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block1_row1`
                    )}
                  </H3>
                  <Flex
                    data-testid="security-trust-badge"
                    alignContent="flex-start"
                    alignItems="center"
                    marginBottom="lg"
                  >
                    <Box marginRight="sm">
                      <RibbonPerson />
                    </Box>
                    {I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block1_row1_subtext`
                    )}
                  </Flex>
                  <Divider />
                  <Flex marginTop="lg">
                    <H3>Headquarters location</H3>
                  </Flex>
                  <Flex
                    data-testid="security-trust-badge"
                    alignContent="flex-start"
                    alignItems="center"
                    marginBottom="lg"
                  >
                    <Box marginRight="sm">
                      <Building />
                    </Box>
                    {I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block1_row2_subtext`
                    )}
                  </Flex>
                  <Divider />
                  <Flex marginTop="lg">
                    <H1>
                      {I18n.t(
                        `${i18Prefix}.questionnaire.modal.body.block2_title`
                      )}
                    </H1>
                  </Flex>
                  <H3>
                    {I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row1`
                    )}
                  </H3>
                  <Flex
                    data-testid="security-trust-badge"
                    alignContent="flex-start"
                    alignItems="center"
                    marginBottom="lg"
                  >
                    <Box marginRight="sm">
                      <Location />
                    </Box>
                    {I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row1_subtext`
                    )}
                  </Flex>
                  <Divider />
                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row2`
                    )}
                    subtext={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row2_subtext`
                    )}
                    yesText={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row2_yes`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row3`
                    )}
                    yesText={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block2_row3_yes`
                    )}
                  />
                  <Divider />

                  <Flex marginTop="lg">
                    <H1>
                      {I18n.t(
                        `${i18Prefix}.questionnaire.modal.body.block3_title`
                      )}
                    </H1>
                  </Flex>
                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row1`
                    )}
                    subtext={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row1_subtext`
                    )}
                    yesText={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row1_yes`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row2`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row3`
                    )}
                    yesText={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row3_yes`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row4`
                    )}
                  />
                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row5`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row6`
                    )}
                    yesText={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row6_yes`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block3_row7`
                    )}
                  />
                  <Divider />

                  <Flex marginTop="lg">
                    <H1>
                      {I18n.t(
                        `${i18Prefix}.questionnaire.modal.body.block4_title`
                      )}
                    </H1>
                  </Flex>
                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row1`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row2`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row3`
                    )}
                    yesText={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row3_yes`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row4`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row5`
                    )}
                  />
                  <Divider />

                  <BooleanInfoField
                    title={I18n.t(
                      `${i18Prefix}.questionnaire.modal.body.block4_row6`
                    )}
                  />
                </Card>
              </Page.Column>
            </Page.Row>
          </Page.Body>
          <Page.Footer style={{ overflow: 'auto' }}>
            <Button
              data-testid="close-button"
              onClick={close}
              style={{ margin: '16px', float: 'right' }}
            >
              {I18n.t(`${i18Prefix}.questionnaire.modal.close_button`)}
            </Button>
          </Page.Footer>
        </Page.Main>
      </Page>
    </Tearsheet>
  );
};

export default QuestionnaireModal;
