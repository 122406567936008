import React from 'react';
import PT from 'prop-types';
import MarketplaceAppForm from '@/react/common/marketplace_form';
import {
  MARKETPLACE_APP_FIELDS_PT,
  PRODUCT_TOOLS_PT,
  REGIONS_PT,
} from '@/react/shared/developerAppShowPropTypes';

// why does this file exist? it's just a wrapper around MarketplaceAppForm

const TabMarketplace = ({
  fields,
  publishedFields,
  api,
  onUpdate,
  onChange,
  productTools,
  isProcoreEmployee,
  regions,
  trackEvent,
  handleCreateOrUpdateMarketplaceApp,
  marketplaceDraftListingPath,
  newDraftPreviewActive,
  developerAppId,
  marketplaceEnabled,
  onAppChange,
}) => {
  return (
    <MarketplaceAppForm
      isAdminView
      canEdit
      canSubmitMarketplaceApp
      unmappedFields={fields}
      unmappedPublishedFields={publishedFields}
      save={handleCreateOrUpdateMarketplaceApp} // Save expects a function that returns a promise
      uploadWistiaVideo={api.uploadWistiaVideo}
      isProcoreEmployee={isProcoreEmployee}
      onChange={onChange}
      onUpdate={onUpdate}
      productTools={productTools}
      regions={regions}
      trackEvent={trackEvent}
      marketplaceDraftListingPath={marketplaceDraftListingPath}
      newDraftPreviewActive={newDraftPreviewActive}
      developerAppId={developerAppId}
      marketplaceEnabled={marketplaceEnabled}
      onAppChange={onAppChange}
      updateDeveloperApp={api.updateApp}
    />
  );
};

TabMarketplace.propTypes = {
  handleCreateOrUpdateMarketplaceApp: PT.func.isRequired,
  api: PT.shape({
    uploadWistiaVideo: PT.func.isRequired,
  }).isRequired,
  onChange: PT.func.isRequired,
  onUpdate: PT.func,
  isProcoreEmployee: PT.bool,
  productTools: PT.arrayOf(PRODUCT_TOOLS_PT.TYPES),
  regions: PT.arrayOf(REGIONS_PT.TYPES),
  trackEvent: PT.func,
  fields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES).isRequired,
  publishedFields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES),
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
};

TabMarketplace.defaultProps = {
  onUpdate: () => {},
  trackEvent: () => {},
  productTools: [],
  isProcoreEmployee: false,
  regions: [],
  publishedFields: MARKETPLACE_APP_FIELDS_PT.DEFAULTS,
};

export default TabMarketplace;
