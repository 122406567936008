import React from 'react';
import { I18nProvider } from '@procore/core-react';
import withBugsnag from '@/react/shared/BugsnagProvider';
import { compose } from 'recompose';
import Index from './IndexPage';
import i18n from '../i18n';
import OauthApplicationIndexContainer from './OauthApplicationIndexContainer';

const IndexWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <Index {...props} />
  </I18nProvider>
);

export default compose(withBugsnag, OauthApplicationIndexContainer)(IndexWithI18n);
