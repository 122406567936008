import React, { useEffect } from 'react';
import moment from 'moment';
import PT from 'prop-types';
import {
  Breadcrumbs,
  Flex,
  DetailPage,
  ListPage,
  Spinner,
  Toast,
  ToolHeader,
  Token,
  Table,
  Box,
  FlexList,
  useI18nContext,
} from '@procore/core-react';
import PaginationSection from '../../../shared/Pagination';
import UserFilters from '../../../shared/SearchBar/UserFilter';

const ReviewQueue = ({ reviewQueueStore: { state, handlers } }) => {
  const I18n = useI18nContext();
  useEffect(() => {
    handlers.fetch(state.pagination.pageNumber);
  }, []);

  return (
    <DetailPage width="xl" initialIsVisible className="full-screen">
      <DetailPage.Main className="page-body">
        <DetailPage.Breadcrumbs>
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumbs.Crumb>{I18n.t('admin')}</Breadcrumbs.Crumb>
            <Breadcrumbs.Crumb>
              <a href="/admin/developer_apps">{I18n.t('developerApps')}</a>
            </Breadcrumbs.Crumb>
            <Breadcrumbs.Crumb active>
              {I18n.t('reviewQueue')}
            </Breadcrumbs.Crumb>
          </Breadcrumbs>
        </DetailPage.Breadcrumbs>

        <ToolHeader>
          <ToolHeader.Title>{I18n.t('reviewQueue')}</ToolHeader.Title>
        </ToolHeader>

        <ListPage.ContentControls style={{ flexShrink: 0 }}>
          <ListPage.Control block>
            <UserFilters
              I18n={I18n}
              filters={state.filters}
              handleChange={handlers.filterChange}
              getUserOptions={handlers.getOptions}
              onError={handlers.onError}
            />
          </ListPage.Control>
          <ListPage.Control>
            <PaginationSection {...state.pagination} pageTo={handlers.fetch} />
          </ListPage.Control>
        </ListPage.ContentControls>

        <DetailPage.Body className="full-screen">
          <Spinner loading={state.loading}>
            <Body
              I18n={I18n}
              apps={state.apps}
              error={state.error}
              handleSort={handlers.sortChange}
            />

            <ListPage.ContentControls>
              <ListPage.Control style={{ marginLeft: 'auto' }}>
                <PaginationSection
                  {...state.pagination}
                  pageTo={handlers.fetch}
                />
              </ListPage.Control>
            </ListPage.ContentControls>
          </Spinner>
        </DetailPage.Body>
      </DetailPage.Main>
    </DetailPage>
  );
};

const Body = ({ I18n, apps, error, handleSort }) => {
  if (error) {
    return (
      <Flex direction="row" alignItems="center" justifyContent="center">
        <Toast variant="error">{I18n.t('errors.occurred')}</Toast>
      </Flex>
    );
  }

  return (
    <Table.Container>
      <Table data-qa="review-queue-table">
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('internal_name');
              }}
              data-qa="internal-name-table-header"
            >
              {I18n.t('name')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('owner');
              }}
              data-qa="owner-table-header"
            >
              {I18n.t('ownerEmail')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('in_review_at');
              }}
              data-qa="in-review-at-table-header"
            >
              {I18n.t('inReviewAt')}
            </Table.HeaderCell>
            <Table.HeaderCell>{I18n.t('inReview')}</Table.HeaderCell>
            <Table.HeaderCell width="8%">
              {I18n.t('marketplaceListing')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {apps.map((app) => (
            <TableRow
              I18n={I18n}
              key={app.id}
              hasMarketplaceListingInReview={
                app.has_marketplace_listing_in_review
              }
              hasAppVersionInReview={app.has_app_version_in_review}
              hasPublishedApp={app.has_published_app}
              id={app.id}
              marketplaceListingInReviewAt={
                app.marketplace_listing_in_review_at
              }
              appVersionInReviewAt={app.app_version_in_review_at}
              internalName={app.internal_name}
              owner={app.owner}
            />
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

const getToken = (text) => (
  <Box padding="lg">
    <Token>
      <Token.Label>{text}</Token.Label>
    </Token>
  </Box>
);

const formatDate = (date) => moment(date).format('YYYY-MM-DD');

const getDateOrDefault = (marketplaceDate, manifestDate, defaultResponse) => {
  if (marketplaceDate && manifestDate) {
    return marketplaceDate > manifestDate
      ? formatDate(marketplaceDate)
      : formatDate(manifestDate);
  } else if (marketplaceDate && !manifestDate) {
    return formatDate(marketplaceDate);
  } else if (!marketplaceDate && manifestDate) {
    return formatDate(manifestDate);
  }
  return defaultResponse;
};

const TableRow = ({
  I18n,
  hasMarketplaceListingInReview,
  hasAppVersionInReview,
  hasPublishedApp,
  id,
  marketplaceListingInReviewAt,
  appVersionInReviewAt,
  internalName,
  owner,
}) => {
  const manifestTag = hasAppVersionInReview ? getToken(I18n.t('manifest')) : '';
  const marketplaceTag = hasMarketplaceListingInReview
    ? getToken(I18n.t('marketplace'))
    : '';
  const updateIcon = hasPublishedApp ? I18n.t('update') : I18n.t('new');

  return (
    <Table.BodyRow>
      <Table.BodyCell>
        <Table.LinkCell href={`/admin/developer_apps/${id}`}>
          {internalName}
        </Table.LinkCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{owner.email}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">
          {getDateOrDefault(
            marketplaceListingInReviewAt,
            appVersionInReviewAt,
            I18n.t('notAvailable')
          )}
        </Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <FlexList>
          {marketplaceTag}
          {manifestTag}
        </FlexList>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{hasMarketplaceListingInReview && updateIcon}</Box>
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

ReviewQueue.propTypes = {
  reviewQueueStore: PT.shape({
    handlers: PT.shape({
      fetch: PT.func.isRequired,
      onError: PT.func.isRequired,
      filterChange: PT.func.isRequired,
      sortChange: PT.func.isRequired,
      getOptions: PT.func.isRequired,
    }),
    state: PT.shape({
      apps: PT.array.isRequired,
      error: PT.shape({}),
      loading: PT.bool,
      filters: PT.shape({
        user: PT.array.isRequired,
        search: PT.string.isRequired,
        sort: PT.string.isRequired,
        inReview: PT.bool.isRequired,
      }).isRequired,
      pagination: PT.shape({
        pageNumber: PT.number.isRequired,
        pageSize: PT.number.isRequired,
        total: PT.number.isRequired,
        totalPages: PT.number.isRequired,
      }).isRequired,
    }),
  }),
};

ReviewQueue.defaultProps = {
  reviewQueueStore: {
    state: {
      loading: false,
    },
  },
};

export default ReviewQueue;
