import React, { useEffect } from 'react';
import moment from 'moment';
import PT from 'prop-types';
import {
  Breadcrumbs,
  Flex,
  DetailPage,
  ListPage,
  Spinner,
  Toast,
  ToolHeader,
  Table,
  Box,
  useI18nContext,
} from '@procore/core-react';
import { Check } from '@procore/core-icons';
import Filters from '../../../shared/SearchBar/UserFilter';
import PaginationSection from '../../../shared/Pagination';

const Index = ({ developerAppIndexStore: { state, handlers } }) => {
  const I18n = useI18nContext();
  useEffect(() => {
    handlers.fetch(state.pagination.pageNumber);
  }, []);

  return (
    <DetailPage width="xl" initialIsVisible>
      <DetailPage.Main className="page-body">
        <DetailPage.Breadcrumbs>
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumbs.Crumb>{I18n.t('admin')}</Breadcrumbs.Crumb>
            <Breadcrumbs.Crumb active>
              {I18n.t('developerApps')}
            </Breadcrumbs.Crumb>
          </Breadcrumbs>
        </DetailPage.Breadcrumbs>

        <ToolHeader>
          <ToolHeader.Title>{I18n.t('developerApps')}</ToolHeader.Title>
        </ToolHeader>

        <ListPage.ContentControls style={{ flexShrink: 0 }}>
          <ListPage.Control block>
            <Filters
              I18n={I18n}
              filters={state.filters}
              handleChange={handlers.filterChange}
              getUserOptions={handlers.getOptions}
              onError={handlers.onError}
            />
          </ListPage.Control>
          <ListPage.Control>
            <PaginationSection {...state.pagination} pageTo={handlers.fetch} />
          </ListPage.Control>
        </ListPage.ContentControls>

        <DetailPage.Body>
          <Spinner loading={state.loading}>
            <Body
              I18n={I18n}
              apps={state.apps}
              liveAppIds={state.liveAppIds}
              error={state.error}
              handleSort={handlers.sortChange}
            />

            <ListPage.ContentControls>
              <ListPage.Control style={{ marginLeft: 'auto' }}>
                <PaginationSection
                  {...state.pagination}
                  pageTo={handlers.fetch}
                />
              </ListPage.Control>
            </ListPage.ContentControls>
          </Spinner>
        </DetailPage.Body>
      </DetailPage.Main>
    </DetailPage>
  );
};

const Body = ({ I18n, apps, liveAppIds, error, handleSort }) => {
  if (error) {
    return (
      <Flex direction="row" alignItems="center" justifyContent="center">
        <Toast variant="error">{I18n.t('errors.occurred')}</Toast>
      </Flex>
    );
  }

  return (
    <Table.Container>
      <Table data-qa="apps-index-table">
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('app_id');
              }}
              data-qa="published-at-table-header"
              width="8%"
            >
              {I18n.t('published')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('internal_name');
              }}
              data-qa="internal-name-table-header"
            >
              {I18n.t('name')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('owner');
              }}
              data-qa="owner-table-header"
            >
              {I18n.t('ownerEmail')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('created_at');
              }}
              data-qa="created-at-table-header"
            >
              {I18n.t('createdAt')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('in_review_at');
              }}
              data-qa="in-review-at-table-header"
            >
              {I18n.t('inReviewAt')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('deleted_at');
              }}
              data-qa="deleted-at-table-header"
            >
              {I18n.t('deletedAt')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {apps.map((app) => (
            <TableRow
              I18n={I18n}
              key={app.id}
              id={app.id}
              internalName={app.internal_name}
              owner={app.owner}
              publishedAt={app.app_id}
              createdAt={app.created_at}
              inReviewAt={app.marketplace_listing_in_review_at}
              deletedAt={app.deleted_at}
              liveAppIds={liveAppIds}
            />
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

const formatDate = (date, defaultResponse) => {
  return date ? moment(date).format('YYYY-MM-DD') : defaultResponse;
};

const TableRow = ({
  I18n,
  id,
  internalName,
  owner,
  publishedAt,
  createdAt,
  inReviewAt,
  deletedAt,
  liveAppIds,
}) => {

  const icon = liveAppIds.includes(publishedAt) ? <Check /> : '';
  return (
    <Table.BodyRow>
      <Table.BodyCell>
        <Box padding="lg">{icon}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.LinkCell href={`/admin/developer_apps/${id}`}>
          {internalName}
        </Table.LinkCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{owner.email}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{formatDate(createdAt, I18n.t('notAvailable'))}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{formatDate(inReviewAt, I18n.t('notAvailable'))}</Box>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{formatDate(deletedAt, I18n.t('notAvailable'))}</Box>
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

Index.propTypes = {
  developerAppIndexStore: PT.shape({
    handlers: PT.shape({
      fetch: PT.func.isRequired,
      onError: PT.func.isRequired,
      filterChange: PT.func.isRequired,
      sortChange: PT.func.isRequired,
      getOptions: PT.func.isRequired,
      sidebarToggle: PT.func.isRequired,
    }),
    state: PT.shape({
      apps: PT.array.isRequired,
      liveAppIds: PT.array.isRequired,
      error: PT.shape({}),
      loading: PT.bool,
      filters: PT.shape({
        user: PT.array.isRequired,
        search: PT.string.isRequired,
        sort: PT.string.isRequired,
      }).isRequired,
      pagination: PT.shape({
        pageNumber: PT.number.isRequired,
        pageSize: PT.number.isRequired,
        total: PT.number.isRequired,
        totalPages: PT.number.isRequired,
      }).isRequired,
      sidebarOpen: PT.bool,
    }),
  }),
};

Index.defaultProps = {
  developerAppIndexStore: {
    state: {
      loading: false,
      sidebarOpen: false,
    },
  },
};

export default Index;
