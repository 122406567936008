import React from 'react';
import { Flex, Box, useI18nContext } from '@procore/core-react';

const Pending = () => {
  const I18n = useI18nContext();
  const supportSiteText = I18n.t('sandbox.visitSupportSite', {
    support_site: `<a href="https://support.procore.com/" target="_blank">${I18n.t(
      'sandbox.supportSite'
    )}</a>`,
  });

  return (
    <Box padding="xl">
      <Flex alignItems="center" justifyContent="center" direction="column">
        <h3>{I18n.t('sandbox.buildingSandbox')}</h3>
        <p>{I18n.t('sandbox.thisMayTakeTime')}</p>
        <p dangerouslySetInnerHTML={{ __html: supportSiteText }} />
      </Flex>
    </Box>
  );
};

export default Pending;
