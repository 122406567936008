import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    admin: 'Admin',
    procoreId: 'Procore ID',
    companyName: 'Company',
    createdBy: 'Created By',
    creatorEmail: 'Creator Email',
    developers: 'Developers',
    developersTitle: 'Registered Developers',
    errors: {
      occurred: 'An error occurred',
    },
    email: 'Email',
    inReview: 'In Review',
    manageDeveloperApps: 'Manage Developer Apps',
    manifest: 'Manifest',
    marketplace: 'Marketplace',
    marketplaceListing: 'Marketplace Listing',
    name: 'Name',
    new: 'New',
    notAvailable: 'N/A',
    reviewQueue: 'Review Queue',
    sidebar: {
      minimizeSidebar: 'Minimize Sidebar',
    },
    update: 'Update',
    search: 'Search'
  },
});
