/* eslint-disable react-hooks/rules-of-hooks */
import useProviders, { useStateHook } from '@procore/react-state-patterns';

export default useProviders(
  useStateHook(
    {
      draftManifest: undefined,
      semanticVersion: undefined,
      viewingManifest: null,
      tableExpanded: false,
      showCreateModal: false,
      initialEditorContent: null,
    },
    {
      setDraftManifest: () => (draftManifest) => ({ draftManifest }),
      setSemanticVersion: () => (semanticVersion) => ({ semanticVersion }),
      setCreateModalVisibilityAndInitialContent: () => (
        isVisible,
        content
      ) => ({
        showCreateModal: isVisible,
        initialEditorContent: content,
      }),
      closeAndResetModalContent: () => (content) => ({
        showCreateModal: false,
        draftManifest: content,
      }),
      setModalVisibility: () => (visibility) => ({
        showCreateModal: visibility,
      }),
      setViewingManifest: () => (manifest) => ({
        viewingManifest: manifest,
      }),
      toggleTableExpanded: ({ tableExpanded }) => () => ({
        tableExpanded: !tableExpanded,
      }),
    },
    'sandboxManifestTableStore'
  )
);
