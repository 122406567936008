import React, { useRef } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { compose } from 'recompose';
import { Input } from '@procore/core-react';
import { withFocus } from '../decorators/Focus';
import { withRevealOnHover } from '../decorators/RevealOnHover';
import { Label, FormError } from './Form';

const StatelessField = ({
  className,
  focused,
  required,
  label,
  value = '',
  readOnly,
  type,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  errors,
  id,
  ...rest
}) => {
  const randomUniqueKey = useRef(globalThis.crypto.randomUUID());
  return (
    <div className={cx(className, { 'a-field--selected': focused })}>
      <Label
        required={required}
        label={label}
        labelFor={id || randomUniqueKey.current}
      />
      <Input
        id={id || randomUniqueKey.current}
        error={errors && errors.length > 0}
        readOnly={readOnly}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...rest}
      />
      <FormError errors={errors} />
    </div>
  );
};

StatelessField.propTypes = {
  className: PT.string,
  label: PT.node,
  onChange: PT.func,
  onClick: PT.func,
  required: PT.bool,
  type: PT.string,
  value: PT.string,
  id: PT.string,
  errors: PT.array,
};

StatelessField.defaultProps = {
  className: '',
  label: '',
  required: false,
  type: 'text',
  value: '',
};

export const Field = withFocus(StatelessField);
export const RevealOnHover = compose(
  withFocus,
  withRevealOnHover
)(StatelessField);
