import I18nUtil from '@/utils/i18nUtil';
import { MARKETPLACE_TABS } from './constants';

export default I18nUtil.withI18n({
  en: {
    needHelp: 'Need help?',
    contactUs: 'Contact Us',
    helpSubject: 'Procore Marketplace - Help with',
    marketplaceError: 'Error: unable to retrieve marketplace app.',
    requiredField: 'Required Field',
    approve: 'Approve',
    reject: 'Reject',
    longUploadInProgress:
      'Please stay on this page while uploading. Navigating away from this page will stop the upload.',
    errors: {
      bannerTitle: 'The following errors occurred:',
      marketplaceUpdate:
        'An error occurred while updating the marketplace listing.',
      marketplaceFetch:
        'An error occurred while loading the marketplace listing.',
      notValid: 'Please correct the errors below before submitting for review',
      fieldRequired: 'This field is required',
      invalidEmail: 'This email is invalid',
      invalidUrl: 'This URL is invalid',
      maxStringLength: 'Character limit exceeded (%{max})',
      minItems: 'At least %{min} items are required',
      maxCategories: 'Max number of categories exceeded',
      regionRequired:
        'You must select a region or country where your app is available',
      fileType: 'Invalid file type',
    },
    footer: {
      needHelp: 'Need help?',
      contactUs: 'Contact Us',
      maximizeConversionRates: 'Maximizing Marketplace Conversion Rates',
    },
    labels: {
      marketplaceApp: {
        version: {
          title: 'Version Release',
          description:
            'Ensure accurate component definition and instructions. This version will be released after it has been approved by our team.',
          link: 'Learn more about version requirements here.',
          releaseVersionPlaceholder: 'Select version',
          draftDeveloperNotesPlaceholder:
            'Enter any additional information about your app',
          published: 'Published',
          inReview: 'In Review',
        },
        adminAppSpecifications: {
          title: 'Admin App Specifications (Procore Internal Use Only)',
          description:
            'Provide additional details about your app to showcase its value. Share key features and multimedia attachments. This step is reserved for the Procore Marketplace team and will not be published on the Marketplace.',
          additionalNotesTitle: 'Additional Notes',
        },
        showcaseVideo: 'Showcase Video',
        releaseVersion: 'Release Version',
        draftDeveloperNotes: 'Notes for Version Submission',
        publishSilently:
          'Publish this app without notifying owner and collaborators',
        adminMessage: 'Admin Message',
        rejectionMessage: 'Marketplace submission status: Rejected',
        approvalMessage: 'Marketplace submission status: Approved',
        rejectBody:
          'Please review the comments provided by our Procore team. Once you have made the necessary modifications, you can resubmit the listing.',
        detailsButton: 'Details',
        statusMessage: "This marketplace listing's current status is: ",
        inReview: 'In Review',
        inReviewMessage: 'Marketplace submission status: In Review',
        inReviewDetails:
          'You must withdraw from review if you want to make changes.',
        about: 'About Your Company',
        regions: 'Regions',
        builtBy: 'Built By',
        soldBy: 'Sold By',
        regionOrCountry: 'You must select a region or country',
        name: 'Name',
        searchDescription: 'Search Description',
        tagline: 'Tagline',
        description: 'Description',
        videos: 'Video',
        screenshots: 'App Preview & Screenshots',
        logo: 'Logo',
        howItWorks: 'How it Works',
        category: 'Category',
        learnHowUrl: 'Learn How URL',
        contactUsEmail: 'Contact Us Email',
        supportEmail: 'Email',
        supportWebsiteUrl: 'Support Website URL',
        supportWebsiteLabel: 'Support Website Label',
        supportPhone: 'Support Phone',
        supportDocumentation: 'Support Documentation',
        salesInfo: 'Sales Info',
        salesName: 'Sales Contact Name',
        salesPhone: 'Sales Contact Phone',
        salesEmail: 'Sales Contact Email',
        helpfulLinkUrl: 'Website URL',
        helpfulLinkLabel: 'Website Label',
        featureBullet: 'App Feature',
        productTool: 'Product',
        integrationRequirement: 'Requirement',
        beta: 'Beta',
        costsMoney: 'Costs Money',
        submitForApproval: 'Submit for Review',
        resubmitForApproval: 'Resubmit for Review',
        save: 'Save',
        saveDraft: 'Save Draft',
        preview: 'Preview',
        withdrawForApproval: 'Withdraw from Review',
        showInConcierge: 'Show In Concierge',
        installationOption: {
          title: 'Marketplace Installations',
          description:
            'Enable Marketplace installations to display an Install button on your listing.',
        },
        betaRelease: {
          title: 'Is this application in a beta release?',
          description:
            'Indicate if the app is in a beta version, typically used for testing purposes.',
          betaVersion: 'Beta Version',
        },
        subscription_required_question:
          'Does the app require an additional subscription to another system?',
        third_party_connector_required_question:
          'Is there a third-party connector needed for the integration, or is it included in the subscription?',
        free_trial_question: 'Does your app offer a free trial?',
        pricing_link: 'External Pricing Link',
        pricing_description: 'Pricing Description',
        availability_label:
          'Select the regions where your app will be accessible',
        securityCompliance: {
          toggleSecurityEnabled: 'The security & compliance section has been enabled.',
          toggleSecurityDisabled: 'The security & compliance section has been disabled.',
          header: 'Partner Security Review | Self-Certified Responses',
          subHeader:
            'These details can help customers during their app evaluation and foster trust regarding your security and compliance measures. Please fill out this section to provide better transparency for your customers.',
          learnMore: 'Learn more about it here.',
          tabTitle: 'Security & Compliance Section',
          questionnaireTitle: 'Questionnaire',
          reviewQuestions: 'Review Questions',
          companyInformation: 'Company Information',
          privacy: 'Privacy & Data Governance',
          companyName: 'Company Legal Name',
          headquarters: 'Headquarters Location',
          dataProcessing: 'Data Processing Locations',
          toggleEnabled: 'Enable Security & Compliance Section',
          toggleDisabled: 'Disable Security & Compliance Section',
          subProcessors:
            'Does your company engage subprocessors / vendors to process or store customer data?',
          subProcessorsDetails: 'Additional Subprocessor Details',
          sensitiveData:
            'Do you or your subprocessors process or store sensitive personal data as part of the services/products you provide?',
          sensitiveDataDetails: 'Sensitive Data Handling Details',
          toggle:
            'Activate this section to respond to security and compliance questions.',
          description:
            'Please note that some fields are currently disabled and will only become active based on your responses to the corresponding questions.',
          securityPractices: 'Information Security Practices',
          hasSecurityContact:
            'Does your company have a dedicated security function?',
          securityEmailContact: 'Security Issues Email Contact',
          hasSecurityAudits:
            'Does your company undertake audits or other reviews to ensure security controls are implemented and operating effectively?',
          hasSecurityStandards:
            'Are you accredited to any relevant security standards?',
          selectSecurityStandards: 'Security Standards',
          otherSecurityStandards: 'Other Security Standards',
          hasPenetrationTesting:
            'Do you undertake penetration testing (or similar technical security testing, code review or vulnerability assessment); and are you able to provide copies of results/findings?',
          canNotifyProcore:
            'Do you have mechanisms to notify Procore and customers in case of a security breach or data loss?',
          hasDisclosureProgram:
            'Do you have a vulnerability disclosure program?',
          disclosureProgramURL: 'URL to Public Facing Program',
          hasBugBounty: 'Do you have a bug bounty program?',
          applicationSecurityFeatures: 'Application Security / Features',
          hasDataEncryptionAtRest:
            'Is customer data encrypted at rest a minimum standard of AES-256 or equivalent?',
          hasDataEncryptionInTransit:
            'Is customer data encrypted in transit a minimum standard of TLS 1.2?',
          hasSSOSupport: 'Does your application support Single Sign On (SSO)?',
          hasSAMLSupport:
            'Does your application support Security Assertion Markup Language (SAML)?',
          hasMFASupport:
            'Is multifactor authentication (MFA) required for back-end system access used to process customer data?',
          hasFASSupport:
            'Does your integration support authenticated requests to Procore File Access Service (FAS)?',
          ssoProviders: 'SSO Providers',
          otherSSOProviders: 'Other SSO Providers',
          questionnaire: {
            title: 'Questionnaire',
            modal: {
              page_title: 'Security & Compliance',
              title_note:
                'Here you will find the questions and their potential responses.',
              close_button: 'Return to Listing',
              body: {
                block1_title: 'Company Information',
                block1_row1: 'Company legal name',
                block1_row1_subtext:
                  "You'll need to provide the company legal name.",
                block1_row2: 'Headquarters location',
                block1_row2_subtext: "You'll need to select a location.",
                block2_title: 'Privacy & Data Governance',
                block2_row1: 'Data processing locations',
                block2_row1_subtext: "You'll need to select a location.",
                block2_row2:
                  'Does your company engage subprocessors / vendors to process or store customer data?',
                block2_row2_subtext:
                  '(e.g. cloud hosting providers, backup vendors, service providers, etc.) ("Subprocessors")',
                block2_row2_yes:
                  "Yes. You'll be asked to provide additional subprocessor details.",
                block2_row3:
                  'Do you or your subprocessors process or store sensitive personal data as part of the services/products you provide?',
                block2_row3_yes:
                  "Yes. You'll be asked to provide sensitive data handling details.",
                block3_title: 'Information Security Practices',
                block3_row1:
                  'Does your company have a dedicated security function?',
                block3_row1_subtext: 'e.g. security employees and management',
                block3_row1_yes:
                  "Yes. You'll be asked to provide security issues email contact.",
                block3_row2:
                  'Does your company undertake audits or other reviews to ensure security controls are implemented and operation effectively?',
                block3_row3:
                  'Are you accredited to any relevant security standards?',
                block3_row3_yes:
                  "Yes. You'll be asked to provide your security standards.",
                block3_row4:
                  'Do you undertake penetration testing (or similar technical security testing, code review or vulnerability assessment); and are you able to provide copies of results/findings?',
                block3_row5:
                  'Do you have mechanisms to notify Procore and customers in case of a security breach or data loss?',
                block3_row6: 'Do you have a vulnerability disclosure program?',
                block3_row6_yes:
                  "Yes. You'll be asked to provide the URL to public facing program.",
                block3_row7: 'Do you have a bug bounty program?',
                block4_title: 'Application Security / Features',
                block4_row1:
                  'Is customer data encrypted at rest a minimum standard of AES-256 or equivalent?',
                block4_row2:
                  'Is customer data encrypted in transit a minimum standard of TLS 1.2?',
                block4_row3:
                  'Does your application support Single Sign On (SSO)?',
                block4_row3_yes:
                  "Yes. You'll be asked to provide the SSO providers.",
                block4_row4:
                  'Does your application support Security Assertion Markup Language (SAML)?',
                block4_row5:
                  'Is multifactor authentication (MFA) required for back-end system access used to process customer data?',
                block4_row6:
                  'Does your integration support authenticated request to Procore File Access Service (FAS)?',
                yes: 'Yes',
                no: 'No',
              },
            },
          },
        },
        pricing: {
          subheader: 'App Pricing',
          subscription_required_response: {
            yes: 'Yes, an additional subscription is required',
            no: 'No, an additional subscription is not required',
          },
          third_party_connector_required_response: {
            yes: 'Yes, a third-party connector is needed for the integration',
            no: 'No, the integration includes the necessary connector in the subscription',
          },
          free_trial_response: {
            yes: 'Yes, a free trial is available',
            no: 'No, a free trial is not available',
          },
          pricing_link: {
            placeHolder:
              "Enter the link for users to access your app's pricing details",
          },
          pricing_description: {
            placeHolder:
              'e.g. Flexible pricing model tailored to individual user needs. Refer to our pricing page for more details',
          },
        },
        availability: {
          title: 'App Availability',
          allRegions: 'All Countries or Regions',
          specificRegions: 'Specific Countries or Regions',
          allRegionsDescription:
            'Make your app available in all Procore supported countries and regions.',
          specificRegionsDescription:
            'Make your app available in the countries or regions you select.',
        },
        securityTrust: {
          header: 'Security & Trust - Partner Self-Certified',
          badgeTitle: 'Security & Trust Badge',
          badgeDescription:
            'Enable the Security & Trust Badge on the Procore App Marketplace.',
          selfAssessmentDocument: 'Self-Assessment Document',
        },
        info: {
          securityCompliance: {
            subProcessors:
              '(e.g. cloud hosting providers, backup vendors, service providers, etc.) ("Subprocessors")',
            hasSecurityContact: '(e.g., security employees and management)',
            otherSecurityStandards: 'Specify other relevant security standards',
            otherSSOProviders: 'Specify any additional SSO providers.',
          },
          showcaseVideo:
            'Submit a video demonstrating your app and its various use cases.',
          pricing_description:
            "Describe your app's pricing structure, highlighting key aspects.",
          pricing_link:
            "Provide the link for users to access your app's pricing details.",
          releaseVersion:
            'Specify the app release version. Only promoted versions can be published on the marketplace.',
          draftDeveloperNotes:
            'Brief explanation of what your app is and any additional information.',
          about:
            "Introduce your company's background, experience, and expertise.",
          adminMessage:
            'Include direct feedback to the partner here if the marketplace listing is to be rejected.',
          builtBy: 'The name of your company',
          soldBy: 'The name of the organization selling the app',
          name: 'Public-facing name for your App.',
          searchDescription: 'Used for finding the app',
          tagline:
            "Create a phrase summarizing your app's function. Max 40 characters.",
          description:
            'Provide a high-level summary of what users can do with your app.',
          howItWorks:
            'Provide a description of how the integration between Procore and your App works. 500 character maximum.',
          logo: 'Accepted format PNG with dimensions of 200 x 200 px and a transparent or white background.',
          category: 'Select up to three categories that describe your app.',
          learnHowUrl: 'The URL to learn how to install your app',
          contactUsEmail: 'The email address for the sales team for your app',
          supportEmail: "The contact email for your app's support team.",
          supportWebsiteUrl: "The URL for your app's support website link",
          supportWebsiteLabel:
            "Enter the label for your app's support website link.",
          supportPhone: 'The phone number for your support team.',
          supportDocumentation:
            'A link to the user documentation for your app.',
          salesName: 'The contact name of the primary salesperson for your app',
          salesPhone:
            'The phone number for the primary salesperson for your app',
          salesEmail:
            'The email address for the primary salesperson for your app',
          screenshots:
            'Accepted formats include PNG and JPG with a 4:3 to 16:9 aspect ratio and resolution of 720x480 to 1920x1080.',
          videos:
            'Accepted formats include MP4 and MOV with a minimum resolution of 720p, 24fps. Must include instructional voiceover.',
          helpfulLinks:
            'A list of external links you want to include in your listing. These can be documentation, your company website, etc.',
          helpfulLinkUrl:
            'The url of a link to include in the "helpful links" section of your listing',
          helpfulLinkLabel:
            'The label of a link to include in the "helpful links" section of your listing',
          productTool:
            'Please select the Procore product tools that are required to use your app.',
          regionsRequirement:
            'Select regions/countries where your app is available.',
          regionsNote:
            'You may select an entire region or individual countries within that region.',
          contactRadioButtons: {
            label: 'Type of Button',
            contactUs: {
              label: 'Contact Us Button',
              description:
                'Utilize the "Contact Us Button" to generate a form, initiating a direct connection to a designated sales email for sales lead inquiries.',
            },
            learnHow: {
              label: 'Learn How Button',
              description:
                'If your app is self-service, guide users with the "Learn How Button" to a dedicated website detailing the installation or purchasing process.',
            },
          },
          createLeadButton: 'Create direct lead in Salesforce',
          createLeadNote:
            'By selecting this option the marketplace will display a contact button, no email will be needed, this will create a direct lead in Salesforce.',
          createLeadSecondNote:
            'If you want an email to be sent instead, please select Contact Us Button and provide an email.',
          regionsOtherNote:
            'NOTE: When selecting an entire region, we will include any new countries Procore adds to that region.',
        },
        ordinalIndicators: {
          one: 'st',
          two: 'nd',
          few: 'rd',
          other: 'th',
        },
        ordinalIndicatorsText: {
          one: 'first',
          two: 'second',
          few: 'third',
          other: 'next',
        },
      },
    },
    marketplaceListing: 'Marketplace Listing',
    placeholders: {
      appType: 'Select an App Type...',
      category: 'Select a Category...',
      description: 'Please enter description.',
      name: 'Enter name',
      locationSelect: 'Select Location',
      supportEmail: 'Who to request support from',
      supportUrl:
        'A website users can get information and support for your app.',
      supportPhone: 'x-xxx-xxx-xxxx',
      marketplaceApp: {
        about: "Summarize your company's background",
        builtBy: 'Enter name',
        category: 'Select categories',
        description: 'Enter description',
        feature: 'Enter %{ord} feature description',
        helpfulLinkLabel: 'Enter label',
        helpfulLinkUrl: 'Enter URL',
        howItWorks: 'Enter description',
        phone: 'x-xxx-xxx-xxxx',
        name: 'Enter app name',
        requirement: 'Enter %{ord} requirement',
        soldBy: 'Enter name',
        supportEmail: 'Enter contact email',
        supportWebsiteLabel: 'Enter label',
        supportWebsiteUrl: 'Enter URL',
        tagline: 'Enter tagline',
        versionNotes: 'Enter any additional information about your app',
        subProcessorsDetails: 'Enter subprocessor details',
        sensitiveDataDetails: 'Enter sensitive data details',
        selectSecurityStandards: 'Select security standards',
        otherSecurityStandards: 'Enter other security standards',
        ssoProviders: 'Select SSO Providers',
        otherSSOProviders: 'Enter other SSO providers',
      },
      helpfulLinks: {
        url: 'Enter URL',
        label: 'Enter Label',
      },
      contact: {
        email: 'Enter Email',
        learnHow: 'Enter URL',
      },
      url: 'Enter URL',
    },
    globalLabels: {
      yes: 'Yes',
      no: 'No',
    },
    modals: {
      confirmAccept: {
        title: 'Confirm Listing Approval',
        body: 'By approving this listing, you are confirming that all fields collected are acceptable by marketplace standards. If the listing requires any changes, do not approve it.',
        back: 'Back',
        approve: 'Approve',
      },
      confirmReject: {
        title: 'Confirm Listing Rejection',
        body: 'When you reject this listing, a notification will be sent to the author asking them to correct it. Please make sure any issues you see are listed in your admin message.',
        back: 'Back',
        reject: 'Reject',
      },
      adminMessage: {
        title: 'Reviewer Notes',
        back: 'Back',
      },
      marketplaceSubmitConfirmation: {
        title: 'Submit Listing for Review?',
        body: 'After you submit your listing, our team will verify that it meets Marketplace requirements. You will receive an email notification once the review is complete.',
        confirm: 'Submit for Review',
        cancel: 'Cancel',
      },
      marketplaceWithdrawConfirmation: {
        title: 'Withdraw from Review',
        body: 'If you withdraw your listing from review, you will need to resubmit in order to publish updates to your Marketplace listing.',
        confirm: 'Withdraw from Review',
        cancel: 'Cancel',
      },
    },
    published: 'Published',
    publishedDetails:
      'A version of this app is currently published in the Marketplace. New changes must be submitted for review and approved before taking effect.',
    marketplaceEnabled: 'Enable Marketplace Listing',
    download: 'Download',
    confirmDeletionModal: {
      header: 'Delete File?',
      body: 'Are you sure you want to delete %{file_name}?',
      confirm: 'Delete File',
      cancel: 'Cancel',
    },
    filePreviewModal: {
      noPreviewAvailable: 'No Preview Available',
      close: 'Close',
      viewDocument: 'View the document here',
    },
    tabInfo: {
      titles: {
        [MARKETPLACE_TABS.APP]: 'App Information',
        [MARKETPLACE_TABS.CONTACT]: 'Contact',
        [MARKETPLACE_TABS.FEATURES]: 'Features',
        [MARKETPLACE_TABS.LINKS]: 'Links',
        [MARKETPLACE_TABS.MEDIA]: 'Media Content',
        [MARKETPLACE_TABS.REGIONS]: 'Pricing & Availability',
        [MARKETPLACE_TABS.REQUIREMENTS]: 'Integration Requirements',
        [MARKETPLACE_TABS.SUPPORT]: 'Support',
        [MARKETPLACE_TABS.TOOLS]: 'Required Procore Tools',
        [MARKETPLACE_TABS.SECURITY]: 'Security & Compliance',
      },
      sections: {
        [MARKETPLACE_TABS.APP]: {
          general: 'General',
        },
      },
      descriptions: {
        [MARKETPLACE_TABS.CONTACT]:
          'Collect sales lead inquiries from users via a lead generation button, or allow them to click to learn how they can purchase or install your software on their own. ',
        [MARKETPLACE_TABS.FEATURES]:
          'Enter a description for each app feature you would like to highlight, up to 200 characters. These will display as bullet points at the top of your marketplace listing.',
        [MARKETPLACE_TABS.LINKS]:
          'Include up to three external links in your listing, such as documentation or your company website. These URLs will be listed in the Helpful Links section of your app listing.',
        [MARKETPLACE_TABS.MEDIA]:
          'Include logo, three screenshots of your app in action and one demo video showing app data interaction with Procore. ',
        [MARKETPLACE_TABS.REQUIREMENTS]:
          'Specify the fundamental prerequisites for your application, including necessary configurations and potential third-party dependencies. This section outlines the core functionality needed for your app to operate seamlessly within Procore.',
        [MARKETPLACE_TABS.SUPPORT]:
          "Ensure your support website includes a concise summary of your app's core functionality and its integration with Procore, an English privacy policy outlining third-party data usage, and clear instructions for app usage.",
      },
      linkText: {
        [MARKETPLACE_TABS.CONTACT]: 'Learn more.',
        [MARKETPLACE_TABS.MEDIA]:
          'See more about media upload requirements here.',
      },
    },
  },
});
