import React, { useState } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../constants';
import {
  Box,
  Grid,
  RadioButton,
  Typography,
  useI18nContext,
} from '@procore/core-react';

const CONTACT_STATE = {
  NO_SHOW: 0,
  SHOW_CONTACT_US: 1,
  SHOW_LEARN_HOW: 2,
  CREATE_LEAD: 3,
};

const getState = (fields) => {
  if (fields.create_lead) {
    return CONTACT_STATE.CREATE_LEAD;
  }
  if (fields.contact_us_email && !fields.create_lead) {
    return CONTACT_STATE.SHOW_CONTACT_US;
  }
  if (fields.learn_how_url && !fields.contact_us_email && !fields.create_lead) {
    return CONTACT_STATE.SHOW_LEARN_HOW;
  }
  return CONTACT_STATE.NO_SHOW;
};

const TabContact = ({
  fields,
  validations,
  onFieldChange,
  isProcoreEmployee,
  isDisabled,
  FormLabel,
}) => {
  const [status, setStatus] = useState(getState(fields));
  const I18n = useI18nContext();

  const onChange = (fieldPath, value) =>
    onFieldChange([MARKETPLACE_TABS.CONTACT, fieldPath], value);

  return (
    <Grid.Row>
      <Grid.Col colWidth={8}>
        <Typography intent="label">
          {I18n.t('labels.marketplaceApp.info.contactRadioButtons.label')}
        </Typography>
        <Box marginTop="sm" data-qa="marketplace-app-contact-toggle">
          <RadioButton
            data-testid="marketplace-contact-us-button"
            name="contactType"
            checked={status === CONTACT_STATE.SHOW_CONTACT_US}
            disabled={isDisabled}
            onChange={() => {
              onChange('create_lead', false);
              setStatus(CONTACT_STATE.SHOW_CONTACT_US);
              // make sure to clear out learn how if
              onChange('learn_how_url', '');
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography intent="body">
                {I18n.t(
                  'labels.marketplaceApp.info.contactRadioButtons.contactUs.label'
                )}
              </Typography>
              <Typography intent="body">
                {I18n.t(
                  'labels.marketplaceApp.info.contactRadioButtons.contactUs.description'
                )}
              </Typography>
            </Box>
          </RadioButton>
          <RadioButton
            data-testid="marketplace-learn-how-button"
            name="contactType"
            checked={status === CONTACT_STATE.SHOW_LEARN_HOW}
            disabled={isDisabled}
            onChange={() => {
              onChange('create_lead', false);
              setStatus(CONTACT_STATE.SHOW_LEARN_HOW);
              // make sure to clear out email
              onChange('contact_us_email', '');
            }}
          >
            <Box display="flex" flexDirection="column">
              <Typography intent="body">
                {I18n.t(
                  'labels.marketplaceApp.info.contactRadioButtons.learnHow.label'
                )}
              </Typography>
              <Typography intent="body">
                {I18n.t(
                  'labels.marketplaceApp.info.contactRadioButtons.learnHow.description'
                )}
              </Typography>
            </Box>
          </RadioButton>

          {isProcoreEmployee && (
            <RadioButton
              data-testid="marketplace-create-lead-button"
              name="contactType"
              checked={status === CONTACT_STATE.CREATE_LEAD}
              disabled={isDisabled}
              onChange={(e) => {
                setStatus(CONTACT_STATE.CREATE_LEAD);
                onChange('create_lead', e.target.checked);
                // make sure to clear out email learn how
                onChange('contact_us_email', '');
                onChange('learn_how_url', '');
              }}
            >
              <Box display="flex" flexDirection="column">
                <Typography intent="body">
                  {I18n.t('labels.marketplaceApp.info.createLeadButton')}
                </Typography>
              </Box>
            </RadioButton>
          )}
        </Box>
      </Grid.Col>

      <Grid.Col colWidth={12}>
        <Box display="flex" justifyContent="flex-start" marginTop="sm">
          {status === CONTACT_STATE.CREATE_LEAD ? (
            <Box
              data-testid="marketplace-app-create-lead-display"
              marginTop="md"
            >
              <Typography intent="body">
                {I18n.t('labels.marketplaceApp.info.createLeadNote')}
              </Typography>
              <Typography intent="body">
                {I18n.t('labels.marketplaceApp.info.createLeadSecondNote')}
              </Typography>
            </Box>
          ) : null}
          {status === CONTACT_STATE.SHOW_CONTACT_US ? (
            <Box flex="0 0 50%">
              <Form.Field
                required
                data-qa="marketplace-app-contact-us-email"
                data-testid="marketplace-app-contact-us-email"
                disabled={isDisabled}
                label={
                  <FormLabel
                    changeFields={['contact_us_email']}
                    contentI18nTag="contactUsEmail"
                    copyText={fields.contact_us_email}
                  />
                }
                type="email"
                value={fields.contact_us_email}
                errors={validations.contact_or_learn_how}
                onChange={(e) => onChange('contact_us_email', e.target.value)}
                placeholder={I18n.t('placeholders.contact.email')}
              />
            </Box>
          ) : null}
          {status === CONTACT_STATE.SHOW_LEARN_HOW ? (
            <Box flex="0 0 50%">
              <Form.Field
                required
                data-qa="marketplace-app-learn-how-url"
                data-testid="marketplace-app-learn-how-url"
                disabled={isDisabled}
                label={
                  <FormLabel
                    changeFields={['learn_how_url']}
                    contentI18nTag="learnHowUrl"
                    copyText={fields.learn_how_url}
                  />
                }
                type="text"
                value={fields.learn_how_url}
                errors={validations.contact_or_learn_how}
                onChange={(e) => onChange('learn_how_url', e.target.value)}
                placeholder={I18n.t('placeholders.contact.learnHow')}
              />
            </Box>
          ) : null}
        </Box>
      </Grid.Col>
    </Grid.Row>
  );
};

TabContact.propTypes = {
  I18n: PT.shape({}).isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  isProcoreEmployee: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabContact;
