import React, { useState } from 'react';
import {
  Button,
  Flex,
  Typography,
  InfoBanner,
  Banner,
  useI18nContext,
  spacing,
} from '@procore/core-react';

export const MarketplaceInfoBanner = ({ dismissBanner }) => {
  const I18n = useI18nContext();
  const [bannerExpanded, setBannerExpanded] = useState(false);

  return (
    <InfoBanner>
      <Banner.Content>
        <Banner.Title>{I18n.t('marketplace.banner.title')}</Banner.Title>
        <Banner.Body>{I18n.t('marketplace.banner.body')}</Banner.Body>
      </Banner.Content>

      <Banner.Action>
        <Button onClick={() => setBannerExpanded((prev) => !prev)}>
          {bannerExpanded
            ? I18n.t('marketplace.banner.collapse')
            : I18n.t('marketplace.banner.expand')}
        </Button>
      </Banner.Action>

      <Banner.Dismiss onClick={dismissBanner} />

      <Banner.ExpandableContent
        expanded={bannerExpanded}
        style={{ paddingRight: `${spacing.lg}px` }}
      >
        <Flex direction="column">
          <Typography intent="body" weight="semibold">
            {I18n.t('marketplace.banner.detailedUpdates')}
          </Typography>
          <ul style={{ margin: 0, paddingLeft: `${spacing.lg}px` }}>
            {['first', 'second', 'third', 'fourth'].map((key) => (
              <li key={key}>{I18n.t(`marketplace.banner.updates.${key}`)}</li>
            ))}
          </ul>
        </Flex>
      </Banner.ExpandableContent>
    </InfoBanner>
  );
};
