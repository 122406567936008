import React, { useState } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS, generateWistiaLink } from '../constants';
import ChangeIndicator from '../ChangeIndicator';
import DropzoneModalsDocUpload from '@/react/shared/dropzone/DropzoneModalsDocUpload';
import {
  Box,
  Grid,
  Flex,
  FlexList,
  Switch,
  Typography,
  H3,
  Dropzone,
  useDropzone,
  Select,
  useI18nContext,
  Pill,
  TextEditor,
} from '@procore/core-react';
import { ShieldStar } from '@procore/core-icons/dist';
import { EyeOff } from '@procore/core-icons';
import { Divider, InlineLink } from '../utils';

const VersionString = ({ version }) => {
  const I18n = useI18nContext();
  let versionString = null;
  if (version?.state === 'published') {
    versionString = 'labels.marketplaceApp.version.published';
  }
  if (version?.state === 'in_review') {
    versionString = 'labels.marketplaceApp.version.inReview';
  }

  return (
    <Flex justifyContent="space-between">
      {version?.semantic_version ||
        I18n.t('labels.marketplaceApp.version.releaseVersionPlaceholder')}
      {versionString && (
        <Pill color={version?.state === 'published' ? 'blue' : 'green'}>
          {I18n.t(versionString)}
        </Pill>
      )}
    </Flex>
  );
};

VersionString.propTypes = {
  version: PT.shape({
    semantic_version: PT.string,
    state: PT.string,
  }),
};

const TabAppInfo = ({
  fields,
  validations,
  onFieldChange,
  I18n,
  isDisabled,
  isAdminView,
  hasChanged,
  publishedFields,
  FormLabel,
  uploadWistiaVideo,
  setError,
  possibleVersions = [],
}) => {
  const onChange =
    (...fieldPath) =>
    (value) =>
      onFieldChange([MARKETPLACE_TABS.APP, ...fieldPath], value);

  const onDrop = (acceptedFiles) => {
    const uploadedFile = {
      file: acceptedFiles[0],
      type: 'Document',
      name: acceptedFiles[0].name,
      description: acceptedFiles[0].name,
      original_filename: acceptedFiles[0].name,
      url: acceptedFiles[0].url,
    };
    onChange('security_questionnaire_document')(uploadedFile);
  };

  const onShowcaseVideoDrop = (acceptedFiles) => {
    uploadWistiaVideo(acceptedFiles[0])
      .then((resp) => {
        const { data } = resp;
        const showcaseVideo = {
          type: 'ShowcaseVideo',
          external_url: generateWistiaLink(data.hashed_id),
          original_filename: data.name,
          description: data.name,
          name: data.name,
        };
        onChange('showcase_video')(showcaseVideo);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const removeFile = () => {
    onChange('remove_media_ids')([
      ...(fields.remove_media_ids || []),
      fields.security_questionnaire_document.id,
    ]);
    onChange('security_questionnaire_document')(null);
  };

  const removeShowcaseVideoFile = () => {
    onChange('remove_media_ids')([
      ...(fields.remove_media_ids || []),
      fields.showcase_video.id,
    ]);
    onChange('showcase_video')(null);
  };

  const dropzoneState = useDropzone({
    multiple: false,
    onDrop,
    disabled: !fields.security_badge,
  });

  const showcaseVideoDropzoneState = useDropzone({
    multiple: false,
    onDrop: onShowcaseVideoDrop,
    accept: ['video/mp4', 'video/quicktime'],
  });

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <H3>{I18n.t(`tabInfo.sections.${MARKETPLACE_TABS.APP}.general`)}</H3>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={6}>
          <Form.Field
            required
            data-qa="marketplace-app-name"
            data-testid="marketplace-app-name"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['public_name']}
                contentI18nTag="name"
                copyText={fields.public_name}
              />
            }
            type="text"
            value={fields.public_name}
            errors={validations.public_name}
            onChange={(e) => onChange('public_name')(e.target.value)}
            placeholder={I18n.t('placeholders.marketplaceApp.name')}
          />
        </Grid.Col>

        <Grid.Col colWidth={6}>
          <Form.Field
            required
            data-qa="marketplace-tagline"
            data-testid="marketplace-tagline"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['tagline']}
                contentI18nTag="tagline"
                copyText={fields.tagline}
              />
            }
            type="text"
            value={fields.tagline}
            errors={validations.tagline}
            onChange={(e) => onChange('tagline')(e.target.value)}
            placeholder={I18n.t('placeholders.marketplaceApp.tagline')}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={6}>
          <Form.Field
            required
            data-qa="marketplace-built-by"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['built_by']}
                contentI18nTag="builtBy"
                copyText={fields.built_by}
              />
            }
            type="text"
            value={fields.built_by}
            errors={validations.built_by}
            onChange={(e) => onChange('built_by')(e.target.value)}
            placeholder={I18n.t('placeholders.marketplaceApp.builtBy')}
          />
        </Grid.Col>

        <Grid.Col colWidth={6}>
          <Form.Field
            required
            data-testid="marketplace-sold-by"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['sold_by']}
                contentI18nTag="soldBy"
                copyText={fields.sold_by}
              />
            }
            type="text"
            value={fields.sold_by}
            errors={validations.sold_by}
            onChange={(e) => onChange('sold_by')(e.target.value)}
            placeholder={I18n.t('placeholders.marketplaceApp.soldBy')}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={6}>
          <Form.Textarea
            required
            data-qa="marketplace-description"
            data-testid="marketplace-description"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['description']}
                contentI18nTag="description"
                copyText={fields.description}
              />
            }
            type="text"
            value={fields.description}
            placeholder={I18n.t('placeholders.marketplaceApp.description')}
            errors={validations.description}
            onChange={(e) => onChange('description')(e.target.value)}
          />
        </Grid.Col>

        <Grid.Col colWidth={6}>
          <Form.Textarea
            required
            data-qa="marketplace-about"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['about']}
                contentI18nTag="about"
                copyText={fields.about}
              />
            }
            type="text"
            value={fields.about}
            errors={validations.about}
            onChange={(e) => onChange('about')(e.target.value)}
            placeholder={I18n.t('placeholders.marketplaceApp.about')}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={6}>
          <Form.AsyncPicker
            required
            multi
            data-qa="marketplace-app-category"
            data-testid="marketplace-app-category"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['category_ids']}
                contentI18nTag="category"
                showCopyButton={false}
              />
            }
            value={fields.category_ids}
            errors={validations.category_ids}
            url="/api/v1/categories"
            placeholder={I18n.t('placeholders.marketplaceApp.category')}
            onChange={(values) => {
              onChange('category_ids')(values.map((value) => value.id));
            }}
          />
        </Grid.Col>

        <Grid.Col colWidth={6}>
          <Form.Textarea
            required
            data-qa="marketplace-how-it-works"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['how_it_works']}
                contentI18nTag="howItWorks"
                copyText={fields.how_it_works}
              />
            }
            type="text"
            value={fields.how_it_works}
            errors={validations.how_it_works}
            onChange={(e) => onChange('how_it_works')(e.target.value)}
            placeholder={I18n.t('placeholders.marketplaceApp.howItWorks')}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Divider />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <H3>{I18n.t('labels.marketplaceApp.version.title')}</H3>
          <Typography intent="body" color="gray45">
            {I18n.t('labels.marketplaceApp.version.description')}
            <InlineLink
              className="override-default"
              href="https://developers.procore.com/documentation/partner-content-reqs"
              target="_blank"
            >
              {I18n.t('labels.marketplaceApp.version.link')}
            </InlineLink>
          </Typography>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={6}>
          <Form.FieldWrapper
            errors={validations.app_version_id}
            fieldId="marketplace-version"
            required
            label={
              <FormLabel
                changeFields={['app_version_id']}
                contentI18nTag="releaseVersion"
              />
            }
          >
            <Select
              block
              id="marketplace-version"
              data-testid="marketplace-version"
              disabled={isDisabled}
              placeholder={I18n.t(
                'labels.marketplaceApp.version.releaseVersionPlaceholder'
              )}
              label={<VersionString version={fields.app_version} />}
              error={validations.app_version_id}
              onSelect={({ item }) => {
                onChange('app_version_id')(item);
                onChange('app_version')(
                  possibleVersions.find((v) => v.app_version_id === item)
                );
              }}
            >
              {possibleVersions.map((version) => (
                <Select.Option
                  key={version.app_version_id}
                  value={version.app_version_id}
                >
                  <VersionString version={version} />
                </Select.Option>
              ))}
            </Select>
          </Form.FieldWrapper>
        </Grid.Col>

        <Grid.Col colWidth={6}>
          <Form.Textarea
            required
            data-qa="marketplace-draft-developer-notes"
            data-testid="marketplace-draft-developer-notes"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['draft_developer_notes']}
                contentI18nTag="draftDeveloperNotes"
                copyText={fields.draft_developer_notes}
              />
            }
            type="text"
            value={fields.draft_developer_notes}
            errors={validations.draft_developer_notes}
            onChange={(e) => onChange('draft_developer_notes')(e.target.value)}
            style={{ height: '96px' }}
            placeholder={I18n.t('placeholders.marketplaceApp.versionNotes')}
          />
        </Grid.Col>

        <Grid.Col colWidth={12} data-testid="marketplace-installation-option">
          <Box
            display="flex-column"
            data-testid="marketplace-installation-option-title"
            marginBottom="xs"
          >
            <Typography intent="label">
              {I18n.t('labels.marketplaceApp.installationOption.title')}
            </Typography>
            <Typography
              intent="small"
              color="gray45"
              style={{ marginTop: '4px' }}
            >
              {I18n.t('labels.marketplaceApp.installationOption.description')}
            </Typography>
          </Box>

          <Switch
            data-testid="marketplace-installation-option-switch"
            checked={fields.installable}
            disabled={isDisabled}
            onChange={() => {
              onChange('installable')(!fields.installable);
            }}
          >
            <Typography intent="body">
              {I18n.t('labels.marketplaceApp.installationOption.title')}
            </Typography>
          </Switch>
        </Grid.Col>

        {isAdminView && (
          <Grid.Col colWidth={12} data-testid="marketplace-beta">
            <Box
              display="flex-column"
              marginBottom="xs"
              data-testid="marketplace-beta-title"
            >
              <Typography intent="label">
                {I18n.t('labels.marketplaceApp.betaRelease.title')}
              </Typography>
              <Typography
                intent="small"
                color="gray45"
                style={{ marginTop: '4px' }}
              >
                {I18n.t('labels.marketplaceApp.betaRelease.description')}
              </Typography>
            </Box>

            <Switch
              data-testid="marketplace-beta-switch"
              checked={fields.beta}
              onChange={() => {
                onChange('beta')(!fields.beta);
              }}
            >
              <Typography intent="body">
                {I18n.t('labels.marketplaceApp.betaRelease.betaVersion')}
              </Typography>
            </Switch>
          </Grid.Col>
        )}
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Divider />
        </Grid.Col>
      </Grid.Row>

      {isAdminView && (
        <>
          <Grid.Row>
            <Grid.Col colWidth={12}>
              <Flex
                data-testid="security-trust-badge"
                alignContent="flex-start"
                alignItems="center"
              >
                <Box marginRight="sm">
                  <ShieldStar />
                </Box>
                <H3>{I18n.t('labels.marketplaceApp.securityTrust.header')}</H3>
              </Flex>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Box
                display="flex-column"
                data-testid="security-trust-badge-description"
                marginBottom="xs"
              >
                <Typography intent="label">
                  {I18n.t('labels.marketplaceApp.securityTrust.badgeTitle')}
                </Typography>
                <Typography
                  intent="small"
                  color="gray45"
                  style={{ marginTop: '4px' }}
                >
                  {I18n.t(
                    'labels.marketplaceApp.securityTrust.badgeDescription'
                  )}
                </Typography>
              </Box>

              <Switch
                data-testid="security-trust-badge-switch"
                checked={fields.security_badge}
                onChange={() => {
                  onChange('security_badge')(!fields.security_badge);
                }}
              >
                <Typography intent="body">
                  {I18n.t('labels.marketplaceApp.securityTrust.badgeTitle')}
                </Typography>
              </Switch>
            </Grid.Col>

            <Grid.Col colWidth={6} data-testid="document-upload-container">
              <Typography intent="label">
                {I18n.t(
                  'labels.marketplaceApp.securityTrust.selfAssessmentDocument'
                )}
              </Typography>

              <Box
                data-testid="dropzone"
                style={{ height: '180px' }}
                marginTop="sm"
              >
                <Dropzone {...dropzoneState} />
              </Box>

              <FlexList data-testid="uploaded-file-container" wrap="wrap">
                {fields.security_questionnaire_document && (
                  <DropzoneModalsDocUpload
                    disabled={false}
                    file={fields.security_questionnaire_document}
                    removeFile={removeFile}
                  />
                )}
              </FlexList>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col>
              <Divider />
            </Grid.Col>
          </Grid.Row>
        </>
      )}

      <Grid.Row data-testid="marketplace-admin-app-specfications">
        <Grid.Col colWidth={12}>
          <Flex data-testid="marketplace-admin-app-specs-title">
            <Box marginRight="sm">
              <EyeOff />
            </Box>
            <Box display="flex-column">
              <H3>
                {I18n.t('labels.marketplaceApp.adminAppSpecifications.title')}
              </H3>
              <Typography intent="body">
                {I18n.t(
                  'labels.marketplaceApp.adminAppSpecifications.description'
                )}
              </Typography>
            </Box>
          </Flex>
        </Grid.Col>

        <Grid.Col colWidth={6} data-testid="marketplace-admin-additional-notes">
          <Typography intent="label" style={{ marginBottom: '4px' }}>
            {I18n.t(
              'labels.marketplaceApp.adminAppSpecifications.additionalNotesTitle'
            )}
          </Typography>
          <TextEditor
            type="text"
            data-testid="additional-notes"
            value={fields.notes}
            disabled={isDisabled}
            onChange={(e) => {
              onChange('notes')(e);
            }}
          />
        </Grid.Col>
        <Grid.Col colWidth={6} data-testid="showcase-video-container">
          <Form.FieldWrapper
            errors={validations.showcase_video}
            fieldId="showcase_video"
            required
            label={
              <FormLabel
                changeFields={['showcase_video']}
                contentI18nTag="showcaseVideo"
              />
            }
          >
            <Box
              data-testid="showcase-video-dropzone"
              paddingTop="sm"
              style={{ height: '180px' }}
            >
              <Dropzone
                {...showcaseVideoDropzoneState}
                disabled={isDisabled}
                id={'showcase_video'}
              />
            </Box>

            <FlexList
              data-testid="showcase-video-uploaded-file-container"
              wrap="wrap"
            >
              {fields.showcase_video && (
                <DropzoneModalsDocUpload
                  file={fields.showcase_video}
                  removeFile={removeShowcaseVideoFile}
                />
              )}
            </FlexList>
          </Form.FieldWrapper>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

TabAppInfo.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({
    public_name: PT.string,
    description: PT.string,
    tagline: PT.string,
    category_ids: PT.arrayOf(PT.string),
    built_by: PT.string,
    how_it_works: PT.string,
    costs_money: PT.bool,
    beta: PT.bool,
    security_badge: PT.bool,
    security_questionnaire_document: PT.shape({}),
    showcase_video: PT.shape({}),
    remove_media_ids: PT.arrayOf(PT.string),
    installable: PT.bool,
    app_version: PT.shape({
      semantic_version: PT.string,
      state: PT.string,
    }),
    app_version_id: PT.string,
    draft_developer_notes: PT.string,
    sold_by: PT.string,
    notes: PT.string,
  }).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.objectOf(PT.arrayOf(PT.string)).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
  FormLabel: PT.elementType.isRequired,
  possibleVersions: PT.arrayOf(
    PT.shape({
      app_version_id: PT.string,
      semantic_version: PT.string,
      state: PT.string,
    })
  ),
};

export default TabAppInfo;
