import React, { useState, useEffect } from 'react';
import { Select } from '@procore/core-react';
import Form from '@/react/shared/form';
import { buildCountryList } from '@/global_helpers/getCountries';

const CountrySelect = ({
  countries: baseCountries,
  field,
  name,
  label,
  I18n,
  onChange,
  isDisabled,
  FormLabel,
  errors
}) => {
  const [countries, setCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(field);

  const onSelect = ({ item }) => {
    onChange(item.id);
    setSelectedCountry(item);
  };

  useEffect(() => {
    setSelectedCountry(field);
  }, [field]);

  useEffect(() => {
    const loadCountries = async () => {
      const countryList = await buildCountryList(
        baseCountries,
        I18n.locale
      );

      setCountries(countryList);
    };
    loadCountries();
  }, [I18n.locale, baseCountries]);

  const onClear = () => {
    onChange(null);
    setSelectedCountry(null);
  };

  const onSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Form.FieldWrapper
      data-testid={`country-select-${name}`}
      fieldId={`country-select-${name}`}
      required
      label={
        <FormLabel
          changeFields={[name]}
          contentI18nTag={label}
          showInfo={false}
        />
      }
      errors={errors}
    >
      <Select
        block
        id={`country-select-${name}`}
        data-testid={`country-select-${name}`}
        disabled={isDisabled}
        placeholder={I18n.t('placeholders.locationSelect')}
        label={selectedCountry?.name}
        onSelect={onSelect}
        onClear={onClear}
        onSearch={onSearch}
        error={!!errors?.length}
      >
        {countries &&
          countries
            .filter((country) =>
              country?.name?.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((country) => (
              <Select.Option
                key={country?.id}
                value={country}
                data-testid={`option-${country?.code}`}
                selected={country.id === setSelectedCountry?.id}
              >
                {country?.name}
              </Select.Option>
            ))}
      </Select>
    </Form.FieldWrapper>
  );
};

export default CountrySelect;
