import React from 'react';
import { I18nProvider } from '@procore/core-react';
import PermissionsBuilder from './PermissionsBuilder';
import i18n from './i18n';

const PermissionsBuilderWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <PermissionsBuilder {...props} />
  </I18nProvider>
);

export default PermissionsBuilderWithI18n;
