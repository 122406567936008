import React from 'react';
import PT from 'prop-types';
import ReactDropzone from 'react-dropzone';
import { Box, Flex, Spinner, Token, useI18nContext } from '@procore/core-react';
import cx from 'classnames';
import ConfirmMediumDeletionModal from './ConfirmMediumDeletionModal';
import FilePreviewModal from './FilePreviewModal';

const Dropzone = ({
  onDrop,
  acceptedTypes,
  className,
  children,
  disabled,
  files,
  removeFile,
  multiple,
  loading,
  loadingLabel,
  ...rest
}) => {
  const I18n = useI18nContext();
  const isDisabled = loading || disabled;

  return (
    <Spinner loading={loading} label={loadingLabel}>
      <div className="a-form--dropzone">
        <ReactDropzone
          multiple={multiple}
          disabled={isDisabled}
          onDrop={(...args) => {
            onDrop(...args);
          }}
          accept={acceptedTypes.join(',')}
          className={cx('dropzone', className, {
            isDisabled,
          })}
          {...rest}
        >
          <Flex alignItems="center" justifyContent="center" direction="column">
            {multiple ? I18n.t('attachFiles') : I18n.t('attachFile')}
          </Flex>
        </ReactDropzone>

        <Flex wrap="wrap">
          {files
            ?.filter((file) => file)
            .map((file, index) => (
              <DropzoneModals
                key={file.id || file.url}
                I18n={I18n}
                disabled={disabled}
                file={file}
                index={index}
                removeFile={removeFile}
              />
            ))}
        </Flex>
      </div>
    </Spinner>
  );
};

const DropzoneModals = ({ I18n, disabled, file, index, removeFile }) => {
  const [previewModalOpen, setPreviewModalOpen] = React.useState(false);
  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const remove = (file, index) => () => removeFile(file, index);

  return (
    <Box padding="sm">
      <Token variant="icon" disabled={disabled}>
        <>
          <Token.Label
            className="preview-link"
            onClick={() => setPreviewModalOpen(true)}
            data-qa={`file-label-${index}`}
          >
            {file.name}
          </Token.Label>

          <FilePreviewModal
            I18n={I18n}
            file={file}
            isVisible={previewModalOpen}
            hide={() => {
              setPreviewModalOpen(false);
            }}
          />
        </>

        <>
          <Token.Remove onClick={() => setDeletionModalOpen(true)} />

          <ConfirmMediumDeletionModal
            I18n={I18n}
            hide={() => setDeletionModalOpen(false)}
            isVisible={deletionModalOpen}
            onConfirm={remove(file, index)}
            file={file}
          />
        </>
      </Token>
    </Box>
  );
};

Dropzone.propTypes = {
  loading: PT.bool,
  loadingLabel: PT.string,
  acceptedTypes: PT.arrayOf(PT.string),
  children: PT.node,
  className: PT.string,
  disabled: PT.bool,
  files: PT.arrayOf(PT.shape({})).isRequired,
  multiple: PT.bool.isRequired,
  onDrop: PT.func.isRequired,
  removeFile: PT.func,
};

Dropzone.defaultProps = {
  loading: false,
  loadingLabel: '',
  acceptedTypes: [],
  className: null,
  children: null,
  disabled: false,
  removeFile: () => {},
};

export default Dropzone;
