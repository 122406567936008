import React from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { Flex, FlexList, Typography, Box } from '@procore/core-react';
import DropzoneComponent from '../dropzone';
import InfoPopover from '../infoPopover/InfoPopover';
import { Error } from '@procore/core-icons';
import styled from 'styled-components';

const ErrorContainer = styled.span`
  color: red;
  margin-right: 4px;
`;

const FullWidthDiv = styled.div`
  width: 100%;
`;

export const Description = ({ className, children }) => (
  <div className={cx('a-form--description', className)}>{children}</div>
);

export const Content = ({ className, children, vertical }) => {
  const classes = cx('a-form--content', className, {
    vertical: !!vertical,
  });

  return <div className={classes}>{children}</div>;
};

export const Row = ({ className, children }) => (
  <div className={cx('a-form--row', className)}>{children}</div>
);

export const Column = ({ className, children }) => (
  <div className={cx('a-form--col', className)}>{children}</div>
);

export const Label = ({ className, required, label, labelFor }) => (
  <label
    className={cx('a-label', className, { required: required })}
    htmlFor={labelFor}
  >
    <FlexList size="xs">{label}</FlexList>
  </label>
);

export const PopoverLabel = ({ labelContent, popoverContent }) => (
  <span>
    {labelContent}
    <InfoPopover>{popoverContent}</InfoPopover>
  </span>
);

export const Dropzone = ({
  required,
  requirements,
  label,
  children,
  errors,
  ...rest
}) => (
  <>
    <Label label={label} required={required} />

    {requirements && (
      <ul>
        {requirements.map((requirement, idx) => (
          <li key={idx}>{requirement}</li>
        ))}
      </ul>
    )}
    <DropzoneComponent {...rest}>{children}</DropzoneComponent>
    <FormError errors={errors} />
  </>
);

export const FormError = ({ errors }) => {
  if (!errors || errors.length === 0) {
    return <></>;
  }

  const errorMessages = Array.isArray(errors) ? errors : [errors];
  return (
    <Flex paddingTop="sm" paddingBottom="sm">
      <ErrorContainer paddingLeft="xs">
        <Error icon="error-o" size="sm" />
      </ErrorContainer>
      <Typography color="red50" intent="small">
        {errorMessages.join(', ')}
      </Typography>
    </Flex>
  );
};

export const FormFieldWrapper = ({
  className,
  errors,
  children,
  label,
  required,
  fieldId,
}) => {
  return (
    <FullWidthDiv className={className}>
      {label && <Label label={label} required={required} labelFor={fieldId} />}
      {children}
      <FormError errors={errors} />
    </FullWidthDiv>
  );
};

FormFieldWrapper.propTypes = {
  className: PT.string,
  errors: PT.array,
  children: PT.node,
  label: PT.node,
  required: PT.bool,
  fieldId: PT.string,
};

Label.propTypes = {
  className: PT.string,
  required: PT.bool,
  label: PT.node,
  labelFor: PT.string,
};

FormError.propTypes = {
  errors: PT.array,
};
