import React, { useState } from 'react';
import {
  Banner,
  Button,
  ErrorBanner,
  Modal,
  Form,
  Spinner,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import api from './api';

const oneSecondDelay = 1000; // 1000ms
const tenSecondDelay = 10000; // 10000ms

const redirectToApp = (id) => {
  window.location.assign(`/developer_apps/${id}`);
};

const New = () => {
  const i18n = useI18nContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submit = (values) => {
    setLoading(true);

    api
      .createDeveloperApp(values)
      .then((resp) => {
        const devApp = resp.data;

        // if the sandbox is already created,
        // redirect to the app
        if (devApp?.sandbox) {
          redirectToApp(devApp.id);
        } else {
          // poll every second, and redirect if
          // the sandbox is ready. Ideally this would
          // be done via websockets in the future, but
          // at the moment the priority from product is
          // a fast release on this feature.
          setInterval(() => {
            api
              .getDeveloperApp(devApp.id)
              .then((resp) => {
                if (resp.data?.sandbox) {
                  redirectToApp(devApp.id);
                }
              })
          }, oneSecondDelay);

          // after ten seconds, redirect regardless
          // of sandbox status
          setTimeout(() => {
            redirectToApp(devApp.id);
          }, tenSecondDelay);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      })
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        data-testid="create-app-button"
      >
        {i18n.t('button')}
      </Button>

      <Modal
        open={open}
        onClickOverlay={() => setOpen(false)}
        onClose={() => setOpen(false)}
        data-testid="create-app-form"
      >
        <Modal.Header onClose={() => setOpen(false)}>
          {i18n.t('modal.title')}
        </Modal.Header>

        <Form
          onSubmit={submit}
        >
          <Form.Form>
            <Spinner loading={loading}>
              <Modal.Body>
                { error && (
                  <ErrorBanner>
                    <Banner.Content>
                      <Banner.Title>
                        {i18n.t('modal.errorTitle')}
                      </Banner.Title>
                      <Banner.Body data-testid="app-creation-error">
                        {error}
                      </Banner.Body>
                    </Banner.Content>
                  </ErrorBanner>
                )}

                <Typography intent="body">
                  {i18n.t('modal.description')}
                </Typography>

                <Form.Row>
                  <Form.Text
                    name="internal_name"
                    label={i18n.t('modal.appName')}
                    data-testid="app-name"
                    colWidth="4"
                    required
                  />
                </Form.Row>

                <Typography intent="body">
                  {i18n.t('modal.agreement')}
                  <a href="/terms_and_conditions">{i18n.t('modal.agreementLink')}</a>
                </Typography>
              </Modal.Body>

              <Modal.Footer>
                <Modal.FooterButtons>
                  <Button variant="tertiary" onClick={() => setOpen(false)} data-testid="create-app-cancel">
                    {i18n.t('modal.cancel')}
                  </Button>
                  <Button type="submit" variant="primary" data-testid="create-app-confirm">
                        {i18n.t('modal.submit')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </Spinner>
          </Form.Form>
        </Form>
      </Modal>
    </>
  );
}

export default New;
