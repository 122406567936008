import moment from 'moment';

const YESTERDAY_AT_FORMAT = '[Yesterday at] h:mm a';
const FULL_DATE_AT_TIME_FORMAT = 'MMMM Do YYYY, [at] h:mm a';

function getNotificationDisplayTime(notificationTimeStamp) {
  const currentMoment = moment();
  const notificationMoment = moment(notificationTimeStamp);

  const durationFromNow = moment.duration(
    currentMoment.diff(notificationMoment)
  );

  if (durationFromNow <= 0) {
    return notificationMoment.format(FULL_DATE_AT_TIME_FORMAT);
  }

  const durationInDays = durationFromNow.asDays();

  if (durationInDays < 1) {
    const durationInMinutes = durationFromNow.asMinutes();
    // Today
    if (durationInMinutes < 1) {
      return `${durationFromNow.asSeconds()} seconds ago`;
    } else if (durationInMinutes < 60) {
      const minutes = Math.floor(durationFromNow.minutes());
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    }
    const hours = Math.floor(durationFromNow.asHours());
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else if (durationInDays >= 1 && durationInDays < 2) {
    // Yesterday
    return notificationMoment.format(YESTERDAY_AT_FORMAT);
  }
  // Greater than two days ago
  return notificationMoment.format(FULL_DATE_AT_TIME_FORMAT);
}

export default {
  getNotificationDisplayTime,
};
