import React, { useEffect, useState } from 'react';
import { Card, FlexList } from '@procore/core-react';
import AppCredentialsForm from '@/react/developer_apps/show/AppCredentialsForm';
import { UseShowContext } from '../Show.context';
import { SandboxContainer } from '../containers/SandboxContainer';

const Credentials = () => {
  const [err, setErr] = React.useState(null);

  const {
    developerAppId,
    canEdit,
    appCredentialsDocPath,
    redirectUriDocPath,
    api,
    secretResetCount,
    state: { devApp, setDevApp, permissions },
  } = UseShowContext();

  const resetAppSecret = (uid, onSuccess, onError) => {
    api
      .resetAppSecret(devApp.uid)
      .then(onSuccess)
      .catch(onError);
  };

  const resetAppSecretSuccess = (resp) => {
    setDevApp({ ...devApp, credentials: resp.data });
  };

  const [fields, setFields] = useState(devApp);
  useEffect(() => {
    setFields(devApp);
  }, [devApp]);

  const onChange = (name, value) =>
    setFields({
      ...fields,
      __modified__: true,
      [name]: value,
    });

  const [
    resetCredentialsConfirmed,
    setResetCredentialsConfirmed,
  ] = React.useState(false);
  const toggleResetCredentialsConfirmed = () =>
    setResetCredentialsConfirmed(!resetCredentialsConfirmed);

  const [loading, setLoading] = useState(false);

  const onSave = () => {
    setLoading(true);
    api
      .updateDeveloperApp(developerAppId, fields)
      .then((resp) => {
        setDevApp({ ...devApp, ...resp.data });
        setLoading(false);
      })
      .catch((e) => {
        setErr(e);
        setLoading(false);
      });
  };

  const forgetSecret = () => {
    setDevApp({
      ...devApp,
      credentials: {
        ...devApp.credentials,
        secret: null,
      },
    });
  };

  return (
    <FlexList size="lg" direction="column" alignItems="stretch" data-testid="credentials">
      <Card>
        <AppCredentialsForm
          onSave={onSave}
          onError={setErr}
          canEdit={canEdit}
          onChange={onChange}
          fields={fields}
          appFieldPermissions={permissions}
          appCredentialsDocPath={appCredentialsDocPath}
          redirectUriDocPath={redirectUriDocPath}
          resetAppSecret={resetAppSecret}
          resetAppSecretSuccess={resetAppSecretSuccess}
          developerAppId={developerAppId}
          credentials={devApp.credentials || {}}
          forgetSecret={forgetSecret}
          loading={loading}
          resetCredentialsConfirmed={resetCredentialsConfirmed}
          toggleResetCredentialsConfirmed={toggleResetCredentialsConfirmed}
          canModifyCredentials
          error={err}
          secretResetCount={secretResetCount}
        />
      </Card>
      <SandboxContainer />
    </FlexList>
  );
};

Credentials.defaultProps = {
  credentials: {},
  fields: {},
};

export default Credentials;
