export const PERMISSION_LEVELS = [
  { name: 'none', id: 1 },
  { name: 'readonly', id: 2 },
  { name: 'standard', id: 3 },
  { name: 'admin', id: 4 },
];

export const PERMISSION_NAME_TO_ID = {
  none: 1,
  readonly: 2,
  standard: 3,
  admin: 4,
};

export const mapPermissionDisplayName = (
  permissions = { company: [], project: [] }
) => {
  const acc = {
    company: {},
    project: {},
  };
  permissions.company.forEach((p) => {
    acc.company[p.name] = p.friendly_name;
  });
  permissions.project.forEach((p) => {
    acc.project[p.name] = p.friendly_name;
  });
  return acc;
};
