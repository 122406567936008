import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    present: 'This field is required.',
    supported: '%{field} where your app is available.',
    maxStringLength: '%{field} must be no longer than %{length} characters',
    allValuesPresent: 'Must have at least %{length} items',
  },
});
