import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Flex,
  Form,
  Modal,
  Switch,
  Typography,
  useI18nContext
} from '@procore/core-react';

const AddParameterModal = ({ open, setOpen, editingProperty, saveProperty }) => {
  const I18n = useI18nContext();

  const [required, setRequired] = useState(editingProperty?.required);
  useEffect(() => {
    setRequired(editingProperty?.required);
  }, [editingProperty])

  const onSubmit = (values) => {
    saveProperty({ ...values, required });
    setOpen(false);
  };

  const fullWidth = 12;

  return (
    <Modal open={open} onClickOverlay={() => setOpen(false)} onClose={() => setOpen(false)}>
      <Modal.Header onClose={() => setOpen(false)}>
        {I18n.t('modals.addParameter.title')}
      </Modal.Header>

      <Form initialValues={editingProperty} onSubmit={onSubmit}>
        <Form.Form>
          <Modal.Body>
            <Form.Row>
              <Form.Text
                name="name"
                label={I18n.t('modals.addComponent.name')}
                colWidth={fullWidth}
                required
                data-testid="parameter-name"
                description={I18n.t('modals.addParameter.nameDetails')}
                placeholder={I18n.t('modals.addParameter.namePlaceholder')}
              />
            </Form.Row>

            <Form.Row>
              <Form.Text
                name="key"
                label={I18n.t('modals.addComponent.key')}
                colWidth={fullWidth}
                required
                data-testid="parameter-key"
                description={I18n.t('modals.addParameter.keyDetails')}
                placeholder={I18n.t('modals.addParameter.keyPlaceholder')}
              />
            </Form.Row>

            <Form.Row>
              <Form.TextArea
                name="description"
                label={I18n.t('modals.addComponent.description')}
                colWidth={fullWidth}
                required
                data-testid="parameter-description"
                placeholder={I18n.t('modals.addParameter.descriptionPlaceholder')}
              />
            </Form.Row>

            <Form.Row>
              <Flex style={{gridColumnStart: '1', gridColumnEnd: '12'}} direction="column">
                <Box padding="sm none">
                  <Typography weight="bold">
                    {I18n.t('modals.addComponent.required')}
                  </Typography>
                </Box>
                <Flex style={{width: '100%'}}>
                  <Switch
                    checked={required}
                    onClick={() => setRequired(!required)}
                    data-testid="parameter-required"
                  />
                  <Box padding="none xs">
                    <Typography intent="body">
                      {I18n.t('modals.addParameter.requiredDescription')}
                    </Typography>
                  </Box>
                </Flex>
              </Flex>
            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Modal.FooterNotation>
              {I18n.t('modals.addParameter.footerText')}
            </Modal.FooterNotation>

            <Modal.FooterButtons>
              <Button
                size="md"
                variant="tertiary"
                onClick={() => setOpen(false)}
                data-testid="add-parameter-cancel-button"
              >
                {I18n.t('modals.addParameter.cancel')}
              </Button>
              <Button
                size="md"
                type="submit"
                data-testid="parameter-save"
              >
                {I18n.t('modals.addParameter.confirm')}
              </Button>
            </Modal.FooterButtons>
          </Modal.Footer>
        </Form.Form>
      </Form>
    </Modal>
  );
}

AddParameterModal.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  editingProperty: PT.shape({
    required: PT.bool,
  }).isRequired,
  saveProperty: PT.func.isRequired,
};

export default AddParameterModal;
