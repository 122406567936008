import React from 'react';
import {
  Card,
  Box,
  Flex,
  H2,
  LinkButton,
  Typography,
  H1,
} from '@procore/core-react';
import styled from 'styled-components';
import { useI18nContext } from '@procore/core-react';

const GrowCard = styled(Card)`
  flex-grow: 1;
  width: 50%;
`;

const CenteredTypography = styled(Typography)`
  text-align: center;
`;

const ListingApplication = ({ devAppId }) => {
  const listingActivationLink = `https://docs.google.com/forms/d/e/1FAIpQLSe9BoKQGepg-rKD7KDRjKm1idrVEjVXD-01gEEC04fVsGDpGg/viewform?usp=pp_url&entry.319564604=A+current+Procore+Partner+-+I+am+ready+to+complete+the+Marketplace+listing+and+launch+my+app.&entry.525846413=${devAppId}`;
  const partnerRequestLink = `https://docs.google.com/forms/d/e/1FAIpQLSe9BoKQGepg-rKD7KDRjKm1idrVEjVXD-01gEEC04fVsGDpGg/viewform?usp=pp_url&entry.319564604=Not+yet+a+Procore+Partner+-+I+am+ready+to+complete+vetting+and+necessary+contracts+ahead+of+my+application+submission.&entry.525846413=${devAppId}`;

  const I18n = useI18nContext();

  return (
    <>
      <Box paddingBottom="lg" data-testid="marketplace-listing-application">
        <H1>{I18n.t('listingActivation.title')}</H1>
        <div>
          <Typography intent="body" color="gray40">
            {I18n.t('listingActivation.description')}
          </Typography>
        </div>
        <div>
          <Typography intent="body" color="gray40">
            {I18n.t('listingActivation.contact')}{' '}
            <a href="mailto:partnerprograms@procore.com">
              partnerprograms@procore.com
            </a>
          </Typography>
        </div>
      </Box>
      <Flex display="flex" gap="sm">
        <GrowCard>
          <Flex
            padding="lg"
            direction="column"
            alignItems="center"
            gap="md"
            data-testid="listing-application-card"
          >
            <img src="/assets/images/partner.svg" />
            <H2>{I18n.t('listingActivation.activation.title')}</H2>
            <CenteredTypography intent="body" color="gray40">
              {I18n.t('listingActivation.activation.description')}
            </CenteredTypography>
            <LinkButton
              data-testid="listing-activation-link"
              variant="secondary"
              href={listingActivationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {I18n.t('listingActivation.activation.button')}
            </LinkButton>
          </Flex>
        </GrowCard>
        <GrowCard>
          <Flex
            padding="lg"
            direction="column"
            alignItems="center"
            gap="md"
            data-testid="partner-application-card"
          >
            <img src="/assets/images/marketplace.svg" />
            <H2>{I18n.t('listingActivation.partner.title')}</H2>
            <CenteredTypography intent="body" color="gray40" textA>
              {I18n.t('listingActivation.partner.description')}
            </CenteredTypography>
            <LinkButton
              data-testid="partner-application-link"
              variant="secondary"
              href={partnerRequestLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {I18n.t('listingActivation.partner.button')}
            </LinkButton>
          </Flex>
        </GrowCard>
      </Flex>
    </>
  );
};

export { ListingApplication };
