import React, { createContext, useContext, useMemo, useState } from 'react';
import PT from 'prop-types';

const ErrorContext = createContext(null);

export const useErrorContext = () => {
  const context = useContext(ErrorContext);

  if (!context) {
    throw new Error('useErrorContext must be used within an ErrorProvider');
  }

  return context;
};

export const ErrorProvider = ({ children }) => {
  const [errors, setErrors] = useState();

  const addErrors = (errors) => {
    setErrors([...errors]);
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const value = useMemo(() => ({ errors, addErrors, clearErrors }), [errors]);

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};

ErrorProvider.propTypes = {
  children: PT.node.isRequired,
};
