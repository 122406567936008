import axios from 'axios';
import Qs from 'qs';
import { getHeaders, PATCH_HEADERS, POST_HEADERS } from '@/utils/apiUtil';
import { MANIFEST_TYPES } from '../../../shared/constants';

const WISTIA_UPLOAD_URL = 'https://upload.wistia.com';

const api = (wistiaApiKey, wistiaMarketplaceProjectId) => ({
  updateMarketplaceApp(marketplaceAppId, payload) {
    return axios.patch(
      `/api/v1/admin/submissions/${marketplaceAppId}`,
      payload,
      getHeaders(PATCH_HEADERS)
    );
  },

  createMarketplaceApp(developerAppId, data) {
    return axios.post(
      `/api/v1/admin/developer_apps/${developerAppId}/submissions`,
      data,
      getHeaders(POST_HEADERS)
    );
  },

  get(appID) {
    return axios.request({
      url: `/api/v1/admin/developer_apps/${appID}`,
      method: 'get',
      headers: getHeaders(),
    });
  },

  getManifests(appID, appType) {
    const params = {
      'filters[developer_app_id]': appID,
    };

    if (appType === MANIFEST_TYPES.PRODUCTION) {
      params['filters[versionable_type]'] = 'ProductionEnvironment';
    } else if (appType === MANIFEST_TYPES.SANDBOX) {
      params['filters[versionable_type]'] = 'Sandbox';
    }

    return axios.request({
      url: '/api/v1/manifests',
      method: 'get',
      paramsSerializer: Qs.stringify,
      params,
    });
  },

  createManifest(devAppID, appType, manifest, semanticVersion) {
    return axios.request({
      url: '/api/v1/app_versions',
      method: 'post',
      data: {
        app_version: {
          developer_app_id: devAppID,
          environment_type: appType,
          manifest: {
            content: manifest,
          },
          semantic_version: semanticVersion,
        },
      },
      paramsSerializer: Qs.stringify,
    });
  },

  updateAppVersion(appVersion) {
    return axios.patch(
      `/api/v1/admin/app_versions/${appVersion.id}`,
      {
        app_version: appVersion,
      },
      getHeaders(PATCH_HEADERS)
    );
  },

  promoteManifest(appVersionId) {
    return axios.request({
      url: '/api/v1/admin/app_version_promotions',
      method: 'post',
      data: {
        app_version_promotion: {
          source_app_version_id: appVersionId,
        },
      },
    });
  },

  updateApp(app) {
    const params = {
      developer_app: {
        ...app
      },
    };

    if (app.sandbox) {
      params.developer_app.sandbox_attributes = {
        ...app.sandbox,
      };
    }

    return axios.request({
      url: `/api/v1/admin/developer_apps/${app.id}`,
      method: 'patch',
      data: params,
      headers: getHeaders(),
    });
  },

  undeleteApp(app) {
    return axios.request({
      url: `/api/v1/admin/developer_apps/${app.id}`,
      method: 'patch',
      data: {
        developer_app: {
          deleted_at: null,
        },
      },
      headers: getHeaders(),
    });
  },

  uploadWistiaVideo(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('api_password', wistiaApiKey);
    data.append('project_id', wistiaMarketplaceProjectId);
    return axios.post(WISTIA_UPLOAD_URL, data, getHeaders());
  },

  attachLogo(app, file) {
    const data = new FormData();
    data.append('developer_app[thumbnail]', file);

    return axios.put(
      `/api/v1/admin/developer_apps/${app.id}`,
      data,
      getHeaders()
    );
  },

  removeLogo(app) {
    return axios.put(
      `/api/v1/admin/developer_apps/${app.id}`,
      { developer_app: { remove_thumbnail: true } },
      getHeaders()
    );
  },
});

export default api;
