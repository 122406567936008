import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    cancel: 'Cancel',
    close: 'Close',
    create: 'Create',
    releasedAt: 'Released to Production',
    appVersionKey: 'App Version Key',
    createInfo:
      'Creating a new manifest version will not take effect for existing users until they update their app. New users that connect your app will be on the latest manifest version.',
    appManifestSectionInfo:
      'UID and name will be autogenerated under the "app_manifest" root key and will override any input for these fields.',
    createNewVersion: 'Create New Version',
    manifestContent: 'Manifest Content',
    noManifestsCreated: 'No Manifests Created',
    showOldVersions: 'Show Old Versions',
    hideOldVersions: 'Hide Old Versions',
    version: 'Version',
    view: 'View',
    jsonParseError: 'Invalid JSON, could not parse manifest',
    state: 'State',
    submitReview: 'Submit for Review',
    submitReviewModal: {
      header: 'Submit Manifest for Marketplace Review?',
      body: 'Once you submit your Manifest for Marketplace review, our team will carefully examine it.' +
      ' Please include a brief explanation of what your app is and any additional information our App Validation Engineer might need for testing your app.',
      confirm: 'Submit for Review',
      cancel: 'Cancel',
      inputLabel: 'Submission Notes',
      inputPopover:
        'Please include a brief explanation of what your app will display and any additional information an App Validation Engineer would need for testing your app (e.g. a test username and password if authentication is required, example parameters, etc.)',
    },
    approveReview: 'Approve',
    approveReviewModal: {
      header: 'Approve Manifest?',
      body: 'Are you sure you want to approve this manifest?',
      confirm: 'Approve',
      cancel: 'Cancel',
      inputLabel: 'Approval Notes',
      inputPopover:
        'These notes will be emailed to the developer. Please provide a comment or include any information the app developer may need to know.',
    },
    rejectReview: 'Reject',
    rejectReviewModal: {
      header: 'Reject Manifest?',
      body: 'Are you sure you want to reject this manifest?',
      confirm: 'Reject',
      cancel: 'Cancel',
      inputLabel: 'Rejection Notes',
      inputPopover:
        'These notes will be emailed to the developer. Please let the app developer know why you are rejecting their submission.',
    },
    withdrawReview: 'Withdraw from Review',
    withdrawReviewModal: {
      header: 'Withdraw Manifest?',
      body: 'Are you sure you want to withdraw this manifest from review?',
      confirm: 'Withdraw from Review',
      cancel: 'Cancel',
    },
    publishedToMarketplace: 'Published to Marketplace',
    releaseNotes: 'Release Notes',
    view: 'View',
  },
});
