import React from 'react';
import { compose, mapProps, withStateHandlers } from 'recompose';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import { buildDatesForTimePeriod } from '@/react/common/Metrics/utils';
import moment from 'moment';

const installsTotal = (metricsData) => {
  return pathOr(
    0,
    ['daily_app_installations_aggregates', 'total_count'],
    metricsData
  );
};

const mktViewsTotal = (metricsData) => {
  return pathOr(
    0,
    ['daily_marketplace_app_views_aggregates', 'total_count'],
    metricsData
  );
};

const numPages = (total, pageSize) => {
  return Math.max(1, Math.ceil(total / pageSize));
};

const rangeForPage = (currPage, pageSize) => ({
  start: pageSize * (currPage - 1),
  end: pageSize * currPage - 1,
});

const paginateData = (aggregateData, pageRange) => {
  const { start, end } = pageRange;
  const currPageDates = buildDatesForTimePeriod(aggregateData).slice(
    start,
    end
  );

  return Object.keys(aggregateData).reduce((pageAggregates, metric) => {
    const rows = aggregateData[metric].rows;
    const rowsForMetric = {};
    if (Object.keys(rows).length !== 0) {
      currPageDates.map((dateString) => {
        rowsForMetric[moment(dateString).format('YYYY-MM-D')] =
          rows[dateString];
      });
    }
    pageAggregates[metric] = {
      rows: rowsForMetric,
      total_count: rowsForMetric.length,
    };
    return pageAggregates;
  }, {});
};

const setUpPagination = (metricsData, pageNumber = 1, pageSize = 25) => {
  const { app_installations, ...aggregatesData } = metricsData;
  const numRows = buildDatesForTimePeriod(aggregatesData).length;
  const pagesCount = numPages(numRows, pageSize);
  const dateRange = rangeForPage(pageNumber, pageSize);
  const currentPageData = paginateData(aggregatesData, dateRange);

  return {
    paginationConfig: {
      pageNumber: pageNumber,
      pageSize: pageSize,
      total: numRows,
      totalPages: pagesCount,
    },
    paginationData: currentPageData,
  };
};

const extractMetricsData = (metricsData) => {
  const { developer_app_id, ...data } = metricsData;
  return data;
};

const MetricsContainer = (WrappedComponent) =>
  withStateHandlers(
    (props) => ({
      aggregatesData: {
        daily_app_installations_aggregates: {
          rows: {},
          total_count: 0,
        },
        daily_marketplace_app_views_aggregates: {
          rows: {},
          total_count: 0,
        },
      },
      appInstallations: {},
      appInstallsTotal: 0,
      mappedParams: {},
      marketplaceAppViewsTotal: 0,
      developerAppId: props.metricsData.developer_app_id,
      developerAppsShowPath: props.developerAppsShowPath,
      I18n: props.I18n,
      internalAppName: props.internalAppName,
      isAdminView: !!props.isAdminView,
      metricsData: extractMetricsData(props.metricsData),
      paginationConfigAndData: {
        paginationConfig: {
          pageNumber: 1,
          pageSize: 25,
          total: 0,
          totalPages: 1,
        },
        paginationData: {
          daily_app_installations_aggregates: {
            rows: {},
            total_count: 0,
          },
          daily_marketplace_app_views_aggregates: {
            rows: {},
            total_count: 0,
          },
        },
      },
    }),
    {
      setUpParams: ({ metricsData }) => () => {
        const { app_installations, ...aggregatesData } = metricsData;
        return {
          aggregatesData: aggregatesData,
          appInstallations: app_installations,
          appInstallsTotal: installsTotal(metricsData),
          marketplaceAppViewsTotal: mktViewsTotal(metricsData),
          paginationConfigAndData: setUpPagination(metricsData),
        };
      },

      getPage: ({ metricsData }) => (pageNumber) => ({
        paginationConfigAndData: setUpPagination(metricsData, pageNumber),
      }),
    }
  )(({ ...props }) => <WrappedComponent {...props} />);

export const MetricsStorePT = PT.shape({
  state: PT.shape({
    aggregatesData: PT.shape({}).isRequired,
    appInstallations: PT.shape({}).isRequired,
    appInstallsTotal: PT.number.isRequired,
    internalAppName: PT.string,
    isAdminView: PT.bool.isRequired,
    marketplaceAppViewsTotal: PT.shape.isRequired,
    metricsData: PT.shape({}),
    paginationConfigAndData: PT.shape({
      paginationConfig: PT.shape({
        pageNumber: PT.number.isRequired,
        pageSize: PT.number.isRequired,
        total: PT.number.isRequired,
        totalPages: PT.number.isRequired,
      }).isRequired,
      paginationData: PT.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
  handlers: PT.shape({
    getPage: PT.func.isRequired,
  }).isRequired,
});

export default compose(
  MetricsContainer,
  mapProps(
    ({
      aggregatesData,
      appInstallations,
      appInstallsTotal,
      developerAppId,
      developerAppsShowPath,
      I18n,
      internalAppName,
      isAdminView,
      marketplaceAppViewsTotal,
      pagination,
      paginationConfigAndData,
      getPage,
      setUpParams,
      ...props
    }) => ({
      metricsStore: {
        state: {
          aggregatesData,
          appInstallations,
          appInstallsTotal,
          developerAppId,
          developerAppsShowPath,
          I18n,
          internalAppName,
          isAdminView,
          marketplaceAppViewsTotal,
          paginationConfigAndData,
        },
        handlers: {
          getPage,
          setUpParams,
        },
      },
      ...props,
    })
  )
);
