import React from 'react';
import PT from 'prop-types';

import { Box, Button, Link, useI18nContext } from '@procore/core-react';

import CopyOnClick from '@/react/shared/copyOnClick/CopyOnClick';
import InfoPopover from '@/react/shared/infoPopover/InfoPopover';

const ComponentListItem = ({
  componentName,
  componentTooltipText,
  componentTooltipLink,
  templateData,
  injectComponent,
  schemaV4,
}) => {
  const I18n = useI18nContext();
  return (
    <Box display="flex-column" marginTop="xs">
      <Box marginBottom="xs">
        {componentName}
        <InfoPopover placement="right">
          <Box padding="xs">
            <Box
              marginBottom="sm"
              dangerouslySetInnerHTML={{ __html: componentTooltipText }}
            />
            <Box>
              {`${I18n.t('learnMore')} `}
              <Link href={componentTooltipLink} target="_blank">
                {componentName.toLocaleLowerCase()}
              </Link>
              {` ${I18n.t('type')}`}.
            </Box>
          </Box>
        </InfoPopover>
      </Box>
      <Box>
        {schemaV4 && (
          <Button variant="secondary" size="sm" onClick={injectComponent}>
            {I18n.t('injectComponent')}
          </Button>
        )}
        {!schemaV4 && (
          <CopyOnClick textToCopy={templateData} textSpacing={2}>
            <Button variant="secondary" size="sm">
              {I18n.t('copyCodeSnippet')}
            </Button>
          </CopyOnClick>
        )}
      </Box>
    </Box>
  );
};

ComponentListItem.propTypes = {
  componentName: PT.string.isRequired,
  componentTooltipText: PT.string.isRequired,
  componentTooltipLink: PT.string.isRequired,
  I18n: PT.shape({}),
  templateData: PT.shape({}),
  injectComponent: PT.func.isRequired,
  schemaV4: PT.bool.isRequired,
};

ComponentListItem.defaultProps = {
  I18n: {
    t: () => {},
  },
};

export default ComponentListItem;
