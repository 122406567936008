import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    cancel: 'Cancel',
    error: 'Error',
    delete: 'Delete',
    deleteApp: 'Delete App',
    deleteAppTitle: 'Delete This App',
    deleteAppInfo: `Deleting this app will make this app inaccessible and remove it from the
    "My Apps" page. It will be removed from all collaborators' accounts as well.`,
    deleteAppWarning: `Deleting this app will make this app inaccessible and remove it
    from the "My Apps" page. It will be removed from all collaborators' accounts as well. You cannot undo this.`,
    deleteAppConfirmation: 'Are you sure you want to delete this app?',
  },
});
