import React, { useEffect } from 'react';
import classNames from 'classnames';
import { FlexList, H3, Tabs, useI18nContext, Flex } from '@procore/core-react';
import {
  ALL_TABS,
  DEFAULT_TABS,
  StyledTab,
  TAB_SECTIONS,
  TABS_PER_SECTION,
  conditionalTabs,
  canShowTab,
} from './SideNav.constants';
import { UseShowContext } from '../Show.context';
import { useSideNavContext } from './SideNav.context';

const SideNav = () => {
  const i18n = useI18nContext();
  const {
    state: { devApp, trackEvent },
  } = UseShowContext();
  const { activeTab, setActiveTab } = useSideNavContext();

  useEffect(() => {}, []);

  const tabs = {
    ...DEFAULT_TABS,
    ...conditionalTabs(devApp),
  };

  const changeTab = (val) => {
    setActiveTab(val);

    if (val === ALL_TABS.METRICS) {
      trackEvent('developers.app_metrics.view_report.clicked');
    }
  };

  return (
    <div data-testid={'side-nav-container'}>
      {Object.values(TAB_SECTIONS).map((section) => (
        <Flex
          key={section}
          list
          direction="column"
          padding="16px 0"
          space="none"
        >
          <H3 data-testid={`${section}-tab-section`}>
            {i18n.t(`tabSection.${section}`)}
          </H3>
          <FlexList direction="column" padding="16px 0" space="none">
            {TABS_PER_SECTION[section]
              .filter((tab) => canShowTab(devApp, tab))
              .map((val) => (
                <StyledTab
                  className={classNames({ selected: activeTab === val })}
                  data-testid={`${val}-nav`}
                  key={val}
                >
                  <Tabs.Link
                    data-testid={`${val}-nav-link`}
                    onClick={() => changeTab(val)}
                    paddingLeft="16px"
                  >
                    {i18n.t(val)}
                  </Tabs.Link>
                </StyledTab>
              ))}
          </FlexList>
        </Flex>
      ))}
    </div>
  );
};

export default SideNav;
