import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    button: 'Create a New App',
    modal: {
      cancel: 'Cancel',
      submit: 'Create App',
      title: 'Create New App',
      description: 'Enter an app name. This will be the name you use to refer to your application internally within your organization.',
      agreement: 'By proceeding, you agree to the ',
      agreementLink: 'Procore Platform Policies',
      appName: 'App Name',
      errorTitle: 'Something Went Wrong',
    }
  }
});
