import axios from 'axios';
import { getHeaders, PATCH_HEADERS, POST_HEADERS } from '@/utils/apiUtil';
import { MANIFEST_TYPES } from '@/react/shared/constants';
import Qs from 'qs';

const WISTIA_UPLOAD_URL = 'https://upload.wistia.com';

const api = (wistiaApiKey, wistiaMarketplaceProjectId) => ({
  createMarketplaceApp(developerAppId, data) {
    return axios.post(
      `/api/v1/developer_apps/${developerAppId}/submissions`,
      data,
      getHeaders(POST_HEADERS)
    );
  },

  updateMarketplaceApp(marketplaceAppId, payload) {
    return axios.patch(
      `/api/v1/submissions/${marketplaceAppId}`,
      payload,
      getHeaders(PATCH_HEADERS)
    );
  },

  fetchPermissions(devAppId) {
    return axios.get('/api/v1/developer_app_permissions', {
      headers: getHeaders(),
      params: { developer_app_id: devAppId },
    });
  },

  fetchCollaborators(devAppId) {
    return axios.get('/api/v1/collaborators', {
      headers: getHeaders(),
      params: { developer_app_id: devAppId },
    });
  },

  updateDeveloperApp(devAppId, data) {
    return axios.patch(
      `/api/v1/developer_apps/${devAppId}`,
      { developer_app: data },
      getHeaders(PATCH_HEADERS)
    );
  },

  updateDeveloperAppFormData(devAppId, data) {
    return axios.patch(
      `/api/v1/developer_apps/${devAppId}`,
      data,
      getHeaders(PATCH_HEADERS)
    );
  },

  updateAppVersion(appVersion) {
    return axios.patch(
      `/api/v1/app_versions/${appVersion.id}`,
      { app_version: appVersion },
      getHeaders(PATCH_HEADERS)
    );
  },

  fetchDeveloperApp(devAppId) {
    return axios.get(`/api/v1/developer_apps/${devAppId}`, {
      headers: getHeaders(),
      params: { developer_app_id: devAppId },
    });
  },

  resetAppSecret(uid) {
    return axios.post(`/api/v1/oauth_applications/${uid}/reset_secret`, {
      headers: getHeaders(),
    });
  },

  destroyDeveloperApp(id) {
    return axios.request({
      url: `/api/v1/developer_apps/${id}`,
      method: 'delete',
    });
  },

  fetchInvitations(devAppId) {
    return axios.get('/api/v1/invitations', {
      headers: getHeaders(),
      params: { developer_app_id: devAppId },
    });
  },

  sendInvitation(developerAppId, email, role) {
    return axios.post('/api/v1/invitations', {
      developer_app_id: developerAppId,
      email,
      role,
      headers: getHeaders(POST_HEADERS),
    });
  },

  cancelInvitation(developerAppId, id) {
    return axios.patch(
      `/api/v1/invitations/${id}`,
      {
        developer_app_id: developerAppId,
        invitation: {
          status: 'canceled'
        }
      },
      getHeaders(PATCH_HEADERS)
    );
  },

  removeCollaborator(developerAppId, id) {
    return axios.request({
      url: `/api/v1/collaborators/${id}`,
      method: 'delete',
      params: { developer_app_id: developerAppId },
    });
  },

  updateCollaborators(developerAppId, collaborators) {
    return axios.patch('/api/v1/collaborators', {
      developer_app_id: developerAppId,
      collaborators,
      headers: getHeaders(PATCH_HEADERS),
    });
  },

  uploadWistiaVideo(file) {
    const data = new FormData();
    data.append('file', file);
    data.append('api_password', wistiaApiKey);
    data.append('project_id', wistiaMarketplaceProjectId);
    return axios.post(WISTIA_UPLOAD_URL, data, getHeaders());
  },

  getManifests(appID, appType) {
    const params = {
      'filters[developer_app_id]': appID,
    };

    if (appType === MANIFEST_TYPES.PRODUCTION) {
      params['filters[versionable_type]'] = 'ProductionEnvironment';
    } else if (appType === MANIFEST_TYPES.SANDBOX) {
      params['filters[versionable_type]'] = 'Sandbox';
    }

    return axios.request({
      url: '/api/v1/manifests',
      method: 'get',
      paramsSerializer: Qs.stringify,
      params,
    });
  },

  getVersion(appVersion) {
    return axios.request({
      url: `/api/v1/app_versioned_manifests/${appVersion}`,
      method: 'get',
    });
  },

  createManifest(devAppID, manifest) {
    return axios.post('/api/v1/app_versioned_manifests', {
      developer_app_id: devAppID,
      environment_type: 'Sandbox',
      configured_manifest_content: manifest.configured_manifest_content,
      semantic_version: manifest.semantic_version,
    });
  },

  promoteManifest(appVersionId, releaseNotes) {
    return axios.request({
      url: '/api/v1/app_version_promotions',
      method: 'post',
      data: {
        app_version_promotion: {
          source_app_version_id: appVersionId,
          changelog: releaseNotes || null,
        },
      },
    });
  },
});

export function post(path) {
  return axios.request(path, {
    method: 'post',
    data: {},
    headers: getHeaders(POST_HEADERS),
  });
};

export function updateSandbox(sandboxId, data) {
  return axios.patch(
    `/api/v1/sandboxes/${sandboxId}`,
    data,
    getHeaders(PATCH_HEADERS)
  );
};

export function resetSandboxAppSecret(uid) {
  return axios.post(`/api/v1/sandboxes/${uid}/reset_secret`, {
    headers: getHeaders(),
  });
};

export default api;
