import React, { useEffect } from 'react';
import PT from 'prop-types';
import {
  Breadcrumbs,
  Flex,
  DetailPage,
  ListPage,
  Spinner,
  Toast,
  ToolHeader,
  Table,
  Box,
  useI18nContext,
} from '@procore/core-react';
import Filters from '../../../shared/SearchBar/Filters';
import PaginationSection from '../../../shared/Pagination';

const Index = ({ developersIndexStore: { state, handlers } }) => {
  const I18n = useI18nContext();
  useEffect(() => {
    handlers.fetch(state.pagination.pageNumber);
  }, []);

  return (
    <DetailPage width="xl" initialIsVisible className="full-screen">
      <DetailPage.Main className="page-body">
        <DetailPage.Breadcrumbs>
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumbs.Crumb>{I18n.t('admin')}</Breadcrumbs.Crumb>
            <Breadcrumbs.Crumb active>{I18n.t('developers')}</Breadcrumbs.Crumb>
          </Breadcrumbs>
        </DetailPage.Breadcrumbs>

        <ToolHeader>
          <ToolHeader.Title>{I18n.t('developersTitle')}</ToolHeader.Title>
        </ToolHeader>

        <ListPage.ContentControls style={{ flexShrink: 0 }}>
          <ListPage.Control block>
            <Filters
              searchValue={state.filters.search || ""}
              handleChange={handlers.filterChange}
              getUserOptions={handlers.getOptions}
              onError={handlers.onError}
            />
          </ListPage.Control>
          <ListPage.Control>
            <PaginationSection {...state.pagination} pageTo={handlers.fetch} />
          </ListPage.Control>
        </ListPage.ContentControls>

        <DetailPage.Body className="full-screen">
          <DetailPage.Card>
              <Spinner loading={state.loading}>
                <Body
                  I18n={I18n}
                  apps={state.apps}
                  liveAppIds={state.liveAppIds}
                  error={state.error}
                  handleSort={handlers.sortChange}
                />

                <ListPage.ContentControls>
                  <ListPage.Control style={{ marginLeft: 'auto' }}>
                    <PaginationSection
                      {...state.pagination}
                      pageTo={handlers.fetch}
                    />
                  </ListPage.Control>
                </ListPage.ContentControls>
              </Spinner>
          </DetailPage.Card>
        </DetailPage.Body>
      </DetailPage.Main>
    </DetailPage>
  );
};

const Body = ({ I18n, apps, liveAppIds, error, handleSort }) => {
  if (error) {
    return (
      <Flex direction="row" alignItems="center" justifyContent="center">
        <Toast variant="error">{I18n.t('errors.occurred')}</Toast>
      </Flex>
    );
  }

  return (
    <Table.Container>
      <Table data-qa="apps-index-table">
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('name');
              }}
              data-qa="name-table-header"
            >
              {I18n.t('name')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('companyName');
              }}
              data-qa="company-name-table-header"
            >
              {I18n.t('companyName')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('email');
              }}
              data-qa="email-table-header"
            >
              {I18n.t('email')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sortable
              onClick={() => {
                handleSort('procore_id');
              }}
              data-qa="procore-id-table-header"
            >
              {I18n.t('procoreId')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>

        <Table.Body>
          {apps.map((app) => (
            <TableRow
              I18n={I18n}
              key={app.id}
              id={app.id}
              name={app.name}
              companyName={app.company_name}
              email={app.email}
              procoreId={app.procore_id}
            />
          ))}
        </Table.Body>
      </Table>
    </Table.Container>
  );
};

const TableRow = ({ id, name, companyName, email, procoreId }) => {
  const base_url = `/admin/developers/${id}/edit`;
  return (
    <Table.BodyRow>
      <Table.BodyCell>
        <Table.LinkCell href={base_url}>{name}</Table.LinkCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.LinkCell href={base_url}>{companyName}</Table.LinkCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.LinkCell href={base_url}>{email}</Table.LinkCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Box padding="lg">{procoreId}</Box>
      </Table.BodyCell>
    </Table.BodyRow>
  );
};

Index.propTypes = {
  developersIndexStore: PT.shape({
    handlers: PT.shape({
      fetch: PT.func.isRequired,
      onError: PT.func.isRequired,
      filterChange: PT.func.isRequired,
      sortChange: PT.func.isRequired,
      getOptions: PT.func.isRequired,
      sidebarToggle: PT.func.isRequired,
    }),
    state: PT.shape({
      apps: PT.array.isRequired,
      liveAppIds: PT.array,
      error: PT.shape({}),
      loading: PT.bool,
      filters: PT.shape({
        user: PT.array.isRequired,
        search: PT.string.isRequired,
        sort: PT.string.isRequired,
      }).isRequired,
      pagination: PT.shape({
        pageNumber: PT.number.isRequired,
        pageSize: PT.number.isRequired,
        total: PT.number.isRequired,
        totalPages: PT.number.isRequired,
      }).isRequired,
      sidebarOpen: PT.bool,
    }),
  }),
};

Index.defaultProps = {
  developersIndexStore: {
    state: {
      loading: false,
      sidebarOpen: false,
    },
  },
};

export default Index;
