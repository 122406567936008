export const debounce = (func, wait) => {
  if (!wait) {
    return func;
  }
  let timeOut;
  return (...args) => {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      func(...args);
    }, wait);
  };
};

export default {
  debounce,
};
