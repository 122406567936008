import React from 'react';
import PT from 'prop-types';
import {
  Box,
  ErrorBanner,
  Banner,
  useI18nContext,
  Grid,
} from '@procore/core-react';
import Form from '@/react/shared/form';
import { getIsDisabledFunc } from './permissionUtil';
import FormFooter from './FormFooter';

const AppSettingsForm = ({
  canEdit,
  onSave,
  fields,
  onChange,
  appFieldPermissions,
  error,
}) => {
  const I18n = useI18nContext();
  const _isDisabled = getIsDisabledFunc(canEdit, appFieldPermissions);
  const onFieldChange = (field, value) => onChange(field, value, 'settings');

  return (
    <>
      <Box padding="xl xl none xl">
        <h2>{I18n.t('appSettings')}</h2>
      </Box>
      {error && (
        <Box padding="none xl xl xl">
          <Form.Row>
            <ErrorBanner>
              <Banner.Content>
                <Banner.Title>{I18n.t('errorOccurred')}</Banner.Title>
                <Banner.Body>{error}</Banner.Body>
              </Banner.Content>
            </ErrorBanner>
          </Form.Row>
        </Box>
      )}

      <Box display="block" padding="none xl xl xl">
        <Form>
          <Form.Content>
            <Grid gutterX="lg" gutterY="lg" style={{ width: '100%' }}>
              <Grid.Row>
                <Grid.Col colWidth={6}>
                  <Form.Field
                    required
                    data-qa="developer-app-name"
                    data-testid="developer-app-name"
                    disabled={_isDisabled('internal_name')}
                    label={I18n.t('labels.appName')}
                    type="text"
                    value={fields.internal_name}
                    onChange={(e) =>
                      onFieldChange('internal_name', e.target.value)
                    }
                  />
                </Grid.Col>
                <Grid.Col colWidth={6}>
                  <Form.Dropzone
                    files={
                      fields.thumbnail
                        ? [
                            {
                              url: fields.thumbnail.preview,
                              type: 'Logo',
                              name: 'Thumbnail',
                            },
                          ]
                        : []
                    }
                    multiple={false}
                    disabled={_isDisabled('thumbnail')}
                    label={I18n.t('labels.appAvatar')}
                    data-qa="developer-app-logo"
                    data-testid="developer-app-logo"
                    onDrop={(accepted) => {
                      onFieldChange('thumbnail', accepted[0]);
                    }}
                    removeFile={() => {
                      onFieldChange('remove_thumbnail', true);
                      onFieldChange('thumbnail', undefined);
                    }}
                    acceptedTypes={['image/jpeg', 'image/png']}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Form.Content>
        </Form>
      </Box>
      <FormFooter
        buttonQaTag="update-app-settings-button"
        saveDisabled={!canEdit || !fields.__modified__}
        onSaveClick={onSave}
      />
    </>
  );
};

AppSettingsForm.propTypes = {
  onSave: PT.func.isRequired,
  canEdit: PT.bool.isRequired,
  onChange: PT.func.isRequired,
  fields: PT.shape({}).isRequired,
  appFieldPermissions: PT.shape({}).isRequired,
  error: PT.string,
};

AppSettingsForm.defaultProps = {
  error: '',
};

export default AppSettingsForm;
