import qs from 'qs';
import { DEFAULT_PAGE, PER_PAGE } from '../constants/pagination';

export function getParams() {
  const url = window.location.href.split('?');
  const params = qs.parse(url[1]);

  params.per_page = params.per_page || PER_PAGE;
  params.page = params.page || DEFAULT_PAGE;

  return params;
}

export function setParams(newParams, updateUrl) {
  const url = window.location.href.split('?');
  const params = qs.parse(url[1]);

  const updated = { ...params, ...newParams };

  if (updated.filters === undefined) {
    updated.filters = {};
  }

  updated.page = updated.page || 1;
  updated.per_page = updated.per_page || 50;

  const stringified = qs.stringify(updated, {
    arrayFormat: 'brackets',
    encode: false,
  });

  if (updateUrl !== false) {
    window.history.pushState(
      window.history.state,
      '',
      `${url[0]}?${stringified}`
    );
  }

  return updated;
}
