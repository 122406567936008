import React, { useEffect } from 'react';
import PT from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Button,
  DetailPage,
  Flex,
  Page,
  Sidebar,
  Spinner,
  Toast,
  ToolHeader,
  useI18nContext,
} from '@procore/core-react';

const IndexPage = ({ adminNotificationsUrl, notificationsStore }) => {
  const I18n = useI18nContext();
  useEffect(() => {
    notificationsStore.handlers.fetchAll();
  }, []);
  const { state } = notificationsStore;
  return (
    <DetailPage width="xl" initialIsVisible className="full-screen">
      <DetailPage.Main className="page-body">
        <DetailPage.Breadcrumbs>
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumbs.Crumb>{I18n.t('page.admin')}</Breadcrumbs.Crumb>
            <Breadcrumbs.Crumb active>
              {I18n.t('page.notifications')}
            </Breadcrumbs.Crumb>
          </Breadcrumbs>
        </DetailPage.Breadcrumbs>

        <ToolHeader>
          <ToolHeader.Title>{I18n.t('page.notifications')}</ToolHeader.Title>
        </ToolHeader>

        <DetailPage.Body className="full-screen">
          <DetailPage.Card>
            <DetailPage.Section>
              <Spinner loading={state.loading}>
                <Body
                  I18n={I18n}
                  adminNotificationsUrl={adminNotificationsUrl}
                  notifications={state.notifications}
                  error={state.error}
                />
              </Spinner>
            </DetailPage.Section>
          </DetailPage.Card>
        </DetailPage.Body>
      </DetailPage.Main>

      <DetailPage.Aside open className="sidebar">
        <Sidebar>
          <Sidebar.Button key="create-button">
            <Button
              key="create-button-elem"
              data-qa="create-notification-button"
              variant="primary"
              block
              size="lg"
              onClick={() => {
                window.location = `${adminNotificationsUrl}/new`;
              }}
            >
              <i className="ci ci-create" />{' '}
              {I18n.t('page.createNotification')}
            </Button>
          </Sidebar.Button>
          <Sidebar.Divider />
        </Sidebar>
      </DetailPage.Aside>
    </DetailPage>
  );
};

const Body = ({ I18n, notifications = [], error, adminNotificationsUrl }) => {
  if (error) {
    return (
      <Flex direction="row" alignItems="center" justifyContent="center">
        <Toast variant="error">{I18n.t('page.errorOccurred')}</Toast>
      </Flex>
    );
  }

  const getEditUrl = (id) => `${adminNotificationsUrl}/${id}/edit`;

  return (
    <Box>
      <table className="table table--sortable">
        <thead>
          <tr>
            <th>{I18n.t('page.title')}</th>
            <th>{I18n.t('page.summary')}</th>
            <th width="8%">{I18n.t('page.visible')}</th>
            <th width="8%">{I18n.t('page.critical')}</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((n) => (
            <TableRow key={n.id} editUrl={getEditUrl(n.id)} {...n} />
          ))}
        </tbody>
      </table>
    </Box>
  );
};

const TableRow = ({
  title,
  summary,
  visible,
  critical,
  href,
  editUrl,
  draft,
  visible_by_show_after,
}) => {
  const getCheckedIcon = (checked) => (checked ? '✅' : '❌');
  const isVisible = (visible, draft, visible_by_show_after) =>
    visible && !draft && visible_by_show_after;

  return (
    <tr>
      <td>
        <a href={editUrl}>{title}</a>
      </td>
      <td>{summary}</td>
      <td>
        {getCheckedIcon(isVisible(visible, draft, visible_by_show_after))}
      </td>
      <td>{getCheckedIcon(critical)}</td>
    </tr>
  );
};

IndexPage.propTypes = {
  I18n: PT.shape({ t: PT.func }),
  filters: PT.shape({}),
  adminNotificationsUrl: PT.string,
  notificationsStore: PT.shape({
    state: PT.shape({
      notifications: PT.arrayOf(PT.shape({})),
      error: PT.instanceOf(Error),
      loading: PT.bool,
      notificationsAPIBaseUrl: PT.string,
    }),
    handlers: PT.shape({
      fetch: PT.func,
    }),
  }).isRequired,
};

IndexPage.defaultProps = {
  I18n: { t: () => {} },
};

export default IndexPage;
