import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { H2, TextEditor, Title, Box, useI18nContext, } from '@procore/core-react';
import { NotepadPencil } from '@procore/core-icons';

const AdminMessageForm = ({
  adminMessage = '',
  onChange,
  isConcierge = false,
}) => {
  const I18n = useI18nContext();
  const [localAdminMessage, setAdminMessage] = useState(adminMessage);
  const [timeoutHandler, setTimeoutHandler] = useState(null);

  useEffect(() => {
    setAdminMessage((oldVal) => {
      if (oldVal === null) return adminMessage;
      return oldVal;
    });
  }, [adminMessage]);

  useEffect(() => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
    const handler = setTimeout(() => {
      onChange('admin_message', localAdminMessage);
    }, 200);
    setTimeoutHandler(handler);
    return () => {
      clearTimeout(handler);
    };
  }, [localAdminMessage]);

  return (
    <>
      <Form>
        <Form.Content>
          <Form.Column>
            <Box paddingBottom='md'>
              <Title>
                <Title.Assets>
                  <NotepadPencil />
                </Title.Assets>
                <Title.Text>
                  <H2>{I18n.t('labels.marketplaceApp.adminMessage')}</H2>
                </Title.Text>
                <Title.Subtext>
                  {I18n.t('labels.marketplaceApp.info.adminMessage')}
                </Title.Subtext>
              </Title>
            </Box>
            <TextEditor
              data-testid="marketplace-admin-message"
              data-qa="marketplace-admin-message"
              type="text"
              value={localAdminMessage}
              onChange={(val) => setAdminMessage(val)}
            />
            <Form.Checkbox
              data-qa="marketplace-concierge-app"
              data-testid="marketplace-concierge-app"
              label={I18n.t('labels.marketplaceApp.showInConcierge')}
              checked={isConcierge}
              name="Is Concierge App"
              onClick={(e) => onChange('is_concierge_app', e.target.checked)}
            />
          </Form.Column>
        </Form.Content>
      </Form>
    </>
  );
};

AdminMessageForm.propTypes = {
  adminMessage: PT.string,
  onChange: PT.func.isRequired,
  isConcierge: PT.bool,
};

export default AdminMessageForm;
