export const MANIFEST_STATES = {
  CONFIDENTIAL: 'confidential',
  IN_REVIEW: 'in_review',
  PUBLISHED: 'published',
};

export const REVIEW_NEXT_STATE_MAPPINGS = {
  submit: MANIFEST_STATES.IN_REVIEW,
  withdraw: MANIFEST_STATES.CONFIDENTIAL,
  reject: MANIFEST_STATES.CONFIDENTIAL,
  approve: MANIFEST_STATES.PUBLISHED,
};
