import React, { useState } from 'react';
import {
  Button,
  useI18nContext,
  Flex,
  Box,
  Pill,
  Typography,
} from '@procore/core-react';
import { ViewManifestModal } from '../../modals/ViewManifestModal';

export const HEADER_STATES = {
  DRAFT: { text: 'configurationsTab.header.draft', color: 'blue' },
  READY_FOR_TESTING: {
    text: 'configurationsTab.header.readyForTesting',
    color: 'magenta',
  },
  PROMOTED: { text: 'configurationsTab.header.promoted', color: 'green' },
  UNDER_REVIEW: {
    text: 'configurationsTab.header.underReview',
    color: 'yellow',
  },
  APPROVED: {
    text: 'configurationsTab.header.approvedVersion',
    color: 'green',
  },
  UNKOWN_STATE: { text: 'configurationsTab.header.unknownState', color: 'red' },
};

export const getDisplayState = (sandboxManifest = null, productionManifest = null) => {
  if (!sandboxManifest) {
    return HEADER_STATES.DRAFT;
  }
  if (
    sandboxManifest?.version > productionManifest?.version ||
    (sandboxManifest && !productionManifest)
  ) {
    return HEADER_STATES.READY_FOR_TESTING;
  }
  if (sandboxManifest.version === productionManifest.version) {
    switch (productionManifest.state) {
      case 'confidential':
        return HEADER_STATES.PROMOTED;
      case 'published':
        return HEADER_STATES.APPROVED;
      case 'in_review':
        return HEADER_STATES.UNDER_REVIEW;
      default:
        return HEADER_STATES.UNKOWN_STATE;
    }
  }
  return HEADER_STATES.UNKOWN_STATE;
};

export const _manifestToString = (I18n, manifest = {}) => {
  let manifestString;
  try {
    manifestString = JSON.stringify(manifest, null, 2);
  } catch (e) {
    manifestString = I18n.t('jsonParseError');
  }
  return manifestString;
};

export const ConfigurationsHeader = ({
  sandboxManifest = null,
  productionManifest = null,
  onSave,
  onPromote,
  reviewSubmitComponent,
  promotionDisabled,
  loading,
  saveDisabled = false
}) => {
  const I18n = useI18nContext();

  const displayStatus = getDisplayState(sandboxManifest, productionManifest);
  let titleText = I18n.t('configurationsTab.header.newVersion');
  if (displayStatus !== HEADER_STATES.DRAFT) {
    titleText = I18n.t('configurationsTab.header.version', {
      version: sandboxManifest.semantic_version,
    });
  }

  let saveText = I18n.t('configurationsTab.header.saveVersion');
  if (displayStatus === HEADER_STATES.DRAFT) {
    saveText = I18n.t('configurationsTab.header.createVersion');
  }

  const displayReviewButton =
    displayStatus === HEADER_STATES.PROMOTED ||
    displayStatus === HEADER_STATES.UNDER_REVIEW;

  const [manifestVisible, setManifestVisible] = useState(false);

  return (
    <Flex padding="lg none" justifyContent="space-between">
      {!loading && (
        <>
          <Flex gap="md" alignItems="center">
            <Typography intent="h1">{titleText}</Typography>
            <Pill color={displayStatus.color}>
              {I18n.t(displayStatus.text)}
            </Pill>
          </Flex>
          <Flex>
            {displayStatus !== HEADER_STATES.DRAFT && (
              <>
                <Button
                  variant="tertiary"
                  onClick={() => setManifestVisible(true)}
                  data-testid="view-manifest-button"
                >
                  {I18n.t('configurationsTab.header.viewManifest')}
                </Button>
                <ViewManifestModal
                  version={sandboxManifest.semantic_version}
                  manifest={_manifestToString(I18n, sandboxManifest.content)}
                  isOpen={manifestVisible}
                  close={() => { setManifestVisible(false) }}
                />
              </>
            )}
            {displayReviewButton && reviewSubmitComponent}
            {displayStatus === HEADER_STATES.READY_FOR_TESTING && (
              <Box padding="none lg none lg">
                <Button
                  display="inline-block"
                  disabled={promotionDisabled}
                  onClick={onPromote}
                >
                  {I18n.t('configurationsTab.promote')}
                </Button>
              </Box>
            )}
            <Box padding="none">
              <Button
                disabled={saveDisabled}
                display="inline-block"
                data-testid="save-button"
                onClick={onSave}
              >
                {saveText}
              </Button>
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
};
