import React, { useEffect } from 'react';
import Card from '@/react/shared/card/Card';
import CardBlock from '@/react/shared/card/CardBlock';
import PT from 'prop-types';
import StringUtil from '@/utils/stringUtil';
import Notification from '../Notification';
import cx from 'classnames';
import { useI18nContext } from '@procore/core-react';

const ListPage = ({
  loadNotificationsOnMount,
  notificationsStore,
  notificationsBaseUrl,
}) => {
  const I18n = useI18nContext();

  useEffect(() => {
    const store = notificationsStore;
    if (loadNotificationsOnMount) {
      store.handlers.fetch();
    }
  }, []);

  const getTruncatedSummary = (notification) =>
    StringUtil.truncate(notification.summary, { length: 160 });

  const { state } = notificationsStore;
  const { notifications, loading } = state;

  let body;
  if (loading) {
    body = (
      <Card>
        <CardBlock>
          <h1>{I18n.t('loadingNotifications')}</h1>
        </CardBlock>
      </Card>
    );
  } else if (notifications && notifications.length > 0) {
    body = notifications.map((n, i) => {
      const classes = cx({
        'a-card--unread': !n['read_at'],
        'a-card-block--margin-after': i === notifications.length - 1,
      });

      return (
        <a
          href={`${notificationsBaseUrl}/${n.id}`}
          key={n.id}
          className="dev-notification"
        >
          <Notification
            hasDivider
            className={classes}
            title={n.title}
            timestamp={n.created_at}
            markdown={getTruncatedSummary(n)}
            footer={
              <span className="dev-notification-read-more">
                {I18n.t('readMore')}
              </span>
            }
          />
        </a>
      );
    });
  } else {
    body = (
      <Card>
        <CardBlock>
          <h1>{I18n.t('noNotifications')}</h1>
          <strong>{I18n.t('noNotificationsSubtitle')}</strong>
        </CardBlock>
      </Card>
    );
  }

  return (
    <div className="notificications-list-page">
      <div className="margin-y-md">
        <h1>{I18n.t('notifications')}</h1>
      </div>
      {body}
    </div>
  );
};

ListPage.propTypes = {
  notificationsStore: PT.shape({
    state: PT.shape({
      loading: PT.bool,
      error: PT.instanceOf(Error),
      notifications: PT.array,
    }),
    handlers: PT.shape({
      fetch: PT.func,
    }),
  }).isRequired,
  notificationsBaseUrl: PT.string.isRequired,
};

// Wrap in higher order component that handles notification state management
export default ListPage;
