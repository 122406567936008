import React from 'react';
import PT from 'prop-types';
import { AsyncPicker } from '../form/Pickers.jsx';
import Filters from './Filters.jsx';
import styles from './filter-style.module.scss';

const UserFilters = ({
  I18n,
  filters,
  handleChange,
  getUserOptions,
  onError,
}) => (
  <Filters
    searchValue={filters.search}
    handleChange={handleChange}
    additionalControls={
      <AsyncPicker
        data-qa="created-by-filter"
        name="form-field-name"
        value={filters.user}
        placeholder={I18n.t('createdBy')}
        multi={true}
        valueKey="id"
        labelKey="email"
        loadOptions={(input, callback) => {
          getUserOptions(input, callback, onError);
        }}
        onChange={(value) => {
          handleChange('user', value);
        }}
      />
    }
  />
);

Filters.propTypes = {
  filters: PT.shape({
    search: PT.string,
    sort: PT.string,
    user: PT.array,
    inReview: PT.bool,
  }),
  handleChange: PT.func.isRequired,
  getUserOptions: PT.func.isRequired,
  onError: PT.func.isRequired,
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
};

export default UserFilters;
