import React from 'react';
import PT from 'prop-types';
import { Button, ConfirmModal, Modal } from '@procore/core-react';

const SimpleConfirmModal = ({
  visibility,
  onConfirm,
  header,
  body,
  confirmText,
  cancelText,
  dataQaRoot,
}) => (
  <ConfirmModal
    open={visibility.isVisible}
    onClickOverlay={visibility.hide}
    onClose={visibility.hide}
    headline={header}
  >
    <Modal.Body>{body}</Modal.Body>

    <Modal.Footer>
      <Modal.FooterButtons>
        <Button
          variant="tertiary"
          onClick={visibility.hide}
          data-qa={`${dataQaRoot}-cancel`}
        >
          {cancelText}
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            onConfirm();
            visibility.hide();
          }}
          data-qa={`${dataQaRoot}-confirm`}
        >
          {confirmText}
        </Button>
      </Modal.FooterButtons>
    </Modal.Footer>
  </ConfirmModal>
);

SimpleConfirmModal.propTypes = {
  header: PT.string.isRequired,
  body: PT.node.isRequired,
  confirmText: PT.string.isRequired,
  cancelText: PT.string.isRequired,
  dataQaRoot: PT.string.isRequired,
  visibility: PT.shape({}).isRequired,
  onConfirm: PT.func.isRequired,
};

export default SimpleConfirmModal;
