import React from 'react';
import PT from 'prop-types';
import { path } from 'ramda';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../constants';
import ChangeIndicator from '../ChangeIndicator';
import { Box, Grid } from '@procore/core-react';

class TabProducts extends React.Component {
  renderProductTool(productTool, index) {
    const { fields, publishedFields, onFieldChange, isDisabled, isAdminView } =
      this.props;

    const onChange =
      (...fieldPath) =>
      (value) =>
        onFieldChange([MARKETPLACE_TABS.TOOLS, ...fieldPath], value);

    return (
      <Box display="block" marginBottom="lg">
        <Form.Checkbox
          key={index}
          data-qa={`marketplace-product-${index}`}
          disabled={isDisabled}
          label={
            <ChangeIndicator
              changed={
                isAdminView &&
                !!path(['product_tools', productTool.id], fields) !==
                  !!path(['product_tools', productTool.id], publishedFields)
              }
            >
              {productTool.title}
            </ChangeIndicator>
          }
          checked={fields.product_tools[productTool.id]}
          onClick={(e) =>
            onChange('product_tools', productTool.id)(e.target.checked)
          }
        />
      </Box>
    );
  }

  render() {
    const NUM_OF_COLUMNS = 3;
    const { I18n } = this.props;
    const { productTools } = this.props;

    const chunkArray = (arr, chunkSize) => {
      const chunkedArr = [];
      for (let index = 0; index < arr.length; index += chunkSize) {
        const chunk = arr.slice(index, index + chunkSize);
        chunkedArr.push(chunk);
      }
      return chunkedArr;
    };

    const toolRowsArr = chunkArray(productTools, NUM_OF_COLUMNS);

    const tableBodyRows = toolRowsArr.map((row, rowIdx) => (
      <tr key={`body-row-${rowIdx}}`}>
        {row.map((tool, colIdx) => (
          <td key={`body-column-${colIdx}`}>
            {this.renderProductTool(tool, `${colIdx}-${rowIdx}`)}
          </td>
        ))}
      </tr>
    ));

    return (
      <Grid.Row>
        <Grid.Col colWidth={12}>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                {[...Array(NUM_OF_COLUMNS)].map((_, idx) => (
                  <th key={idx} />
                ))}
              </tr>
            </thead>
            <tbody>{tableBodyRows}</tbody>
          </table>
        </Grid.Col>
      </Grid.Row>
    );
  }
}

TabProducts.propTypes = {
  I18n: PT.shape({}).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
};

export default TabProducts;
