import React from 'react';
import PT from 'prop-types';

import { Button, Modal, useI18nContext } from '@procore/core-react';

import PermissionsTable from './PermissionsTable';

const PermissionDetailsModal = ({ open, setOpen }) => {
  const I18n = useI18nContext();

  return (
    <Modal
      open={open}
      onClickOverlay={() => {
        setOpen(false);
      }}
    >
      <Modal.Header
        onClose={() => {
          setOpen(false);
        }}
      >
        {I18n.t('modals.permissionsDetails.title')}
      </Modal.Header>
      <Modal.Body>
        <PermissionsTable />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {I18n.t('modals.permissionsDetails.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PermissionDetailsModal.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
};

export default PermissionDetailsModal;
