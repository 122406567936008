import React from 'react';
import PT from 'prop-types';
import { path } from 'ramda';
import Form from '@/react/shared/form';
import {
  Flex,
  Box,
  Typography,
  RadioButton,
  Input,
  TextArea,
  Grid,
  H3,
} from '@procore/core-react';

import { Info } from '@procore/core-icons';
import { MARKETPLACE_TABS } from '../constants';
import ChangeIndicator from '../ChangeIndicator';
import { Divider } from '../utils';

const NUM_OF_COLUMNS = 1;

const chunkArray = (arr, chunkSize) => {
  const chunkedArr = [];
  for (let index = 0; index < arr.length; index += chunkSize) {
    const chunk = arr.slice(index, index + chunkSize);
    chunkedArr.push(chunk);
  }
  return chunkedArr;
};

// This sync method of having to mark specific countries as false on the front end is
// putting backend logic on the front end and makes handling the data more complex
// this really should switch to a sync model where the backend updates the db to match the incoming
// payload and not rely on the fe for keeping the data correct

const TabRegions = ({
  I18n,
  validations,
  regions,
  fields = { regions: {}, countries: {} },
  onFieldChange,
  isDisabled,
  isAdminView,
  FormLabel,
  publishedFields
}) => {
  const regionState = React.useMemo(
    () =>
      regions.map((region) => {
        region.checked = !!fields.regions[region.id];
        if (region.countries.length) {
          const someCountry = region.countries.some(
            (country) => !!fields.countries[country.id]
          );
          const allCountry = region.countries.every(
            (country) => !!fields.countries[country.id]
          );
          region.indeterminate = someCountry && !allCountry;
        }
        return region;
      }),
    [fields.regions, fields.countries]
  );

  const renderRegion = (region, index) => {
    const onChange =
      (...fieldPath) =>
      (value) => {
        if (regionState[index].countries.length) {
          const selectedCountries = regionState[index].countries.reduce(
            (acc, country) => {
              acc[country.id] = value;
              return acc;
            },
            {}
          );
          onFieldChange([MARKETPLACE_TABS.REGIONS, 'countries'], {
            ...fields.countries,
            ...selectedCountries,
          });
        }
        onFieldChange([MARKETPLACE_TABS.REGIONS, ...fieldPath], value);
      };

    return (
      <Form.Checkbox
        key={region.id}
        data-qa={`marketplace-region-${index}`}
        data-testid={`marketplace-region-${index}`}
        disabled={isDisabled}
        label={
          <ChangeIndicator
            changed={
              isAdminView &&
              !!path(['regions', region.id], fields) !==
                !!path(['regions', region.id], publishedFields)
            }
          >
            {region.name}
          </ChangeIndicator>
        }
        checked={region.checked}
        onClick={(e) => onChange('regions', region.id)(e.target.checked)}
        indeterminate={region.indeterminate}
      />
    );
  };

  const renderCountry = (country, index, isChecked = false) => {
    const onChange =
      (...fieldPath) =>
      (value) =>
        onFieldChange([MARKETPLACE_TABS.REGIONS, ...fieldPath], value);

    return (
      <Box display="block" marginBottom="lg">
        <Form.Checkbox
          key={country.id}
          data-qa={`marketplace-country-${index}`}
          data-testid={`marketplace-country-${index}`}
          disabled={isDisabled}
          label={
            <ChangeIndicator
              changed={
                isAdminView &&
                !!path(['countries', country.id], fields) !==
                  !!path(['countries', country.id], publishedFields)
              }
            >
              {country.name}
            </ChangeIndicator>
          }
          checked={isChecked}
          onClick={(e) => onChange('countries', country.id)(e.target.checked)}
        />
      </Box>
    );
  };

  // on select all, set all regions to true and all current countries to false
  const onSelectAll = (setAll) => {
    if (setAll) {
      const allRegions = regions.reduce((acc, region) => {
        acc[region.id] = setAll;
        return acc;
      }, {});
      const allCountries = regions.reduce((acc, region) => {
        region.countries.forEach((country) => {
          acc[country.id] = setAll;
        });
        return acc;
      }, {});
      onFieldChange([MARKETPLACE_TABS.REGIONS, 'regions'], allRegions);
      onFieldChange([MARKETPLACE_TABS.REGIONS, 'countries'], allCountries);
    }

    setSelectAll(setAll);
  };

  // Regions are a parent of countries but some regions don't have underlining countries
  // so we should only check for if all regions are selected here
  // The below can be set to true, false, or null (if no regions or countries have been selected)
  // NOTE: revisit our country/region model as both Canada and USA are regions instead of countries
  // which is a business logic decision that confuses the data model
  const [selectAll, setSelectAll] = React.useState(() => {
    if (
      Object.keys(fields.regions).length === 0 &&
      Object.keys(fields.countries).length === 0
    ) {
      return null;
    }
    const allSelectedRegions = Object.values(regions).filter(
      (region) => fields.regions[region.id]
    );
    const allCountries = regions.reduce((acc, region) => {
      return [...acc, ...region.countries];
    }, []);
    const allSelectedCountries = Object.values(allCountries).filter(
      (country) => fields.countries[country.id]
    );
    return (
      allSelectedRegions.length === regions.length &&
      allCountries.length === allSelectedCountries.length
    );
  });

  return (
    <div>
      <Grid.Row>
        <Grid.Col>
          <H3>{I18n.t('labels.marketplaceApp.pricing.subheader')}</H3>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col data-testid="marketplace-pricing-subscription-required">
          <Form.FieldWrapper
            required
            label={
              <FormLabel
                changeFields={['subscription_required']}
                contentI18nTag="subscription_required_question"
                showInfo={false}
              />
            }
          >
            <RadioButton
              name="subscription"
              data-testid="subscription-button-yes"
              error={validations?.subscription_required?.length}
              checked={fields.subscription_required}
              disabled={isDisabled}
              onChange={() => {
                onFieldChange(
                  [MARKETPLACE_TABS.REGIONS, 'subscription_required'],
                  true
                );
              }}
            >
              <Typography intent="body" weight="regular">
                {I18n.t(
                  'labels.marketplaceApp.pricing.subscription_required_response.yes'
                )}
              </Typography>
            </RadioButton>
            <RadioButton
              name="subscription"
              data-testid="subscription-button-no"
              checked={fields.subscription_required === false}
              error={validations?.subscription_required?.length}
              disabled={isDisabled}
              onChange={() => {
                onFieldChange(
                  [MARKETPLACE_TABS.REGIONS, 'subscription_required'],
                  false
                );
              }}
            >
              <Typography intent="body" weight="regular">
                {I18n.t(
                  'labels.marketplaceApp.pricing.subscription_required_response.no'
                )}
              </Typography>
            </RadioButton>
          </Form.FieldWrapper>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col data-testid="marketplace-pricing-connector-required">
          <Form.FieldWrapper
            required
            label={
              <FormLabel
                changeFields={['connector_required']}
                contentI18nTag="third_party_connector_required_question"
                showInfo={false}
              />
            }
          >
            <RadioButton
              name="connector"
              data-testid="connector-button-yes"
              checked={fields.connector_required}
              error={validations?.connector_required?.length}
              disabled={isDisabled}
              onChange={() => {
                onFieldChange(
                  [MARKETPLACE_TABS.REGIONS, 'connector_required'],
                  true
                );
              }}
            >
              <Typography intent="body" weight="regular">
                {I18n.t(
                  'labels.marketplaceApp.pricing.third_party_connector_required_response.yes'
                )}
              </Typography>
            </RadioButton>
            <RadioButton
              name="connector"
              data-testid="connector-button-no"
              checked={fields.connector_required === false}
              error={validations?.connector_required?.length}
              disabled={isDisabled}
              onChange={() => {
                onFieldChange(
                  [MARKETPLACE_TABS.REGIONS, 'connector_required'],
                  false
                );
              }}
            >
              <Typography intent="body" weight="regular">
                {I18n.t(
                  'labels.marketplaceApp.pricing.third_party_connector_required_response.no'
                )}
              </Typography>
            </RadioButton>
          </Form.FieldWrapper>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col data-testid="marketplace-pricing-free-trial">
          <Form.FieldWrapper
            required
            label={
              <FormLabel
                changeFields={['has_trials']}
                contentI18nTag="free_trial_question"
                showInfo={false}
              />
            }
          >
            <RadioButton
              name="free_trials"
              data-testid="trials-button-yes"
              checked={fields.has_trials}
              error={validations?.has_trials?.length}
              disabled={isDisabled}
              onChange={() => {
                onFieldChange([MARKETPLACE_TABS.REGIONS, 'has_trials'], true);
              }}
            >
              <Typography intent="body" weight="regular">
                {I18n.t(
                  'labels.marketplaceApp.pricing.free_trial_response.yes'
                )}
              </Typography>
            </RadioButton>
            <RadioButton
              name="free_trials"
              data-testid="trials-button-no"
              checked={fields.has_trials === false}
              error={validations?.has_trials?.length}
              disabled={isDisabled}
              onChange={() => {
                onFieldChange([MARKETPLACE_TABS.REGIONS, 'has_trials'], false);
              }}
            >
              <Typography intent="body" weight="regular">
                {I18n.t('labels.marketplaceApp.pricing.free_trial_response.no')}
              </Typography>
            </RadioButton>
          </Form.FieldWrapper>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={6} data-testid="marketplace-pricing-url">
          <Form.FieldWrapper
            errors={validations.pricing_url}
            label={
              <FormLabel
                changeFields={['has_trials']}
                contentI18nTag="pricing_link"
              />
            }
          >
            <Input
              data-testid="pricing-url"
              placeholder={I18n.t(
                'labels.marketplaceApp.pricing.pricing_link.placeHolder'
              )}
              disabled={isDisabled}
              error={validations?.pricing_url?.length}
              value={fields.pricing_url}
              onChange={(e) =>
                onFieldChange(
                  [MARKETPLACE_TABS.REGIONS, 'pricing_url'],
                  e.target.value
                )
              }
            />
          </Form.FieldWrapper>
        </Grid.Col>
        <Grid.Col colWidth={6} data-testid="marketplace-pricing-description">
          <Form.FieldWrapper
            errors={validations.pricing_description}
            required
            label={
              <FormLabel
                changeFields={['has_trials']}
                contentI18nTag="pricing_description"
              />
            }
          >
            <TextArea
              data-testid="pricing-description"
              placeholder={I18n.t(
                'labels.marketplaceApp.pricing.pricing_description.placeHolder'
              )}
              error={validations?.pricing_description?.length}
              value={fields.pricing_description}
              disabled={isDisabled}
              onChange={(e) =>
                onFieldChange(
                  [MARKETPLACE_TABS.REGIONS, 'pricing_description'],
                  e.target.value
                )
              }
            />
          </Form.FieldWrapper>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col>
          <Divider />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col colWidth={12}>
          <H3>{I18n.t('labels.marketplaceApp.availability.title')}</H3>
        </Grid.Col>

        <Grid.Col colWidth={12} data-testid="marketplace-select-all-region">
          <Form.FieldWrapper
            errors={selectAll === false && validations.region_or_country}
            required
            label={
              <FormLabel
                changeFields={['countries', 'regions']}
                contentI18nTag="availability_label"
                showInfo={false}
              />
            }
          >
            <RadioButton
              data-testid="region-select-all-true"
              data-qa="region-select-all-true"
              checked={selectAll}
              disabled={isDisabled}
              error={validations?.region_or_country?.length}
              onChange={() => {
                onSelectAll(true);
              }}
            >
              <Flex direction="column">
                <Typography intent="body">
                  {I18n.t('labels.marketplaceApp.availability.allRegions')}
                </Typography>
                <Typography intent="body">
                  {I18n.t(
                    'labels.marketplaceApp.availability.allRegionsDescription'
                  )}
                </Typography>
              </Flex>
            </RadioButton>
            <RadioButton
              data-testid="region-select-all-false"
              data-qa="region-select-all-false"
              error={validations?.region_or_country?.length}
              disabled={isDisabled}
              checked={selectAll === false} // needs to explicitly check false due to null state
              onChange={() => {
                onSelectAll(false);
              }}
            >
              <Flex direction="column">
                <Typography intent="body">
                  {I18n.t('labels.marketplaceApp.availability.specificRegions')}
                </Typography>
                <Typography intent="body">
                  {I18n.t(
                    'labels.marketplaceApp.availability.specificRegionsDescription'
                  )}
                </Typography>
              </Flex>
            </RadioButton>
          </Form.FieldWrapper>
        </Grid.Col>
      </Grid.Row>

      {selectAll === false && (
        <Grid.Row>
          <Grid.Col colWidth={12}>
            {regionState.map((region, regionIdx) => (
              <div
                key={`body-region-${regionIdx}`}
                style={{
                  borderBottom: '1px solid #e5e7e8',
                  width: '100%',
                  paddingTop: '10px',
                  paddingBottom: '20px',
                }}
              >
                <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                  {renderRegion(region, regionIdx)}
                </div>
                <table style={{ width: '95%', marginLeft: '5%' }}>
                  <thead>
                    <tr>
                      {[...Array(NUM_OF_COLUMNS)].map((_, idx) => (
                        <th
                          style={{ width: '30%' }}
                          key={`region-${regionIdx}-${idx}`}
                        />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {chunkArray(region.countries, 3).map((row, rowIdx) => (
                      <tr key={`body-row-${rowIdx}}`}>
                        {row.map((country, colIdx) => (
                          <td
                            style={{ width: '30%' }}
                            key={`body-column-${colIdx}`}
                          >
                            {renderCountry(
                              country,
                              rowIdx * 3 + colIdx,
                              fields.countries[country.id]
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </Grid.Col>

          <Grid.Col colWidth={12}>
            <div>
              <strong>Don’t see your country?</strong>&nbsp;&nbsp;
              <a href="mailto:marketplaceqa@procore.com">
                Contact us to add it.
              </a>
            </div>
          </Grid.Col>
        </Grid.Row>
      )}
    </div>
  );
};

TabRegions.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({
    countries: PT.shape({}),
    regions: PT.shape({}),
    pricing_description: PT.string,
    pricing_url: PT.string,
    has_trials: PT.bool,
    connector_required: PT.bool,
    subscription_required: PT.bool,
  }).isRequired,
  publishedFields: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  regions: PT.arrayOf(PT.shape({})),
  validations: PT.shape({
    countries: PT.array,
    regions: PT.array,
    pricing_description: PT.array,
    pricing_url: PT.array,
    has_trials: PT.array,
    connector_required: PT.array,
    subscription_required: PT.array,
    region_or_country: PT.array,
  }),
  FormLabel: PT.node,
};

export default TabRegions;
