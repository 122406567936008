import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { Flex, Typeahead, useI18nContext } from '@procore/core-react';
import styles from './filter-style.module.scss';

const Filters = ({ searchValue = '', handleChange, additionalControls, placeholder = 'search' }) => {
  const [localSearchVal, setlocalVal] = useState(searchValue);
  useEffect(() => {
    handleChange('search', localSearchVal);
  }, [localSearchVal]);
  
  const I18n = useI18nContext();


  return (
    <Flex
      className="app-filters"
      direction="row"
      alignItems="flex-start"
      basis="auto"
    >
      <Typeahead
        className={styles.devPortalTypeahead}
        onChange={(newVal) => {
          setlocalVal(newVal);
        }}
        value={localSearchVal}
        placeholder={I18n.t(placeholder)}
      />
      {additionalControls} 
    </Flex>
  );
};

Filters.propTypes = {
  searchValue: PT.string,
  handleChange: PT.func.isRequired,
  additionalControls: PT.node,
  placeholder: PT.string,
};

export default Filters;
