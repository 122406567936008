const truncate = (text, options = {}) => {
  text = text || '';
  const truncateAtCharNum = options.length || 0;
  const omission = options.omission || '...';

  const textLength = text.length;
  if (textLength <= truncateAtCharNum) {
    return text;
  }
  const lengthWithRoomForOmission = truncateAtCharNum - omission.length;
  return `${text.substr(0, lengthWithRoomForOmission)}${omission}`;
};

export const regExpEscape = (text) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const toParam = (thing = '') =>
  String(thing)
    .toLowerCase()
    .trim()
    .replace(/ /g, '-')
    .replace(/\//g, '-')
    .replace(/\.|\(|\)|'|"/g, '');

export default {
  regExpEscape,
  toParam,
  truncate,
};
