import React from 'react';
import PT from 'prop-types';
import { Box, Modal, useI18nContext, Grid } from '@procore/core-react';
import Form from '@/react/shared/form';
import InfoPopover from '../../../shared/infoPopover/InfoPopover';
import { autoSelectCopy } from '../../../../global_helpers/autoSelectCopy';
import FormFooter from '../FormFooter';
import OAuthInfo from '../OAuthInfo';
import ResetCredentialsConfirmationModal from '../modals/ResetCredentialsConfirmationModal';

const Live = ({
  canEdit,
  onChange,
  onUpdateSandbox,
  fields,
  sandboxCredentialsDocPath,
  redirectUriDocPath,
  resetCredentialsConfirmed,
  toggleResetCredentialsConfirmed,
  resetAppSecret,
  resetAppSecretSuccess,
  onError,
  hasSandboxManifest,
}) => {
  const I18n = useI18nContext();
  const onFieldChange = (field, value) => onChange(field, value, 'sandbox');
  const [resetCredModalOpen, setResetCredModalOpen] = React.useState(false);

  return (
    <>
      <Box padding="xl">
        <Grid gutterX="lg" gutterY="lg" colStackCap="mobileLg">
          <Grid.Row>
            <Grid.Col colWidth={6}>
              {fields.app_id && (
                <Box paddingBottom="lg">
                  <Form.Field
                    readOnly
                    data-qa="sandbox-app-client-id"
                    label={
                      <span>
                        {I18n.t('labels.clientId')}
                        <InfoPopover>
                          <a
                            href={sandboxCredentialsDocPath}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {I18n.t('sandboxClientIdHelp')}
                          </a>
                        </InfoPopover>
                      </span>
                    }
                    type="text"
                    value={fields.app_id}
                    onClick={autoSelectCopy}
                  />
                </Box>
              )}
              <Box paddingBottom="lg">
                <Form.Field
                  readOnly
                  data-qa="sandbox-app-url"
                  label={I18n.t('labels.sandboxUrl')}
                  type="text"
                  value={fields.url}
                  onClick={autoSelectCopy}
                />
              </Box>
              {fields.app_id && (
                <Box paddingBottom="lg">
                  <Form.Textarea
                    disabled={!canEdit}
                    data-qa="sandbox-app-redirect-uri"
                    label={
                      <span>
                        {I18n.t('labels.redirectUri')}
                        <InfoPopover>
                          <a href={redirectUriDocPath} target="_blank">
                            {I18n.t('redirectUriHelp')}
                          </a>
                        </InfoPopover>
                      </span>
                    }
                    value={fields.redirect_uri}
                    onChange={(e) =>
                      onFieldChange('redirect_uri', e.target.value)
                    }
                  />
                </Box>
              )}
              <Box padding="none lg none lg">
                <em>
                  {I18n.t('oneLineURI')}
                  <br />
                  {I18n.t('acceptableURI')}
                </em>
              </Box>
            </Grid.Col>
            <Grid.Col colWidth={6}>
              {fields.app_id && fields.confidential && (
                <Box paddingBottom="lg">
                  <Form.RevealOnHover
                    readOnly
                    label={I18n.t('labels.clientSecret')}
                    type="password"
                    variant="reveal-on-hover"
                    value={fields.app_secret || ''}
                    onClick={autoSelectCopy}
                    data-qa="sandbox-client-secret"
                  />
                </Box>
              )}
              {fields.app_secret && (
                <Box paddingBottom="lg">
                  <ResetCredentialsConfirmationModal
                    isVisible={resetCredModalOpen}
                    show={() => {
                      setResetCredModalOpen(true);
                    }}
                    hide={() => {
                      setResetCredModalOpen(false);
                    }}
                    I18n={I18n}
                    loading={false}
                    appUid={fields.app_id}
                    resetAppSecret={resetAppSecret}
                    resetAppSecretSuccess={resetAppSecretSuccess}
                    onError={onError}
                    resetCredentialsConfirmed={resetCredentialsConfirmed}
                    toggleResetCredentialsConfirmed={
                      toggleResetCredentialsConfirmed
                    }
                    canModifyCredentials
                    qaPrefix="sandbox"
                    production={false}
                  />
                </Box>
              )}
              {fields.confidential !== null && fields.grant_type && (
                <Box paddingBottom="lg">
                  <OAuthInfo I18n={I18n} fields={fields} />
                </Box>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Box>
      <FormFooter
        buttonQaTag="update-sandbox-app-credentials-button"
        saveDisabled={!canEdit || !fields.__modified__}
        onSaveClick={() => onUpdateSandbox(fields.id)}
      />
    </>
  );
};

Live.propTypes = {
  onUpdateSandbox: PT.func.isRequired,
  canEdit: PT.bool.isRequired,
  onChange: PT.func.isRequired,
  fields: PT.shape({}).isRequired,
  sandboxCredentialsDocPath: PT.string.isRequired,
  redirectUriDocPath: PT.string.isRequired,
  hasSandboxManifest: PT.bool,
};

Live.defaultProps = {
  hasSandboxManifest: true,
};

export default Live;
