import React from 'react';
import PropTypes from 'prop-types';
import TopNavOptionsLink from '../TopNavOptionsLink';
import cx from 'classnames';

class TopNavNotificationsLink extends React.Component {
  render() {
    const { numOfUnread } = this.props;

    const notificationClasses = cx('top-nav-notification', {
      'top-nav-notification--unread': numOfUnread > 0,
    });

    return (
      <TopNavOptionsLink
        image={<img alt="Bell Outline" src="/assets/images/bell-outline.svg" />}
        classes={[notificationClasses]}
      >
        <div className="top-nav-notification-count-layout">
          <div className="top-nav-notification-circle putil-relative">
            <div className="top-nav-notification-count putil-absolute putil-middle putil-center">
              {numOfUnread}
            </div>
          </div>
        </div>
      </TopNavOptionsLink>
    );
  }
}

TopNavNotificationsLink.propTypes = {
  numOfUnread: PropTypes.number,
};

export default TopNavNotificationsLink;
