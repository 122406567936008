import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import { Box, Button, Grid } from '@procore/core-react';
import { Plus, Trash } from '@procore/core-icons';
import Form from '@/react/shared/form';
import { useDynamicFields } from '@/react/hooks/useDynamicFields';
import { MARKETPLACE_TABS } from '../constants';

const MAX_LINKS = 3;
const TabLinks = ({
  fields,
  I18n,
  onFieldChange,
  isDisabled,
  FormLabel,
  validations,
}) => {
  const initialFields = fields?.helpful_links || [''];
  const fieldTemplate = { url: '', label: '' };
  const {
    fields: helpfulLinks,
    addField,
    removeField,
    updateField,
  } = useDynamicFields(initialFields, MAX_LINKS, fieldTemplate);

  return (
    <>
      {helpfulLinks.map((link, idx) => {
        const key = `link_${idx}`;
        return (
          <Grid.Row key={key}>
            <Grid.Col colWidth={12}>
              <Box display="flex-row" alignItems="flex-end">
                <Box
                  flex={12}
                  key={key}
                  display="flex-row"
                  alignItems="center"
                  gap="lg"
                >
                  <Box flex={6}>
                    <Form.Field
                      data-qa={`marketplace-app-helpful-link-label-${idx}`}
                      data-testid={`marketplace-app-helpful-link-label-${idx}`}
                      disabled={isDisabled}
                      label={
                        <FormLabel
                          changeFields={['helpful_links', idx, 'label']}
                          contentI18nTag="helpfulLinkLabel"
                          copyText={link.label}
                          showInfo={false}
                        />
                      }
                      type="text"
                      value={link.label}
                      onChange={(e) => {
                        updateField(idx, { label: e.target.value });
                        onFieldChange(
                          [
                            MARKETPLACE_TABS.LINKS,
                            'helpful_links',
                            idx,
                            'label',
                          ],
                          e.target.value
                        );
                      }}
                      placeholder={I18n.t('placeholders.helpfulLinks.label')}
                    />
                  </Box>
                  <Box flex={6}>
                    <Form.Field
                      data-qa={`marketplace-app-helpful-link-url-${idx}`}
                      data-testid={`marketplace-app-helpful-link-url-${idx}`}
                      disabled={isDisabled}
                      label={
                        <FormLabel
                          changeFields={['helpful_links', idx, 'url']}
                          contentI18nTag="helpfulLinkUrl"
                          copyText={link.url}
                          showInfo={false}
                        />
                      }
                      type="text"
                      value={link.url}
                      onChange={(e) => {
                        updateField(idx, { url: e.target.value });
                        onFieldChange(
                          [MARKETPLACE_TABS.LINKS, 'helpful_links', idx, 'url'],
                          e.target.value
                        );
                      }}
                      placeholder={I18n.t('placeholders.helpfulLinks.url')}
                    />
                  </Box>
                </Box>

                <Box marginLeft="sm">
                  {!isDisabled && (
                    <>
                      {idx === helpfulLinks.length - 1 &&
                      helpfulLinks.length < MAX_LINKS ? (
                        <Button
                          data-testid={`marketplace-app-helpful-link-add-${idx}`}
                          icon={<Plus />}
                          aria-label="Add"
                          variant="tertiary"
                          onClick={() =>
                            addField((updated) => {
                              const newIndex = idx + 1;
                              onFieldChange(
                                [
                                  MARKETPLACE_TABS.LINKS,
                                  'helpful_links',
                                  newIndex,
                                ],
                                updated[newIndex]
                              );
                            })
                          }
                        />
                      ) : (
                        <Button
                          data-testid={`marketplace-app-helpful-link-remove-${idx}`}
                          icon={
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Trash size="sm" />
                            </Box>
                          }
                          aria-label="Remove"
                          variant="tertiary"
                          onClick={() =>
                            removeField(idx, (updated) => {
                              onFieldChange(
                                [MARKETPLACE_TABS.LINKS, 'helpful_links'],
                                updated
                              );
                            })
                          }
                          disabled={isDisabled}
                        />
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Grid.Col>
          </Grid.Row>
        );
      })}
    </>
  );
};

TabLinks.propTypes = {
  fields: PT.shape({
    helpful_links: PT.arrayOf(
      PT.shape({
        label: PT.string,
        url: PT.string,
      })
    ),
  }),
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  onFieldChange: PT.func.isRequired,
  isDisabled: PT.bool,
  FormLabel: PT.elementType.isRequired,
};

export default TabLinks;
