import React from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Flex,
  FlexList,
  Modal,
  Table,
  Typography,
  useI18nContext,
  useBuffer,
} from '@procore/core-react';
import { Check } from '@procore/core-icons';
import Form from '@/react/shared/form';
import { autoSelectCopy } from '../../../global_helpers/autoSelectCopy';
import ManifestModal from '../ManifestModal';
import SimpleConfirmModal from '../../shared/SimpleConfirmModal';
import { MANIFEST_STATES, REVIEW_NEXT_STATE_MAPPINGS } from './constants';

const _manifestToString = (I18n, manifest = {}) => {
  let manifestString;
  try {
    manifestString = JSON.stringify(manifest, null, 2);
  } catch (e) {
    manifestString = I18n.t('jsonParseError');
  }
  return manifestString;
};

const ProductionManifestsTable = ({
  manifests,
  isAdmin = false,
  updateAppVersion,
  parentApp,
  prodManifestTableStore,
  canModifyManifests,
  trackEvent,
  canSetSemanticVersions,
  formBasedExperience,
  formBasedSubmitWithdrawOnly,
  currentProductionManifest,
}) => {
  const I18n = useI18nContext();
  const { state, handlers } = prodManifestTableStore;

  const clearViewingManifest = () => handlers.setViewingManifest(null);
  const clearViewingReleaseNotes = () => handlers.setViewingReleaseNotes(null);

  const sortedManifests = manifests.sort((m1, m2) => m2.version - m1.version);

  const latestManifest = sortedManifests.length > 0 ? sortedManifests[0] : null;

  let displayedManifests;
  if (state.tableExpanded) {
    displayedManifests = sortedManifests;
  } else {
    displayedManifests = latestManifest ? [latestManifest] : [];
  }

  const adminApproveRejectReview = (manifest) => {
    if (manifest.state !== MANIFEST_STATES.IN_REVIEW) {
      return submitWithdrawReview(manifest, true);
    }

    return (
      <>
        <Modal.State>
          {(visibility) =>
            updateReviewState({
              manifest,
              visibility,
              canModifyManifests,
              actionType: 'approve',
            })
          }
        </Modal.State>
        <Modal.State>
          {(visibility) =>
            updateReviewState({
              manifest,
              visibility,
              canModifyManifests,
              actionType: 'reject',
            })
          }
        </Modal.State>
      </>
    );
  };

  const submitWithdrawReview = (manifest, canModifyManifests) => (
    <Modal.State>
      {(visibility) => {
        if (manifest.submittable) {
          return updateReviewState({
            manifest,
            visibility,
            actionType: 'submit',
            canModifyManifests,
          });
        }
        if (manifest.state === MANIFEST_STATES.IN_REVIEW) {
          return updateReviewState({
            manifest,
            visibility,
            actionType: 'withdraw',
            canModifyManifests,
          });
        }
        return null;
      }}
    </Modal.State>
  );

  const updateParams = (manifest, actionType, input) => {
    const noteParams = {};
    if (actionType === 'submit') {
      noteParams.developer_notes = input;
    }
    if (actionType === 'approve' || actionType === 'reject') {
      noteParams.admin_notes = input;
    }
    return {
      ...noteParams,
      id: manifest.app_version_id,
      state: REVIEW_NEXT_STATE_MAPPINGS[actionType],
    };
  };

  const modalBody = (actionType, buffer) => {
    const inputIncluded = ['approve', 'reject', 'submit'].includes(actionType);

    return (
      <>
        {I18n.t(`${actionType}ReviewModal.body`)}
        {inputIncluded && (
          <Form>
            <Form.Content>
              <Form.Column>
                <Form.Textarea
                  data-qa={`manifest-${actionType}-notes`}
                  label={
                    <Form.PopoverLabel
                      labelContent={I18n.t(
                        `${actionType}ReviewModal.inputLabel`
                      )}
                      popoverContent={I18n.t(
                        `${actionType}ReviewModal.inputPopover`
                      )}
                    />
                  }
                  type="text"
                  value={buffer.value}
                  onChange={(e) => buffer.update(e.target.value)}
                />
              </Form.Column>
            </Form.Content>
          </Form>
        )}
      </>
    );
  };

  const simpleConfirmModalBuffer = useBuffer({});
  const simpleConfirmModalWithBuffer = ({
    manifest,
    visibility,
    actionType,
  }) => {
    return (
      <SimpleConfirmModal
        header={I18n.t(`${actionType}ReviewModal.header`)}
        body={modalBody(actionType, simpleConfirmModalBuffer)}
        cancelText={I18n.t(`${actionType}ReviewModal.cancel`)}
        confirmText={I18n.t(`${actionType}ReviewModal.confirm`)}
        dataQaRoot={`${actionType}-manifest-review`}
        visibility={visibility}
        onConfirm={() => {
          updateAppVersion(
            updateParams(manifest, actionType, simpleConfirmModalBuffer.value)
          );
          trackEvent(
            'developers.app_manifests.production_manifest.review_state_changed',
            { state: actionType }
          );
        }}
      />
    );
  };

  const updateReviewState = ({
    manifest,
    visibility,
    actionType,
    canModifyManifests,
  }) => {
    return (
      <>
        <Box padding="none lg">
          <Button
            data-qa={`manifest-${manifest.version}-${actionType}-review-button`}
            onClick={visibility.show}
            disabled={!canModifyManifests}
          >
            {I18n.t(`${actionType}Review`)}
          </Button>
        </Box>
        {simpleConfirmModalWithBuffer({ manifest, actionType, visibility })}
      </>
    );
  };

  return (
    <>
      {!formBasedSubmitWithdrawOnly && (
        <>
          <Box padding="md none">
            <FlexList size="sm">
              {manifests.length > 1 && (
                <Button
                  data-qa="show-old-manifests-button"
                  onClick={handlers.toggleTableExpanded}
                  variant="secondary"
                >
                  {state.tableExpanded
                    ? I18n.t('hideOldVersions')
                    : I18n.t('showOldVersions')}
                </Button>
              )}
            </FlexList>
          </Box>
          <Table.Container style={{ width: '100%' }}>
            <Table data-qa="apps-manifests-table">
              <Table.Header>
                <Table.HeaderRow>
                  <Table.HeaderCell data-qa="manifest-version-header" snugfit>
                    {I18n.t('version')}
                  </Table.HeaderCell>
                  <Table.HeaderCell data-qa="manifest-created-at-header">
                    {I18n.t('releasedAt')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    data-qa="manifest-app-version-id-header"
                    snugfit
                  >
                    {I18n.t('appVersionKey')}
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    data-qa="manifest-published-to-marketplace-header"
                    snugfit
                  >
                    {I18n.t('publishedToMarketplace')}
                  </Table.HeaderCell>
                  {canSetSemanticVersions && (
                    <Table.HeaderCell>
                      {I18n.t('releaseNotes')}
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell data-qa="manifest-review-header" snugfit />
                </Table.HeaderRow>
              </Table.Header>
              <Table.Body>
                {displayedManifests.map((manifest) => (
                  <Table.BodyRow key={manifest.id}>
                    <Table.BodyCell snugfit>
                      <Box padding="none lg">
                        <a
                          href="javascript:;"
                          data-qa={`manifest-${manifest.version}-view-button`}
                          onClick={() => handlers.setViewingManifest(manifest)}
                          size="sm"
                          variant="secondary"
                        >
                          {canSetSemanticVersions
                            ? manifest.semantic_version
                            : manifest.version}
                        </a>
                      </Box>
                    </Table.BodyCell>
                    <Table.BodyCell snugfit>
                      <Table.TextCell>{manifest.created_at}</Table.TextCell>
                    </Table.BodyCell>
                    <Table.BodyCell snugfit>
                      <Form.Field
                        readOnly
                        type="text"
                        style={{ padding: '6px', width: '100%' }}
                        data-qa={`manifest-${manifest.version}-app-version-key`}
                        value={manifest.app_version_id}
                        onClick={autoSelectCopy}
                      />
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Box padding="none lg">
                        {manifest.published_to_marketplace ? (
                          <Check size="sm" />
                        ) : null}
                      </Box>
                    </Table.BodyCell>
                    {canSetSemanticVersions && (
                      <Table.BodyCell snugfit>
                        <a
                          href="javascript:;"
                          data-qa={`manifest-${manifest.version}-view-release-notes-button`}
                          onClick={() =>
                            handlers.setViewingReleaseNotes(manifest)
                          }
                          size="sm"
                          variant="secondary"
                        >
                          {I18n.t('view')}
                        </a>
                      </Table.BodyCell>
                    )}
                    {!formBasedExperience && (
                      <Table.BodyCell snugfit>
                        <Flex justifyContent="center">
                          {isAdmin
                            ? adminApproveRejectReview(manifest)
                            : submitWithdrawReview(
                                manifest,
                                canModifyManifests
                              )}
                        </Flex>
                      </Table.BodyCell>
                    )}
                  </Table.BodyRow>
                ))}
              </Table.Body>
            </Table>
          </Table.Container>
          {state.viewingManifest && (
            <ManifestModal
              viewOnly
              parentApp={parentApp}
              onClose={clearViewingManifest}
              manifest={_manifestToString(I18n, state.viewingManifest.content)}
            />
          )}
          {state.viewingReleaseNotes && (
            <Modal open onClickOverlay={clearViewingReleaseNotes}>
              <Modal.Header>{I18n.t('releaseNotes')}</Modal.Header>
              <Modal.Body width="300px">
                <FlexList justifyContent="space-between">
                  <Typography intent="h3">
                    {state.viewingReleaseNotes.semantic_version}
                  </Typography>
                  <>{state.viewingReleaseNotes.created_at}</>
                </FlexList>
                <Box
                  style={{ 'white-space': 'pre-line' }}
                  paddingTop="sm"
                  width="500px"
                >
                  {state.viewingReleaseNotes.changelog}
                </Box>
              </Modal.Body>
              <Modal.Footer>
                <Modal.FooterButtons>
                  <Button onClick={clearViewingReleaseNotes}>
                    {I18n.t('close')}
                  </Button>
                </Modal.FooterButtons>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}

      {formBasedSubmitWithdrawOnly &&
        submitWithdrawReview(currentProductionManifest, canModifyManifests)}
    </>
  );
};

ProductionManifestsTable.propTypes = {
  prodManifestTableStore: PT.shape({
    state: PT.shape({
      viewingManifest: PT.shape({}),
      tableExpanded: PT.bool,
    }),
    handlers: PT.shape({
      setViewingManifest: PT.func,
      toggleTableExpanded: PT.func,
    }),
  }).isRequired,
  manifests: PT.arrayOf(PT.shape({})),
  updateAppVersion: PT.func,
  parentApp: PT.shape({
    id: PT.string.isRequired,
    type: PT.string.isRequired,
  }).isRequired,
  canModifyManifests: PT.bool.isRequired,
  trackEvent: PT.func,
  viewingReleaesNotes: PT.shape({}),
};

ProductionManifestsTable.defaultProps = {
  manifests: [],
};

export default ProductionManifestsTable;
