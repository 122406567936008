import React from 'react';
import { I18nProvider } from '@procore/core-react';
import i18n from './i18n';
import Dropzone from './Dropzone';

export default (props) => {
  return (
    <I18nProvider locale={i18n.locale} translations={i18n.translations}>
      <Dropzone {...props} />
    </I18nProvider>
  );
};
