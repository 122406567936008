import jQuery from 'jquery';

const willOverflow = (totalSize) => (size) => (location) =>
  Number(location) + Number(size) > Number(totalSize);

const Dropdown = (($) => {
  const NAME = 'dropdown';
  const DATA_KEY = 'h.dropdown';
  const EVENT_KEY = `.${DATA_KEY}`;
  const DATA_BEHAVIOR = "[data-behavior='dropdown']";
  const OPEN_CLASS = 'dropdown--open';
  const WEST_CLASS = 'dropdown--drop-west';

  const Event = {
    CLICK: `click${EVENT_KEY}`,
  };

  class Dropdown {
    constructor(element) {
      this._element = element;
      this._addEventListeners();
    }

    determineDirection(event) {
      const triggerEl = event.currentTarget;
      const dropdownEl = triggerEl.nextSibling.nextSibling; // counts newline whitespace
      const trigger = triggerEl.getBoundingClientRect();
      const dropdown = dropdownEl.getBoundingClientRect();

      if (
        willOverflow(document.body.offsetHeight)(dropdown.height)(event.pageY)
      ) {
        dropdownEl.style.top = `${-1 * dropdown.height}px`;
      }
      if (
        willOverflow(document.body.offsetWidth)(dropdown.width)(event.pageX) &&
        event.pageX > dropdown.width
      ) {
        dropdownEl.style.left = `${-1 * (dropdown.width - trigger.width)}px`;
      }
      if (
        $(triggerEl)
          .parent()
          .hasClass(WEST_CLASS) &&
        event.pageX < dropdown.width
      ) {
        $(triggerEl)
          .parent()
          .removeClass(WEST_CLASS);
      }
    }

    toggle(event) {
      const parent = $(this).parent();
      const active = parent.hasClass(OPEN_CLASS);

      event.preventDefault();

      if (active) {
        $(parent).removeClass(OPEN_CLASS);
      } else {
        $(parent).addClass(OPEN_CLASS);
      }
    }

    _addEventListeners() {
      $(this._element).on(Event.CLICK, this.toggle);
    }

    _clearMenus(event) {
      const dropdowns = $(DATA_BEHAVIOR);

      for (let i = 0; i < dropdowns.length; i++) {
        const parent = $(dropdowns[i]).parent();

        if (
          event &&
          event.type === 'click' &&
          $.contains(parent.get(0), event.target)
        ) {
          continue;
        }

        $(parent).removeClass(OPEN_CLASS);
      }
    }

    static _jQueryInterface(config) {
      return this.each(function() {
        let data = $(this).data(DATA_KEY);

        if (!data) {
          $(this).data(DATA_KEY, (data = new Dropdown(this)));
        }

        if (typeof config === 'string') {
          if (data[config] === undefined) {
            throw new Error(`No method named "${config}"`);
          }
          data[config].call(this);
        }
      });
    }
  }

  $(document)
    .on('click', DATA_BEHAVIOR, Dropdown.prototype.toggle)
    .on('click', DATA_BEHAVIOR, Dropdown.prototype.determineDirection)
    .on('click', Dropdown.prototype._clearMenus);

  $.fn[NAME] = Dropdown._jQueryInterface;
  $.fn[NAME].Constructor = Dropdown;

  return Dropdown;
})(jQuery);
