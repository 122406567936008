import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

// Function to dynamically import and register the language
export const loadCountryLocale = async () => {
  countries.registerLocale(en.default || en);
};

export const buildCountryList = async (backendList, locale) => {
  // Check if country names are available for this locale
  let countryList = countries.getNames(locale);

  // If the locale is not yet registered or countryList is empty, load the locale
  if (!countryList || Object.keys(countryList).length === 0) {
    await loadCountryLocale();

    // Try getting the country names again after loading the locale
    countryList = countries.getNames(locale);
  }

  // Map the backend list to include the correct country names
  const formattedCountries = backendList.map(({ id, iso_code }) => {
    const countryName = countryList[iso_code] || 'Unknown Country';
    return {
      id,
      iso_code,
      name: countryName,
    };
  });

  // Return the country names for the locale, or fallback to English
  return formattedCountries;
};
