import React from 'react';
import useProviders, { useStateHook } from '@procore/react-state-patterns';
import api from '../api';

export default useProviders(
  useStateHook(
    (props) => ({
      API: api(props.notificationsAdminAPIBaseUrl),
      notification: {},
      requestPending: false,
      error: null,
    }),
    {
      fetchNotification: ({ API }) => (notificationId, successCb, errorCb) => {
        API.fetchNotification(notificationId, successCb, errorCb);
        return { requestPending: true };
      },
      fetchNotificationSuccess: () => (notification) => ({
        notification,
        requestPending: false,
        error: null,
      }),
      saveNotification: ({ API }) => (notification, successCb, errorCb) => {
        API.save(notification, successCb, errorCb);
        return { requestPending: true, error: null };
      },
      saveNotificationSuccess: () => (notification) => ({
        notification,
        requestPending: false,
        error: null,
      }),
      deleteNotification: ({ API }) => (notificationId, successCb, errorCb) => {
        API.delete(notificationId, successCb, errorCb);
        return { requestPending: true };
      },
      deleteNotificationSuccess: () => () => {
        return { requestPending: false };
      },
      onError: () => (error) => ({
        error,
        requestPending: false,
      }),
      onChange: ({ notification }) => (field, value) => ({
        notification: {
          ...notification,
          [field]: value,
        },
      }),
      onChangeMultiFields: ({ notification }) => (updated) => ({
        notification: {
          ...notification,
          ...updated,
        },
      }),
    },
    ({ state, handlers }) => ({
      notificationsStore: {
        state,
        handlers: {
          ...handlers,
          fetch: (id) =>
            handlers.fetchNotification(
              id,
              handlers.fetchNotificationSuccess,
              handlers.onError
            ),
        },
      },
    })
  )
);
