import React from 'react';
import { ListPage, Pagination } from '@procore/core-react';

export default ({ pageNumber, pageSize, total, pageTo }) => (
  <ListPage.ContentControls>
    <Pagination
      items={total}
      perPage={pageSize}
      activePage={pageNumber}
      onSelectPage={(page) => pageTo(page)}
    />
  </ListPage.ContentControls>
);
