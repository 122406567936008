import React from 'react';
import { ErrorBanner, Banner, Box, useI18nContext } from '@procore/core-react';
import { useErrorContext } from '@/react/context/ErrorProvider.context';

const MarketplaceErrorBanner = () => {
  const I18n = useI18nContext();
  const { errors } = useErrorContext();

  if (!errors || errors.length === 0) return <></>;
  return (
    <Box paddingBottom="xl">
      <ErrorBanner data-qa="marketplace-error-banner">
        <Banner.Content>
          <Banner.Title>{I18n.t('errors.bannerTitle')}</Banner.Title>
          <Banner.Body>
            {errors?.length > 1 ? (
              <ul>
                {errors.map((error, idx) => {
                  const key = `error_${idx}`;
                  return <li key={key}>{error}</li>;
                })}
              </ul>
            ) : (
              errors[0]
            )}
          </Banner.Body>
        </Banner.Content>
      </ErrorBanner>
    </Box>
  );
};

export default MarketplaceErrorBanner;
