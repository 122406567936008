const DEVELOPER_APPS_BETA = 'developer-apps-beta';
const DEVELOPER_APPS_BETA_BANNER_DISMISSED = 'developer-apps-beta-banner-dismissed';
const DEVELOPER_APPS_BETA_FORCED = 'developer-apps-beta-forced';
const SELF_SERVICE_INTRO_DISMISSED = 'self-service-intro-dismissed';

export const setBannerDismissed = (value) => {
  localStorage.setItem(DEVELOPER_APPS_BETA_BANNER_DISMISSED, value);
};

export const setBeta = (value) => {
  localStorage.setItem(DEVELOPER_APPS_BETA, value);
};

export const getBannerDismissed = () => {
  return localStorage.getItem(DEVELOPER_APPS_BETA_BANNER_DISMISSED) === 'true';
}

export const getBeta = () => {
  return localStorage.getItem(DEVELOPER_APPS_BETA) === 'true';
}

export const removeBannerDismissed = () => {
  localStorage.removeItem(DEVELOPER_APPS_BETA_BANNER_DISMISSED);
}

export const removeBeta = () => {
  localStorage.removeItem(DEVELOPER_APPS_BETA);
}

export const includesBannerDismissed = () => {
  return Object.keys(localStorage).includes(DEVELOPER_APPS_BETA_BANNER_DISMISSED);
}

export const includesBeta = () => {
  return Object.keys(localStorage).includes(DEVELOPER_APPS_BETA);
}

export const setForcedIntoBeta = (value) => {
  localStorage.setItem(DEVELOPER_APPS_BETA_FORCED, value);
}

export const getForcedIntoBeta = () => {
  return localStorage.getItem(DEVELOPER_APPS_BETA_FORCED) === 'true';
}

export const includesForcedIntoBeta = () => {
  return Object.keys(localStorage).includes(DEVELOPER_APPS_BETA_FORCED);
}

export const getSelfServiceIntroDismissed = () => {
  return localStorage.getItem(SELF_SERVICE_INTRO_DISMISSED) === 'true';
}

export const setSelfServiceIntroDismissed = (value) => {
  localStorage.setItem(SELF_SERVICE_INTRO_DISMISSED, value);
}
