import { useEffect, useState } from 'react';

export const useBranchSelector = ({ currentBranch, isAdmin, isEmployee }) => {
  const [branch, setBranch] = useState(currentBranch);

  const changeBranch = (activeInput, nextValueInput) => () => {
    activeInput.value = nextValueInput.value;
    const { pathname } = window.location;
    window.location = `${pathname}?custom_service_sha=${activeInput.value}`;
    setBranch(activeInput.value);
  };

  useEffect(() => {
    let activeInput;
    let updateInput;
    let updateBtn;
    if (isEmployee || isAdmin) {
      activeInput = document.getElementById('active_branch_for_oas');
      updateInput = document.getElementById('new_branch_for_oas');
      updateBtn = document.getElementById('update_oas_branch');

      activeInput.value = branch;
      if (updateBtn) {
        updateBtn.addEventListener(
          'click',
          changeBranch(activeInput, updateInput)
        );
      }
    }
    return () => {
      if ((isEmployee || isAdmin) && updateBtn) {
        updateBtn.removeEventListener('click', changeBranch);
      }
    };
  }, [branch, currentBranch, isAdmin, isEmployee]);

  return [branch, setBranch];
};
