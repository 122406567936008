import {
  updateSandbox as updateSandboxApi,
  post,
  resetSandboxAppSecret as resetSandboxAppSecretApi,
} from '../api';

export const makePostRequest = (
  path,
  successCb,
  errorCb
) => {
  post(path)
    .then((resp) => {
      successCb(resp.data);
    })
    .catch((err) => {
      errorCb(err);
    });
};

//success sets the data for sandbox and nulls out error /savingMap
export const updateSandbox = (sandbox, successCb, errorCb) => {
  updateSandboxApi(sandbox.id, sandbox)
    .then((resp) => {
      successCb(resp.data);
    })
    .catch((err) => {
      errorCb(err);
    });
};

export const resetSandboxAppSecret = (uid, successCb, errorCb) => {
  resetSandboxAppSecretApi(uid)
    .then((resp) => {
      const oauthApplication = resp.data;
      successCb(oauthApplication);
    })
    .catch((err) => {
      errorCb(SECTIONS.SANDBOX, err);
    });
};

export const getSandboxFields = (developerApp) => {
  const { sandbox } = developerApp;
  return sandbox;
};
