import React from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import {
  Typography,
  Flex,
  Box,
  Checkbox as CoreCheckbox,
} from '@procore/core-react';
import { Label } from './Form';

const Checkbox = ({
  required,
  checked,
  label,
  disabled,
  onClick,
  className,
  children,
  errors,
  indeterminate,
  ...rest
}) => (
  <Flex
    className={cx(className)}
    justifyContent="flex-start"
    alignItems="center"
  >
    <CoreCheckbox
      disabled={disabled}
      checked={checked}
      onChange={(e) => {
        if (!disabled) {
          onClick && onClick(e);
        }
      }}
      indeterminate={indeterminate}
      {...rest}
    />
    <Box paddingLeft="sm">
      <Label required={required} label={label} />
      {errors && (
        <Typography color="red50" intent="small" italic>
          {errors.join(', ')}
        </Typography>
      )}
    </Box>
    {children}
  </Flex>
);

Checkbox.propTypes = {
  disabled: PT.bool,
  className: PT.string,
  label: PT.node,
  required: PT.bool,
  onClick: PT.func,
  checked: PT.bool,
  children: PT.node,
  errors: PT.arrayOf(PT.string),
  indeterminate: PT.bool,
};

Checkbox.defaultProps = {
  className: '',
  label: '',
  required: false,
  checked: false,
  disabled: false,
  errors: [],
  indeterminate: false,
};

export default Checkbox;
