import React from 'react';
import PT from 'prop-types';
import {
  Breadcrumbs,
  ToolHeader,
  Box,
  Grid,
  Flex,
  FlexList,
  Button,
} from '@procore/core-react';
import MetricsGraph from '@/react/common/Metrics/MetricsGraph';
import MetricsTable from '@/react/common/Metrics/MetricsTable';
import { MetricsStorePT } from './MetricsContainer';
import InstallFeed from '@/react/common/Metrics/InstallFeed';
import Kpi from '@/react/common/Metrics/Kpi';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import PaginationSection from '@/react/shared/Pagination';
import { isEmpty } from 'ramda';
import { trackEvent } from '../analytics';

const cx = classNames.bind(styles);

class Metrics extends React.Component {
  componentDidMount() {
    const { metricsStore } = this.props;
    metricsStore.handlers.setUpParams();
  }

  trackProcoreEvent(key) {
    const {
      metricsStore: { state },
    } = this.props;

    trackEvent(key, {
      developer_app_id: state.developerAppId,
      app_name: state.internalAppName,
    });
  }

  render() {
    const {
      I18n,
      metricsStore: { state, handlers },
    } = this.props;

    const {
      aggregatesData,
      appInstallations,
      appInstallsTotal,
      developerAppId,
      developerAppsShowPath,
      internalAppName,
      isAdminView,
      marketplaceAppViewsTotal,
      paginationConfigAndData: { paginationConfig, paginationData },
    } = state;

    const totalRows = Object.keys(paginationData).reduce((totalRows, key) => {
      return totalRows + paginationData[key].total_count;
    }, 0);

    if (isEmpty(aggregatesData)) {
      return <div>{I18n.t('noMetricsAvailable')}</div>;
    } else {
      return (
        <React.Fragment>
          {!isAdminView && (
            <React.Fragment>
              <Grid>
                <Grid.Row>
                  <Grid.Col>
                    <Breadcrumbs className="breadcrumbs">
                      <a href="/developer_apps">
                        <Breadcrumbs.Crumb>{I18n.t('apps')}</Breadcrumbs.Crumb>
                      </a>
                      <a href={developerAppsShowPath}>
                        <Breadcrumbs.Crumb>{internalAppName}</Breadcrumbs.Crumb>
                      </a>
                      <Breadcrumbs.Crumb active={true}>
                        {I18n.t('appEngagementReport')}
                      </Breadcrumbs.Crumb>
                    </Breadcrumbs>
                  </Grid.Col>
                </Grid.Row>
              </Grid>

              <ToolHeader>
                <ToolHeader.Title>
                  {I18n.t('appEngagementReport')}
                </ToolHeader.Title>
              </ToolHeader>

              <Button
                onClick={() => {
                  this.trackProcoreEvent(
                    'developers.app_metrics.download_csv.clicked'
                  );
                  window.location = `${developerAppsShowPath}/installations.csv`;
                }}
              >
                {I18n.t('exportCSV')}
              </Button>
            </React.Fragment>
          )}
          <Box paddingTop="xl">
            <FlexList size="lg" direction="column" alignItems="stretch">
              <Grid>
                <Grid.Row>
                  <Grid.Col colWidth={9}>
                    <FlexList size="lg" direction="column" alignItems="stretch">
                      {isAdminView && (
                        <Button
                          onClick={() => {
                            window.location = `${window.location}/installations.csv`;
                          }}
                        >
                          {I18n.t('exportCSV')}
                        </Button>
                      )}
                      <Grid>
                        <Grid.Row>
                          <Grid.Col colWidth={9}>
                            <MetricsGraph
                              I18n={I18n}
                              metricsData={aggregatesData}
                            />
                          </Grid.Col>
                          <Grid.Col colWidth={3}>
                            <Flex
                              size="sm"
                              direction="column"
                              alignItems="center"
                              justifyContent="space-between"
                              style={{ height: '100%' }}
                            >
                              <Kpi.Container
                                className={cx(styles.metricsCard)}
                                title={I18n.t(
                                  'detailedMetricsView.totalMarketplaceVisits'
                                )}
                              >
                                <Kpi.Value>
                                  {marketplaceAppViewsTotal}
                                </Kpi.Value>
                              </Kpi.Container>
                              <Kpi.Container
                                className={cx(styles.metricsCard)}
                                title={I18n.t(
                                  'detailedMetricsView.companyInstallations'
                                )}
                              >
                                <Kpi.Value>{appInstallsTotal}</Kpi.Value>
                              </Kpi.Container>
                            </Flex>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid>
                      <PaginationSection
                        {...paginationConfig}
                        pageTo={handlers.getPage}
                      />
                      {totalRows !== 0 && (
                        <MetricsTable
                          I18n={I18n}
                          className={cx(styles.metricsCard)}
                          metricsData={paginationData}
                        />
                      )}
                    </FlexList>
                  </Grid.Col>

                  <Grid.Col
                    colWidth={3}
                    className={cx(styles.metricsInstallColumn)}
                  >
                    {appInstallations.total_count > 0 && (
                      <Box className={cx(styles.metricsInstallWrapper)}>
                        <InstallFeed
                          isAdminView={isAdminView}
                          className={cx(styles.metricsCard)}
                          installations={appInstallations.rows}
                          I18n={I18n}
                        />
                      </Box>
                    )}
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </FlexList>
          </Box>
        </React.Fragment>
      );
    }
  }
}

Metrics.propTypes = {
  I18n: PT.shape({}).isRequired,
  metricsStore: MetricsStorePT,
};

Metrics.defaultProps = {
  metricsStore: {
    state: { internalAppName: '' },
  },
};

export default Metrics;
