import { Box } from '@procore/core-react';
import PT from 'prop-types';
import React from 'react';

const OAuthInfo = ({ I18n, fields }) => {
  return (
    <Box padding="lg">
      <p>
        {I18n.t('credentials.appIsCurrentlySetTo')}
        <strong>
          {' '}
          {fields.grant_type === 'client_credentials' ? I18n.t('credentials.client_credentials') : fields.confidential ? I18n.t('credentials.confidentialTrue') : I18n.t('credentials.confidentialFalse')}
        </strong>
        . <br />
        <span
          dangerouslySetInnerHTML={{
            __html: I18n.t('credentials.confidentialInformation', {
              oauth2GrantType: `<a
                            target="_blank" rel="noopener noreferrer"
                            href="https://developers.procore.com/documentation/oauth-choose-grant-type">
                            ${I18n.t('credentials.oauth2GrantType')}.
                            </a>`,
            }),
          }}
        />
      </p>
    </Box>
  );
};

OAuthInfo.propTypes = {
  I18n: PT.shape({ t: PT.func }),
  fields: PT.shape({}),
};

OAuthInfo.defaultProps = {
  fields: {},
  I18n: { t: () => {} },
};

export default OAuthInfo;
