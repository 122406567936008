import React from 'react';
import { Box, H1, H2, Flex } from '@procore/core-react';

const Container = ({ title, children, className }) => (
  <Box padding="md" className={`kpi-box ${className}`}>
    <H2 className="kpi-title">{title}</H2>
    <Flex alignItems="center" justifyContent="center" className="kpi-flex">
      {children}
    </Flex>
  </Box>
);

const Value = ({ children }) => <H1 className="kpi-value">{children}</H1>;

const Kpi = {
  Container,
  Value,
};

export default Kpi;
