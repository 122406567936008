import React from 'react';
import PT from 'prop-types';
import {
  Flex,
  ProgressBar,
  Typography,
  H2,
  Card,
  useI18nContext,
} from '@procore/core-react';
import styled from 'styled-components';

const LargeProgressBar = styled(ProgressBar)`
  height: 16px;
  transition: none;
  width: 100%;
  margin: 0px;
`;

export const MarketplaceProgressBar = ({ totalStages, completedStages }) => {
  const I18n = useI18nContext();
  return (
    <Card data-testid='progress-bar'>
      <Flex direction="column" gap="sm" padding="lg">
        <H2>{I18n.t('marketplace.progressBar.title')}</H2>
        <Typography intent="body">
          {I18n.t('marketplace.progressBar.stages', {
            totalStages,
            completedStages,
          })}
        </Typography>
        <LargeProgressBar
          value={(completedStages / totalStages) * 100}
          animated={false}
        />
      </Flex>
    </Card>
  );
};

MarketplaceProgressBar.propTypes = {
  totalStages: PT.number.isRequired,
  completedStages: PT.number.isRequired,
};
