import React, { useEffect, useState } from 'react';
import AppSettingsForm from '../../../developer_apps/show/AppSettingsForm';
import DeleteAppModal from '../../../developer_apps/show/DeleteAppCard/DeleteAppModal';
import { UseShowContext } from '../Show.context';
import { DEVELOPER_APPS_PATH } from '../constants';
import {
  Banner,
  Box,
  Button,
  Card,
  ErrorBanner,
  InfoBanner,
  Spinner,
  Tabs,
  Typography,
  Flex,
  Modal,
  useI18nContext,
} from '@procore/core-react';

const SETTING_FIELDS = ['internal_name', 'thumbnail', 'remove_thumbnail'];

const Settings = () => {
  const {
    developerAppId,
    canEdit,
    canDestroy,
    api,
    state: { devApp, setDevApp, permissions },
  } = UseShowContext();

  const [fields, setFields] = useState(devApp);
  const [err, setErr] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [isDeletingApp, setIsDeletingApp] = useState(false);
  const I18n = useI18nContext();

  useEffect(() => {
    const newFields = { ...devApp };

    if (newFields.thumbnail_url && !newFields.thumbnail) {
      newFields.thumbnail = {
        preview: newFields.thumbnail_url,
      };
    }
    setFields(newFields);
  }, [devApp]);

  const onChange = (name, value) => {
    setFields((state) => ({
      ...state,
      __modified__: true,
      [name]: value,
    }));
  };

  const onSave = () => {
    const data = new FormData();

    SETTING_FIELDS.forEach((key) => {
      const value = fields[key];
      if (value !== undefined && value !== null) {
        data.append(`developer_app[${key}]`, value);
      }
    });

    api
      .updateDeveloperAppFormData(developerAppId, data)
      .then((resp) => {
        setDevApp(resp.data);
      })
      .catch((e) => {
        setErr(e);
      });
  };

  const deleteApp = (closeModal) => () => {
    setIsDeletingApp(true);
    api
      .destroyDeveloperApp(developerAppId)
      .then((resp) => {
        window.location = DEVELOPER_APPS_PATH;
        setIsDeletingApp(false);
      })
      .catch((e) => {
        closeModal();
        setDeleteError(e);
        setIsDeletingApp(false);
      });
  };

  const thumbnail = fields.thumbnail
    ? fields.thumbnail
    : {
        preview: fields.thumbnail_url,
      };

  return (
    <>
      <Card>
        <AppSettingsForm
          canEdit={canEdit}
          onSave={onSave}
          fields={{
            thumbnail,
            internal_name: fields.internal_name,
            __modified__: fields.__modified__,
          }}
          onChange={onChange}
          appFieldPermissions={permissions}
          error={err}
        />
      </Card>
      {canDestroy && (
        <Modal.State>
          {(visibility) => (
            <React.Fragment>
              <DeleteAppModal
                I18n={I18n}
                visibility={visibility}
                onDelete={deleteApp(visibility.hide)}
                isDeletingApp={isDeletingApp}
              />
              <Box margin="xl none">
                <Card>
                  <Spinner loading={false}>
                    <Box padding="xl">
                      {deleteError && (
                        <Box marginBottom="xl">
                          <ErrorBanner>
                            <Banner.Content>
                              <Banner.Title>{I18n.t('error')}</Banner.Title>
                              <Banner.Body>{error.message}</Banner.Body>
                            </Banner.Content>
                          </ErrorBanner>
                        </Box>
                      )}
                      <h2>{I18n.t('deleteAppTitle')}</h2>
                      <Typography intent="em" color="gray45">
                        {I18n.t('deleteAppInfo')}
                      </Typography>
                      <Flex justifyContent="flex-end">
                        <Button
                          data-qa="delete-app-button"
                          variant="secondary"
                          onClick={visibility.toggle}
                        >
                          {I18n.t('deleteApp')}
                        </Button>
                      </Flex>
                    </Box>
                  </Spinner>
                </Card>
              </Box>
            </React.Fragment>
          )}
        </Modal.State>
      )}
    </>
  );
};

export default Settings;
