import React, { useMemo, useState, useCallback } from 'react';
import {
  Box,
  Card,
  useI18nContext,
  Typography,
  spacing,
} from '@procore/core-react';
import MarketplaceAppForm from '@/react/common/marketplace_form';
import MarketplaceErrorBanner from '@/react/shared/MarketplaceErrorBanner';
import { serialize } from 'object-to-formdata';
import { UseShowContext } from '../Show.context';
import { ListingApplication } from '../cards/marketplace/ListingApplication';
import { ErrorProvider } from '@/react/context/ErrorProvider.context';
import { MarketplaceProgressBar } from '../cards/marketplace/MarketplaceProgressBar';
import { getRequiredTabs } from '../../../common/marketplace_form/constants';
import {
  getSelfServiceIntroDismissed,
  setSelfServiceIntroDismissed,
} from '@/utils/localStorageUtil';
import { MarketplaceInfoBanner } from '../cards/marketplace/InfoBanner';
import {
  AdminMessageBanner,
  InReviewMessage,
} from '../../../common/marketplace_form/Banners';
import { MARKETPLACE_APP_STATES } from '@/react/shared/constants';
import { isHigherOrEqualSemanticVersion } from '@/utils/semverUtil';

const MarketplaceForm = () => {
  const {
    developerAppId,
    canEdit,
    regions,
    countries,
    productTools,
    marketplaceReviewerEmail,
    marketplaceDraftListingPath,
    marketplaceListingApplicationEnabled,
    newDraftPreviewActive,
    api,
    isProcoreEmployee,
    securityStandards,
    ssoProviders,
    state: { devApp, setDevApp, trackEvent, productionManifests },
  } = UseShowContext();
  const I18n = useI18nContext();

  const [requiredTabs, setRequiredTabs] = useState(() => getRequiredTabs(devApp.last_marketplace_app));

  const createOrUpdateMarketplaceApp = (marketplaceApp, onSuccess, onError) => {
    const marketplaceAppId = devApp?.last_marketplace_app?.id;

    const formattedFormData = (items) => {
      return items.reduce((acc, curr, currIdx) => {
        acc[currIdx] = curr;
        return acc;
      }, {});
    };

    const formData = serialize(
      {
        submission: {
          ...marketplaceApp,
          ...{
            pictures_attributes: formattedFormData(
              marketplaceApp.pictures || []
            ),
            videos_attributes: formattedFormData(marketplaceApp.videos || []),
            showcase_video_attributes: marketplaceApp.showcase_video,
          },
        },
      },
      { allowEmptyArrays: true }
    );

    let apiCall;
    if (marketplaceAppId && marketplaceApp.state !== 'published') {
      apiCall = api.updateMarketplaceApp(marketplaceAppId, formData);
    } else {
      apiCall = api.createMarketplaceApp(developerAppId, formData);
    }

    apiCall
      .then(({ data }) => {
        setDevApp({ ...devApp, last_marketplace_app: data });
        onSuccess();
      })
      .catch(onError);
  };

  const lastPublishedManifest = productionManifests.find(
    (manifest) => manifest.state === 'published'
  );

  const possibleVersions = productionManifests.filter((manifest) =>
    isHigherOrEqualSemanticVersion(
      manifest.semantic_version,
      lastPublishedManifest?.semantic_version
    )
  );

  const [completedTabs, setCompletedTabs] = useState({});

  const setTotalProgress = useCallback((tabStatus) => {
    setCompletedTabs(() =>
      tabStatus.reduce((acc, { tab, valid }) => {
        acc[tab] = valid;
        return acc;
      }, {})
    );
  }, []);

  const validTabs = useMemo(
    () => Object.keys(completedTabs).filter((tab) => completedTabs[tab]).length,
    [completedTabs]
  );

  const [selfServiceBannerDismissed, setSelfServiceBannerDismissed] = useState(
    getSelfServiceIntroDismissed()
  );

  return (
    <ErrorProvider>
      <MarketplaceErrorBanner />
      {(devApp.marketplace_enabled ||
        !marketplaceListingApplicationEnabled) && (
        <>
          {!selfServiceBannerDismissed && (
            <Box paddingBottom="xl">
              <MarketplaceInfoBanner
                dismissBanner={() => {
                  setSelfServiceIntroDismissed(true);
                  setSelfServiceBannerDismissed(true);
                }}
              />
            </Box>
          )}
          <>
            <AdminMessageBanner
              state={devApp.last_marketplace_app?.state}
              approval_state={devApp.last_marketplace_app?.approval_state}
              admin_message={devApp.last_marketplace_app?.admin_message}
              isAdminView={false}
            />
            {devApp.last_marketplace_app?.state ===
              MARKETPLACE_APP_STATES.IN_REVIEW && <InReviewMessage />}
          </>
          <Box paddingBottom="xl" paddingTop="md">
            <Typography intent="h2">{I18n.t('marketplaceListing')}</Typography>
          </Box>
          <Box paddingBottom="xl" style={{ width: '100%' }}>
            <MarketplaceProgressBar
              completedStages={validTabs}
              totalStages={requiredTabs.length}
            />
          </Box>

          <Card
            data-testid="marketplace-form"
            style={{ marginBottom: `${spacing.xl}px` }}
          >
            <MarketplaceAppForm
              isAdminView={false}
              canEdit={canEdit}
              canSubmitMarketplaceApp
              marketplaceDraftListingPath={marketplaceDraftListingPath}
              newDraftPreviewActive={newDraftPreviewActive}
              unmappedFields={devApp.last_marketplace_app}
              save={createOrUpdateMarketplaceApp} // Save expects a function that returns a promise
              uploadWistiaVideo={api.uploadWistiaVideo} // uploadWistiaVideo expects a function that returns a promise
              productTools={productTools}
              regions={regions}
              countries={countries}
              helpEmail={marketplaceReviewerEmail}
              hasPublishedApp={false}
              trackEvent={trackEvent}
              isProcoreEmployee={isProcoreEmployee}
              possibleVersions={possibleVersions}
              setTotalProgress={setTotalProgress}
              showBanners={false}
              securityStandards={securityStandards}
              requiredTabs={requiredTabs}
              setRequiredTabs={setRequiredTabs}
              ssoProviders={ssoProviders}
            />
          </Card>
        </>
      )}
      {marketplaceListingApplicationEnabled && !devApp.marketplace_enabled && (
        <ListingApplication devAppId={developerAppId} />
      )}
    </ErrorProvider>
  );
};

export default MarketplaceForm;
