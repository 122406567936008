import React, { useState } from 'react';
import PT from 'prop-types';

import { Form, Button, Modal, useI18nContext } from '@procore/core-react';

import PermissionsTable from './PermissionsTable';

const AddCollaboratorModal = ({ open, setOpen, sendInvitation }) => {
  const I18n = useI18nContext();

  const [email, setEmail] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  return (
    <Modal
      open={open}
      onClickOverlay={() => {
        setOpen(false);
      }}
    >
      <Modal.Header
        onClose={() => {
          setOpen(false);
        }}
      >
        {I18n.t('modals.addCollaborator.title')}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Form>
            <Form.Row>
              <Form.Field
                required
                name="email"
                label={I18n.t('modals.addCollaborator.emailAddress')}
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Row>
            <Form.Row>
              <Form.RadioButtons
                label={I18n.t('modals.addCollaborator.role')}
                name="role"
                options={[
                  {label: I18n.t('modals.addCollaborator.developer'), id: 'developer'},
                  {label: I18n.t('modals.addCollaborator.admin'), id: 'admin'}
                ]}
                onClick={(e) => {
                  setSelectedRole(e.target.value);
                }}
              />
            </Form.Row>
          </Form.Form>
        </Form>
        <PermissionsTable />
      </Modal.Body>
      <Modal.Footer>
        <Modal.FooterButtons>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            {I18n.t('modals.addCollaborator.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              sendInvitation(email, selectedRole);
            }}
          >
            {I18n.t('modals.addCollaborator.confirm')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

AddCollaboratorModal.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  sendInvitation: PT.func.isRequired,
};

export default AddCollaboratorModal;
