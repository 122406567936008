import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    form: {
      description: 'New app integration process, where defining components, managing permissions, handling versions, and more became seamlessly effortless. ',
      label: 'Form-based app building experience',
      linkLabel: 'Read about this enhanced experience here.',
      title: 'Experience Control',
    },
    modals: {
      optIn: {
        bodyParagraph1:
          'Procore\'s new, intuitive app integration development process makes defining components, managing permissions, and handling versions seamless.',
        bodyParagraph2:
          'You can still access the previous experience by going to your account settings.',
        confirm: 'Got It',
        title: 'New Form-Based App Building Experience!',
      }
    },
    optInAcceptedBanner: {
      body: 'Explore the new seamless and intuitive integration development experience. Remember, you can always adjust your settings at any time.',
      title: 'New Form-Based App Building Experience is Active',
    },
    optInDeclinedBanner: {
      body: 'Dive into our new app integration experience. Join for a seamless journey. ',
      body2: 'Read about this enhanced experience here.',
      button: 'Join the Experience',
      title: 'New App Creation Experience Now Available!',
    },
    forcedIntoExperienceBanner: {
      title: 'New Form-Based App Building Experience is Active',
      body: 'All users have been transitioned to the new intuitive integration development experience. You can still access the previous experience by going to your account settings. Reach out to %{supportEmail} with any questions.',
    },
    toast: {
      enabled: 'Form-based app building experience has been enabled.',
      disabled: 'Form-based app building experience has been disabled.',
    }
  }
});
