import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    attachFile: 'Attach File or Drag and Drop',
    attachFiles: 'Attach Files or Drag and Drop',
    download: 'Download',
    confirmDeletionModal: {
      header: 'Delete File?',
      body: 'Are you sure you want to delete %{file_name}?',
      confirm: 'Delete File',
      cancel: 'Cancel',
    },
    filePreviewModal: {
      noPreviewAvailable: 'No Preview Available',
      close: 'Close',
    },
  },
});
