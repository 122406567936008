import { pathOr } from 'ramda';

export const getIsDisabledFunc = (canEdit, appFieldPermissions) => (
  fieldName
) => {
  return (
    !pathOr(false, [fieldName, 'editable'], appFieldPermissions) || !canEdit
  );
};

export default {
  getIsDisabledFunc,
};
