import React from 'react';
import PT from 'prop-types';
import {
  Box,
  Card,
  ErrorBanner,
  Banner,
  P,
  useI18nContext,
  Grid,
} from '@procore/core-react';
import Form from '@/react/shared/form';
import InfoPopover from '../../shared/infoPopover/InfoPopover';
import { autoSelectCopy } from '../../../global_helpers/autoSelectCopy';
import FormFooter from './FormFooter';
import { getIsDisabledFunc } from './permissionUtil';
import ResetCredentialsConfirmationModal from './modals/ResetCredentialsConfirmationModal';
import AppCredentialsModal from './modals/AppCredentialsModal';
import OAuthInfo from './OAuthInfo';

const AppCredentialsForm = ({
  fields,
  onChange,
  onSave,
  appCredentialsDocPath,
  redirectUriDocPath,
  canEdit,
  appFieldPermissions,
  resetAppSecret,
  resetAppSecretSuccess,
  credentials,
  forgetSecret,
  error,
  loading,
  onError,
  resetCredentialsConfirmed,
  toggleResetCredentialsConfirmed,
  canModifyCredentials,
  secretResetCount,
}) => {
  const I18n = useI18nContext();
  const _isDisabled = getIsDisabledFunc(canEdit, appFieldPermissions);
  const onFieldChange = (field, value) => onChange(field, value, 'credentials');

  const [resetCredModalOpen, setResetCredModalOpen] = React.useState(false);
  const [showCredModalOpen, setShowCredModalOpen] = React.useState(false);

  const errors = error?.response?.data?.errors;
  const errorMessage = errors ? errors.join(' ') : error?.message || error;

  return (
    <>
      <Box padding="xl xl none xl">
        <h2>{I18n.t('appCredentials')}</h2>
      </Box>
      {errorMessage && (
        <Box padding="none xl xl xl">
          <Form.Row>
            <ErrorBanner>
              <Banner.Content>
                <Banner.Title>{I18n.t('errorOccurred')}</Banner.Title>
                <Banner.Body>{errorMessage}</Banner.Body>
              </Banner.Content>
            </ErrorBanner>
          </Form.Row>
        </Box>
      )}

      <Box padding="xl xl xl xl">
        <Grid gutterX="lg" gutterY="lg" colStackCap="mobileLg">
          <Grid.Row>
            <Grid.Col colWidth={6}>
              {fields.uid ? (
                <>
                  <Box paddingBottom="lg">
                    <Form.Field
                      readOnly
                      data-qa="developer-app-client-id"
                      label={
                        <span>
                          {I18n.t('labels.clientId')}
                          <InfoPopover>
                            <a href={appCredentialsDocPath} target="_blank">
                              {I18n.t('clientIdHelp')}
                            </a>
                          </InfoPopover>
                        </span>
                      }
                      type="text"
                      value={fields.uid}
                      onClick={autoSelectCopy}
                    />
                  </Box>
                  <Form.Textarea
                    disabled={_isDisabled('redirect_uri')}
                    data-qa="developer-app-redirect-uri"
                    label={
                      <span>
                        {I18n.t('labels.redirectUri')}
                        <InfoPopover>
                          <a href={redirectUriDocPath} target="_blank">
                            {I18n.t('redirectUriHelp')}
                          </a>
                        </InfoPopover>
                      </span>
                    }
                    value={fields.redirect_uri}
                    onChange={(e) =>
                      onFieldChange('redirect_uri', e.target.value)
                    }
                    data-testid="redirect-uri"
                  />
                  <Box padding="lg lg none lg">
                    <em>
                      {I18n.t('oneLineURI')}
                      <br />
                      {I18n.t('acceptableURI')}
                      <br />
                      {I18n.t('urnRedirectlessOAuth')}
                    </em>
                  </Box>
                </>
              ) : (
                <P
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('credentials.noCredentialsMessage', {
                      dataConnectionDocLink: `<a
                  target="_blank" rel="noopener noreferrer"
                  href="https://developers.procore.com/documentation/building-apps-app-types">
                  ${I18n.t('credentials.noCredentialsMessageLinkText')}
                  </a>`,
                    }),
                  }}
                />
              )}
            </Grid.Col>
            <Grid.Col colWidth={6}>
              {fields.confidential !== null && (
                <Form.Column>
                  {fields.confidential && fields.uid && (
                    <ResetCredentialsConfirmationModal
                      isVisible={resetCredModalOpen}
                      show={() => {
                        setResetCredModalOpen(true);
                      }}
                      hide={() => {
                        setResetCredModalOpen(false);
                      }}
                      I18n={I18n}
                      loading={loading}
                      appUid={credentials.uid}
                      resetAppSecret={resetAppSecret}
                      resetAppSecretSuccess={resetAppSecretSuccess}
                      onError={(err) => onError(SECTIONS.CREDENTIALS, err)}
                      resetCredentialsConfirmed={resetCredentialsConfirmed}
                      toggleResetCredentialsConfirmed={
                        toggleResetCredentialsConfirmed
                      }
                      canModifyCredentials={canModifyCredentials}
                      qaPrefix="production"
                      secretResetCount={secretResetCount}
                      production={true}
                    />
                  )}

                  <AppCredentialsModal
                    isVisible={showCredModalOpen}
                    hide={() => {
                      setShowCredModalOpen(false);
                    }}
                    I18n={I18n}
                    credentials={credentials}
                    forgetSecret={forgetSecret}
                  />
                  {fields.grant_type && (
                    <OAuthInfo I18n={I18n} fields={fields} />
                  )}
                </Form.Column>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Box>
      <FormFooter
        buttonQaTag="update-app-credentials-button"
        saveDisabled={!canEdit || !fields.__modified__}
        onSaveClick={onSave}
      />
    </>
  );
};

AppCredentialsForm.propTypes = {
  error: PT.string,
  onSave: PT.func.isRequired,
  canEdit: PT.bool.isRequired,
  onChange: PT.func.isRequired,
  fields: PT.shape({}).isRequired,
  appFieldPermissions: PT.shape({}).isRequired,
  appCredentialsDocPath: PT.string.isRequired,
  redirectUriDocPath: PT.string.isRequired,
  credentials: PT.shape({}).isRequired,
  loading: PT.bool,
  forgetSecret: PT.func.isRequired,
  resetAppSecret: PT.func.isRequired,
  resetAppSecretSuccess: PT.func.isRequired,
  onError: PT.func.isRequired,
  canModifyCredentials: PT.bool.isRequired,
  secretResetCount: PT.number.isRequired,
};

AppCredentialsForm.defaultProps = {
  error: '',
  loading: false,
};

export default AppCredentialsForm;
