import React from 'react';
import { Button, Box, useI18nContext } from '@procore/core-react';

const FakeSandboxButton = ({ generateFakeSandbox }) => {
  const I18n = useI18nContext();
  return (
    <Box padding="xl">
      <Button
        data-qa="generate-fake-sandbox-button"
        variant="primary"
        onClick={generateFakeSandbox}
      >
        {I18n.t('generateFakeSandbox')}
      </Button>
    </Box>
  );
};

export default FakeSandboxButton;
