import React from 'react';
import {
  Box,
  Card,
  colors,
  Content,
  Flex,
  Typography,
  useI18nContext,
} from '@procore/core-react';

const SandboxMissingCard = () => {
  const i18n = useI18nContext();

  return (
    <Card data-testid="sandbox-missing">
      <Box padding="221px none">
        <Flex alignItems="row" style={{
          'backgroundColor': colors.gray15,
          'overflowY': 'visible',
          'position': 'relative'
        }}>
          <Box style={{width: '50%'}} padding="xl" marginLeft="10%" >
            <Content>
              <Typography
                intent="h1"
                color={colors.white}
              >
                  {i18n.t('sandboxMissingTitle')}
              </Typography>
              <Typography
                as="p"
                color={colors.white}
                style={{'fontSize': '16px'}}
              >
                {i18n.t('sandboxMissingDetail')}
              </Typography>
            </Content>
          </Box>
          <Box style={{
              position: 'absolute',
              top: '60%',
              left: '60%',
              width: '36%'
          }}>
            <img style={{'maxWidth': '100%'}} src="/assets/images/sandbox_missing_graphic.svg"/>
          </Box>
        </Flex>
        <Box margin="sm 10%">
          <img src="/assets/images/procore-logo-black.svg"/>
        </Box>
      </Box>
    </Card>
  );
};

export default SandboxMissingCard;
