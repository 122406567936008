import React from 'react';
import API from '../api';

import useProviders, { useStateHook } from '@procore/react-state-patterns';

export default useProviders(
  useStateHook(
    {
      isDeletingApp: false,
      error: null,
      api: API(),
    },
    {
      deleteDeveloperApp: ({ api }) => (id, successCb, errorCb) => {
        api
          .destroyDeveloperApp(id)
          .then(successCb)
          .catch(errorCb);
        return { isDeletingApp: true };
      },
      deleteDeveloperAppSuccess: () => () => ({
        isDeletingApp: false,
        error: null,
      }),
      onError: () => (error) => ({ error, isDeletingApp: false }),
    },
    'deleteCardStore'
  )
);
