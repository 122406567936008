/* eslint-disable react/jsx-no-target-blank */

import React from 'react';
import PT from 'prop-types';
import {
  Banner,
  Box,
  Button,
  Card,
  ErrorBanner,
  InfoBanner,
  Spinner,
  Tabs,
} from '@procore/core-react';
import InfoPopover from '../../shared/infoPopover/InfoPopover';
import SandboxManifestsTable from '../../common/SandboxManifestsTable';
import ProductionManifestsTable from '../../common/ProductionManifestsTable';
import { MANIFEST_TABS, MANIFEST_TYPES } from '../../shared/constants';
import { SECTIONS } from './StateContainer';

// eslint-disable-next-line complexity
const ManifestsCard = ({
  createManifest,
  developerAppId,
  I18n,
  loadingState,
  manifestSupportDocPath,
  productionManifests,
  sandboxManifests,
  sandboxStatus,
  sandboxUid,
  switchTab,
  tab,
  promoteManifest,
  updateAppVersion,
  error,
  showSandboxBanner,
  toggleSandboxBanner,
  canModifyManifests,
  trackEvent,
  canViewClientCredentials,
  canSetSemanticVersions,
  canCreateSandboxManifests,
  dmsaPermissions
}) => {
  const scrollToSandboxCard = () => {
    document
      .querySelector("[data-anchor='sandbox-card-header']")
      .scrollIntoView({ behavior: 'smooth' });
  };

  // eslint-disable-next-line react/jsx-no-target-blank
  return (
    <Card>
      <Box padding="xl">
        <Box paddingBottom="md">
          <h2 style={{ display: 'inline' }}>{I18n.t('manageManifests')}</h2>
          <InfoPopover>
            <a href={manifestSupportDocPath} target="_blank">
              {I18n.t('manifestHelp')}
            </a>
          </InfoPopover>
        </Box>
        {error && (
          <ErrorBanner>
            <Banner.Content>
              <Banner.Title>{I18n.t('errorOccurred')}</Banner.Title>
              <Banner.Body>{error}</Banner.Body>
            </Banner.Content>
          </ErrorBanner>
        )}
        {showSandboxBanner && (
          <InfoBanner>
            <Banner.Content>
              <Banner.Title>{I18n.t('sandboxManifestCreated')}</Banner.Title>
              <Banner.Body>{I18n.t('visitSandbox')}</Banner.Body>
            </Banner.Content>
            <Banner.Action>
              <Button variant="secondary" onClick={scrollToSandboxCard}>
                {I18n.t('view')}
              </Button>
            </Banner.Action>
            <Banner.Dismiss onClick={() => toggleSandboxBanner(false)} />
          </InfoBanner>
        )}
        <Tabs>
          {/* Placing references in an array to guarantee iteration order */}
          {[MANIFEST_TABS.SANDBOX, MANIFEST_TABS.PRODUCTION].map((tabName) => (
            <Tabs.Tab
              key={tabName}
              data-qa={`manifest-tab-${tabName
                .toLowerCase()
                .replace(' ', '-')}`}
              variant={tab === tabName ? 'active' : ''}
            >
              <Tabs.Link onClick={() => switchTab(tabName)}>
                {tabName}
              </Tabs.Link>
            </Tabs.Tab>
          ))}
        </Tabs>

        {!sandboxUid &&
          !(sandboxStatus === 'live') &&
          tab === MANIFEST_TABS.SANDBOX &&
          I18n.t('manifestSandboxCredentialsRequired')}
        {(!!sandboxUid || sandboxStatus === 'live') &&
          tab === MANIFEST_TABS.SANDBOX && (
            <Spinner loading={loadingState[SECTIONS.SANDBOX]}>
              <SandboxManifestsTable
                parentApp={{
                  id: developerAppId,
                  type: MANIFEST_TYPES.SANDBOX,
                }}
                createManifest={createManifest}
                promoteManifest={promoteManifest}
                manifests={sandboxManifests}
                canModifyManifests={canModifyManifests}
                trackEvent={trackEvent}
                canViewClientCredentials={canViewClientCredentials}
                canSetSemanticVersions={canSetSemanticVersions}
                canCreateSandboxManifests={canCreateSandboxManifests}
                dmsaPermissions={dmsaPermissions}
              />
            </Spinner>
          )}

        {!productionManifests &&
          tab === MANIFEST_TABS.PRODUCTION &&
          I18n.t('manifestProductionCredentialsRequired')}
        {!!productionManifests && tab === MANIFEST_TABS.PRODUCTION && (
          <Spinner loading={loadingState[SECTIONS.SANDBOX]}>
            <ProductionManifestsTable
              parentApp={{
                id: developerAppId,
                type: MANIFEST_TYPES.PRODUCTION,
              }}
              updateAppVersion={updateAppVersion}
              manifests={productionManifests}
              canModifyManifests={canModifyManifests}
              trackEvent={trackEvent}
              canSetSemanticVersions={canSetSemanticVersions}
              dmsaPermissions={dmsaPermissions}
            />
          </Spinner>
        )}
      </Box>
    </Card>
  );
};

ManifestsCard.propTypes = {
  developerAppId: PT.string.isRequired,
  sandboxManifests: PT.arrayOf(PT.shape({})).isRequired,
  sandboxStatus: PT.string.isRequired,
  productionManifests: PT.arrayOf(PT.shape({})).isRequired,
  manifestSupportDocPath: PT.string.isRequired,
  createManifest: PT.func.isRequired,
  promoteManifest: PT.func.isRequired,
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  loadingState: PT.shape({}).isRequired,
  updateAppVersion: PT.func.isRequired,
  error: PT.string,
  showSandboxBanner: PT.bool,
  toggleSandboxBanner: PT.func,
  canModifyManifests: PT.bool.isRequired,
  trackEvent: PT.func.isRequired,
  canViewClientCredentials: PT.bool.isRequired,
  canSetSemanticVersions: PT.bool.isRequired,
  canCreateSandboxManifests: PT.bool.isRequired,
  sandboxUid: PT.string.isRequired,
  switchTab: PT.func.isRequired,
  tab: PT.string.isRequired,
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
};

ManifestsCard.defaultProps = {
  error: '',
  showSandboxBanner: false,
  toggleSandboxBanner: () => {},
};

export default ManifestsCard;
