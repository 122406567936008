const toggleResponse = {
  init: () => {
    document.querySelectorAll('[data-toggle-response-master]').forEach((el) => {
      el.addEventListener('click', toggleResponse.toggle);
    });
    toggleResponse.initClosed();
    toggleResponse.initOpen();
  },

  initClosed: () => {
    document
      .querySelectorAll(
        '[data-toggle-response-master][data-toggle-response-open="false"]'
      )
      .forEach((el) => {
        const id = el.dataset.toggleResponseMaster;
        if (el.dataset.toggleResponseTextWhenClosed) {
          el.innerText = el.dataset.toggleResponseTextWhenClosed;
        }
        document
          .querySelectorAll(`[data-toggle-response-slave=${id}]`)
          .forEach((s) => {
            s.style.display = 'none';
          });
      });
  },

  initOpen: () => {
    document
      .querySelectorAll(
        '[data-toggle-response-master][data-toggle-response-open="true"]'
      )
      .forEach((el) => {
        if (el.dataset.toggleResponseTextWhenOpen) {
          el.innerText = el.dataset.toggleResponseTextWhenOpen;
        }
      });
  },

  toggle: ({ target: master }) => {
    const id = master.dataset.toggleResponseMaster;
    const wasOpen = master.dataset.toggleResponseOpen || 'false';
    const textWhenOpen = master.dataset.toggleResponseTextWhenOpen;
    const textWhenClosed = master.dataset.toggleResponseTextWhenClosed;

    const slaves = document.querySelectorAll(
      `[data-toggle-response-slave=${id}]`
    );

    if (wasOpen === 'true') {
      slaves.forEach((s) => {
        s.style.display = 'none';
      });
      master.dataset.toggleResponseOpen = false;
      if (textWhenClosed) {
        master.innerText = textWhenClosed;
      }
    } else if (wasOpen === 'false') {
      slaves.forEach((s) => {
        s.style.display = 'inherit';
      });
      master.dataset.toggleResponseOpen = true;
      if (textWhenOpen) {
        master.innerText = textWhenOpen;
      }
    }
  },

  destory: () => {
    document.querySelectorAll('[data-toggle-response-master]').forEach((el) => {
      el.removeEventListener('click', toggleResponse.toggle);
    });
  },
};

module.exports = toggleResponse;
