import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Form,
  Modal,
  useI18nContext,
} from '@procore/core-react';

const PromoteConfirmationModal = ({
  open,
  setOpen,
  appVersionId,
  semanticVersion,
  promoteManifest,
}) => {
  const I18n = useI18nContext();

  const [releaseNotes, setReleaseNotes] = useState('');

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onClickOverlay={() => setOpen(false)}
    >
      <Modal.Header>
        {I18n.t(
          'configurationsTab.promoteConfirmationModal.header',
          { version: semanticVersion }
        )}
      </Modal.Header>

      <Modal.Body>
        <p>
          {I18n.t(
            'configurationsTab.promoteConfirmationModal.promotionExplanation'
          )}
        </p>

        <p>
          {I18n.t(
            'configurationsTab.promoteConfirmationModal.releaseNotesExplanation'
          )}
        </p>
          <Form>
            <Form.Form>
              <Form.Row>
                <Form.TextArea
                  colStart={1}
                  colWidth={12}
                  required
                  name="releaseNotes"
                  label={I18n.t(
                    'configurationsTab.promoteConfirmationModal.releaseNotes'
                  )}
                  type="text"
                  value={releaseNotes}
                  onChange={(e) => {
                    setReleaseNotes(e.target.value);
                  }}
                  data-testid="release-notes"
                />
              </Form.Row>
            </Form.Form>
          </Form>
      </Modal.Body>

      <Modal.Footer>
        <Modal.FooterButtons>
          <Button variant="tertiary" onClick={() => setOpen(false)} data-testid="promote-cancel">
            {I18n.t('configurationsTab.promoteConfirmationModal.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              promoteManifest(appVersionId, releaseNotes);
            }}
            data-testid="promote-confirm"
          >
            {I18n.t('configurationsTab.promoteConfirmationModal.confirm')}
          </Button>
        </Modal.FooterButtons>
      </Modal.Footer>
    </Modal>
  );
};

PromoteConfirmationModal.propTypes = {
  open: PT.bool.isRequired,
  setOpen: PT.func.isRequired,
  semanticVersion: PT.string,
  promoteManifest: PT.func.isRequired,
  manifest: PT.shape({
    id: PT.string.isRequired,
  }),
};

export default PromoteConfirmationModal;
