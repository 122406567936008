import axios from 'axios';
import { getHeaders, POST_HEADERS } from '@/utils/apiUtil';

const api = {
  createDeveloperApp(data) {
    return axios.post(
      '/api/v1/developer_apps',
      data,
      getHeaders(POST_HEADERS)
    );
  },

  getDeveloperApp(id) {
    return axios.get(
      `/api/v1/developer_apps/${id}`,
      { headers: getHeaders() }
    );
  },
};

export default api;
