import React from 'react';
import compose from 'recompose/compose';
import { I18nProvider, useI18nContext } from '@procore/core-react';
import Metrics from './Metrics';
import MetricsContainer from './MetricsContainer';
import i18n from '@/react/developer_apps/show/i18n';

const WrappedComponent = (props) => {
  const I18n = useI18nContext();
  return <Metrics I18n={I18n} {...props} />;
};

const MetricsWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <WrappedComponent {...props} />
  </I18nProvider>
);

export default compose(MetricsContainer)(MetricsWithI18n);
