import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MultiSelect } from '@procore/core-react';

const SSOSelect = ({
  name,
  label,
  onChange,
  I18n,
  isDisabled,
  FormLabel,
  ssoOptions: baseSSOOptions,
  errors,
  field = null,
}) => {
  const [selectedSSO, setSelectedSSO] = useState(
    field?.map((option) => ({ label: option, id: option }))
  );
  const [ssoOptions, setSSOOptions] = useState(() =>
    baseSSOOptions.map((option) => ({ label: option, id: option }))
  );

  useEffect(() => {
    setSelectedSSO(field?.map((option) => ({ label: option, id: option })));
  }, [field]);

  const handleMultiSelectChange = (selected) => {
    setSelectedSSO(selected);
    
    onChange(selected.map(({ label }) => label));
  };

  return (
    <Form.FieldWrapper
      fieldId={`multi-sso-select-${name}`}
      data-testid={`multi-sso-select-${name}`}
      required
      label={
        <FormLabel
          changeFields={[name]}
          contentI18nTag={label}
          showInfo={false}
        />
      }
      errors={errors}
    >
      <MultiSelect
        data-testid={`multi-sso-select-${name}`}
        disabled={isDisabled}
        block
        onChange={handleMultiSelectChange}
        options={ssoOptions}
        value={selectedSSO}
        qa={{
          option: (option) => `multi-option-${option.id}`,
          token: (option) => `multi-token-${option.id}`,
          tokenClear: (option) => `multi-token-clear-${option.id}`,
        }}
        placeholder={I18n.t('placeholders.marketplaceApp.ssoProviders')}
        error={errors?.length}
      />
    </Form.FieldWrapper>
  );
};

SSOSelect.propTypes = {
  name: PT.string.isRequired,
  label: PT.string.isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  onChange: PT.func.isRequired,
  isDisabled: PT.bool,
  FormLabel: PT.elementType.isRequired,
  ssoOptions: PT.arrayOf(PT.shape({})).isRequired,
  errors: PT.arrayOf(PT.string),
};

export default SSOSelect;
