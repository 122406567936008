import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    admin: 'Admin',
    appId: 'App ID',
    associatedDeveloperApp: 'Associated Developer App',
    environment: 'Environment',
    errors: {
      occurred: 'An error occurred',
    },
    name: 'Name',
    notAvailable: 'N/A',
    oauthApplications: 'OAuth Applications',
    ownerEmail: 'Owner Email',
    ownerName: 'Owner Name',
    search: 'Search'
  },
});
