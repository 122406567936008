import React from 'react';
import PT from 'prop-types';
import { equals } from 'ramda';
import Form from '@/react/shared/form';
import {
  Box,
  Flex,
  FlexList,
  Typography,
  Dropzone,
  useDropzone,
  useI18nContext,
  Grid,
} from '@procore/core-react';
import ChangeIndicator from '../ChangeIndicator';
import DropzoneModalsDocUpload from '@/react/shared/dropzone/DropzoneModalsDocUpload';
import { MARKETPLACE_TABS, generateWistiaLink } from '../constants';

const mediaArraysEqual = (arrOne = [], arrTwo = []) => {
  const sortedUrls = (arr) => arr.map((el) => el.url).sort();
  return equals(sortedUrls(arrOne), sortedUrls(arrTwo));
};

const TabMedia = ({
  I18n,
  isDisabled,
  onFieldChange,
  fields,
  validations,
  publishedFields,
  uploadWistiaVideo,
  setVideos,
  setImages,
  setError,
  loadingValuesMap,
  setLoadingValue,
  isAdminView,
  FormLabel,
}) => {
  const onChange =
    (...fieldPath) =>
    (value) =>
      onFieldChange([MARKETPLACE_TABS.MEDIA, ...fieldPath], value);

  const onDrop = (acceptedFiles) => {
    const preview = URL.createObjectURL(acceptedFiles[0]);
    const uploadedFile = {
      preview: preview,
    };
    onChange('small_logo')(acceptedFiles[0]);
    onChange('small_logo_url')(uploadedFile.preview);
    onChange('remove_small_logo')(false);
  };

  const onScreenshotsDrop = (acceptedFiles) => {
    const pictures = acceptedFiles.map((file) => {
      return {
        file,
        type: 'Picture',
        description: file.name,
        name: file.name,
      };
    });
    onChange('pictures')([...(fields.pictures || []), ...pictures]);
  };

  const onVideosDrop = (acceptedFiles) => {
    setLoadingValue('video', true);
    const requests = acceptedFiles.map((file) => {
      return uploadWistiaVideo(file);
    });
    Promise.all(requests)
      .then((resps) => {
        const newVideos = resps.map((resp) => {
          const { data } = resp;
          return {
            type: 'Video',
            external_url: generateWistiaLink(data.hashed_id),
            original_filename: data.name,
            description: data.name,
            name: data.name,
          };
        });
        onChange('videos')([...(fields.videos || []), ...newVideos]);
        setLoadingValue('video', false);
      })
      .catch((error) => {
        setError(error);
        setLoadingValue('video', false);
      });
  };

  const dropzoneState = useDropzone({
    multiple: false,
    onDrop: onDrop,
    accept: ['image/jpeg', 'image/png'],
  });

  const screenshotsDropzoneState = useDropzone({
    onDrop: onScreenshotsDrop,
    accept: ['image/jpeg', 'image/png'],
  });

  const videosDropzoneState = useDropzone({
    onDrop: onVideosDrop,
    accept: ['video/mp4', 'video/quicktime'],
  });

  const removeFile = () => {
    onChange('small_logo')({});
    onChange('small_logo_url')('');
    onChange('remove_small_logo')(true);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col colWidth={6}>
          <Flex
            data-testid="logo-upload-container"
            marginTop="md"
            marginRight="lg"
            direction="column"
          >
            <Form.FieldWrapper
              fieldId="marketplace-app-logo"
              required
              errors={validations.small_logo_url}
              label={
                <FormLabel
                  changeFields={['small_logo', 'small_logo_url']}
                  contentI18nTag="logo"
                />
              }
            >
              <Box
                data-testid="logo-dropzone"
                paddingTop="sm"
                data-qa="marketplace-app-logo"
                style={{ height: '180px', width: '100%' }}
              >
                <Dropzone {...dropzoneState} disabled={isDisabled} />
              </Box>

              <FlexList data-testid="logo-uploaded-file-container" wrap="wrap">
                {fields.small_logo_url && (
                  <DropzoneModalsDocUpload
                    file={{
                      url: fields.small_logo.preview,
                      type: 'Logo',
                      name: 'Logo',
                    }}
                    removeFile={removeFile}
                  />
                )}
              </FlexList>
            </Form.FieldWrapper>
          </Flex>
        </Grid.Col>
        <Grid.Col colWidth={6}>
          <Flex
            data-testid="screenshots-upload-container"
            marginTop="md"
            direction="column"
          >
            <Form.FieldWrapper
              fieldId="marketplace-app-screenshots"
              required
              errors={validations.pictures}
              label={
                <FormLabel
                  changeFields={['pictures']}
                  contentI18nTag="screenshots"
                />
              }
            >
              <Box
                data-testid="screenshots-dropzone"
                paddingTop="sm"
                data-qa="marketplace-app-screenshots"
                style={{ height: '180px', width: '100%' }}
              >
                <Dropzone {...screenshotsDropzoneState} disabled={isDisabled} />
              </Box>

              <Flex
                data-testid="screenshots-uploaded-file-container"
                wrap="wrap"
              >
                {fields.pictures?.map((file) => (
                  <DropzoneModalsDocUpload
                    key={file.id || file.url}
                    file={file}
                    removeFile={(file) => {
                      const newImages = fields.pictures?.filter((image) => {
                        return image !== file;
                      });
                      if (file.id) {
                        onChange('remove_media_ids')([
                          ...(fields.remove_media_ids || []),
                          file.id,
                        ]);
                      }
                      setImages(newImages);
                    }}
                  />
                ))}
              </Flex>
            </Form.FieldWrapper>
          </Flex>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col colWidth={12}>
          <Flex
            data-testid="videos-upload-container"
            data-qa="marketplace-app-videos"
            marginTop="sm"
            direction="column"
          >
            <Form.FieldWrapper
              fieldId="marketplace-app-videos"
              errors={validations.videos}
              label={
                <FormLabel changeFields={['videos']} contentI18nTag="videos" />
              }
            >
              <Box
                data-testid="videos-dropzone"
                style={{ height: '180px', width: '100%' }}
              >
                <Dropzone {...videosDropzoneState} disabled={isDisabled} />
              </Box>

              <FlexList
                data-testid="videos-uploaded-file-container"
                wrap="wrap"
              >
                {fields.videos?.map((file, index) => (
                  <DropzoneModalsDocUpload
                    file={file}
                    index={index}
                    removeFile={(file) => {
                      const newVideos = fields.videos.filter((video) => {
                        return video !== file;
                      });
                      if (file.id) {
                        onChange('remove_media_ids')([
                          ...(fields.remove_media_ids || []),
                          file.id,
                        ]);
                      }
                      setVideos(newVideos);
                    }}
                  />
                ))}
              </FlexList>
            </Form.FieldWrapper>
          </Flex>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

TabMedia.propTypes = {
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({
    id: PT.string,
    pictures: PT.arrayOf(PT.shape({})),
    videos: PT.arrayOf(PT.shape({})),
    remove_media_ids: PT.arrayOf(PT.string),
    small_logo: PT.shape({
      preview: PT.string,
    }),
    small_logo_url: PT.string,
  }).isRequired,
  publishedFields: PT.shape({
    pictures: PT.shape([]),
    videos: PT.shape([]),
    small_logo_url: PT.string,
  }).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  setError: PT.func.isRequired,
  setImages: PT.func.isRequired,
  setVideos: PT.func.isRequired,
  validations: PT.shape({
    small_logo_url: PT.arrayOf(PT.string),
  }),
  uploadWistiaVideo: PT.func.isRequired,
  loadingValuesMap: PT.shape({
    global: PT.bool,
    logo: PT.bool,
    screenshot: PT.bool,
    video: PT.bool,
  }),
  setLoadingValue: PT.func,
  FormLabel: PT.elementType.isRequired,
};

TabMedia.defaultProps = {
  loadingValuesMap: {
    global: false,
    logo: false,
    screenshot: false,
    video: false,
  },
  validations: {},
  setLoadingValue: () => {},
};

export default TabMedia;
