import React from 'react';
import { Box } from '@procore/core-react';
import PT from 'prop-types';

import Dropdown from './dropdown/Dropdown';

const PopoverTag = ({ title, children }) => (
  <Dropdown
    dropDownDirection="east"
    dropdownTarget={
      <span className="margin-l-xs">
        <p className="popover-tag">{title}</p>
      </span>
    }
  >
    <Box padding="md">{children}</Box>
  </Dropdown>
);

PopoverTag.propTypes = {
  title: PT.string.isRequired,
  children: PT.node.isRequired,
};

export default PopoverTag;
