const DirtyFormDetector = {
  init() {
    const getParentFormSubmitButton = function(node) {
      const parentForm = $(node).closest('form');
      return $(parentForm).find(':submit');
    };

    const formListeners = $('[data-dirty-form-listener]');
    // Attach listeners to all dirty form listener elements
    for (let i = 0; i < formListeners.length; i++) {
      const node = formListeners[i];

      const formSubmit = getParentFormSubmitButton(node);
      if (formSubmit) {
        $(formSubmit).attr('disabled', true);
      }

      $(node).on('change input', function() {
        // A change to a form element has been made, so enable the closest submit button
        const formSubmit = getParentFormSubmitButton(this);
        if (formSubmit) {
          $(formSubmit).attr('disabled', false);
        }
      });
    }
  },
};

module.exports = DirtyFormDetector;
